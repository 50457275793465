import LINK from 'assets/link.svg'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import type { HistoryTXDataProps, HistoryTXProps } from 'types/history'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './TX.module.scss'

const TX = (History: HistoryTXProps): JSX.Element => {
    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)

    const formatAmount = (amount: string, i: number): string => {
        const bigNumberAmount = BigNumber.from(amount)

        return utils.formatUnits(bigNumberAmount, CONFIG.tokenList[History.data[i].token].decimals)
    }

    return (
        <a
            className={styles.wrapper}
            href={explorerUrl.concat(`tx/${History.link}`)}
            target={'_blank'}
            rel="noreferrer"
        >
            <div className={styles.infos}>
                <div className={styles.logos}>
                    {History.data.map((data: HistoryTXDataProps, i: number) => {
                        return (
                            <img
                                key={i}
                                src={CONFIG.tokenList[data.token].imageUrl}
                                style={{
                                    top: `${(10 / History.data.length) * i}px`,
                                    left: `${(10 / History.data.length) * i}px`,
                                }}
                                alt="logo"
                            ></img>
                        )
                    })}
                </div>
                <div className={styles.info}>
                    <div className={styles.tokens}>
                        {History.data.map((data: HistoryTXDataProps, i: number) => {
                            if (i === 0) {
                                return (
                                    <span key={i} className={styles.input}>
                                        {CONFIG.tokenList[data.token].symbol}
                                    </span>
                                )
                            } else if (History.data.length === i + 1) {
                                return (
                                    <span key={i} className={styles.output}>
                                        &nbsp; {'> '}
                                        {CONFIG.tokenList[data.token].symbol}
                                    </span>
                                )
                            } else {
                                return (
                                    <span key={i} className={styles.input}>
                                        &nbsp; {'+ '} {CONFIG.tokenList[data.token].symbol}
                                    </span>
                                )
                            }
                        })}
                    </div>
                    <div className={styles.amounts}>
                        {History.data.map((data: HistoryTXDataProps, i: number) => {
                            if (i === 0) {
                                return (
                                    <span key={i} className={styles.input}>
                                        {History.data.length > 2
                                            ? setFixedNumber(formatAmount(data.amount, i))
                                            : Number(data.amount.toString()).toFixed(4)}{' '}
                                    </span>
                                )
                            } else if (History.data.length === i + 1) {
                                return (
                                    <span key={i} className={styles.output}>
                                        &nbsp; {'> '}
                                        {Number(data.amount.toString()).toFixed(4)}
                                    </span>
                                )
                            } else {
                                return (
                                    <span key={i} className={styles.input}>
                                        &nbsp; {'+ '}{' '}
                                        {History.data.length > 2
                                            ? setFixedNumber(formatAmount(data.amount, i))
                                            : Number(data.amount.toString()).toFixed(4)}
                                    </span>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.link}>
                <img src={LINK} alt="link" />
                <div
                    className={styles.ball}
                    style={{ background: History.reverted === 'REVERTED' ? '#FF5050' : '#38e08c' }}
                ></div>
            </div>
        </a>
    )
}

export { TX }
