import SWAP from 'assets/limit-order-swap.svg'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import {
    setLimitOrderInputTokens,
    setLimitOrderInputValues,
    setLimitOrderOutputToken,
    setLimitOrderOutputValue,
} from 'store/slicers/limitOrder'

import styles from './SwapSeparator.module.scss'

const SwapSeparator = (): JSX.Element => {
    const dispatch = useDispatch()

    const inputToken = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const outputToken = useSelector((state: RootState) => state.limitOrder.outputToken)
    const inputValue = useSelector((state: RootState) => state.limitOrder.inputValues)
    const outputValue = useSelector((state: RootState) => state.limitOrder.outputValue)

    const handleSwap = (): void => {
        // Swap tokens
        dispatch(setLimitOrderInputTokens(outputToken))
        dispatch(setLimitOrderOutputToken(inputToken))

        const inputDecimals = CONFIG.tokenList[inputToken].decimals
        const outputDecimals = CONFIG.tokenList[outputToken].decimals

        const adjustedInputValue = utils.formatUnits(inputValue, outputDecimals)
        const adjustedOutputValue = utils.formatUnits(outputValue, inputDecimals)

        dispatch(setLimitOrderInputValues(BigNumber.from(utils.parseUnits(adjustedOutputValue, inputDecimals))))
        dispatch(setLimitOrderOutputValue(BigNumber.from(utils.parseUnits(adjustedInputValue, outputDecimals))))
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.swapSeparator} onClick={handleSwap}>
                <img src={SWAP} alt="swap" className={styles.icon} />
            </div>
        </div>
    )
}

export { SwapSeparator }
