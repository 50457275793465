import { useAccount, useNetwork, useStarkProfile } from '@starknet-react/core'
import BATCHON from 'assets/batch-off.svg'
import DOOROPEN from 'assets/connectWallet.svg'
import LEADERBOAEDOFF from 'assets/leaderboard-dark.svg'
import LIMITORDEROFF from 'assets/limit-order-dark.svg'
import MOON from 'assets/moon.svg'
import SUN from 'assets/sun.svg'
import SWAPON from 'assets/swap-off.svg'
import { CONFIG } from 'config/config'
import { Turn as Hamburger } from 'hamburger-react'
import { useGetBalances } from 'hooks/useGetBalancesFromAPI'
import type { ModalController } from 'hooks/useModal'
import { useTheme } from 'hooks/useTheme'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IoSettingsOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import type { RootState } from 'store'
import { setIsBatch } from 'store/slicers/batch'
import { setIsLimitOrder } from 'store/slicers/limitOrder'
import { setApproves, setConnectModal } from 'store/slicers/settings'
import { setTokenAmounts } from 'store/slicers/starknet'
import { clsnm } from 'utils/clsnm'
import { parseAddress, parseAddressMobile, parseStarkname } from 'utils/parseAddress'

import styles from './HamburgerMenu.module.scss'

const HamburgerMenu = ({
    settingsModal,
    walletModal,
}: {
    settingsModal: ModalController
    walletModal: ModalController
}): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { account, address } = useAccount()
    const { theme, toggleTheme } = useTheme()
    const { chain } = useNetwork()
    const { getBalances } = useGetBalances()

    const [door, setDoor] = useState<boolean>(false)
    const [isOpen, setOpen] = useState<boolean>(false)
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)
    const [batchHover, setBatchHover] = useState<boolean>(false)
    const [arenaHover, setArenaHover] = useState<boolean>(false)
    const [limitOrderHover, setLimitOrderHover] = useState<boolean>(false)

    const modalRef = useRef<HTMLDivElement | null>(null)

    const isBatchSwap = useSelector((state: RootState) => state.batch.isBatch)
    const domain = useSelector((state: RootState) => state.starknet.domain)

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    const isArena = location.pathname === '/arena'
    const isLimitOrder = location.pathname === '/limit-order'

    const { data, isError } = useStarkProfile({ address })

    const handleClickOutside = (event: MouseEvent): void => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        let timer: NodeJS.Timeout
        if (isOpen) {
            timer = setTimeout(() => {
                document.addEventListener('click', handleClickOutside)
            }, 100)
        } else {
            document.removeEventListener('click', handleClickOutside)
        }

        return () => {
            clearTimeout(timer)
            document.removeEventListener('click', handleClickOutside)
        }
    }, [isOpen])

    useEffect(() => {
        if (account) {
            getBalances(false)
            if (chain.testnet) {
                toast.error(`${CONFIG.toast_network_error_message}`)
            }
        } else {
            dispatch(setTokenAmounts(new Array(CONFIG.tokenList.length).fill(0)))
            dispatch(setApproves(new Array(CONFIG.tokenList.length).fill(false)))
        }
    }, [account, address])

    useEffect(() => {
        if (isLimitOrder) {
            dispatch(setIsLimitOrder(true))
        } else {
            dispatch(setIsLimitOrder(false))
        }
    }, [isLimitOrder])

    return (
        <div className={styles.wrapper}>
            <div className={styles.hamburger}>
                <Hamburger toggled={isOpen} toggle={setOpen} color={'#00ADB5'} duration={0.5} />
            </div>
            {isOpen && (
                <div className={styles.module} ref={modalRef}>
                    <div className={styles.buttons}>
                        <div
                            onMouseOver={(): void => setDoor(true)}
                            onMouseOut={(): void => setDoor(false)}
                            onClick={(): void => {
                                if (!account) {
                                    dispatch(setConnectModal(true))
                                } else {
                                    walletModal.open()
                                }
                            }}
                            className={account ? clsnm(styles.connectWallet, styles.connected) : styles.connectWallet}
                        >
                            <div className={account ? clsnm(styles.text, styles.connected) : styles.text}>
                                {!account ? (
                                    <>
                                        <span>CONNECT WALLET</span>
                                        <img src={DOOROPEN} alt="icon" className={styles.door} />
                                    </>
                                ) : isMobile ? (
                                    <>
                                        {!door && domain ? (
                                            <img
                                                src={
                                                    !isError
                                                        ? data?.profilePicture
                                                        : `https://${data?.profilePicture}.ipfs.w3s.link`
                                                }
                                                alt="icon"
                                                className={styles.profilePicture}
                                            />
                                        ) : null}
                                        <span>
                                            {!door && address
                                                ? domain
                                                    ? parseStarkname(domain)
                                                    : parseAddressMobile(address)
                                                : 'WALLET'}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span>
                                            {!door && address
                                                ? domain
                                                    ? parseStarkname(domain)
                                                    : parseAddress(address)
                                                : 'WALLET'}
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className={arenaHover ? clsnm(styles.settingsButton, styles.settings) : styles.settingsButton}
                        onClick={(): void => {
                            navigate('/arena')
                        }}
                        onMouseOver={(): void => setArenaHover(true)}
                        onMouseOut={(): void => setArenaHover(false)}
                    >
                        <div className={styles.batchLogo}>
                            <img src={LEADERBOAEDOFF} alt="logo"></img>
                        </div>
                        <div className={styles.name}>ARENA</div>
                    </div>

                    <div
                        className={batchHover ? clsnm(styles.settingsButton, styles.settings) : styles.settingsButton}
                        onClick={(): void => {
                            if (isArena || isLimitOrder) {
                                navigate('/')
                            } else {
                                dispatch(setIsBatch(!isBatchSwap))
                            }
                        }}
                        onMouseOver={(): void => setBatchHover(true)}
                        onMouseOut={(): void => setBatchHover(false)}
                    >
                        <div className={styles.batchLogo}>
                            <img src={isBatchSwap ? BATCHON : SWAPON} alt="logo"></img>
                        </div>
                        <div className={styles.name}>
                            {isArena || isLimitOrder
                                ? isBatchSwap
                                    ? 'BATCH SWAP'
                                    : 'SWAP'
                                : isBatchSwap
                                  ? 'SWAP'
                                  : 'BATCH SWAP'}
                        </div>
                    </div>

                    <div
                        className={
                            limitOrderHover ? clsnm(styles.settingsButton, styles.settings) : styles.settingsButton
                        }
                        onClick={(): void => {
                            navigate('/limit-order')
                        }}
                        onMouseOver={(): void => setLimitOrderHover(true)}
                        onMouseOut={(): void => setLimitOrderHover(false)}
                    >
                        <div className={styles.batchLogo}>
                            <img src={LIMITORDEROFF} alt="logo"></img>
                        </div>
                        <div className={styles.name}>LIMIT ORDER (BETA)</div>
                    </div>

                    <div
                        className={
                            isSettingsOpen ? clsnm(styles.settingsButton, styles.settings) : styles.settingsButton
                        }
                        onClick={(): void => settingsModal.open()}
                        onMouseOver={(): void => setIsSettingsOpen(true)}
                        onMouseOut={(): void => setIsSettingsOpen(false)}
                    >
                        <IoSettingsOutline size={24} />
                        <div className={styles.name}>SETTINGS</div>
                    </div>

                    <div
                        className={styles.themeButton}
                        onClick={(): void => {
                            toggleTheme()
                        }}
                    >
                        {theme === 'light' ? <img src={MOON} alt="icon" /> : <img src={SUN} alt="icon" />}
                    </div>
                </div>
            )}
        </div>
    )
}

export { HamburgerMenu }
