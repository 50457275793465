import { useAccount, useProvider } from '@starknet-react/core'
import { CONFIG } from 'config/config'
import { STARKNET_CONTRACTS } from 'constants/addresses'
import { BigNumber, utils } from 'ethers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setIsApprove } from 'store/slicers/limitOrder'

const formatTokenAmount = (rawAmount: string, decimals: number): string => {
    const amountBN = BigInt(rawAmount)
    const divisor = BigInt(10 ** decimals)
    const integerPart = amountBN / divisor
    const decimalPart = amountBN % divisor

    const decimalString = decimalPart.toString().padStart(decimals, '0')

    if (decimalPart > 0) {
        const formattedDecimal = parseFloat(`0.${decimalString}`).toFixed(6)
        const trimmedDecimal = parseFloat(formattedDecimal).toString()
        if (trimmedDecimal === '0') {
            return integerPart.toString()
        }
        return `${integerPart}.${trimmedDecimal.split('.')[1]}`
    } else {
        return integerPart.toString()
    }
}

export const useGetApprove = (): {
    getApprove: () => Promise<void>
} => {
    const dispatch = useDispatch()
    const { address } = useAccount()
    const { provider } = useProvider()

    const [approveValue, setApproveValue] = useState<string>('')

    const inputToken = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const inputValue = useSelector((state: RootState) => state.limitOrder.inputValues)

    const inputValueFormatted = utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals)

    useEffect(() => {
        getApprove()

        if (Number(approveValue) >= Number(inputValueFormatted)) {
            dispatch(setIsApprove(true))
        } else {
            dispatch(setIsApprove(false))
        }
    }, [approveValue, inputValueFormatted])

    const getApprove = async (): Promise<void> => {
        if (!address) {
            return
        } else {
            try {
                const approveTX = await provider.callContract({
                    contractAddress: CONFIG.tokenList[inputToken].address,
                    entrypoint: 'allowance',
                    calldata: [address.toLocaleLowerCase(), STARKNET_CONTRACTS.LIMIT_ORDER_ADDRESS],
                })

                const approve = utils.formatUnits(BigNumber.from(approveTX.result[0]).toString(), 0)
                const formattedApprove = formatTokenAmount(approve, parseInt(CONFIG.tokenList[inputToken].decimals))

                setApproveValue(formattedApprove)
            } catch (error) {
                console.error('Error getting approve:', error)
            }
        }
    }

    return {
        getApprove,
    }
}
