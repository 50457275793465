import { useSelector } from 'react-redux'
import type { RootState } from 'store'

import styles from './FailedV2.module.scss'

const FailedV2 = (): JSX.Element => {
    const isCanceled = useSelector((state: RootState) => state.limitOrder.isCanceled)
    const isClear = useSelector((state: RootState) => state.limitOrder.isClear)

    return (
        <div className={styles.wrapper}>
            {isCanceled ? (
                <div className={styles.info}>
                    {isClear ? (
                        <span>Limit Orders Clearing Failed.</span>
                    ) : (
                        <span>Limit Order Cancellation Failed.</span>
                    )}
                </div>
            ) : (
                <div className={styles.info}>Limit Order Failed.</div>
            )}
            {/* <div className={styles.tx}>
                {isValidStarknetAddressOrDomain(pendingPayload) ? (
                    <a
                        target={'_blank'}
                        rel="noreferrer"
                        className={styles.tx}
                        href={explorerUrl.concat(`tx/${pendingPayload}`)}
                    >
                        <div className={styles.urlWrapper}>
                            <img src={COPY} alt="copy"></img>
                            <span className={styles.url}>Tx: {parseAddress(pendingPayload)}</span>
                        </div>
                    </a>
                ) : (
                    pendingPayload
                )}
            </div> */}
        </div>
    )
}

export { FailedV2 }
