import SEARCH from 'assets/search.svg'
import React from 'react'

import styles from './SearchBox.module.scss'

type SearchBoxProps = {
    inputValue: string
    setInputValue: (value: string) => void
    handleSearchSubmit: () => Promise<void>
}

const SearchBox: React.FC<SearchBoxProps> = ({ inputValue, setInputValue, handleSearchSubmit }) => {
    return (
        <div className={styles.searchBox}>
            <input
                className={styles.input}
                autoComplete="off"
                autoCorrect="off"
                type="text"
                inputMode="text"
                pattern="^[0-9]*[.,]?[0-9]*$"
                maxLength={79}
                spellCheck="false"
                value={inputValue}
                placeholder="Enter Starknet Address or StarknetID"
                onChange={(e): void => {
                    const newValue = e.target.value.replace(/\s+/g, '').toLowerCase()
                    setInputValue(newValue)
                }}
                onKeyUp={(e): void => {
                    if (e.key === 'Enter') {
                        handleSearchSubmit()
                    }
                }}
            />
            <div className={styles.icon} onClick={handleSearchSubmit}>
                <img src={SEARCH} alt="search icon"></img>
            </div>
        </div>
    )
}

export default SearchBox
