import type { PathInfo, RouterResponse } from 'types/route'

const justRoute = (
    Router: RouterResponse,
    index: number,
): {
    pathPart: Array<string>
    pathInfoPart: PathInfo
} => {
    const path: Array<string> = []
    const pathInfo: PathInfo = {}

    path.push(Router.inputToken.address)
    path.push(Router.outputToken.address)
    const EP = Router.route[index].swaps
    for (let i = 0; i < EP.length; i++) {
        for (let u = 0; u < EP[i].length; u++) {
            const item: string = EP[i][u].toTokenAddress
            if (pathInfo[item] === undefined) {
                pathInfo[item] = {
                    protocols: [],
                    percents: [],
                }
            }
            pathInfo[item].protocols.push(EP[i][u].protocol)
            pathInfo[item].percents.push(EP[i][u].percent)

            if (!path.includes(EP[i][u].toTokenAddress)) {
                const index = path.indexOf(EP[i][u].fromTokenAddress)
                path.splice(index + 1, 0, EP[i][u].toTokenAddress)
            }
        }
    }
    return {
        pathPart: path,
        pathInfoPart: pathInfo,
    }
}

export const setRouterData = (
    Router: RouterResponse,
): {
    path: Array<Array<string>>
    pathInfo: Array<Array<PathInfo>>
} => {
    const path: Array<Array<string>> = []
    const pathInfo: Array<Array<PathInfo>> = []

    for (let i = 0; i < Router.route.length; i++) {
        const { pathPart, pathInfoPart } = justRoute(Router, i)
        const emptyArray: Array<PathInfo> = []

        path.push(pathPart)
        emptyArray.push(pathInfoPart)
        pathInfo.push(emptyArray)
    }

    return {
        path: path,
        pathInfo: pathInfo,
    }
}
