export const setFixedNumber = (value: string, des = 4): string => {
    const dotIndex: number = value.indexOf('.')

    if (dotIndex === -1) {
        return Number(value).toFixed(0)
    } else if (Number(value) === 0) {
        return '0'
    } else {
        for (let i = 0; i <= des; i++) {
            if (value[dotIndex + (des - i)] === '0' || value[dotIndex + (des - i)] === undefined) {
                continue
            } else {
                return Number(value).toFixed(des - i)
            }
        }
    }

    return Number(value).toFixed(des)
}
