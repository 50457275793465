import { useAccount } from '@starknet-react/core'
import WALLET from 'assets/blue-wallet.svg'
import ARROW from 'assets/down-arrow2.svg'
import { SelectToken } from 'components/SelectToken/SelectToken'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useGetBalances } from 'hooks/useGetBalancesFromAPI'
import { useModal } from 'hooks/useModal'
import { useEffect, useState } from 'react'
import type { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setIsLimitOrderWaiting, setIsOutputFocused, setLimitOrderOutputValue, setRate } from 'store/slicers/limitOrder'
import { WAITING } from 'types/route'
import { useDebounce } from 'use-debounce'
import { formatValue } from 'utils/formatValue'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './Output.module.scss'

const Output = (): JSX.Element => {
    const dispatch = useDispatch()
    const { account } = useAccount()

    const { getBalances } = useGetBalances()
    const selectInputTokenModal = useModal()

    const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const inputToken = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const inputValue = useSelector((state: RootState) => state.limitOrder.inputValues)
    const outputToken = useSelector((state: RootState) => state.limitOrder.outputToken)
    const outputValue = useSelector((state: RootState) => state.limitOrder.outputValue)
    const rate = useSelector((state: RootState) => state.limitOrder.rate)

    const [inputValueState, setInputValueState] = useState<string>('0')
    const [isFocus, setIsFocus] = useState<boolean>(false)
    const [debouncedValue] = useDebounce(outputValue, 500)

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let result = event.target.value
            .replace(/,/g, '. ')
            .replace(/[^.\d]/g, '')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')

        if (Number(result) >= 1) {
            result = result.replace(/^0+/, '')
        }

        if (result.startsWith('.')) {
            result = '0' + result
        }

        dispatch(setIsLimitOrderWaiting(WAITING.PENDING))

        if (result !== '' && !isNaN(Number(result))) {
            const parsedResult = BigNumber.from(utils.parseUnits(result, CONFIG.tokenList[outputToken].decimals))
            dispatch(setLimitOrderOutputValue(parsedResult))
        }
        setInputValueState(result)
    }

    const safelyParseUnits = (value: string, decimals: number): BigNumber | null => {
        try {
            const fixedValue = Number(value).toFixed(decimals)
            return BigNumber.from(utils.parseUnits(fixedValue, decimals))
        } catch (error) {
            console.error('Error parsing units:', error)
            return null
        }
    }

    useEffect(() => {
        setInputValueState(setFixedNumber(utils.formatUnits(outputValue, CONFIG.tokenList[outputToken].decimals), 6))
    }, [debouncedValue, outputToken, inputToken])

    useEffect(() => {
        const inputValueFormatted = utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals)
        const rateValueFormatted = utils.formatUnits(rate, CONFIG.tokenList[outputToken].decimals)

        const value = Number(rateValueFormatted) * Number(inputValueFormatted)
        const valueFormatted = setFixedNumber(value.toString(), 6)
        if (!isFocus) {
            if (value === 0) {
                setInputValueState('0')
            } else {
                dispatch(
                    setLimitOrderOutputValue(
                        BigNumber.from(utils.parseUnits(valueFormatted, CONFIG.tokenList[outputToken].decimals)),
                    ),
                )
                setInputValueState(valueFormatted.toString())
            }
        }
    }, [rate, outputToken, inputToken, inputValue])

    useEffect(() => {
        if (inputValueState.trim() === '') {
            dispatch(setLimitOrderOutputValue(BigNumber.from(0)))
        } else if (isFocus && inputValueState !== '0') {
            const inputValueFormatted = utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals)
            const parsedValue = !isNaN(Number(inputValueState)) ? inputValueState : '0'

            if (Number(inputValueFormatted) !== 0) {
                const rateCalc = Number(parsedValue) / Number(inputValueFormatted)
                const rateBigNumber = safelyParseUnits(
                    rateCalc.toString(),
                    Number(CONFIG.tokenList[outputToken].decimals),
                )

                if (rateBigNumber) {
                    dispatch(setRate(rateBigNumber))
                } else {
                    console.error('Failed to set rate: Invalid number')
                }
            }
        }
    }, [inputValueState])

    return (
        <>
            <SelectToken type="limitOrderOutput" modal={selectInputTokenModal} />
            <div className={styles.wrapper}>
                <div className={styles.input}>
                    <div
                        className={styles.inputToken}
                        onClick={(): void => {
                            selectInputTokenModal.open()
                            getBalances(true)
                        }}
                    >
                        <div className={styles.hover}>
                            <img
                                src={CONFIG.tokenList[outputToken].imageUrl}
                                height={isMobile ? 23 : 24}
                                width={isMobile ? 23 : 24}
                                alt="logo"
                            />
                            <div className={styles.name}>{CONFIG.tokenList[outputToken].symbol}</div>
                            <img src={ARROW} alt="arrow" className={styles.arrow} />
                        </div>
                    </div>
                    <div className={styles.swapInput}>
                        <input
                            className={styles.swapInput}
                            autoComplete="off"
                            autoCorrect="off"
                            type="text"
                            inputMode="decimal"
                            pattern="^[0-9]*[.,]?[0-9]*$"
                            minLength={1}
                            maxLength={79}
                            spellCheck="false"
                            placeholder={'0.0'}
                            value={inputValueState}
                            onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                handleChange(value)
                            }}
                            onFocus={(): void => {
                                if (Number(inputValueState) === 0) {
                                    setInputValueState('')
                                }
                                setIsFocus(true)
                                dispatch(setIsOutputFocused(true))
                            }}
                            onBlur={(): void => {
                                if (inputValueState === '') {
                                    setInputValueState('0')
                                }
                                setIsFocus(false)
                                dispatch(setIsOutputFocused(false))
                            }}
                        />
                    </div>

                    <div className={styles.balance}>
                        <div
                            className={styles.wallet}
                            onClick={(): void => {
                                if (!account) return
                                dispatch(setLimitOrderOutputValue(BigNumber.from(tokenAmounts[outputToken])))
                                setInputValueState(
                                    setFixedNumber(
                                        utils.formatUnits(
                                            tokenAmounts[outputToken],
                                            CONFIG.tokenList[outputToken].decimals,
                                        ),
                                        6,
                                    ),
                                )
                            }}
                        >
                            <img src={WALLET} alt="logo"></img>
                            {formatValue(
                                setFixedNumber(
                                    utils.formatUnits(
                                        tokenAmounts[outputToken],
                                        CONFIG.tokenList[outputToken].decimals,
                                    ),
                                    4,
                                ),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Output }
