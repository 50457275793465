import { useAccount } from '@starknet-react/core'
import DARKWALLET from 'assets/dark-wallet2.svg'
import WHITEWALLET from 'assets/white-wallet2.svg'
import { CONFIG } from 'config/config'
import { BigNumber, ethers, utils } from 'ethers'
import { useGetPath } from 'hooks/useGetPath'
import { useTheme } from 'hooks/useTheme'
import { useEffect, useRef, useState } from 'react'
import type { ChangeEvent } from 'react'
import ReactLoading from 'react-loading'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setInputValue } from 'store/slicers/input'
import { setOutputValue } from 'store/slicers/output'
import { setWaiting } from 'store/slicers/route'
import { setReset } from 'store/slicers/settings'
import { WAITING } from 'types/route'
import { useDebounce } from 'use-debounce'
import { clsnm } from 'utils/clsnm'
import { formatValue } from 'utils/formatValue'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './InputBar.module.scss'

const InputBar = (): JSX.Element => {
    const dispatch = useDispatch()
    const { getPath } = useGetPath()
    const { account } = useAccount()
    const { theme } = useTheme()

    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const isReset = useSelector((state: RootState) => state.settings.reset)
    const inputToken = useSelector((state: RootState) => state.input.token)
    const inputValue = useSelector((state: RootState) => state.input.value)
    const outputToken = useSelector((state: RootState) => state.output.token)
    const outputValue = useSelector((state: RootState) => state.output.value)
    const isRouterWait = useSelector((state: RootState) => state.route.waiting)
    const liquiditySources = useSelector((state: RootState) => state.settings.liquiditySources)

    const [inputValueState, setInputValueState] = useState<string>('0')
    const [isInputFocus, setIsInputFocus] = useState<boolean>(false)
    const [range, setRange] = useState<number>(0)
    const [previousTokens, setPreviousTokens] = useState<{
        input: number
        output: number
    }>({ input: 0, output: 1 })

    const [debouncedValue] = useDebounce(inputValue, 750)
    const isPositiveInput = BigNumber.from(inputValue).gt(0)

    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let result = event.target.value
            .replace(/,/g, '. ')
            .replace(/[^.\d]/g, '')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')

        if (Number(result) >= 1) {
            result = result.replace(/^0+/, '')
        }

        if (result.startsWith('.')) {
            result = '0' + result
        }

        if (result !== inputValueState) {
            if (result === '') {
                dispatch(setInputValue(BigNumber.from(0)))
                setRange(0)
            } else {
                dispatch(
                    setInputValue(utils.parseUnits(Number(result).toString(), CONFIG.tokenList[inputToken].decimals)),
                )

                let rangeValue = BigNumber.from(
                    utils.parseUnits(Number(result).toString(), CONFIG.tokenList[inputToken].decimals),
                )
                    .mul(100)
                    .div(BigNumber.from(tokenAmounts[inputToken]).gt(0) ? BigNumber.from(tokenAmounts[inputToken]) : 1)
                if (rangeValue.gt(100)) {
                    rangeValue = BigNumber.from(100)
                } else if (rangeValue.lt(0)) {
                    rangeValue = BigNumber.from(0)
                }
                setRange(rangeValue.toNumber())
            }
        }
        setInputValueState(result)
    }

    const handleRangeBar = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        const result = event.target.value.replace('%', '')
        const fullAmount = utils.formatUnits(tokenAmounts[inputToken], CONFIG.tokenList[inputToken].decimals)
        if (!account) {
            setRange(100)
            dispatch(setInputValue(BigNumber.from(tokenAmounts[inputToken])))
            setInputValueState(setFixedNumber(fullAmount))
        }

        if (Number(result) >= 100) {
            setRange(100)
            dispatch(setInputValue(BigNumber.from(tokenAmounts[inputToken])))
            setInputValueState(setFixedNumber(fullAmount))
        } else if (Number(result) <= 0) {
            setRange(0)
            dispatch(setInputValue(BigNumber.from(0)))
            setInputValueState('0')
        } else {
            setRange(Number(result))
            const final = utils.formatUnits(
                BigNumber.from(tokenAmounts[inputToken]).mul(result).div(100).toString(),
                CONFIG.tokenList[inputToken].decimals,
            )
            dispatch(setInputValue(BigNumber.from(tokenAmounts[inputToken]).mul(result).div(100)))
            setInputValueState(setFixedNumber(final))
        }
    }

    const handlePercentageClick = (percentage: number): void => {
        const newValue = BigNumber.from(tokenAmounts[inputToken]).mul(percentage).div(100)

        dispatch(setInputValue(newValue))
        setInputValueState(setFixedNumber(utils.formatUnits(newValue, CONFIG.tokenList[inputToken].decimals)))
        setRange(percentage)
    }

    useEffect(() => {
        dispatch(setWaiting(isPositiveInput ? WAITING.PENDING : WAITING.TRUE))

        if (!isPositiveInput) {
            dispatch(setOutputValue(BigNumber.from(0)))
            if (isReset) {
                dispatch(setReset(false))
                setInputValueState('0')
            }
        }

        const scheduleGetPath = (): void => {
            getPath().finally(() => {
                if (timeoutRef.current) clearTimeout(timeoutRef.current)
                timeoutRef.current = setTimeout(scheduleGetPath, 10000)
            })
        }

        if (isPositiveInput) scheduleGetPath()

        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
        }
    }, [debouncedValue, liquiditySources, inputToken, outputToken])

    useEffect(() => {
        if (previousTokens.input !== inputToken && previousTokens.output !== outputToken) {
            setInputValueState(utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals))
        }

        setPreviousTokens({ input: inputToken, output: outputToken })
    }, [inputToken, outputToken])

    useEffect(() => {
        if (isReset) {
            dispatch(setReset(false))
        }
    }, [inputValueState])

    useEffect(() => {
        setInputValueState('0')
        dispatch(setInputValue(BigNumber.from(0)))
        setRange(0)
    }, [inputToken, dispatch])

    return (
        <>
            <div className={styles.values}>
                <div
                    className={isInputFocus ? clsnm(styles.input, styles.focusInput) : styles.input}
                    style={{ marginRight: '5px' }}
                >
                    <div className={styles.range}>
                        <div className={isInputFocus ? clsnm(styles.border, styles.borderFocus) : styles.border}>
                            <div
                                className={styles.bg}
                                style={{
                                    width: `${(range * (isMobile ? 238 : 126)) / 100}px`,
                                }}
                            ></div>
                            <div className={styles.bg2} style={{ width: `${isMobile ? 238 : 130}px` }}>
                                <div className={styles.filler}></div>
                                <button
                                    onClick={(): void => handlePercentageClick(25)}
                                    className={range === 25 ? styles.zIndex : ''}
                                >
                                    <div className={styles.dot}></div>
                                </button>
                                <button
                                    onClick={(): void => handlePercentageClick(50)}
                                    className={range === 50 ? styles.zIndex : ''}
                                >
                                    <div className={styles.dot}></div>
                                </button>
                                <button
                                    onClick={(): void => handlePercentageClick(75)}
                                    className={range === 75 ? styles.zIndex : ''}
                                >
                                    <div className={styles.dot}></div>
                                </button>
                                <div className={styles.filler}></div>
                            </div>
                            <input
                                type="range"
                                onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                    handleRangeBar(value)
                                }}
                                min={0}
                                max={100}
                                step={1}
                                value={range}
                                className={styles.rangeBar}
                            />
                        </div>
                        <div className={isInputFocus ? clsnm(styles.board, styles.boardFocus) : styles.board}>
                            <input
                                type="text"
                                value={`${range}`}
                                onClick={(e): void => (e.target as HTMLInputElement).select()}
                                onChange={(e): void => {
                                    if (!isNaN(Number(e.target.value))) {
                                        handleRangeBar(e)
                                    }
                                }}
                                className={isInputFocus ? clsnm(styles.board, styles.boardFocus) : styles.board}
                            />

                            <div className={styles.percent}>
                                <p>%</p>
                            </div>
                        </div>
                    </div>
                    <div className={isInputFocus ? clsnm(styles.balance, styles.focusBalance) : styles.balance}>
                        <div
                            className={styles.wallet}
                            onClick={(): void => {
                                if (!account) return
                                dispatch(setInputValue(tokenAmounts[inputToken]))
                                setInputValueState(
                                    setFixedNumber(
                                        utils.formatUnits(
                                            tokenAmounts[inputToken],
                                            CONFIG.tokenList[inputToken].decimals,
                                        ),
                                    ),
                                )
                                setRange(100)
                            }}
                        >
                            <img src={theme === 'dark' ? DARKWALLET : WHITEWALLET} alt="icon"></img>
                            {formatValue(
                                setFixedNumber(
                                    utils.formatUnits(tokenAmounts[inputToken], CONFIG.tokenList[inputToken].decimals),
                                ),
                            )}
                        </div>
                    </div>
                    <input
                        className={styles.input}
                        autoComplete="off"
                        autoCorrect="off"
                        type="text"
                        inputMode="decimal"
                        pattern="^[0-9]*[.,]?[0-9]*$"
                        minLength={1}
                        maxLength={79}
                        spellCheck="false"
                        placeholder={'0.0'}
                        value={inputValueState}
                        onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                            handleChange(value)
                        }}
                        onFocus={(): void => {
                            setIsInputFocus(true)
                            if (Number(inputValueState) === 0) {
                                setInputValueState('')
                            }
                        }}
                        onBlur={(): void => {
                            setIsInputFocus(false)
                            if (inputValueState === '') {
                                setInputValueState('0')
                            }
                        }}
                    />
                </div>
                <div className={styles.output}>
                    <div className={styles.balance}>
                        <div className={styles.wallet}>
                            <img src={theme === 'dark' ? DARKWALLET : WHITEWALLET} alt="icon"></img>
                            {formatValue(
                                setFixedNumber(
                                    utils.formatUnits(
                                        tokenAmounts[outputToken],
                                        CONFIG.tokenList[outputToken].decimals,
                                    ),
                                ),
                            )}
                        </div>
                    </div>
                    <div className={styles.value}>
                        {isRouterWait === WAITING.PENDING ? (
                            <ReactLoading type={'spin'} color={'#00ADB5'} height={30} width={30} />
                        ) : isRouterWait === WAITING.TRUE ? (
                            Number(0)
                        ) : Number(inputValue) > 0 ? (
                            formatValue(
                                Number(
                                    ethers.utils.formatUnits(
                                        ethers.BigNumber.from(outputValue),
                                        CONFIG.tokenList[outputToken].decimals,
                                    ),
                                ).toFixed(Number(outputValue) > 0 ? 4 : 0),
                            )
                        ) : (
                            '0'
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export { InputBar }
