import { BigNumber } from 'ethers'
import { uint256 } from 'starknet'

const UINT_128_MAX = BigNumber.from(2).pow(128).sub(2)

// if zeroForOne is false it means that token0 is the input token and div sqrt price limit by x
export function sqrtPriceLimitParse(
    sqrt_price_low: BigNumber,
    sqrt_price_high: BigNumber,
    isZeroForOne: boolean,
): { low: BigNumber; high: BigNumber } {
    const sqrtPriceLowStart = sqrt_price_low
    const sqrtPriceHighStart = sqrt_price_high
    if (!isZeroForOne) {
        if (sqrtPriceLowStart.gt(UINT_128_MAX)) {
            const uintSqrtPrice = uint256.bnToUint256(sqrtPriceLowStart.toString())
            sqrt_price_low = BigNumber.from(uintSqrtPrice.low)
            sqrt_price_high = BigNumber.from(uintSqrtPrice.high).mul(20)
        } else {
            const sqrtPriceLowMulW10 = BigNumber.from(sqrt_price_low).mul(50)
            sqrtPriceLowMulW10.gt(UINT_128_MAX)
                ? (sqrt_price_high = sqrt_price_high.add(BigNumber.from(10).pow(12)))
                : (sqrt_price_low = sqrtPriceLowMulW10)
        }
    } else {
        if (sqrt_price_low.gt(UINT_128_MAX)) {
            sqrt_price_low = UINT_128_MAX.div(10)
            sqrt_price_high = BigNumber.from(0)
        } else {
            sqrt_price_low = BigNumber.from(sqrt_price_low).div(50)
            sqrt_price_high = BigNumber.from(0)
        }
    }
    return {
        low: sqrt_price_low,
        high: sqrt_price_high,
    }
}
