import BATCHOFF from 'assets/batch-off.svg'
import BATCHON from 'assets/batch-on.svg'
import LEADERBOAEDOFF from 'assets/leaderboard-dark.svg'
import LEADERBOAEDON from 'assets/leaderboard.svg'
import LIMITORDEROFF from 'assets/limit-order-dark.svg'
import LIMITORDERON from 'assets/limit-order.svg'
import SWAPOFF from 'assets/swap-off.svg'
import SWAPON from 'assets/swap-on.svg'
import type { ModalController } from 'hooks/useModal'
import { useEffect, useState } from 'react'
import { IoSettingsOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
// import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'
import type { RootState } from 'store'
import { setIsBatch } from 'store/slicers/batch'
import { setIsLimitOrder } from 'store/slicers/limitOrder'
import { clsnm } from 'utils/clsnm'

import styles from './Navigator.module.scss'

const Navigator = ({ settingsModal }: { settingsModal: ModalController }): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const isBatchSwap = useSelector((state: RootState) => state.batch.isBatch)
    const [batchHover, setBatchHover] = useState<boolean>(false)
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)
    const [arenaHover, setArenaHover] = useState<boolean>(false)
    const [limitOrderHover, setLimitOrderHover] = useState<boolean>(false)

    // const isSwap = location.pathname === '/'
    const isArena = location.pathname === '/arena'
    const isLimitOrder = location.pathname === '/limit-order'

    // const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

    const getImageSource = (): string => {
        if (isArena || isLimitOrder) {
            if (batchHover) return isBatchSwap ? BATCHON : SWAPON
            return isBatchSwap ? BATCHOFF : SWAPOFF
        }

        return batchHover ? (isBatchSwap ? SWAPON : BATCHON) : isBatchSwap ? SWAPOFF : BATCHOFF
    }

    const getName = (): string => {
        if (isArena || isLimitOrder) {
            return isBatchSwap ? 'Batch' : 'Swap'
        }
        return isBatchSwap ? 'Swap' : 'Batch'
    }

    useEffect(() => {
        if (isLimitOrder) {
            dispatch(setIsLimitOrder(true))
        } else {
            dispatch(setIsLimitOrder(false))
        }
    }, [isLimitOrder])

    return (
        <div className={styles.wrapper}>
            <div
                className={clsnm(
                    styles.button,
                    !isArena && styles.arenaButton,
                    isArena && styles.settings,
                    arenaHover && styles.settings,
                )}
                onClick={(): void => {
                    navigate('/arena')
                }}
                onMouseOver={(): void => setArenaHover(true)}
                onMouseOut={(): void => setArenaHover(false)}
            >
                <img
                    className={styles.icon}
                    src={isArena ? LEADERBOAEDON : arenaHover ? LEADERBOAEDON : LEADERBOAEDOFF}
                    alt="logo"
                ></img>
                <span className={styles.name}>Arena</span>
            </div>
            <div
                className={batchHover ? clsnm(styles.button, styles.settings) : styles.button}
                onClick={(): void => {
                    if (isArena || isLimitOrder) {
                        navigate('/')
                    } else {
                        dispatch(setIsBatch(!isBatchSwap))
                    }
                }}
                onMouseOver={(): void => setBatchHover(true)}
                onMouseOut={(): void => setBatchHover(false)}
            >
                <img className={styles.icon} src={getImageSource()} alt="logo"></img>
                <span className={styles.name}>{getName()}</span>
            </div>

            <div
                className={clsnm(
                    styles.button,
                    isLimitOrder && styles.settings,
                    limitOrderHover && styles.settings,

                    // isMobile ? styles.settings : isSwap && styles.showerButton,
                )}
                onClick={(): void => {
                    navigate('/limit-order')
                }}
                onMouseOver={(): void => setLimitOrderHover(true)}
                onMouseOut={(): void => setLimitOrderHover(false)}
            >
                <img
                    className={styles.icon}
                    src={isLimitOrder ? LIMITORDERON : limitOrderHover ? LIMITORDERON : LIMITORDEROFF}
                    alt="logo"
                ></img>
                <span className={styles.name}>
                    Limit&nbsp;Order
                    {/* <span className={styles.name}>(Beta)</span> */}
                </span>
            </div>
            <div
                className={isSettingsOpen ? clsnm(styles.button, styles.settings) : styles.button}
                onClick={(): void => {
                    settingsModal.open()
                }}
                onMouseOver={(): void => setIsSettingsOpen(true)}
                onMouseOut={(): void => setIsSettingsOpen(false)}
            >
                <IoSettingsOutline className={styles.icon} size={24} />
                <span className={styles.name}>Settings</span>
            </div>
        </div>
    )
}
export { Navigator }
