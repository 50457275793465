import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

export type ModalController = {
    isOpen: boolean
    open: () => void
    close: () => void
}

/**
 * @dev Hook used to control modal state
 */
export const useModalWithState = (
    isOpen: boolean,

    stateFunction: any,
): ModalController => {
    const dispatch = useDispatch()

    const open = useCallback(() => dispatch(stateFunction(true)), [])

    const close = useCallback(() => dispatch(stateFunction(false)), [])

    const modal = useMemo(() => ({ isOpen, open, close }), [isOpen, open, close])

    return modal
}
