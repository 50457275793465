import { CONFIG } from 'config/config'

type OrderFilter = {
    wallet_address?: string
    maker_asset?: string
    taker_asset?: string
    maker_amount?: string
    taker_amount?: string
    order_price?: string
    page?: number // default 1
    pageSize?: number // default 10
}

export const getUserOrderHistory = async (wallet_address: string) => {
    const params = {
        wallet_address: wallet_address,
    }
    const url = new URL(`${CONFIG.limit_order_api}order/history`)
    url.searchParams.append('wallet_address', params.wallet_address)
    url.searchParams.append('pageSize', '100')
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json;charset=UTF-8',
        },
    })
    const data = await response.json()
    return data
}
