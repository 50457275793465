/* eslint-disable  */
import { useEffect, useRef } from 'react'

export function useOnClickOutside<T extends HTMLElement>(
    handler: Function,
    elements?: any,
): React.MutableRefObject<T | null> {
    const ref = useRef<T | null>(null)

    useEffect(() => {
        const listener = (event: any) => {
            if (
                (!ref.current || ref.current.contains(event.target)) &&
                (!elements || elements.filter((el: any) => el?.contains?.(event.target)).length > 0)
            ) {
                return
            }

            handler(event)
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [handler, elements])

    return ref
}
