import { ArenaPage, LimitOrderPage, SwapPage } from 'Pages'
import { PATHS } from 'constants/paths'
import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { useInitialTheme } from './hooks/useInitialTheme'

function App(): JSX.Element {
    useInitialTheme()

    useEffect(() => {
        console.log('Welcome to Fibrous Finance!')
    }, [])

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path={PATHS.home} element={<SwapPage />} />
                    <Route path={PATHS.arena} element={<ArenaPage />} />
                    <Route path={PATHS.limitOrder} element={<LimitOrderPage />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App
