import { useAccount } from '@starknet-react/core'
import QUIT from 'assets/quitV2.svg'
import WHITEWALLET from 'assets/white-wallet.svg'
import { SelectToken } from 'components/SelectToken/SelectToken'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useGetBalances } from 'hooks/useGetBalancesFromAPI'
import { useGetPath } from 'hooks/useGetPath'
import { useModal } from 'hooks/useModal'
import { useSetBatchValues } from 'hooks/useSetBatchValues'
import { useEffect, useState } from 'react'
import type { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { removeBatchInput, setIsBatchWaiting } from 'store/slicers/batch'
import { WAITING } from 'types/route'
import { useDebounce } from 'use-debounce'
import { clsnm } from 'utils/clsnm'
import { formatValue } from 'utils/formatValue'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './Input.module.scss'

const Input = ({ inputKey }: { inputKey: number }): JSX.Element => {
    const dispatch = useDispatch()
    const { account } = useAccount()

    const { getBatchPathOne } = useGetPath()
    const { getBalances } = useGetBalances()
    const { setBatchInputValue } = useSetBatchValues()
    const selectInputTokenModal = useModal()

    const isMobile = useMediaQuery({
        query: '(max-width: 650px)',
    })

    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const batchOutputToken = useSelector((state: RootState) => state.batch.outputToken)
    const batchInputTokens = useSelector((state: RootState) => state.batch.inputTokens)
    const batchInputValues = useSelector((state: RootState) => state.batch.inputValues)
    const routeResult = useSelector((state: RootState) => state.batch.routerResponses)

    const [inputValueState, setInputValueState] = useState<string>('0')
    const [isInputFocus, setIsInputFocus] = useState<boolean>(false)
    const [isQuitFocus, setIsQuitFocus] = useState<boolean>(false)
    const [, setIsQuit] = useState<boolean>(false)
    const [range, setRange] = useState<number>(0)
    const [debouncedValue] = useDebounce(batchInputValues[inputKey], 500)

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let result = event.target.value
            .replace(/,/g, '. ')
            .replace(/[^.\d]/g, '')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')

        if (Number(result) >= 1) {
            result = result.replace(/^0+/, '')
        }

        if (result.startsWith('.')) {
            result = '0' + result
        }

        dispatch(setIsBatchWaiting(WAITING.PENDING))
        if (result !== inputValueState) {
            if (result === '') {
                setBatchInputValue(inputKey, BigNumber.from(0))
                setRange(0)
            } else {
                setBatchInputValue(
                    inputKey,
                    BigNumber.from(
                        utils.parseUnits(
                            Number(result).toString(),
                            CONFIG.tokenList[batchInputTokens[inputKey]].decimals,
                        ),
                    ),
                )
                let rangeValue = BigNumber.from(
                    utils.parseUnits(Number(result).toString(), CONFIG.tokenList[batchInputTokens[inputKey]].decimals),
                )
                    .mul(100)
                    .div(
                        BigNumber.from(tokenAmounts[batchInputTokens[inputKey]]).gt(0)
                            ? tokenAmounts[batchInputTokens[inputKey]]
                            : 1,
                    )
                if (rangeValue.gt(100)) {
                    rangeValue = BigNumber.from(100)
                } else if (rangeValue.lt(0)) {
                    rangeValue = BigNumber.from(0)
                }
                setRange(rangeValue.toNumber())
            }
        }
        setInputValueState(result)
    }

    const handleRangeBar = (event: ChangeEvent<HTMLInputElement>): void => {
        dispatch(setIsBatchWaiting(WAITING.PENDING))
        event.preventDefault()
        const result = event.target.value.replace('%', '')

        if (!account) {
            setRange(100)
            setBatchInputValue(inputKey, BigNumber.from(tokenAmounts[batchInputTokens[inputKey]]))
            setInputValueState(setFixedNumber(tokenAmounts[batchInputTokens[inputKey]].toString()))
        }

        if (Number(result) >= 100) {
            setRange(100)
            setBatchInputValue(inputKey, BigNumber.from(tokenAmounts[batchInputTokens[inputKey]]))
            setInputValueState(
                setFixedNumber(
                    utils.formatUnits(
                        BigNumber.from(tokenAmounts[batchInputTokens[inputKey]]).mul(result).div(100),
                        CONFIG.tokenList[batchInputTokens[inputKey]].decimals,
                    ),
                ),
            )
        } else if (Number(result) <= 0) {
            setRange(0)
            setBatchInputValue(inputKey, BigNumber.from(0))
            setInputValueState('0')
        } else {
            setRange(Number(result))
            setBatchInputValue(
                inputKey,
                BigNumber.from(tokenAmounts[batchInputTokens[inputKey]]).mul(Number(result)).div(100),
            )
            setInputValueState(
                setFixedNumber(
                    utils.formatUnits(
                        BigNumber.from(tokenAmounts[batchInputTokens[inputKey]]).mul(result).div(100),
                        CONFIG.tokenList[batchInputTokens[inputKey]].decimals,
                    ),
                ),
            )
        }
    }

    const handlePercentageClick = (percentage: number): void => {
        const inputToken = batchInputTokens[inputKey]
        const newValue = BigNumber.from(tokenAmounts[inputToken]).mul(percentage).div(100)

        setBatchInputValue(inputKey, newValue)
        setInputValueState(setFixedNumber(utils.formatUnits(newValue, CONFIG.tokenList[inputToken].decimals)))
        setRange(percentage)
    }

    const handleTokenPrice = (): string => {
        if (!(routeResult[inputKey] && BigNumber.from(batchInputValues[inputKey]).gt(0))) return '$0'
        const value = (
            Number(
                utils.formatUnits(batchInputValues[inputKey], CONFIG.tokenList[batchInputTokens[inputKey]].decimals),
            ) * routeResult[inputKey]?.inputToken.price
        ).toFixed(2)
        const numberLength: number = value.length
        const returnValue: string = value[numberLength - 1] === '0' ? Number(value).toFixed(2) : value
        return returnValue === '0.00' ? 'Unavailable' : '$' + formatValue(returnValue)
    }

    useEffect(() => {
        if (BigNumber.from(batchInputValues[inputKey]).gt(0)) {
            getBatchPathOne()
        }
        if (
            BigNumber.from(
                utils.parseUnits(
                    Number(inputValueState).toString(),
                    CONFIG.tokenList[batchInputTokens[inputKey]].decimals,
                ),
            ).gt(BigNumber.from(batchInputValues[inputKey]))
        ) {
            setInputValueState(
                setFixedNumber(
                    utils.formatUnits(
                        batchInputValues[inputKey],
                        CONFIG.tokenList[batchInputTokens[inputKey]].decimals,
                    ),
                ),
            )
        }
    }, [debouncedValue, batchInputTokens[inputKey], batchOutputToken])

    useEffect(() => {
        if (!isInputFocus && isQuitFocus) {
            setTimeout(() => {
                setIsQuit(isInputFocus)
            }, 200)
        } else {
            setIsQuit(isInputFocus)
            setIsQuitFocus(false)
        }
    }, [isInputFocus])

    return (
        <>
            <SelectToken type="batchInput" key={inputKey} inputKey={inputKey} modal={selectInputTokenModal} />
            <div className={styles.wrapper}>
                <div className={isInputFocus ? clsnm(styles.input, styles.focusInput) : styles.input}>
                    <div
                        className={styles.quit}
                        onMouseOver={(): void => {
                            setIsQuitFocus(true)
                        }}
                        onMouseOut={(): void => {
                            setIsQuitFocus(false)
                        }}
                        onClick={(): void => {
                            dispatch(removeBatchInput(inputKey))
                        }}
                    >
                        <img src={QUIT} alt="quit" />
                    </div>

                    <div className={styles.range}>
                        <div className={isInputFocus ? clsnm(styles.border, styles.borderFocus) : styles.border}>
                            <div
                                className={styles.bg}
                                style={{
                                    width: `${(range * (isMobile ? 258 : 334)) / 100}px`,
                                }}
                            ></div>
                            <div className={styles.bg2} style={{ width: `${isMobile ? 268 : 334}px` }}>
                                <div className={styles.filler}></div>
                                <button
                                    onClick={(): void => handlePercentageClick(25)}
                                    className={range === 25 ? styles.zIndex : ''}
                                >
                                    <div className={styles.dot}></div>
                                </button>
                                <button
                                    onClick={(): void => handlePercentageClick(50)}
                                    className={range === 50 ? styles.zIndex : ''}
                                >
                                    <div className={styles.dot}></div>
                                </button>
                                <button
                                    onClick={(): void => handlePercentageClick(75)}
                                    className={range === 75 ? styles.zIndex : ''}
                                >
                                    <div className={styles.dot}></div>
                                </button>
                                <div className={styles.filler}></div>
                            </div>
                            <input
                                type="range"
                                onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                    handleRangeBar(value)
                                }}
                                min={0}
                                max={100}
                                step={1}
                                value={range}
                                className={styles.rangeBar}
                            ></input>
                        </div>
                        <div className={isInputFocus ? clsnm(styles.board, styles.boardFocus) : styles.board}>
                            <input
                                type="text"
                                value={`${range}`}
                                onClick={(e): void => (e.target as HTMLInputElement).select()}
                                onChange={(e): void => {
                                    if (!isNaN(Number(e.target.value))) {
                                        handleRangeBar(e)
                                    }
                                }}
                                className={isInputFocus ? clsnm(styles.board, styles.boardFocus) : styles.board}
                            />
                            <div className={styles.percent}>
                                <p>%</p>
                            </div>
                        </div>
                    </div>
                    <div className={isInputFocus ? clsnm(styles.balance, styles.focusBalance) : styles.balance}>
                        <div
                            className={styles.wallet}
                            onClick={(): void => {
                                if (!account) return
                                setBatchInputValue(inputKey, BigNumber.from(tokenAmounts[batchInputTokens[inputKey]]))
                                setInputValueState(
                                    setFixedNumber(
                                        utils.formatUnits(
                                            tokenAmounts[batchInputTokens[inputKey]],
                                            CONFIG.tokenList[batchInputTokens[inputKey]].decimals,
                                        ),
                                    ),
                                )
                                setRange(100)
                            }}
                        >
                            <img src={WHITEWALLET} alt="logo"></img>
                            {formatValue(
                                setFixedNumber(
                                    utils.formatUnits(
                                        tokenAmounts[batchInputTokens[inputKey]],
                                        CONFIG.tokenList[batchInputTokens[inputKey]].decimals,
                                    ),
                                ),
                            )}
                        </div>
                    </div>
                    <div
                        className={styles.inputToken}
                        onClick={(): void => {
                            selectInputTokenModal.open()
                            getBalances(true)
                        }}
                    >
                        <div className={styles.hover}>
                            <img
                                src={CONFIG.tokenList[batchInputTokens[inputKey]].imageUrl}
                                className={styles.logo}
                                height={isMobile ? 25 : 35}
                                width={isMobile ? 25 : 35}
                                alt="logo"
                            />
                            <div className={styles.name}>{CONFIG.tokenList[batchInputTokens[inputKey]].symbol}</div>
                        </div>
                    </div>
                    <div className={styles.swapInput}>
                        <input
                            className={styles.swapInput}
                            autoComplete="off"
                            autoCorrect="off"
                            type="text"
                            inputMode="decimal"
                            pattern="^[0-9]*[.,]?[0-9]*$"
                            minLength={1}
                            maxLength={79}
                            spellCheck="false"
                            placeholder={'0.0'}
                            value={inputValueState}
                            onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                handleChange(value)
                            }}
                            onFocus={(): void => {
                                setIsInputFocus(true)
                                if (Number(inputValueState) === 0) {
                                    setInputValueState('')
                                }
                            }}
                            onBlur={(): void => {
                                setIsInputFocus(false)
                                if (inputValueState === '') {
                                    setInputValueState('0')
                                }
                            }}
                        />
                    </div>
                    {routeResult[inputKey] && Number(batchInputValues[inputKey]) > 0 && (
                        <div className={styles.inputPrice}>{handleTokenPrice()}</div>
                    )}
                </div>
            </div>
        </>
    )
}

export { Input }
