import ROUTEROK from 'assets/router-ok.svg'
import { CONFIG } from 'config/config'
import { useTheme } from 'hooks/useTheme'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import styled from 'styled-components'
import type { InfoStoreType } from 'types/route'
import { returnSrc } from 'utils/returnSrc'

import { Hop, HopTokenLogo, HopsForOne, JustHop, RouterOk } from './structures/structures'

const Rate = styled.div`
    font-family: 'Rubik';
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    border-radius: 15px;
    color: #121f33;
    left: 93px;
    height: 15px;
    width: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
`

const Line = styled.hr`
    position: absolute;
    width: 300px;
    left: 126px;
    z-index: -2;
    border: 0.1px solid #384a66;
`

const OneRoute = ({
    setIsInfoOpen,
    setWhichInfo,
}: {
    setIsInfoOpen: (value: boolean) => void
    setWhichInfo: (value: InfoStoreType) => void
}): JSX.Element => {
    const { theme } = useTheme()

    const routeResult = useSelector((state: RootState) => state.route.routeResult)
    const path = useSelector((state: RootState) => state.route.path)

    const setInfoFalse = (): void => {
        setIsInfoOpen(false)
    }

    const setInfoTrue = (): void => {
        setWhichInfo({
            pathIndex: 0,
            tokenAddress: routeResult?.outputToken.address,
        })
        setIsInfoOpen(true)
    }

    return (
        <>
            <Line />
            <RouterOk src={ROUTEROK} />
            <Rate
                style={{
                    color: `
                ${theme === 'light' ? '#121F33' : '#FFFFFF'}
                `,
                }}
            >
                {routeResult.route[0].percent}
            </Rate>
            {path[0].length === 2 ? (
                <JustHop>
                    <Hop onMouseOut={setInfoFalse} onMouseOver={setInfoTrue}>
                        <HopTokenLogo src={returnSrc(path[0][1], CONFIG.tokenList)} />
                    </Hop>
                </JustHop>
            ) : (
                <HopsForOne>
                    {path[0].map((item, i) => {
                        if (i === 0) return

                        return (
                            <Hop
                                onMouseOut={setInfoFalse}
                                onMouseOver={() => {
                                    setWhichInfo({
                                        pathIndex: 0,
                                        tokenAddress: item,
                                        // protocols: item.protocols,
                                    })
                                    setIsInfoOpen(true)
                                }}
                                key={i}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <HopTokenLogo src={returnSrc(item, CONFIG.tokenList)} />
                                </div>
                            </Hop>
                        )
                    })}
                </HopsForOne>
            )}
        </>
    )
}

export { OneRoute }
