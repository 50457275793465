import type { ModalController } from 'hooks/useModal'
import { Button, Modal } from 'ui'

import styles from './Disclaimer.module.scss'

const Disclaimer = ({ modal }: { modal: ModalController }): JSX.Element => {
    return (
        <Modal className={styles.wrapper} isOpen={modal.isOpen} close={modal.close} closeOnClickOutside={false}>
            <div className={styles.title}>Mainnet Disclaimer</div>
            <div className={styles.text}>
                Fibrous is currently in alpha development phase. The smart contracts powering Fibrous have not yet been
                audited, and there may be unknown bugs or vulnerabilities in the code.
                <br />
                <br />
                Therefore, please be aware that using this app carries risk and could result in the loss of funds.
                Please exercise caution and use Fibrous at your own risk.
            </div>
            <Button
                onClick={(): void => {
                    localStorage.setItem('disclaimer', 'true')
                    modal.close()
                }}
                className={styles.swap}
                width={'100%'}
                height={'70px'}
                fontWeight="fw500"
                fontSize="fs20"
                color={'swap'}
            >
                I Know, LFG!
            </Button>
        </Modal>
    )
}

export { Disclaimer }
