import React from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import type { Event, UserSummary } from 'types/arena'

import styles from './UserInfo.module.scss'

type UserInfoProps = {
    lastSearchedAddress: string | null
    address: string | null | undefined
    displayValue: string
    rankingData: Array<UserSummary>
    rankData: Array<UserSummary>
    activeIndex: number
    setLastSearchedAddress: React.Dispatch<React.SetStateAction<string | null>>
    EVENTS: Array<Event>
    searchedUserInfo?: UserSummary
    currentUserData?: UserSummary
    addressDomains: Record<string, string>
    validateAndParseAddress: (address: string) => string
    parseAddress: (address: string) => string
}

const UserInfo: React.FC<UserInfoProps> = ({
    lastSearchedAddress,
    address,
    displayValue,
    rankingData,
    rankData,
    activeIndex,
    setLastSearchedAddress,
    EVENTS,
    searchedUserInfo,
    currentUserData,
    addressDomains,
    validateAndParseAddress,
    parseAddress,
}) => {
    const formatRank = (rank: number): string => {
        if (rank <= 10000) {
            return rank.toString()
        } else {
            return '+10k'
        }
    }

    const findRank = (address: string): number => {
        const index = rankData.findIndex((user) => user.sender === address)
        if (index === -1) {
            return 10001
        }
        return index + 1
    }

    const formatVolume = (volume: string): string => {
        const formattedVolume = Number(volume)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return formattedVolume
    }

    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)

    return (
        <aside className={styles.userInfo}>
            <div
                className={styles.currentUserInfo}
                onClick={(): void => {
                    if (address) {
                        setLastSearchedAddress(address)
                    }
                }}
            >
                <span>{formatRank(findRank(lastSearchedAddress || address || ''))}</span>

                <span>{displayValue}</span>
                <span>
                    {EVENTS[activeIndex].eventType === 'chrismas' ? (
                        <div className={styles.points}>
                            <div>{`$${formatVolume(
                                searchedUserInfo?.total_volume || currentUserData?.total_volume || '0',
                            )}`}</div>
                            /
                            <div>
                                {Math.round(Number(searchedUserInfo?.total_tx || currentUserData?.total_tx || 0))}
                            </div>
                            /<div>{formatVolume(searchedUserInfo?.points || currentUserData?.points || '0')}</div>
                        </div>
                    ) : EVENTS[activeIndex].eventType === 'volume' ? (
                        `$${formatVolume(searchedUserInfo?.total_volume || currentUserData?.total_volume || '0')}`
                    ) : (
                        Math.round(Number(searchedUserInfo?.count || currentUserData?.count || 0))
                    )}
                </span>
            </div>
            <div className={styles.tableContainer}>
                <table className={styles.userTable}>
                    <thead>
                        <tr className={styles.tableHeader}>
                            <th className={styles.rank}></th>

                            <th className={styles.address}>Address</th>
                            <th className={styles.eventValue}>
                                {EVENTS[activeIndex].eventType === 'chrismas'
                                    ? 'Volume'
                                    : EVENTS[activeIndex].eventType === 'volume'
                                      ? 'Volume'
                                      : 'Count'}
                            </th>
                            <th
                                className={styles.eventValue}
                                style={{
                                    display: EVENTS[activeIndex].eventType === 'chrismas' ? '' : 'none',
                                }}
                            >
                                {'Count'}
                            </th>
                            <th
                                className={styles.eventValue}
                                style={{
                                    display: EVENTS[activeIndex].eventType === 'chrismas' ? '' : 'none',
                                }}
                            >
                                {'Elo'}
                            </th>
                        </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                        {rankingData.map((user, index) => (
                            <tr
                                key={user.sender}
                                className={styles.tableRow}
                                style={{
                                    color: index < 10 ? 'var(--default-text-12)' : 'var(--default-text-9)',
                                    fontWeight: index < 20 ? '700' : '400',
                                }}
                            >
                                <td className={styles.rank}>{user.place}</td>
                                <td
                                    className={styles.address}
                                    onClick={(): void => {
                                        window.open(
                                            `${explorerUrl}contract/${validateAndParseAddress(user.sender)}`,
                                            '_blank',
                                            'noopener,noreferrer',
                                        )
                                    }}
                                >
                                    {addressDomains[user.sender] || parseAddress(validateAndParseAddress(user.sender))}
                                </td>
                                <td className={styles.eventValue}>
                                    {EVENTS[activeIndex].eventType === 'chrismas'
                                        ? `$${formatVolume(user.total_volume)}`
                                        : EVENTS[activeIndex].eventType === 'volume'
                                          ? `$${formatVolume(user.total_volume)}`
                                          : Math.round(Number(user.count))}
                                </td>
                                <td
                                    className={styles.eventValue}
                                    style={{
                                        display: EVENTS[activeIndex].eventType === 'chrismas' ? '' : 'none',
                                    }}
                                >
                                    {Math.round(Number(user.total_tx))}
                                </td>
                                <td
                                    className={styles.eventValue}
                                    style={{
                                        display: EVENTS[activeIndex].eventType === 'chrismas' ? '' : 'none',
                                    }}
                                >
                                    {formatVolume(user.points!)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </aside>
    )
}

export default UserInfo
