import { Destination } from 'components/Swap/Destination/Destination'
import { Indicator } from 'components/Swap/Indicator/Indicator'
import { Info } from 'components/Swap/Info/Info'
import { InputBar } from 'components/Swap/InputBar/InputBar'
import { SwapButton } from 'components/Swap/SwapButton/SwapButton'
import { TokenBase } from 'components/Swap/TokenBase/TokenBase'

import type { ModalController } from '../../hooks/useModal'
import styles from './Swap.module.scss'

const Swap = ({ pendingsModal }: { pendingsModal: ModalController }): JSX.Element => {
    return (
        <div className={styles.wrapper}>
            <Info />
            <TokenBase />
            <InputBar />
            <SwapButton pendingsModal={pendingsModal} />
            <Indicator />
            <Destination />
        </div>
    )
}

export { Swap }
