import { typedData } from 'starknet'

import { Order,  types } from './constants'

function getDomain(chainId: string): typedData.StarkNetDomain {
    return {
        name: 'Fibrous Finance',
        version: '1',
        chainId,
    }
}

function getTypedDataHash(myStruct: Order, chainId: string, owner: string): string {
    return typedData.getMessageHash(getTypedData(myStruct, chainId), owner)
}
// Needed to reproduce the same structure as:
// https://github.com/0xs34n/starknet.js/blob/1a63522ef71eed2ff70f82a886e503adc32d4df9/__mocks__/typedDataStructArrayExample.json
function getTypedData(myStruct: Order, chainId: string): typedData.TypedData {
    return {
        types,
        primaryType: 'Order',
        domain: getDomain(chainId),
        message: { ...myStruct },
    }
}

export const signMessage = async (signer: string, chain_id: string, order: any) => {
    const typedDataValidate: typedData.TypedData = {
        domain: getDomain(chain_id),
        message: { ...order },
        primaryType: 'Order',
        types: types,
    }
    const typedDataHash = getTypedDataHash(order, chain_id, signer)
    return {typedDataValidate : typedDataValidate, typedDataHash: typedDataHash}
}
