import { useAccount } from '@starknet-react/core'
import COPY from 'assets/copyDark.svg'
import DARKARROW from 'assets/rightArrow.svg'
import LIGHTARROW from 'assets/rightArrowLight.svg'
import { CONFIG } from 'config/config'
import { useTheme } from 'hooks/useTheme'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import type { InvokeTransactionReceiptResponse } from 'types/network'
import {
    getInputAmountFromEvents,
    getInputTokenIdFromEvents,
    getOutputAmountFromEvents,
    getOutputTokenIdFromEvents,
} from 'utils/filterEvents'
import { parseAddress } from 'utils/parseAddress'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './SuccessfulV2.module.scss'

const SuccessfulV2 = ({ data }: { data: InvokeTransactionReceiptResponse }): JSX.Element => {
    const { address } = useAccount()
    const { theme } = useTheme()

    const pendingPayload = useSelector((state: RootState) => state.pending.payload)
    const pendingData = useSelector((state: RootState) => state.pending.pendingData)
    const isRocksHolder = useSelector((state: RootState) => state.starknet.rocksHolder)
    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)

    const areTokensMatched =
        (pendingData.inputToken === 10 && pendingData.outputToken === 12) ||
        (pendingData.inputToken === 12 && pendingData.outputToken === 10)

    const formattedOutputValue = Number(pendingData.outputValue).toFixed(4)
    const formattedInputValue = Number(pendingData.inputValue).toFixed(4)

    const computedOutputValue = areTokensMatched
        ? formattedOutputValue
        : address && data && data.events
          ? setFixedNumber(
                getOutputAmountFromEvents(
                    data.events,
                    address,
                    getOutputTokenIdFromEvents(data.events, address, CONFIG.tokenList),
                    CONFIG.tokenList,
                ),
            )
          : formattedOutputValue

    const computedInputValue = areTokensMatched
        ? formattedInputValue
        : address && data && data.events
          ? setFixedNumber(
                getInputAmountFromEvents(
                    data.events,
                    address,
                    getInputTokenIdFromEvents(data.events, address, CONFIG.tokenList),
                    CONFIG.tokenList,
                ),
            )
          : formattedInputValue

    const inputTokenSrc = areTokensMatched
        ? CONFIG.tokenList[pendingData.inputToken].imageUrl
        : CONFIG.tokenList[
              address && data && data.events
                  ? getInputTokenIdFromEvents(data.events, address, CONFIG.tokenList)
                  : pendingData.inputToken
          ].imageUrl

    const outputTokenSrc = areTokensMatched
        ? CONFIG.tokenList[pendingData.outputToken].imageUrl
        : CONFIG.tokenList[
              address && data && data.events
                  ? getOutputTokenIdFromEvents(data.events, address, CONFIG.tokenList)
                  : pendingData.outputToken
          ].imageUrl

    return (
        <div className={styles.wrapper}>
            <div className={styles.infos}>
                <div className={styles.info}>Transaction Successful!</div>
                <a
                    target={'_blank'}
                    rel="noreferrer"
                    className={styles.tx}
                    href={explorerUrl.concat(`tx/${pendingPayload}`)}
                >
                    <img src={COPY} alt="copy"></img>
                    <div className={styles.text}>TX: {parseAddress(pendingPayload)}</div>
                </a>
            </div>
            <div className={styles.tokens} style={isRocksHolder ? { background: '#691F6F' } : {}}>
                <div className={styles.token}>
                    <img src={inputTokenSrc} alt="logo"></img>
                    <div className={styles.amount} style={isRocksHolder ? { color: '#FFF' } : {}}>
                        {computedInputValue}
                    </div>
                </div>
                <div className={styles.ok}>
                    <img src={isRocksHolder ? LIGHTARROW : theme === 'dark' ? LIGHTARROW : DARKARROW}></img>
                </div>
                <div className={styles.token}>
                    <img src={outputTokenSrc} alt="logo"></img>
                    <div className={styles.amount} style={isRocksHolder ? { color: '#FFF' } : {}}>
                        {computedOutputValue}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { SuccessfulV2 }
