import { configureStore } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import { useSelector } from 'react-redux'
import batchSlice from 'store/slicers/batch'
import batchPendingSlice from 'store/slicers/batchPending'
import inputSlice from 'store/slicers/input'
import limitOrderSlice from 'store/slicers/limitOrder'
import outputSlice from 'store/slicers/output'
import pendingSlice from 'store/slicers/pending'
import routeSlice from 'store/slicers/route'

import settingsSlice from './slicers/settings'
import starknetReducer from './slicers/starknet'
import themeSlice from './slicers/theme'

export const store = configureStore({
    reducer: {
        starknet: starknetReducer,
        theme: themeSlice,
        input: inputSlice,
        output: outputSlice,
        settings: settingsSlice,
        route: routeSlice,
        pending: pendingSlice,
        batch: batchSlice,
        batchPending: batchPendingSlice,
        limitOrder: limitOrderSlice,
    },
    middleware: (getDefaultMiddleware) => {
        const customizedMiddleware = getDefaultMiddleware({
            serializableCheck: false,
        })
        return customizedMiddleware
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
