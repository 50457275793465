import { EmptyRoute } from 'components/Route/Routes/EmptyRoute'
import { MultiRoute } from 'components/Route/Routes/MultiRoute'
import { OneRoute } from 'components/Route/Routes/OneRoute'
import { CONFIG } from 'config/config'
import { AMMS } from 'constants/amms'
import { ethers, utils } from 'ethers'
import { useTheme } from 'hooks/useTheme'
import { useRef, useState } from 'react'
import type { MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import styled from 'styled-components'
import type { InfoStoreType } from 'types/route'
import { WAITING } from 'types/route'
import { clsnm } from 'utils/clsnm'
import { returnSrc } from 'utils/returnSrc'

import styles from './Route.module.scss'

const BORDERCOLOR = '#384A66'

const Wrapper = styled.div`
    width: 500px;
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 500px) {
        width: auto;
        overflow-x: scroll;
        justify-content: left;
    }
`

const AllRoutes = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 450px;
    position: relative;
`

const Base = styled.div`
    width: 78px;
    height: 78px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid ${BORDERCOLOR};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
`

const BaseTokenLogo = styled.img`
    width: 55px;
    height: 55px;
    border-radius: 50%;
`

const InfoPopUp = styled.div`
    position: absolute;
    transform: translateX(-50%) translateY(-100%);
    background-color: red;
    z-index: 4;
    background: var(--default-color-14);
    backdrop-filter: blur(2px);
    border-radius: 16px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 15px;

    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: white;

    img {
        border-radius: 50%;
    }
`

const Route = (): JSX.Element => {
    const { theme } = useTheme()

    const path = useSelector((state: RootState) => state.route.path)
    const pathInfo = useSelector((state: RootState) => state.route.pathInfo)

    const inputToken = useSelector((state: RootState) => state.input.token)
    const outputToken = useSelector((state: RootState) => state.output.token)

    const inputValue = useSelector((state: RootState) => state.input.value)
    const outputValue = useSelector((state: RootState) => state.output.value)

    const isRouterWaiting = useSelector((state: RootState) => state.route.waiting)

    const infoRef = useRef<HTMLDivElement>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)

    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault()
        if (!infoRef.current || !wrapperRef.current) return

        infoRef.current.style.left = e.pageX.toString() + 'px'
        infoRef.current.style.top = (Number(e.pageY) - 15).toString() + 'px'
    }

    const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false)
    const [whichInfo, setWhichInfo] = useState<InfoStoreType>({
        pathIndex: 0,
        tokenAddress: '0x017c9af289d63d8687ebc54284532460d6afd7de2ec2973ad7633415f2bb8655',
    })

    const [isTokenAmountInfoOpen, setIsTokenAmountInfoOpen] = useState<boolean>(false)

    const [whichTokenAmountInfo, setWhichTokenAmountInfo] = useState<boolean>(false)

    const setInfoContent = (value: InfoStoreType): void => {
        if (whichInfo === value) return
        setWhichInfo(value)
    }

    return (
        <Wrapper ref={wrapperRef} onMouseMove={handleMouseEvent}>
            {isTokenAmountInfoOpen && (
                <InfoPopUp ref={infoRef}>
                    {!whichTokenAmountInfo
                        ? utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals) +
                          ' ' +
                          CONFIG.tokenList[inputToken].symbol
                        : Number(
                              ethers.utils.formatUnits(
                                  ethers.BigNumber.from(outputValue),
                                  CONFIG.tokenList[outputToken].decimals,
                              ),
                          )
                              .toFixed(4)
                              .toString() +
                          ' ' +
                          CONFIG.tokenList[outputToken].symbol}
                </InfoPopUp>
            )}
            {isInfoOpen && pathInfo && (
                <InfoPopUp ref={infoRef}>
                    {pathInfo[whichInfo?.pathIndex][0][whichInfo.tokenAddress]?.percents.map(
                        (data: string, i: number) => {
                            return (
                                <div className={styles.text} key={i}>
                                    {Number(data.slice(0, -1)).toFixed(0)}% |{' '}
                                    {(inputToken === 10 && outputToken === 12) ||
                                    (inputToken === 12 && outputToken === 10)
                                        ? 'Starknet Voting Power'
                                        : AMMS[
                                              pathInfo[whichInfo.pathIndex][0][whichInfo.tokenAddress]?.protocols[i] - 1
                                          ].name}
                                    <br></br>
                                </div>
                            )
                        },
                    )}
                </InfoPopUp>
            )}
            <AllRoutes>
                <div className={styles.time}>
                    <div
                        className={
                            isRouterWaiting === WAITING.FALSE && Number(inputValue) > 0
                                ? clsnm(styles.bar, styles.start)
                                : styles.bar
                        }
                    ></div>
                    <Base
                        onMouseOut={(): void => setIsTokenAmountInfoOpen(false)}
                        onMouseOver={(): void => {
                            setIsTokenAmountInfoOpen(true)
                            setWhichTokenAmountInfo(false)
                        }}
                        style={{
                            backgroundColor: `
                    ${theme === 'light' ? '#F3F5F8' : '#0B182B'}
                    `,
                        }}
                    >
                        <BaseTokenLogo src={returnSrc(CONFIG.tokenList[inputToken].address, CONFIG.tokenList)} />
                    </Base>
                </div>
                {!(isRouterWaiting === WAITING.FALSE) || Number(inputValue) <= 0 ? (
                    <EmptyRoute />
                ) : path.length === 1 ? (
                    <OneRoute setIsInfoOpen={setIsInfoOpen} setWhichInfo={setInfoContent} />
                ) : path.length > 1 ? (
                    <MultiRoute setIsInfoOpen={setIsInfoOpen} setWhichInfo={setInfoContent} />
                ) : null}
                <Base
                    onMouseOut={(): void => setIsTokenAmountInfoOpen(false)}
                    onMouseOver={(): void => {
                        setIsTokenAmountInfoOpen(true)
                        setWhichTokenAmountInfo(true)
                    }}
                    style={{
                        backgroundColor: `
                ${theme === 'light' ? '#F3F5F8' : '#0B182B'}
                `,
                    }}
                >
                    <BaseTokenLogo src={returnSrc(CONFIG.tokenList[outputToken].address, CONFIG.tokenList)} />
                </Base>
            </AllRoutes>
        </Wrapper>
    )
}

export { Route }
