import type BN__default from 'bn.js'
import {
    DAITOKEN,
    DAIv0TOKEN,
    ETHTOKEN,
    LORDSTOKEN,
    USDCTOKEN,
    USDTTOKEN,
    WBTCTOKEN,
    WSTETHTOKEN,
} from 'constants/tokens'
import { BigNumber } from 'ethers'
import { validateAndParseAddress } from 'starknet'

import { sqrtPriceLimitParse } from './uint256'

export declare type Token = any

export declare type Pool = any

export declare enum Protocol {
    AMM1,
    AMM2,
    AMM3,
    AMM4,
    AMM5,
}

export type Percent = `${string}%`

export declare type Swap = {
    protocol: Protocol
    poolId: string
    poolAddress: string
    fromTokenAddress: string
    toTokenAddress: string
    percent: Percent
    ekuboData: {
        current_tick: string
        tick_sign: string
        fee: string
        tick_spacing: string
        sqrt_price_low: string
        sqrt_price_high: string
        pool_key: string
    }
}
export declare type Route = {
    percent: Percent
    swaps: Array<Array<Swap>>
}
export declare type RouterResponse = {
    success?: boolean
    inputToken: Token
    inputAmount: string
    outputToken: Token
    outputAmount: string
    estimatedGasUsed: string
    route: Array<Route>
    time: number
    initial: boolean
}

const MYSWAP_POOL_IDS: Array<{ pools: Array<string>; id: number }> = [
    { pools: [ETHTOKEN.address, USDCTOKEN.address], id: 1 },
    { pools: [DAIv0TOKEN.address, ETHTOKEN.address], id: 2 },
    { pools: [WBTCTOKEN.address, USDCTOKEN.address], id: 3 },
    { pools: [ETHTOKEN.address, USDTTOKEN.address], id: 4 },
    { pools: [USDCTOKEN.address, USDTTOKEN.address], id: 5 },
    { pools: [DAIv0TOKEN.address, USDCTOKEN.address], id: 6 },
    { pools: [WSTETHTOKEN.address, ETHTOKEN.address], id: 7 },
    { pools: [LORDSTOKEN.address, ETHTOKEN.address], id: 8 },
].map(({ pools, id }) => ({
    pools: pools.map((pool) => validateAndParseAddress(pool)),
    id,
}))

const findMySwapIdx = (tokenA: string, tokenB: string): number => {
    tokenA = validateAndParseAddress(tokenA)
    tokenB = validateAndParseAddress(tokenB)

    for (const { pools, id } of MYSWAP_POOL_IDS) {
        if (pools.includes(tokenA) && pools.includes(tokenB)) {
            return id
        }
    }

    throw new Error("mySwap index couldn't found")
}

/** [token_in, token_out, amount_in low, amount_in high, min received low, min received high, destination] */
export type CairoSwapRoute = [string, string, string, string, string, string, string]

const trimPercent = (p: Percent): number => Number(p.replace('%', ''))
const parsePercent = (p: number): string => String(Math.floor(Number(p.toFixed(4)) * 10000))

type BigNumberish = string | number | BN__default
/**
 * Formats the response from the Fibrous API into a flattened array of swaps
 * @param res Response from the Fibrous API
 * @returns Flattened array of swaps, ready to be passed to the Starknet contract
 */
export function formatRouterCall(
    res: RouterResponse,
    slippage: number,
    destination: string,
    fmtFee: number,
): Array<BigNumberish> {
    const SLIPPAGE_EXTENSION = 10 ** 6
    const inputToken = validateAndParseAddress(res.inputToken.address)
    const outputToken = validateAndParseAddress(res.outputToken.address)
    const inputAmount = res.inputAmount
    const routeParam: Array<CairoSwapRoute> = []

    /** [token_in, token_out, percent, protocol, pool_address, extradata length, our contract address, token0 address, token1 address, fee, tick spacing, extension, sqrt price limit low, sqrt price limit high] */
    const swapParams: Array<string> = []

    // ****------------------------------***
    //** Route param
    // * 1. token in
    // * 2. token out
    // * 3. amount in low
    // * 4. amount in high
    // * 5. min received low
    // * 6. min received high
    // * 7. destination
    //*/

    //** swap params length */

    //** Swap params
    // * 1. token in
    // * 2. token out
    // * 3. percent
    // * 4. protocol id
    // * 5. pool address
    // * 6. extradata length ? > 0 : 0
    // * 7. our contract address
    // * 8. token0 address
    // * 9. token1 address
    // * 10. fee
    // * 11. tick spacing
    // * 12. extension
    // * 13. sqrt price limit low
    // * 14. sqrt price limit high
    //** */
    const minReceived =
        (BigInt(res.outputAmount) * BigInt(Number(((1 - slippage - fmtFee) * SLIPPAGE_EXTENSION).toFixed(8)))) /
        BigInt(SLIPPAGE_EXTENSION)

    routeParam.push([
        inputToken,
        outputToken,
        inputAmount,
        '0x00',
        String(minReceived),
        '0x00',
        destination,
    ] as CairoSwapRoute)

    let calldatas = []
    let swapLength = 0
    let totalInputPercent = 0
    for (let i = 0; i < res.route.length; i++) {
        const swaps = res.route[i].swaps
        for (let j = 0; j < swaps.length; j++) {
            swapLength += swaps[j].length
            for (let k = 0; k < swaps[j].length; k++) {
                const swap = swaps[j][k]
                let percent = ''
                swapParams.push(validateAndParseAddress(swap?.fromTokenAddress))
                swapParams.push(validateAndParseAddress(swap?.toTokenAddress))
                if (inputToken == validateAndParseAddress(swap?.fromTokenAddress)) {
                    if (res.initial) {
                        const swapPercent = trimPercent(swap.percent)
                        percent = (swapPercent * 10000).toString()
                        totalInputPercent += Number(percent)
                    } else {
                        const mainPercent = trimPercent(res.route[i].percent)
                        const swapPercent = trimPercent(swap.percent)
                        percent = parsePercent(Number((mainPercent * swapPercent) / 100))
                        totalInputPercent += Number(percent)
                    }
                } else {
                    percent = parsePercent(Number(swap.percent.split('%')[0]))
                }
                swapParams.push(percent)
                swapParams.push(swap?.protocol.toString()) // protocol id
                swapParams.push(
                    swap.protocol === 1 // if myswap send pool id else send pool address
                        ? String(findMySwapIdx(swap.fromTokenAddress, swap.toTokenAddress))
                        : validateAndParseAddress(swap?.poolId.split(':')[1]),
                )
                const token0 = validateAndParseAddress(swap?.poolId.split(':')[2])
                if (swap?.protocol == 5) {
                    const sqrtPriceParsed = sqrtPriceLimitParse(
                        BigNumber.from(swap?.ekuboData?.sqrt_price_low),
                        BigNumber.from(swap?.ekuboData?.sqrt_price_high),
                        token0 == validateAndParseAddress(swap?.fromTokenAddress),
                    )

                    swapParams.push('7') // extradata length for ekubo it's constant
                    swapParams.push(validateAndParseAddress(swap?.poolId.split(':')[2])) // token0 address
                    swapParams.push(validateAndParseAddress(swap?.poolId.split(':')[3])) // token1 address
                    swapParams.push(swap?.ekuboData?.fee) // fee for ekubo
                    swapParams.push(swap?.ekuboData?.tick_spacing) // tick spacing for ekubo
                    swapParams.push('0x00') // extension for ekubo
                    swapParams.push(sqrtPriceParsed.low.toString()) // sqr price limit low for ekubo
                    swapParams.push(sqrtPriceParsed.high.toString()) // sqr price limit high for ekubo
                } else if (swap?.protocol == 6) {
                    const sqrtPriceParsed = sqrtPriceLimitParse(
                        BigNumber.from(swap?.ekuboData?.sqrt_price_low),
                        BigNumber.from(swap?.ekuboData?.sqrt_price_high),
                        token0 == validateAndParseAddress(swap?.fromTokenAddress),
                    )
                    swapParams.push('5') // extradata length

                    swapParams.push(swap.ekuboData?.pool_key) // pool key
                    swapParams.push(
                        validateAndParseAddress(token0) == validateAndParseAddress(swap.fromTokenAddress) ? '1' : '0',
                    ) // is token0
                    swapParams.push('1') // exact input
                    swapParams.push(sqrtPriceParsed.low.toString()) // sqr price limit low for v3
                    swapParams.push(sqrtPriceParsed.high.toString()) // sqr price limit high for v3
                } else if (swap?.protocol == 8) {
                    swapParams.push('3') // extradata length
                    swapParams.push(swap?.ekuboData?.fee) // fee for jedi cl
                    const sqrtPriceParsed = sqrtPriceLimitParse(
                        BigNumber.from(swap?.ekuboData?.sqrt_price_low),
                        BigNumber.from(swap?.ekuboData?.sqrt_price_high),
                        token0 == validateAndParseAddress(swap?.fromTokenAddress),
                    )
                    swapParams.push(sqrtPriceParsed.low.toString()) // sqr price limit low for v3
                    swapParams.push(sqrtPriceParsed.high.toString()) // sqr price limit high for v3
                } else if (swap?.protocol == 10) {
                    swapParams.push('7') // extradata length
                    swapParams.push(swap?.ekuboData?.pool_key)
                    swapParams.push(
                        validateAndParseAddress(token0) == validateAndParseAddress(swap.fromTokenAddress) ? '0' : '1',
                    ) // is token0
                    swapParams.push('1') // exact input
                    const sqrtPriceParsed = sqrtPriceLimitParse(
                        BigNumber.from(swap?.ekuboData?.sqrt_price_low),
                        BigNumber.from(swap?.ekuboData?.sqrt_price_high ? swap?.ekuboData?.sqrt_price_high : '0'),
                        token0 == validateAndParseAddress(swap?.fromTokenAddress),
                    )
                    swapParams.push(sqrtPriceParsed.low.toString()) // sqr price limit low for v3
                    swapParams.push(sqrtPriceParsed.high.toString()) // sqr price limit high for v3
                    swapParams.push('1') //
                    swapParams.push('0x0') //
                } else {
                    swapParams.push('0x00') // extradata length
                }
            }
        }
    }
    calldatas = [...routeParam.flat(), swapLength, ...swapParams]
    if (totalInputPercent < 999900) return []
    return [...calldatas] as Array<BigNumberish>
}
