import { useTheme } from 'hooks/useTheme'
import { Spinner } from 'ui'

import { Hop, JustHop, Line } from './structures/structures'

const EmptyRoute = (): JSX.Element => {
    const { theme } = useTheme()

    return (
        <>
            <Line />
            <JustHop>
                <Hop
                    style={{
                        backgroundColor: `
            ${theme === 'light' ? '#F3F5F8' : '#0B182B'}
            `,
                    }}
                >
                    <Spinner size={24} />
                </Hop>
            </JustHop>
        </>
    )
}

export { EmptyRoute }
