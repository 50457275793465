export const isValidStarknetAddressOrDomain = (value: string): boolean => {
    if (/^0x[a-fA-F0-9]{60,67}$/.test(value)) return true
    if (/^[a-z0-9-]+\.stark$/.test(value)) return true
    return false
}

export const isValidStarknetAddress = (value: string): boolean => {
    if (/^0x[a-fA-F0-9]{60,67}$/.test(value)) return true
    return false
}

export const isValidStarknetDomain = (value: string): boolean => {
    if (/^[a-z0-9-]+\.stark$/.test(value)) return true
    return false
}
