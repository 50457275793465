import { useAccount } from '@starknet-react/core'
import { TX } from 'components/FooterV2/History/TX/TX'
import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setHistoryData } from 'store/slicers/settings'
import type { HistoryTXProps } from 'types/history'

import styles from './History.module.scss'

const History = (): JSX.Element => {
    const historyData = useSelector((state: RootState) => state.settings.historyData)
    const { address } = useAccount()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!address) return
        const history: string | null = localStorage.getItem(`${address}-history`)
        if (history === null) {
            localStorage.setItem(`${address}-history`, JSON.stringify([]))
        } else {
            dispatch(setHistoryData(history))
        }
    }, [address])

    useEffect(() => {
        if (historyData === '[]') return
        localStorage.setItem(`${address}-history`, historyData)
    }, [historyData])

    return (
        <div className={styles.history}>
            {historyData &&
                JSON.parse(historyData)
                    .reverse()
                    .map((data: HistoryTXProps, i: number) => {
                        return (
                            <Fragment key={i}>
                                <div
                                    className={styles.line}
                                    style={{
                                        display: i === 0 ? 'none' : 'flex',
                                    }}
                                ></div>
                                <TX {...data} />
                            </Fragment>
                        )
                    })}

            {historyData && JSON.parse(historyData).length === 0 && 'There is no transaction history.'}
        </div>
    )
}

export { History }
