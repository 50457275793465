import { useTheme } from 'hooks/useTheme'
import ReactLoading from 'react-loading'

import { Hop, JustHop, Line } from './structures/structures'

const EmptyRoute = (): JSX.Element => {
    const { theme } = useTheme()

    return (
        <>
            <Line />
            <JustHop>
                <Hop
                    style={{
                        backgroundColor: `
                ${theme === 'light' ? '#F3F5F8' : '#0B182B'}
                `,
                    }}
                >
                    <ReactLoading type={'spin'} color={'#00ADB5'} height={24} width={24} />
                </Hop>
            </JustHop>
        </>
    )
}

export { EmptyRoute }
