import Charts from 'components/LimitOrder/Charts/Charts'
import Orders from 'components/LimitOrder/Orders/Orders'
import SwapOrder from 'components/LimitOrder/SwapOrder/SwapOrder'

import type { ModalController } from '../../hooks/useModal'
import styles from './LimitOrder.module.scss'

const LimitOrder = ({ pendingsModal }: { pendingsModal: ModalController }): JSX.Element => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.swapOrder}>
                <SwapOrder pendingsModal={pendingsModal} />
            </div>
            <div className={styles.chartsAndOrders}>
                <Charts />
                <Orders pendingsModal={pendingsModal} />
            </div>
        </div>
    )
}

export { LimitOrder }
