import ROUTEROK from 'assets/router-ok.svg'
import { CONFIG } from 'config/config'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import type { InfoStoreType } from 'types/route'
import { returnSrc } from 'utils/returnSrc'

import {
    ChannelReturner,
    ChannelsForMulti,
    Hop,
    HopTokenLogo,
    HopsForMulti,
    JustHop,
    Line,
    Rate,
    RouterOk,
} from './structures/structures'

const MultiRoute = ({
    setIsInfoOpen,
    setWhichInfo,
}: {
    setIsInfoOpen: (value: boolean) => void
    setWhichInfo: (value: InfoStoreType) => void
}): JSX.Element => {
    const routeResult = useSelector((state: RootState) => state.route.routeResult)
    const path = useSelector((state: RootState) => state.route.path)
    return (
        <>
            <ChannelsForMulti
                style={{
                    height: ` ${(90 + (path.length - 2) * 70).toString()}px`,
                }}
            >
                {path.map((obje: Array<string>, index: number) => {
                    return (
                        <ChannelReturner path={path} channel={index} key={index}>
                            <RouterOk src={ROUTEROK} />
                            {index !== 0 && index !== path.length - 1 && <Line />}
                            <Rate>{routeResult.route[index].percent}</Rate>
                            {obje.length === 2 ? (
                                <JustHop>
                                    <Hop
                                        onMouseOut={(): void => setIsInfoOpen(false)}
                                        onMouseOver={(): void => {
                                            setWhichInfo({
                                                pathIndex: index,
                                                tokenAddress: routeResult?.outputToken.address,
                                            })
                                            setIsInfoOpen(true)
                                        }}
                                    >
                                        <HopTokenLogo
                                            src={returnSrc(routeResult?.outputToken.address, CONFIG.tokenList)}
                                        />
                                    </Hop>
                                </JustHop>
                            ) : (
                                <HopsForMulti>
                                    {obje.map((data: string, i: number) => {
                                        return i === 0 || i + 1 === obje.length ? null : (
                                            <Hop
                                                onMouseOut={(): void => setIsInfoOpen(false)}
                                                onMouseOver={(): void => {
                                                    setWhichInfo({
                                                        pathIndex: index,
                                                        tokenAddress: data,
                                                    })
                                                    setIsInfoOpen(true)
                                                }}
                                                key={i}
                                            >
                                                <HopTokenLogo src={returnSrc(data, CONFIG.tokenList)} />
                                            </Hop>
                                        )
                                    })}
                                    <Hop
                                        onMouseOut={(): void => setIsInfoOpen(false)}
                                        onMouseOver={(): void => {
                                            setWhichInfo({
                                                pathIndex: index,
                                                tokenAddress: routeResult?.outputToken.address,
                                            })
                                            setIsInfoOpen(true)
                                        }}
                                    >
                                        <HopTokenLogo
                                            src={returnSrc(routeResult?.outputToken.address, CONFIG.tokenList)}
                                        />
                                    </Hop>
                                </HopsForMulti>
                            )}
                        </ChannelReturner>
                    )
                })}
            </ChannelsForMulti>
        </>
    )
}

export { MultiRoute }
