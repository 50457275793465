import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import { PendingState } from 'types/pending'

import styles from './PendingV2.module.scss'

const PendingV2 = (): JSX.Element => {
    const pendingPayload = useSelector((state: RootState) => state.limitOrder.payload)
    const pendingStatus = useSelector((state: RootState) => state.limitOrder.status)
    const isSigned = useSelector((state: RootState) => state.limitOrder.isSigned)
    const isCanceled = useSelector((state: RootState) => state.limitOrder.isCanceled)
    const isClear = useSelector((state: RootState) => state.limitOrder.isClear)

    return (
        <div className={styles.wrapper}>
            {isCanceled && (
                <div className={styles.tx}>
                    <div className={styles.text}>
                        {isClear ? (
                            <>
                                <span>Waiting </span> for orders clearing confirmation.
                            </>
                        ) : (
                            <>
                                <span>Waiting </span> for order cancellation confirmation.
                            </>
                        )}
                    </div>
                </div>
            )}

            {!isCanceled && pendingStatus === PendingState.PENDING && pendingPayload !== '' && !isSigned && (
                <div className={styles.tx}>
                    <div className={styles.text}>
                        <span>Waiting </span> for approval confirmation.
                    </div>
                </div>
            )}

            {!isCanceled && pendingStatus === PendingState.PENDING && pendingPayload === '' && (
                <div className={styles.tx}>
                    <div className={styles.text}>
                        <span>Please,</span> sign the permit in your wallet
                    </div>
                </div>
            )}
        </div>
    )
}

export { PendingV2 }
