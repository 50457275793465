import { BigNumber } from 'ethers'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setBatchAllInputValues, setBatchOutputValue } from 'store/slicers/batch'
import { setInputValue } from 'store/slicers/input'
import { setLimitOrderInputValues, setLimitOrderOutputValue } from 'store/slicers/limitOrder'
import { setOutputValue } from 'store/slicers/output'
import { setReset } from 'store/slicers/settings'

export const useReset = (): {
    resetInputs: () => Promise<void>
} => {
    const dispatch = useDispatch()
    const batchInputTokens = useSelector((state: RootState) => state.batch.inputTokens)

    const resetInputs = async (): Promise<void> => {
        dispatch(setReset(true))
        dispatch(setBatchAllInputValues(batchInputTokens.map(() => BigNumber.from(0))))
        dispatch(setBatchOutputValue(BigNumber.from(0)))
        dispatch(setInputValue(BigNumber.from(0)))
        dispatch(setOutputValue(BigNumber.from(0)))
        dispatch(setLimitOrderInputValues(BigNumber.from(0)))
        dispatch(setLimitOrderOutputValue(BigNumber.from(0)))
    }

    return { resetInputs }
}
