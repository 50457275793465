import { validateAndParseAddress } from 'starknet'
import type { Token } from 'types/token'

export function addressToId(address: string, tokenList: Array<Token>): number {
    for (let i = 0; i < tokenList.length; i++) {
        if (validateAndParseAddress(tokenList[i].address) === validateAndParseAddress(address)) {
            return i
        }
    }
    return 0
}
