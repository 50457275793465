import { CONFIG } from 'config/config'
import { validateAndParseAddress } from 'starknet'

export const getUserNonce = async (signer: string) => {
    const response = await fetch(
        `${CONFIG.limit_order_api}order/nonce?walletAddress=${validateAndParseAddress(signer)}`,
    )
    const data = await response.json()
    return data.data + 1
}
