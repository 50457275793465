import COPY from 'assets/copyDark.svg'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import { PendingState } from 'types/pending'
import { parseAddress } from 'utils/parseAddress'

import styles from './BatchPending.module.scss'

const BatchPending = (): JSX.Element => {
    const pendingStatus = useSelector((state: RootState) => state.batchPending.status)
    const pendingPayload = useSelector((state: RootState) => state.batchPending.payload)
    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)

    return (
        <div className={styles.wrapper}>
            {pendingStatus === PendingState.PENDING && pendingPayload !== '' && (
                <a
                    target={'_blank'}
                    rel="noreferrer"
                    className={styles.tx}
                    href={explorerUrl.concat(`tx/${pendingPayload}`)}
                >
                    <div className={styles.text}>
                        <span className={styles.submitted}>Transaction Submitted.</span>
                        <div className={styles.urlWrapper}>
                            <img src={COPY} alt="copy"></img>
                            <span className={styles.url}>Tx: {parseAddress(pendingPayload)}</span>
                        </div>
                    </div>
                </a>
            )}
            {pendingStatus === PendingState.PENDING && pendingPayload === '' && (
                <div className={styles.tx}>
                    <div className={styles.text}>
                        <span>Waiting</span>
                        &nbsp; for your signature to execute swap!
                    </div>
                </div>
            )}
        </div>
    )
}

export { BatchPending }
