import WHITEWALLET from 'assets/white-wallet.svg'
import { SelectToken } from 'components/SelectToken/SelectToken'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useGetBalances } from 'hooks/useGetBalancesFromAPI'
import { useModal } from 'hooks/useModal'
import ReactLoading from 'react-loading'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { WAITING } from 'types/route'
import { isThereBatchInput } from 'utils/checkButtonDisable'
import { formatValue } from 'utils/formatValue'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './Output.module.scss'

const Output = (): JSX.Element => {
    const { getBalances } = useGetBalances()
    const selectInputTokenModal = useModal()

    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })

    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const batchOutputToken = useSelector((state: RootState) => state.batch.outputToken)
    const batchOutputValue = useSelector((state: RootState) => state.batch.outputValue)

    const batchInputValues = useSelector((state: RootState) => state.batch.inputValues)
    const isBatchWaiting = useSelector((state: RootState) => state.batch.isBatchWaiting)
    const routeResult = useSelector((state: RootState) => state.batch.routerResponses)

    const formatOutputValue = (): string => {
        if (
            BigNumber.from(batchOutputValue).lte(0) ||
            !(isBatchWaiting === WAITING.FALSE) ||
            !isThereBatchInput(batchInputValues)
        ) {
            return '0'
        } else {
            return formatValue(
                setFixedNumber(utils.formatUnits(batchOutputValue, CONFIG.tokenList[batchOutputToken].decimals)),
            )
        }
    }

    const handleTokenPrice = (): string => {
        if (routeResult.length === 0 || !(BigNumber.from(batchOutputValue).gt(0) || isBatchWaiting === WAITING.PENDING))
            return '$0'
        const currentPrice = Number(routeResult[0].outputToken.price)
        const value = utils.formatUnits(batchOutputValue, CONFIG.tokenList[batchOutputToken].decimals)
        const price = (Number(value) * currentPrice).toFixed(2)
        const numberLength: number = value.length
        const returnValue: string = price[numberLength - 1] === '0' ? Number(price).toFixed(2) : price
        return returnValue === '0.00' ? 'Unavailable' : '$' + formatValue(returnValue)
    }

    return (
        <>
            <SelectToken type="batchOutput" modal={selectInputTokenModal} />
            <div className={styles.wrapper}>
                <div className={styles.input}>
                    <div className={styles.balance}>
                        <div className={styles.wallet}>
                            <img src={WHITEWALLET} alt="walletlogo"></img>
                            {formatValue(
                                setFixedNumber(
                                    utils.formatUnits(
                                        tokenAmounts[batchOutputToken],
                                        CONFIG.tokenList[batchOutputToken].decimals,
                                    ),
                                ),
                            )}
                        </div>
                    </div>
                    <div
                        className={styles.inputToken}
                        onClick={(): void => {
                            selectInputTokenModal.open()
                            getBalances(true)
                        }}
                    >
                        <div className={styles.hover}>
                            <img
                                src={CONFIG.tokenList[batchOutputToken].imageUrl}
                                className={styles.logo}
                                height={isMobile ? 25 : 35}
                                width={isMobile ? 25 : 35}
                                alt="logo"
                            />
                            <div className={styles.name}>{CONFIG.tokenList[batchOutputToken].symbol}</div>
                        </div>
                    </div>
                    <div className={styles.swapInput}>
                        {isBatchWaiting === WAITING.PENDING ? (
                            <div className={styles.spin}>
                                <ReactLoading type={'spin'} color={'#00ADB5'} height={30} width={30} />
                            </div>
                        ) : (
                            <input
                                className={styles.swapInput}
                                autoComplete="off"
                                autoCorrect="off"
                                type="text"
                                inputMode="decimal"
                                pattern="^[0-9]*[.,]?[0-9]*$"
                                minLength={1}
                                maxLength={79}
                                spellCheck="false"
                                placeholder={'0.0'}
                                value={formatOutputValue()}
                                disabled={true}
                            />
                        )}
                    </div>
                    {routeResult && Number(batchOutputValue) > 0 && isBatchWaiting !== WAITING.PENDING && (
                        <div className={styles.outputPrice}>{handleTokenPrice()}</div>
                    )}
                </div>
            </div>
        </>
    )
}

export { Output }
