/* eslint-disable */
import { utils } from 'ethers'
import type { num } from 'starknet'
import { uint256 } from 'starknet'

export const parseAddress = (address: string): string => {
    return address?.substring?.(0, 5) + '...' + address?.substring?.(address?.length - 5)
}

export const parseAddressMobile = (address: string): string => {
    return address?.substring?.(0, 3) + '...' + address?.substring?.(address?.length - 2)
}

export function getUint256CalldataFromBN(bn: num.BigNumberish) {
    return { type: 'struct' as const, ...uint256.bnToUint256(bn) }
}
export function parseInputAmountToUint256(input: string, decimals = 18) {
    return getUint256CalldataFromBN(utils.parseUnits(input, decimals).toString())
}

export function parseInputAmountToUint256ExecuteCall(input: string, decimals: number) {
    const _parsedAmount = uint256.bnToUint256(utils.parseUnits(input, decimals).toString())
    return [_parsedAmount.low, _parsedAmount.high]
}

export function parseStarkname(domain: string): string {
    if (domain.length < 18) {
        return domain
    }

    return domain.slice(0, 5) + '...' + domain.slice(domain.length - 5)
}

export function parseTokenName(name: string): string {
    if (name.length < 7) {
        return name
    }

    return name.slice(0, 4) + '...' + name.slice(name.length - 3)
}
