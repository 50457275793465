import AND from 'assets/and.svg'
import LIGHTAND from 'assets/andLight.svg'
import EKUBO from 'assets/ekubo.svg'
import GRAYLOGO from 'assets/logo-for-darkv3.svg'
import WHITELOGO from 'assets/logo-for-lightv3.svg'
import type { ModalController } from 'hooks/useModal'
import { useTheme } from 'hooks/useTheme'
import { Button, Modal } from 'ui'

import styles from './Ekubo.module.scss'

const Ekubo = ({ modal }: { modal: ModalController }): JSX.Element => {
    const { theme } = useTheme()

    return (
        <Modal className={styles.wrapper} isOpen={modal.isOpen} close={modal.close} closeOnClickOutside={false}>
            <div className={styles.title}> Welcome to Ekubo!</div>

            <div className={styles.ekuboWrapper}>
                <img src={theme === 'dark' ? GRAYLOGO : WHITELOGO} className={styles.logo} alt="icon" />

                <img src={theme === 'dark' ? AND : LIGHTAND} className={styles.and} alt="icon" />

                <img src={EKUBO} className={styles.ekubo} alt="icon" />
            </div>
            <div className={styles.text}>
                Fibrous serves as your reliable exchange platform UI, simplifying transactions with Ekubo's
                state-of-the-art concentrated liquidity technology. For assistance, please visit Ekubo's{' '}
                <a href="https://discord.gg/MqAFVpWCuY" target="_blank" rel="noopener noreferrer">
                    Discord
                </a>{' '}
                or{' '}
                <a href="https://t.me/Ekubo_Protocol" target="_blank" rel="noopener noreferrer">
                    Telegram
                </a>{' '}
                channels.
            </div>

            <Button
                onClick={(): void => {
                    localStorage.setItem('disclaimer', 'true')
                    modal.close()
                }}
                className={styles.swap}
                width={'100%'}
                height={'70px'}
                fontWeight="fw500"
                fontSize="fs20"
                color={'swap'}
            >
                Take me to Ekubo!
            </Button>
        </Modal>
    )
}

export { Ekubo }
