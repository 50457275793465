import DARKARROW from 'assets/darkArrow.svg'
import WHITEARROW from 'assets/whiteArrow.svg'
import { SelectToken } from 'components'
import { CONFIG } from 'config/config'
import { BigNumber } from 'ethers'
import { useGetBalances } from 'hooks/useGetBalancesFromAPI'
import { useModal } from 'hooks/useModal'
import { useTheme } from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setInputToken, setInputValue } from 'store/slicers/input'
import { setOutputToken } from 'store/slicers/output'
import { PendingState } from 'types/pending'
import { Icon } from 'ui'
import { clsnm } from 'utils/clsnm'

import styles from './TokenBase.module.scss'

const TokenBase = (): JSX.Element => {
    const [isrotate, setIsrotate] = useState(false)

    const dispatch = useDispatch()
    const { getBalances } = useGetBalances()
    const { theme } = useTheme()
    const selectInputTokenModal = useModal()
    const selectOutputTokenModal = useModal()

    const inputToken = useSelector((state: RootState) => state.input.token)
    const outputToken = useSelector((state: RootState) => state.output.token)
    const outputValue = useSelector((state: RootState) => state.output.value)
    const pendingStatus = useSelector((state: RootState) => state.pending.status)

    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })

    const switchTokens = (): void => {
        setIsrotate(true)
        const current = inputToken
        dispatch(setInputToken(outputToken))
        dispatch(setOutputToken(current))
        dispatch(setInputValue(BigNumber.from(outputValue)))
        setTimeout(() => {
            setIsrotate(false)
        }, 500)
    }

    useEffect(() => {
        getBalances(true)
    }, [pendingStatus === PendingState.SUCCESSFUL])

    return (
        <>
            <div
                className={clsnm(
                    styles.tokens,
                    (selectInputTokenModal.isOpen || selectOutputTokenModal.isOpen) && styles.includeBorder,
                )}
            >
                <div
                    className={styles.input}
                    onClick={(): void => {
                        selectInputTokenModal.open()
                        selectOutputTokenModal.close()
                        getBalances(true)
                    }}
                >
                    <div className={styles.hover}>
                        <img
                            className={styles.logo}
                            src={CONFIG.tokenList[inputToken].imageUrl}
                            height={isMobile ? 24 : 24}
                            width={isMobile ? 24 : 24}
                            alt="logo"
                        />
                        <div className={styles.name}>{CONFIG.tokenList[inputToken].symbol}</div>
                    </div>
                </div>
                <div className={styles.ok + (isrotate ? ` ${styles.rotate}` : '')} onClick={(): void => switchTokens()}>
                    <Icon className={styles.icon}>
                        <img src={theme === 'dark' ? WHITEARROW : DARKARROW} alt="arrow" />
                    </Icon>
                </div>
                <div
                    className={styles.output}
                    onClick={(): void => {
                        selectInputTokenModal.close()
                        selectOutputTokenModal.open()
                        getBalances(true)
                    }}
                >
                    <div className={styles.hover}>
                        <img
                            className={styles.logo}
                            src={CONFIG.tokenList[outputToken].imageUrl}
                            height={isMobile ? 24 : 24}
                            width={isMobile ? 24 : 24}
                            alt="logo"
                        />
                        <div className={styles.name}>{CONFIG.tokenList[outputToken].symbol}</div>
                    </div>
                </div>
            </div>
            <SelectToken type="input" modal={selectInputTokenModal} />
            <SelectToken type="output" modal={selectOutputTokenModal} />
        </>
    )
}

export { TokenBase }
