import { useAccount, useProvider } from '@starknet-react/core'
import { STARKNET_CONTRACTS } from 'constants/addresses'
import { useDispatch } from 'react-redux'
import type { CallContractResponse } from 'starknet'
import { setRocksHolder } from 'store/slicers/starknet'

// import { useProvider } from './useGetProvider'

export const useIsHolder = (): {
    isHolder: () => Promise<void>
} => {
    const { provider } = useProvider()
    const { address } = useAccount()
    const dispatch = useDispatch()
    const isHolder = async (): Promise<void> => {
        if (!address) {
            return
        } else {
            const isHolderTX: CallContractResponse = await provider.callContract({
                contractAddress: STARKNET_CONTRACTS.STARKROCKS,
                entrypoint: 'balanceOf',
                calldata: [address.toLocaleLowerCase()],
            })

            if (isHolderTX) {
                dispatch(setRocksHolder(Number(isHolderTX?.result[0]) ? true : false))
            } else {
                dispatch(setRocksHolder(false))
            }
        }
    }

    return {
        isHolder,
    }
}
