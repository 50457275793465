import { AMM } from 'types/amm'

export const TENKSWAP = new AMM({
    name: '10K Swap',
    label: '3',
})

export const MYSWAP = new AMM({
    name: 'MySwap',
    label: '1',
})

export const JEDISWAP = new AMM({
    name: 'JediSwap',
    label: '2',
})

export const SITHSWAP = new AMM({
    name: 'SithSwap',
    label: '4',
})

export const EKUBO = new AMM({
    name: 'Ekubo',
    label: '5',
})

export const MYSWAPCL = new AMM({
    name: 'MySwap CL',
    label: '6',
})

export const STARKDEFI = new AMM({
    name: 'StarkDeFi',
    label: '7',
})

export const JEDISWAPCL = {
    name: 'JediSwap CL',
    label: '8',
}

export const NOSTRA = {
    name: 'Nostra',
    label: '9',
}

export const HAIKO = {
    name: 'Haiko',
    label: '10',
}

export const AMMS = [MYSWAP, JEDISWAP, TENKSWAP, SITHSWAP, EKUBO, MYSWAPCL, STARKDEFI, JEDISWAPCL, NOSTRA, HAIKO]
