import { useAccount, useNetwork } from '@starknet-react/core'
import { BatchConfirm } from 'components'
import { CONFIG } from 'config/config'
import type { ModalController } from 'hooks/useModal'
import { useModalWithState } from 'hooks/useModalWithState'
import { Toaster, toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setApprovePermision, setBatchConfirm } from 'store/slicers/settings'
import { setConnectModal } from 'store/slicers/settings'
import { Button } from 'ui'
import { checkButtonDisable, checkButtonText, haveEnoughToken, isThereBatchInput } from 'utils/checkButtonDisable'
import { clsnm } from 'utils/clsnm'

import styles from './BatchSwapButton.module.scss'

const BatchSwapButton = ({ pendingsModal }: { pendingsModal: ModalController }): JSX.Element => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })
    const { account } = useAccount()
    const { chain } = useNetwork()

    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)

    const batchInputTokens = useSelector((state: RootState) => state.batch.inputTokens)
    const batchInputValues = useSelector((state: RootState) => state.batch.inputValues)
    const isBatchWaiting = useSelector((state: RootState) => state.batch.isBatchWaiting)
    const batchConfirm = useSelector((state: RootState) => state.settings.batchConfirm)
    const batchConfirmModal = useModalWithState(batchConfirm, setBatchConfirm)

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <BatchConfirm modal={batchConfirmModal} pendingsModal={pendingsModal} />
            <div
                className={
                    !isThereBatchInput(batchInputValues)
                        ? clsnm(styles.swapButton, styles.disabled)
                        : !haveEnoughToken(batchInputValues, batchInputTokens, tokenAmounts)
                          ? clsnm(styles.swapButton, styles.error)
                          : styles.swapButton
                }
            >
                <Button
                    className={styles.swap}
                    width={'100%'}
                    height={isMobile ? '70px' : '70px'}
                    fontWeight="fw800"
                    color={'swap'}
                    disabled={
                        account && checkButtonDisable(batchInputValues, batchInputTokens, isBatchWaiting, tokenAmounts)
                    }
                    onClick={(): void => {
                        if (!account) {
                            dispatch(setConnectModal(true))
                        } else {
                            if (chain.testnet) {
                                toast.error(`${CONFIG.toast_network_error_message}`)
                            } else {
                                dispatch(setApprovePermision(false))
                                batchConfirmModal.open()
                            }
                        }
                    }}
                >
                    <span>
                        {checkButtonText(batchInputValues, batchInputTokens, tokenAmounts, account ? true : false)}
                    </span>
                </Button>
            </div>
        </>
    )
}

export { BatchSwapButton }
