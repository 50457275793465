import axios from 'axios'
import { CONFIG } from 'config/config'
import { BigNumber } from 'ethers'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setBatchOutputValue, setBatchRouterResponses, setIsBatchWaiting } from 'store/slicers/batch'
import { setOutputValue } from 'store/slicers/output'
import {
    setPath,
    setPathInfo,
    setProtocols,
    setRouteResult,
    setRouteTime,
    setRoutes,
    setWaiting,
} from 'store/slicers/route'
import { ExampleRouteResult, WAITING } from 'types/route'
import type { RouterResponse } from 'types/route'
import { setRouterData } from 'utils/formatRouterData'
import { getExcludedAMMs } from 'utils/getExcludedAMMs'
import { isAllFalse } from 'utils/isAllFalse'
import { setAmounts } from 'utils/setAmounts'
import { setBatchTokenAddresses } from 'utils/setBatchTokenAddresses'

export const useGetPath = (): {
    getPath: () => Promise<void>
    getBatchPath: () => Promise<void>
    sendUserInfos: (_userAddress: string, _txHash: string) => Promise<void>
    getBatchPathOne: () => Promise<void>
} => {
    const dispatch = useDispatch()
    const cancelTokenSource = useRef(axios.CancelToken.source())
    const currentInputValue = useRef<BigNumber | null>(null)

    const inputValue = useSelector((state: RootState) => state.input.value)
    const inputToken = useSelector((state: RootState) => state.input.token)
    const outputToken = useSelector((state: RootState) => state.output.token)

    const batchInputValues = useSelector((state: RootState) => state.batch.inputValues)
    const batchInputTokens = useSelector((state: RootState) => state.batch.inputTokens)
    const batchOutputToken = useSelector((state: RootState) => state.batch.outputToken)
    const liquiditySources = useSelector((state: RootState) => state.settings.liquiditySources)

    const dynamicKey =
        inputToken === 10
            ? '0x0782f0ddca11d9950bc3220e35ac82cf868778edb67a5e58b39838544bc4cd0f'
            : '0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d'

    const pathInfoPower = [
        [
            {
                [dynamicKey]: {
                    protocols: [5],
                    percents: ['100.00%'],
                },
            },
        ],
    ]

    useEffect(() => {
        currentInputValue.current = inputValue
    }, [inputValue])

    const getPath = async (): Promise<void> => {
        if (isAllFalse(liquiditySources)) return

        cancelTokenSource.current.cancel('Operation canceled due to new request.')

        cancelTokenSource.current = axios.CancelToken.source()

        try {
            dispatch(setWaiting(WAITING.PENDING))

            const response = await axios.get(
                `${CONFIG.base_api}route?amount=${inputValue._hex}&tokenInAddress=${CONFIG.tokenList[inputToken].address}&tokenOutAddress=${CONFIG.tokenList[outputToken].address}${getExcludedAMMs(liquiditySources)}`,
                {
                    cancelToken: cancelTokenSource.current.token,
                },
            )

            if (axios.isCancel(response)) {
                console.log('Request canceled:', response.message)
                return
            }

            if (response && currentInputValue.current === inputValue) {
                const { path, pathInfo } = setRouterData(response.data)

                dispatch(setRouteResult(response.data))
                dispatch(setRouteTime(response.data.time))
                dispatch(setWaiting(WAITING.FALSE))
                dispatch(setPath(path))
                dispatch(setPathInfo(pathInfo))
                dispatch(setRoutes(response.data.route))
                dispatch(setProtocols(response.data.route[0].swaps))
                dispatch(setOutputValue(response.data.outputAmount))
            }

            if ((inputToken === 10 && outputToken === 12) || (inputToken === 12 && outputToken === 10)) {
                dispatch(setOutputValue(inputValue))
                dispatch(
                    setPath([
                        [
                            '0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d',
                            '0x0782f0ddca11d9950bc3220e35ac82cf868778edb67a5e58b39838544bc4cd0f',
                        ],
                    ]),
                )
                dispatch(setPathInfo(pathInfoPower))
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message)
            } else {
                dispatch(setWaiting(WAITING.ERROR))
                console.error('Failed to get path:', error)
            }
        }
    }

    const getBatchPath = async (): Promise<void> => {
        if (isAllFalse(liquiditySources)) return
        const urls: Array<string> = batchInputValues.map((inputValue: BigNumber, i: number) => {
            if (!(Number(inputValue) > 0)) {
                return ''
            }
            return `${CONFIG.base_api}route?amount=${inputValue._hex}&tokenInAddress=${
                CONFIG.tokenList[batchInputTokens[i]].address
            }&tokenOutAddress=${CONFIG.tokenList[batchOutputToken].address}${getExcludedAMMs(liquiditySources)}
                    `
        })
        const requests = urls.map(async (url: string) => {
            if (url === '')
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve({ data: ExampleRouteResult })
                    }, 1)
                })

            return axios.get(url)
        })
        let result = BigNumber.from(0)
        const routerResponses: Array<RouterResponse> = []
        Promise.all(requests).then((responses) => {
            let err = false

            responses.forEach((response: any) => {
                if (typeof response.data === 'string') {
                    err = true
                } else {
                    result = result.add(response.data.outputAmount)
                    routerResponses.push(response.data)
                }
            })
            if (!err) {
                dispatch(setBatchOutputValue(result))
                dispatch(setBatchRouterResponses(routerResponses))
                dispatch(setIsBatchWaiting(WAITING.FALSE))
            }
        })
    }

    const getBatchPathOne = async (): Promise<void> => {
        if (isAllFalse(liquiditySources)) return
        await axios

            .get<any>(
                `
        ${CONFIG.base_api}routeBatch?amounts=${setAmounts(batchInputValues)}&tokenInAddresses=${setBatchTokenAddresses(
            batchInputTokens,
            CONFIG.tokenList,
        )}&tokenOutAddresses=${setBatchTokenAddresses(
            batchInputTokens,
            CONFIG.tokenList,
            CONFIG.tokenList[batchOutputToken].address,
        )}${getExcludedAMMs(liquiditySources)}`,
            )
            .then((res) => {
                let totalOutput = BigNumber.from(0)

                for (let i = 0; i < res.data.length; i++) {
                    totalOutput = totalOutput.add(BigNumber.from(res.data[i].outputAmount))
                }
                dispatch(setBatchOutputValue(totalOutput))
                dispatch(setBatchRouterResponses(res.data))
                dispatch(setIsBatchWaiting(WAITING.FALSE))
            })
    }

    const sendUserInfos = async (_userAddress: string, _txHash: string): Promise<void> => {
        await axios

            .post<any>('https://api.fibrous.finance/swapLog', {
                userAddress: _userAddress,
                txHash: _txHash,
            })

            .then((res) => {
                console.log(
                    "We are aware that every transaction takes far too long. Don't be concerned. Summer is coming this time for Stark House! Patience is a virtue.",
                )
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return { getPath, getBatchPath, sendUserInfos, getBatchPathOne }
}
