import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import styled from 'styled-components'

const BORDERCOLOR = '#384A66'

export const ChannelsForOne = styled.div`
    width: 272px;
    left: 25px;
    position: absolute;
    height: 120px;
    z-index: 0;
    border: 1px solid ${BORDERCOLOR};
    border-radius: 20px;
`

export const ChannelsForMulti = styled.div`
    width: 272px;
    left: 25px;
    position: absolute;
    z-index: 0;
    border: 1px solid ${BORDERCOLOR};
    border-radius: 20px;
`

export const ChannelFirst = styled.div`
    width: 272px;
    left: 0px;
    top: -18px;
    position: absolute;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 39px;
`

export const Channel = styled.div`
    width: 272px;
    left: 0px;
    transform: translateY(-50%);
    position: absolute;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 39px;
`

export const ChannelLast = styled.div`
    width: 272px;
    left: 0px;
    bottom: -18px;
    position: absolute;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 39px;
`

export const HopsForOne = styled.div`
    width: 176px;
    padding-right: 28px;
    flex-shrink: 0;
    padding-left: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
`

export const HopsForMulti = styled.div`
    width: 100%;
    width: 190px;
    padding-right: 25px;
    flex-shrink: 0;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
`

export const JustHop = styled.div`
    width: 175px;
    padding-right: 28px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`

export const EmptyHop = styled.div`
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
`

export const Hop = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px ${BORDERCOLOR} solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background);
`

export const HopTokenLogo = styled.img`
    width: 25px;
    height: 25px;
    border-radius: 50%;
`

export const RouterOk = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    width: 51px;
    left: 43px;
`

export const Rate = styled.div`
    font-family: 'Rubik';
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    border-radius: 15px;
    color: var(--default-text-8);
    left: 93px;
    background-color: var(--background);
    height: 15px;
    width: 25px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
`

export const Line = styled.hr`
    position: absolute;
    width: 270px;
    left: 2px;
    z-index: -2;
    border: 0.1px solid ${BORDERCOLOR};
`

interface ChannelReturnerProps extends ComponentPropsWithoutRef<'div'> {
    path: Array<Array<string>>
    children: ReactNode
    channel: number
}

export const ChannelReturner = ({ path, children, channel, ...props }: ChannelReturnerProps): JSX.Element | null => {
    return channel === 0 ? (
        <ChannelFirst {...props}>{children}</ChannelFirst>
    ) : channel === path.length - 1 ? (
        <ChannelLast {...props}>{children}</ChannelLast>
    ) : channel > 0 && channel < path.length - 1 ? (
        <Channel
            style={{
                top: ((100 / (path.length - 1)) * channel).toString() + '%',
            }}
            {...props}
        >
            {children}
        </Channel>
    ) : null
}
