export const parseValues = (address: string): string => {
    if (address.length > 10) {
        return address?.substring?.(0, 5) + '...' + address?.substring?.(address?.length - 5)
    } else {
        return address
    }
}

export const shortenNumber = (numberStr: string, precision: number = 2): string => {
    const number = parseFloat(numberStr.replace(/,/g, ''))

    const thresholds: Array<[number, string]> = [
        [1_000_000_000, 'B'],
        [1_000_000, 'M'],
    ]

    for (const [threshold, suffix] of thresholds) {
        if (number >= threshold) {
            return `${(number / threshold).toFixed(precision)}${suffix}`
        }
    }

    return numberStr
}
