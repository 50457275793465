import type { BigNumber } from 'ethers'
import { useDispatch } from 'react-redux'
import { setBatchInputTokens, setBatchInputValues } from 'store/slicers/batch'

export const useSetBatchValues = (): {
    setBatchInputToken: (key: number, token: number) => void
    setBatchInputValue: (key: number, value: BigNumber) => void
} => {
    const dispatch = useDispatch()

    const setBatchInputToken = (key: number, token: number): void => {
        dispatch(setBatchInputTokens({ key: key, token: token }))
    }

    const setBatchInputValue = (key: number, value: BigNumber): void => {
        dispatch(setBatchInputValues({ key: key, value: value }))
    }

    return {
        setBatchInputToken,
        setBatchInputValue,
    }
}
