import { useAccount, useWaitForTransaction } from '@starknet-react/core'
import { FailedV2 } from 'components/LimitOrderPendings/FailedV2/FailedV2'
import { PendingV2 } from 'components/LimitOrderPendings/PendingV2/PendingV2'
import { SuccessfulV2 } from 'components/LimitOrderPendings/SuccessfulV2/SuccessfulV2'
import type { ModalController } from 'hooks/useModal'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setPendingPayload, setPendingStatus } from 'store/slicers/limitOrder'
import type { InvokeTransactionReceiptResponse } from 'types/network'
import { PendingState } from 'types/pending'
import { ModalV2 } from 'ui'

import styles from './LimitOrderPendings.module.scss'

const LimitOrderPendings = ({ modal }: { modal: ModalController }): JSX.Element => {
    const { account } = useAccount()
    const dispatch = useDispatch()

    const inputToken = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const outputToken = useSelector((state: RootState) => state.limitOrder.outputToken)
    const outputValue = useSelector((state: RootState) => state.limitOrder.outputValue)
    const inputValue = useSelector((state: RootState) => state.limitOrder.inputValues)

    const pendingStatus = useSelector((state: RootState) => state.limitOrder.status)
    const pendingPayload = useSelector((state: RootState) => state.limitOrder.payload)

    const { data, isError } = useWaitForTransaction({
        hash: pendingPayload,
        watch: true,
        retry: true,
    })

    useEffect(() => {
        if ((data as InvokeTransactionReceiptResponse)?.execution_status === 'REJECTED' || isError) {
            dispatch(setPendingStatus(PendingState.FAILED))
        } else if ((data as InvokeTransactionReceiptResponse)?.finality_status === 'ACCEPTED_ON_L2') {
            dispatch(setPendingStatus(PendingState.SUCCESSFUL))
        } else {
            dispatch(setPendingStatus(PendingState.PENDING))
        }
    }, [data])

    useEffect(() => {
        if (modal.isOpen === true) return

        dispatch(setPendingPayload(''))
        dispatch(setPendingStatus(PendingState.PENDING))
    }, [modal.isOpen])

    useEffect(() => {
        if (pendingStatus === PendingState.SUCCESSFUL) {
            const timer = setTimeout(() => {
                modal.close()
            }, 15000)

            return () => clearTimeout(timer)
        }
    }, [pendingStatus])

    return (
        <ModalV2 isOpen={modal.isOpen} close={modal.close} className={styles.wrapper} closeOnClickOutside={false}>
            <div className={styles.content}>
                {pendingStatus === PendingState.SUCCESSFUL && data ? (
                    <SuccessfulV2 />
                ) : pendingStatus === PendingState.FAILED ? (
                    <FailedV2 />
                ) : (
                    <PendingV2 />
                )}
            </div>
        </ModalV2>
    )
}

export { LimitOrderPendings }
