import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { BigNumber } from 'ethers'
import type { RouterResponse } from 'types/route'
import { removeNumberFromArray, removeStringFromArray } from 'utils/removeFromArray'
import { setBatchToken } from 'utils/setBatchToken'

import { WAITING } from '../../types/route'
import { CONFIG } from 'config/config'

export interface BatchState {
    isBatch: boolean
    inputTokens: Array<number>
    inputValues: Array<BigNumber>
    outputToken: number
    outputValue: BigNumber
    routerResponses: Array<RouterResponse>
    isBatchWaiting: WAITING
}

const initialState: BatchState = {
    isBatch: false,
    inputTokens: [0, 1],
    inputValues: [BigNumber.from(0), BigNumber.from(0)],
    outputToken: 2,
    outputValue: BigNumber.from(0),
    routerResponses: [],
    isBatchWaiting: WAITING.TRUE,
}

export const batchSlice = createSlice({
    name: 'batch',
    initialState,
    reducers: {
        setIsBatch: (state, action: PayloadAction<boolean>) => {
            state.isBatch = action.payload
        },
        addBatchInput: (state) => {
            if (state.inputTokens.length < 3) {
                state.inputTokens.push(setBatchToken(state.inputTokens, state.outputToken, CONFIG.tokenList))
                state.inputValues.push(BigNumber.from(0))
            }
        },
        removeBatchInput: (state, action: PayloadAction<number>) => {
            if (state.inputTokens.length > 2) {
                state.inputTokens = removeNumberFromArray(state.inputTokens, action.payload)
                state.inputValues = removeStringFromArray(state.inputValues, action.payload)
            } else {
                state.isBatch = false
                state.inputTokens = [0, 1]
                state.inputValues = [BigNumber.from(0), BigNumber.from(0)]
                state.outputToken = 2
                state.outputValue = BigNumber.from(0)
                state.routerResponses = []
                state.isBatchWaiting = WAITING.TRUE
            }
        },

        setBatchInputTokens: (state, action: PayloadAction<{ key: number; token: number }>) => {
            state.inputTokens[action.payload.key] = action.payload.token
        },

        setBatchInputValues: (state, action: PayloadAction<{ key: number; value: BigNumber }>) => {
            state.inputValues[action.payload.key] = action.payload.value
        },
        setBatchAllInputValues: (state, action: PayloadAction<Array<BigNumber>>) => {
            state.inputValues = action.payload
        },
        setBatchOutputToken: (state, action: PayloadAction<number>) => {
            state.outputToken = action.payload
        },
        setBatchOutputValue: (state, action: PayloadAction<BigNumber>) => {
            state.outputValue = action.payload
        },
        setBatchRouterResponses: (state, action: PayloadAction<Array<RouterResponse>>) => {
            state.routerResponses = action.payload
        },
        setIsBatchWaiting: (state, action: PayloadAction<WAITING>) => {
            state.isBatchWaiting = action.payload
        },
    },
})

export const {
    setIsBatch,
    setBatchInputTokens,
    setBatchInputValues,
    setBatchOutputToken,
    setBatchOutputValue,
    addBatchInput,
    removeBatchInput,
    setBatchRouterResponses,
    setIsBatchWaiting,
    setBatchAllInputValues,
} = batchSlice.actions
export default batchSlice.reducer
