import { useContractWrite, useWaitForTransaction } from '@starknet-react/core'
import { STARKNET_CONTRACTS } from 'constants/addresses'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsSigned, setPendingPayload, setPendingStatus } from 'store/slicers/limitOrder'
import type { InvokeTransactionReceiptResponse } from 'types/network'
import { PendingState } from 'types/pending'

export const useCancelOrders = (
    nonce: Array<number>,
): {
    cancelOrders: () => Promise<void>
} => {
    const dispatch = useDispatch()

    const [hash, setHash] = useState<string>('')

    const cancelOrdersCall = {
        contractAddress: STARKNET_CONTRACTS.LIMIT_ORDER_ADDRESS,
        entrypoint: 'cancelOrders',
        calldata: [nonce.length.toString(), ...nonce.map((n) => n.toString())],
    }

    const { writeAsync } = useContractWrite({ calls: [cancelOrdersCall] })

    const { data, isError } = useWaitForTransaction({
        hash,
        watch: true,
        retry: true,
    })

    useEffect(() => {
        if ((data as InvokeTransactionReceiptResponse)?.execution_status === 'REJECTED' || isError) {
            dispatch(setPendingStatus(PendingState.FAILED))
        } else if ((data as InvokeTransactionReceiptResponse)?.finality_status === 'ACCEPTED_ON_L2') {
            dispatch(setPendingStatus(PendingState.SUCCESSFUL))
        } else {
            dispatch(setPendingStatus(PendingState.PENDING))
        }
    }, [data])

    const cancelOrders = async (): Promise<void> => {
        await writeAsync()
            .then(async (tx: any) => {
                setHash(tx.transaction_hash)
                dispatch(setIsSigned(false))
                dispatch(setPendingPayload(tx.transaction_hash))
            })
            .catch((err: any) => {
                dispatch(setIsSigned(false))
                dispatch(setPendingStatus(PendingState.FAILED))
            })
    }

    return { cancelOrders }
}
