import { useAccount } from '@starknet-react/core'
import axios from 'axios'
import { useCurrentUserData, useRankData, useRankingData, useSearchedUserInfo } from 'hooks/useArenaHooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { validateAndParseAddress } from 'starknet'
import type { RootState } from 'store'
import 'swiper/css'
import 'swiper/css/pagination'
import { isValidStarknetAddressOrDomain } from 'utils/arenaUtils'
import { parseAddress } from 'utils/parseAddress'

import { EVENTS } from '../../config/config'
import styles from './Arena.module.scss'
import EventDescription from './EventDescription/EventDescription'
import EventSlider from './EventSlider/EventSlider'
import SearchBox from './SearchBox/SearchBox'
import UserInfo from './UserInfo/UserInfo'

const Arena = (): JSX.Element => {
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [inputValue, setInputValue] = useState<string>('')
    const [addressDomains, setAddressDomains] = useState<Record<string, string>>({})
    const [lastSearchedAddress, setLastSearchedAddress] = useState<string | null>(null)

    const { address } = useAccount()
    const domain = useSelector((state: RootState) => state.starknet.domain)

    const rankingData = useRankingData(activeIndex)
    const rankData = useRankData(activeIndex)
    const currentUserData = useCurrentUserData(activeIndex, address)
    const searchedUserInfo = useSearchedUserInfo(activeIndex, lastSearchedAddress)

    const displayValue = useMemo(() => {
        if (lastSearchedAddress) {
            if (lastSearchedAddress === address) {
                return domain || parseAddress(validateAndParseAddress(address))
            } else {
                return addressDomains[lastSearchedAddress] || parseAddress(validateAndParseAddress(lastSearchedAddress))
            }
        } else if (address) {
            if (domain) {
                return domain
            } else {
                return parseAddress(validateAndParseAddress(address))
            }
        } else {
            return '0xfibrous'
        }
    }, [lastSearchedAddress, address, domain, addressDomains])

    const handleSearchSubmit = useCallback(async (): Promise<void> => {
        if (!inputValue || !isValidStarknetAddressOrDomain(inputValue)) {
            return
        }

        let processedInput = inputValue.toLowerCase()
        if (processedInput.endsWith('.stark')) {
            try {
                const response = await axios.get(`https://api.starknet.id/domain_to_addr?domain=${processedInput}`)
                if (response.data && response.data.addr) {
                    processedInput = response.data.addr.replace(/^0x0+/, '0x')
                } else {
                    return
                }
            } catch (error) {
                return
            }
        }
        setLastSearchedAddress(processedInput.replace(/^0x0+/, '0x'))
        setInputValue('')
    }, [inputValue])

    useEffect(() => {
        rankingData.forEach(async (user) => {
            try {
                const response = await axios.get(`https://api.starknet.id/addr_to_domain?addr=${user.sender}`)
                if (response.data && response.data.domain) {
                    setAddressDomains((prevDomains) => ({
                        ...prevDomains,
                        [user.sender]: response.data.domain,
                    }))
                }
            } catch (error) {
                // console.error('Error fetching domain:', error)
            }
        })
    }, [rankingData])

    useEffect((): void => {
        if (!lastSearchedAddress) return
        ;(async (): Promise<void> => {
            try {
                const response = await axios.get(`https://api.starknet.id/addr_to_domain?addr=${lastSearchedAddress}`)
                if (response.data && response.data.domain) {
                    setAddressDomains((prevDomains) => ({
                        ...prevDomains,
                        [lastSearchedAddress]: response.data.domain,
                    }))
                }
            } catch (error) {
                // console.error('Error fetching domain for last searched address:', error)
            }
        })()
    }, [lastSearchedAddress])

    return (
        <div className={styles.wrapper}>
            {isMobile && <EventSlider onSlideChange={setActiveIndex} />}
            <div className={styles.header}>
                <EventDescription currentEvent={EVENTS[activeIndex]} />
                <SearchBox
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    handleSearchSubmit={handleSearchSubmit}
                />
            </div>
            <div className={styles.content}>
                {!isMobile && <EventSlider onSlideChange={setActiveIndex} />}
                <UserInfo
                    lastSearchedAddress={lastSearchedAddress}
                    address={address}
                    displayValue={displayValue}
                    rankingData={rankingData}
                    rankData={rankData}
                    activeIndex={activeIndex}
                    setLastSearchedAddress={setLastSearchedAddress}
                    EVENTS={EVENTS}
                    searchedUserInfo={searchedUserInfo}
                    currentUserData={currentUserData}
                    addressDomains={addressDomains}
                    validateAndParseAddress={validateAndParseAddress}
                    parseAddress={parseAddress}
                />
            </div>
        </div>
    )
}

export { Arena }
