import { useAccount, useNetwork, useStarkProfile } from '@starknet-react/core'
import AND from 'assets/and.svg'
import LIGHTAND from 'assets/andLight.svg'
import { ReactComponent as BETA } from 'assets/beta.svg'
// import MOBILELOGO from 'assets/christmasmobile.svg'
import DOOROPEN from 'assets/connectWallet.svg'
import EKUBO from 'assets/ekubo.svg'
import GRAYLOGO from 'assets/logo-for-darkv3.svg'
import WHITELOGO from 'assets/logo-for-lightv3.svg'
// import GRAYLOGO from 'assets/christmasdark.svg'
// import WHITELOGO from 'assets/christmaslight.svg'
import MOBILELOGO from 'assets/mobileLogo.svg'
import MOON from 'assets/moon.svg'
import { ReactComponent as LINE } from 'assets/navbar-line.svg'
import SUN from 'assets/sun.svg'
import { HamburgerMenu } from 'components/Navbar/HamburgerMenu/HamburgerMenu'
import { CONFIG } from 'config/config'
// import { useGetApproves } from 'hooks/useGetApproves'
import { useGetBalances } from 'hooks/useGetBalancesFromAPI'
import { useGetTokens } from 'hooks/useGetTokens'
import { useIsHolder } from 'hooks/useIsHolder'
import type { ModalController } from 'hooks/useModal'
import { useTheme } from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { validateAndParseAddress } from 'starknet'
import type { RootState } from 'store'
import { setInputToken } from 'store/slicers/input'
import { setOutputToken } from 'store/slicers/output'
import {
    setAllLiquiditySources,
    setApproves,
    setConnectModal,
    setDestinationAddress,
    setLiquiditySources,
} from 'store/slicers/settings'
import { setTokenAmounts } from 'store/slicers/starknet'
import { clsnm } from 'utils/clsnm'
import { parseAddress, parseStarkname } from 'utils/parseAddress'

import { useGetStarkname } from '../../hooks/useGetStarkname'
import styles from './Navbar.module.scss'

const Navbar = ({
    settingsModal,
    walletModal,
}: {
    settingsModal: ModalController
    walletModal: ModalController
}): JSX.Element => {
    const dispatch = useDispatch()
    const { account, address, status } = useAccount()
    const { chain } = useNetwork()

    const { isHolder } = useIsHolder()
    const { getBalances } = useGetBalances()
    // const { getApproves } = useGetApproves()
    const { getStarkname } = useGetStarkname()
    const { getTokens } = useGetTokens()
    const { theme, toggleTheme } = useTheme()
    const [searchParams] = useSearchParams()
    const location = useLocation()

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    const [door, setDoor] = useState<boolean>(false)
    const [isEkuboPresent, setIsEkuboPresent] = useState(false)

    const domain = useSelector((state: RootState) => state.starknet.domain)
    const isBatch = useSelector((state: RootState) => state.batch.isBatch)

    const isLimitOrder = location.pathname === '/limit-order'

    const { data, isError } = useStarkProfile({ address })

    const setConfigWithParams = (): void => {
        const liquiditySource = searchParams.get('liquiditySource')?.split(',')
        const inputToken = searchParams.get('inputToken')
        const outputToken = searchParams.get('outputToken')
        const inputTokenIndex = inputToken
            ? CONFIG.tokenList.findIndex(
                  (token) => validateAndParseAddress(token.address) === validateAndParseAddress(inputToken),
              )
            : null
        const outputTokenIndex = outputToken
            ? CONFIG.tokenList.findIndex(
                  (token) => validateAndParseAddress(token.address) === validateAndParseAddress(outputToken),
              )
            : null
        if (inputTokenIndex != null) dispatch(setInputToken(inputTokenIndex))
        if (outputTokenIndex != null) dispatch(setOutputToken(outputTokenIndex))

        if (searchParams.get('ekubo') === '') {
            dispatch(setAllLiquiditySources([false, false, false, false, false, false, false]))
            dispatch(
                setLiquiditySources({
                    key: 4,
                    value: true,
                }),
            )
        }
        if (liquiditySource) {
            dispatch(setAllLiquiditySources([false, false, false, false, false, false, false]))
            liquiditySource.forEach((source) => {
                dispatch(
                    setLiquiditySources({
                        key: parseInt(source),
                        value: true,
                    }),
                )
            })
        }

        if (searchParams.get('ekubo') === '') {
            setIsEkuboPresent(true)
        } else {
            setIsEkuboPresent(false)
        }
    }

    useEffect(() => {
        if (account) {
            isHolder()
            getBalances(true)
            // getApproves()

            if (chain.testnet) {
                toast.error(`${CONFIG.toast_network_error_message}`)
            }
        } else {
            dispatch(setTokenAmounts(new Array(CONFIG.tokenList.length).fill(0)))
            dispatch(setApproves(new Array(CONFIG.tokenList.length).fill(false)))
        }

        setConfigWithParams()
    }, [account, address])

    useEffect(() => {
        getStarkname()
    }, [account, address, status])

    useEffect(() => {
        getTokens()

        const isClear = localStorage.getItem('isClearedV6')

        if (!isClear) {
            localStorage.clear()
            // localStorage.setItem('customTokens', '[]')
            localStorage.setItem('isClearedV6', 'true')

            window.location.reload()
        }

        const interval = setInterval(() => {
            getTokens()
        }, 30000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (window.location.pathname !== '/' || isBatch) {
            dispatch(setDestinationAddress(''))
        }
    }, [window.location.pathname, isBatch])

    return (
        <header className={styles.header}>
            <div className={styles.wrapper}>
                <Toaster position="top-center" reverseOrder={false} />
                <div className={styles.items}>
                    {isMobile ? (
                        isEkuboPresent ? (
                            <div className={styles.ekuboWrapper}>
                                <a href={CONFIG.logo_refresh_url} rel="noreferrer">
                                    <img src={MOBILELOGO} className={styles.mobileLogo} alt="icon" />
                                </a>

                                <img src={theme === 'dark' ? AND : LIGHTAND} className={styles.and} alt="icon" />

                                <a href={CONFIG.ekubo_refresh_url} rel="noreferrer">
                                    <img src={EKUBO} className={styles.ekubo} alt="icon" />
                                </a>
                            </div>
                        ) : isLimitOrder ? (
                            <div className={styles.logoWrapper}>
                                <a href={CONFIG.logo_refresh_url} rel="noreferrer">
                                    <img src={MOBILELOGO} className={styles.mobileLogo} alt="icon" />
                                </a>

                                <LINE className={styles.line} />
                                <BETA className={styles.beta} />
                            </div>
                        ) : (
                            <a href={CONFIG.logo_refresh_url} rel="noreferrer">
                                <img src={MOBILELOGO} className={styles.mobileLogo} alt="icon" />
                            </a>
                        )
                    ) : isEkuboPresent ? (
                        <div className={styles.ekuboWrapper}>
                            <a href={CONFIG.logo_refresh_url} rel="noreferrer">
                                <img src={theme === 'dark' ? GRAYLOGO : WHITELOGO} className={styles.logo} alt="icon" />
                            </a>

                            <img src={theme === 'dark' ? AND : LIGHTAND} className={styles.and} alt="icon" />

                            <a href={CONFIG.ekubo_refresh_url} rel="noreferrer">
                                <img src={EKUBO} className={styles.ekubo} alt="icon" />
                            </a>
                        </div>
                    ) : isLimitOrder ? (
                        <div className={styles.logoWrapper}>
                            <a href={CONFIG.logo_refresh_url} rel="noreferrer">
                                <img src={theme === 'dark' ? GRAYLOGO : WHITELOGO} className={styles.logo} alt="icon" />
                            </a>

                            <LINE className={styles.line} />
                            <BETA className={styles.beta} />
                        </div>
                    ) : (
                        <a href={CONFIG.logo_refresh_url} rel="noreferrer">
                            <img src={theme === 'dark' ? GRAYLOGO : WHITELOGO} className={styles.logo} alt="icon" />
                        </a>
                    )}
                </div>

                {isMobile ? (
                    <HamburgerMenu settingsModal={settingsModal} walletModal={walletModal} />
                ) : (
                    <div className={styles.buttons}>
                        <div
                            className={styles.themeButton}
                            onClick={(): void => {
                                toggleTheme()
                            }}
                        >
                            {theme === 'light' ? <img src={MOON} alt="icon" /> : <img src={SUN} alt="icon" />}
                        </div>
                        <div className={styles.connection}>
                            <div
                                onMouseOver={(): void => setDoor(true)}
                                onMouseOut={(): void => setDoor(false)}
                                onClick={(): void => {
                                    if (!account) {
                                        dispatch(setConnectModal(true))
                                    } else {
                                        walletModal.open()
                                    }
                                }}
                                className={
                                    account ? clsnm(styles.connectWallet, styles.connected) : styles.connectWallet
                                }
                            >
                                <div className={account ? clsnm(styles.text, styles.connected) : styles.text}>
                                    {!account ? (
                                        <>
                                            <span>CONNECT WALLET</span>
                                            <img src={DOOROPEN} alt="icon" className={styles.door} />
                                        </>
                                    ) : (
                                        <>
                                            {!door && domain && data?.profilePicture ? (
                                                <img
                                                    src={data?.profilePicture}
                                                    alt="icon"
                                                    className={styles.profilePicture}
                                                />
                                            ) : null}
                                            <span>
                                                {!door && address
                                                    ? domain
                                                        ? parseStarkname(domain)
                                                        : parseAddress(validateAndParseAddress(address))
                                                    : 'WALLET'}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </header>
    )
}

export { Navbar }
