import { Rate } from './Rate/Rate'
import styles from './RateWrapper.module.scss'

const RateWrapper = (): JSX.Element => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.rate}>
                    <Rate />
                </div>
            </div>
        </div>
    )
}

export { RateWrapper }
