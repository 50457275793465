import { CONFIG } from 'config/config'
import { Signature, uint256 } from 'starknet'

type orderStruct = {
    signer: string
    maker_asset: string
    taker_asset: string
    maker_amount: string
    taker_amount: string
    order_price: string
    use_solver: boolean
    partial_fill: boolean
    expiration: number
    nonce: number
    signature: Signature
    order_hash: string
}
export const addOrder = async (sign: Signature, order_hash: string, orderStruct: any) => {
    const order: orderStruct = {
        signer: orderStruct.signer,
        maker_asset: orderStruct.makerAsset,
        taker_asset: orderStruct.takerAsset,
        maker_amount: uint256.uint256ToBN(orderStruct.makerAmount).toString(),
        taker_amount: uint256.uint256ToBN(orderStruct.takerAmount).toString(),
        order_price: uint256.uint256ToBN(orderStruct.orderPrice).toString(),
        use_solver: orderStruct.useSolver,
        partial_fill: orderStruct.partialFill,
        expiration: Number(orderStruct.expiration),
        nonce: orderStruct.nonce,
        signature: sign,
        order_hash: order_hash,
    }
    const data = JSON.stringify(order)

    await window.fetch(`${CONFIG.limit_order_api}order/placeOrder`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json;charset=UTF-8',
        },
        body: data,
    })
}
