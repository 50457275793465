import COPY from 'assets/copyDark.svg'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import { parseAddress } from 'utils/parseAddress'

import styles from './SuccessfulV2.module.scss'

const SuccessfulV2 = (): JSX.Element => {
    const pendingPayload = useSelector((state: RootState) => state.limitOrder.payload)
    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)
    const isApprove = useSelector((state: RootState) => state.limitOrder.isApprove)
    const isSigned = useSelector((state: RootState) => state.limitOrder.isSigned)
    const isCanceled = useSelector((state: RootState) => state.limitOrder.isCanceled)
    const isClear = useSelector((state: RootState) => state.limitOrder.isClear)

    return (
        <div className={styles.wrapper}>
            <div className={styles.infos}>
                {isCanceled ? (
                    <>
                        <div className={styles.infoCancel}>
                            {isClear ? (
                                <span>Limit Orders Clearing Submitted.</span>
                            ) : (
                                <span>Limit Order Cancellation Submitted.</span>
                            )}
                        </div>
                        <div className={styles.text}>Now you wait for the server to process your request.</div>

                        <a
                            target={'_blank'}
                            rel="noreferrer"
                            className={styles.tx}
                            href={explorerUrl.concat(`tx/${pendingPayload}`)}
                        >
                            <img src={COPY} alt="copy"></img>
                            <div className={styles.text}>TX: {parseAddress(pendingPayload)}</div>
                        </a>
                    </>
                ) : isApprove && isSigned ? (
                    <div className={styles.info}>Limit Order Submitted.</div>
                ) : (
                    <>
                        <div className={styles.info}>Approve Submitted.</div>
                        <div className={styles.text}>Now you can review order and sign it.</div>
                        <a
                            target={'_blank'}
                            rel="noreferrer"
                            className={styles.tx}
                            href={explorerUrl.concat(`tx/${pendingPayload}`)}
                        >
                            <img src={COPY} alt="copy"></img>
                            <div className={styles.text}>TX: {parseAddress(pendingPayload)}</div>
                        </a>
                    </>
                )}
            </div>
            {/* <div className={styles.tokens}></div> */}
        </div>
    )
}

export { SuccessfulV2 }
