import COPY from 'assets/copy.svg'
import PINOFF from 'assets/pinOff.svg'
import PINON from 'assets/pinOn.svg'
import QUIT from 'assets/quitV2.svg'
import SEARCH from 'assets/search.svg'
import NOIMAGE from 'assets/tokens/UNTOKEN.png'
import { ReactComponent as UNRUG } from 'assets/unrug-icon.svg'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useGetBalances } from 'hooks/useGetBalancesFromAPI'
import type { ModalController } from 'hooks/useModal'
import { useSetBatchValues } from 'hooks/useSetBatchValues'
// import { useTheme } from 'hooks/useTheme'
import { Fragment, useEffect, useRef, useState } from 'react'
import { TiWarningOutline } from 'react-icons/ti'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'
import { apiGetTokens } from 'restapi'
import { validateAndParseAddress } from 'starknet'
import type { RootState } from 'store'
import { setBatchOutputToken } from 'store/slicers/batch'
import { setInputToken } from 'store/slicers/input'
import { setLimitOrderInputTokens, setLimitOrderOutputToken } from 'store/slicers/limitOrder'
import { setOutputToken } from 'store/slicers/output'
import { updateTokenList } from 'store/slicers/starknet'
import type { Token } from 'types/token'
import type { ApiToken } from 'types/tokenPrice'
import { Modal } from 'ui'
import { isValidStarknetAddressOrDomain } from 'utils/arenaUtils'
// import { checkArraysEqual } from 'utils/checkArraysEqual'
import { formatValue } from 'utils/formatValue'
import { parseAddress, parseTokenName } from 'utils/parseAddress'
import { isThereBatchToken } from 'utils/setBatchToken'
import { setBatchToken } from 'utils/setBatchToken'
import { setFixedNumber } from 'utils/setFixedNumber'

import { useGetTokenPrices } from '../../hooks/useGetTokenPrices'
import styles from './SelectToken.module.scss'

type SelectTokenProps = {
    modal: ModalController
    type: 'output' | 'input' | 'batchInput' | 'batchOutput' | 'limitOrderInput' | 'limitOrderOutput'
    inputKey?: number
}

const SelectToken = ({ modal, type, inputKey }: SelectTokenProps): JSX.Element => {
    const dispatch = useDispatch()
    const { getBalances } = useGetBalances()
    const { getTokenPrices, calculateTokenBalance } = useGetTokenPrices()
    const { setBatchInputToken } = useSetBatchValues()
    // const { theme } = useTheme()
    const [, setSearchParams] = useSearchParams()

    const [copySuccess, setCopySuccess] = useState<string>('')
    const [showDisclaimer, setShowDisclaimer] = useState(false)
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
    const [isTokenFocus, setIsTokenFocus] = useState<number>(-1)
    const [search, setSearch] = useState('')
    const [apiToken, setApiToken] = useState<Token | null>(null)
    const [sortedTokens, setSortedTokens] = useState<Array<Token>>([])
    const [tooltip, setTooltip] = useState<{
        x: number
        y: number
        visible: boolean
    }>({ x: 0, y: 0, visible: false })
    const [tooltipIndex, setTooltipIndex] = useState<number>(-1)

    const [pinnedTokens, setPinnedTokens] = useState<Array<string>>(() => {
        const storedPinnedTokens = localStorage.getItem('pinnedTokens')
        return storedPinnedTokens
            ? JSON.parse(storedPinnedTokens)
            : [
                  '0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d',
                  '0x004878d1148318a31829523ee9c6a5ee563af6cd87f90a30809e5b0d27db8a9b',
              ]
    })

    const tokensAPI = useSelector((state: RootState) => state.settings.tokens)
    const inputToken = useSelector((state: RootState) => state.input.token)
    const outputToken = useSelector((state: RootState) => state.output.token)
    const inputTokens = useSelector((state: RootState) => state.batch.inputTokens)
    const batchOutputToken = useSelector((state: RootState) => state.batch.outputToken)
    const limitOrderInputTokens = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const limitOrderOutputToken = useSelector((state: RootState) => state.limitOrder.outputToken)
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)
    const isLimitOrder = useSelector((state: RootState) => state.limitOrder.isLimitOrder)

    const pinDivRef = useRef<HTMLDivElement>(null)
    const pinRef = useRef<HTMLImageElement>(null)
    const quitRef = useRef<HTMLImageElement>(null)

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const handleSearch = async (): Promise<void> => {
        if (!search) {
            setApiToken(null)
            setSortedTokens(CONFIG.tokenList)
            return
        }

        const tokenExists =
            isValidStarknetAddressOrDomain(search) &&
            CONFIG.tokenList.some(
                (token) =>
                    token.address === validateAndParseAddress(search) ||
                    token.symbol.toUpperCase() === validateAndParseAddress(search).toUpperCase(),
            )

        if (!tokenExists) {
            try {
                const tokens = tokensAPI

                const foundToken = tokens.find(
                    (token: ApiToken) =>
                        token.address === validateAndParseAddress(search) ||
                        token.symbol.toUpperCase() === validateAndParseAddress(search).toUpperCase(),
                )

                if (foundToken) {
                    setApiToken({
                        address: foundToken.address,
                        name: foundToken.name?.charAt(0).toUpperCase() + foundToken.name?.slice(1) || 'Undefined',
                        symbol: foundToken.symbol?.toUpperCase() || 'UNDEFINED',
                        decimals: foundToken.decimals.toString(),
                        imageUrl: foundToken.imageUrl || NOIMAGE,
                        valuable: foundToken.valuable,
                        verified: foundToken.verified,
                        category: foundToken.category,
                    })
                } else {
                    setApiToken(null)
                }
            } catch (error) {
                console.error('Error fetching tokens:', error)
                setApiToken(null)
            }
        } else {
            setApiToken(null)
        }
    }

    const importToken = (token: Token): void => {
        if (!showDisclaimer || !isCheckboxChecked) {
            setShowDisclaimer(true)
            return
        }
        const existingTokens = JSON.parse(localStorage.getItem('customTokens') || '[]')
        const updatedTokens = [...existingTokens, token]
        localStorage.setItem('customTokens', JSON.stringify(updatedTokens))
        dispatch(updateTokenList({ newTokenList: CONFIG.tokenList }))
        getBalances(true)
        setApiToken(null)
        setShowDisclaimer(false)
        setIsCheckboxChecked(false)
        setToken(null, CONFIG.tokenList.length - 1)
    }

    const setPin = (tokenAddress: string): void => {
        const isPinned = pinnedTokens.includes(tokenAddress)
        let updatedPinnedTokens = [...pinnedTokens]

        if (isPinned) {
            updatedPinnedTokens = updatedPinnedTokens.filter((address) => address !== tokenAddress)
        } else {
            if (updatedPinnedTokens.length < 9) {
                updatedPinnedTokens.push(tokenAddress)
            } else {
                updatedPinnedTokens.shift()
                updatedPinnedTokens.push(tokenAddress)
            }
        }

        setPinnedTokens(updatedPinnedTokens)
        localStorage.setItem('pinnedTokens', JSON.stringify(updatedPinnedTokens))
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setToken = (e: any, i: number): void => {
        if (!(pinRef.current === null || pinDivRef.current === null)) {
            if (e.target.id === pinRef.current.id || e.target.id === pinDivRef.current.id) {
                return
            }
        }
        if (quitRef.current !== null) {
            if (e.target.id === quitRef.current.id) return
        }

        if (type === 'input') {
            dispatch(setInputToken(i))
            setSearchParams({ tokenIn: CONFIG.tokenList[i].address })
            if (outputToken === i) {
                dispatch(setOutputToken(Math.abs((i + 1) % CONFIG.tokenList.length)))
            }
        } else if (type === 'output') {
            dispatch(setOutputToken(i))
            setSearchParams({ tokenOut: CONFIG.tokenList[i].address })
            if (inputToken === i) {
                dispatch(setInputToken(Math.abs((i + 1) % CONFIG.tokenList.length)))
            }
        } else if (type === 'limitOrderInput') {
            dispatch(setLimitOrderInputTokens(i))
            setSearchParams({ tokenIn: CONFIG.tokenList[i].address })
            if (limitOrderOutputToken === i) {
                dispatch(setLimitOrderOutputToken(Math.abs((i + 1) % CONFIG.tokenList.length)))
            }
        } else if (type === 'limitOrderOutput') {
            dispatch(setLimitOrderOutputToken(i))
            setSearchParams({ tokenOut: CONFIG.tokenList[i].address })
            if (limitOrderInputTokens === i) {
                dispatch(setLimitOrderInputTokens(Math.abs((i + 1) % CONFIG.tokenList.length)))
            }
        } else if (type === 'batchInput') {
            if (inputKey !== undefined) {
                setBatchInputToken(inputKey, i)
                if (i === batchOutputToken) {
                    dispatch(setBatchOutputToken(setBatchToken(inputTokens, i, CONFIG.tokenList)))
                }
            }
        } else if (type === 'batchOutput') {
            dispatch(setBatchOutputToken(i))
            for (let u = 0; u < inputTokens.length; u++) {
                if (inputTokens[u] === i) {
                    setBatchInputToken(u, setBatchToken(inputTokens, i, CONFIG.tokenList))
                }
            }
        }
        modal.close()
    }

    const updateLocalTokens = async (): Promise<void> => {
        try {
            const response = await apiGetTokens()
            const apiTokens: Array<Token> = response.data

            const localTokens = JSON.parse(localStorage.getItem('customTokens') || '[]')

            const updatedLocalTokens = localTokens.map((localToken: Token) => {
                const apiToken = apiTokens.find((apiToken: Token) => apiToken.address === localToken.address)

                return apiToken && apiToken.imageUrl !== null
                    ? { ...localToken, imageUrl: apiToken.imageUrl }
                    : localToken
            })
            localStorage.setItem('customTokens', JSON.stringify(updatedLocalTokens))
        } catch (error) {
            console.error('Failed to fetch tokens:', error)
        }
    }

    const getTokenAmount = (tokenAddress: string): BigNumber => {
        const tokenIndex = CONFIG.tokenList.findIndex((t) => t.address === tokenAddress)
        if (tokenIndex === -1 || !tokenAmounts[tokenIndex]) {
            return BigNumber.from(0)
        }
        return tokenAmounts[tokenIndex]
    }

    useEffect(() => {
        const fetchAndSortTokens = async (): Promise<void> => {
            const prices = await getTokenPrices()

            const tokensToSort = isLimitOrder
                ? CONFIG.tokenList.filter((token) => token.verified)
                : [...CONFIG.tokenList]

            const sorted = tokensToSort.sort((a, b) => {
                // if (a.symbol === 'STRK') return -1
                // if (b.symbol === 'STRK') return 1

                const indexA = tokensToSort.findIndex((t) => t.address === a.address)
                const indexB = tokensToSort.findIndex((t) => t.address === b.address)

                const tokenAmountA =
                    indexA >= 0 && tokenAmounts[indexA] ? BigNumber.from(tokenAmounts[indexA]) : BigNumber.from(0)
                const tokenAmountB =
                    indexB >= 0 && tokenAmounts[indexB] ? BigNumber.from(tokenAmounts[indexB]) : BigNumber.from(0)

                const priceA = prices[a.address] || '0'
                const priceB = prices[b.address] || '0'

                const valueA = calculateTokenBalance(priceA, a, tokenAmountA)
                const valueB = calculateTokenBalance(priceB, b, tokenAmountB)

                const hasValueA = tokenAmountA.gt(BigNumber.from(0))
                const hasValueB = tokenAmountB.gt(BigNumber.from(0))

                if (hasValueA && hasValueB) {
                    return valueB - valueA
                } else if (hasValueA) {
                    return -1
                } else if (hasValueB) {
                    return 1
                }

                if (priceA !== '0' && priceB !== '0') {
                    return parseFloat(priceB) - parseFloat(priceA)
                } else if (priceA !== '0') {
                    return -1
                } else if (priceB !== '0') {
                    return 1
                }

                return indexA - indexB
            })

            setSortedTokens(sorted)
        }

        fetchAndSortTokens()
    }, [modal.isOpen])

    useEffect(() => {
        setSearch('')
        const pinned: string | null = localStorage.getItem('pinnedTokens')
        if (pinned === null) return
        setPinnedTokens(JSON.parse(pinned))
    }, [modal.isOpen])

    useEffect(() => {
        const pinned: string | null = localStorage.getItem('pinnedTokens')
        if (pinned === null) {
            localStorage.setItem('pinnedTokens', JSON.stringify([]))
        }
        if (pinnedTokens === null) {
            if (pinned === JSON.stringify(pinnedTokens)) return
            setPinnedTokens([])
        } else {
            if (
                pinned === null ||
                pinned === JSON.stringify(pinnedTokens) ||
                pinnedTokens === null ||
                pinnedTokens.length === 0
            )
                return
            localStorage.setItem('pinnedTokens', JSON.stringify(pinnedTokens))
        }
    }, [pinnedTokens])

    useEffect(() => {
        handleSearch()
    }, [search])

    useEffect(() => {
        setApiToken(null)
        setShowDisclaimer(false)
        setIsCheckboxChecked(false)
    }, [modal.isOpen])

    useEffect(() => {
        const localTokens = localStorage.getItem('customTokens')
        if (localTokens && localTokens !== '[]') {
            updateLocalTokens()
        }
    }, [])

    return (
        <Modal isOpen={modal.isOpen} close={modal.close} className={styles.wrapper}>
            {!showDisclaimer && pinnedTokens !== null && pinnedTokens.length > 0 && (
                <div className={styles.mostUsedTokens}>
                    {sortedTokens.map((data: Token, i: number) => {
                        const originalIndex = CONFIG.tokenList.findIndex(
                            (originalToken) => originalToken.address === data.address,
                        )

                        if (!pinnedTokens.includes(data.address)) {
                            return null
                        }

                        return (
                            <div
                                className={styles.simpleToken}
                                key={i}
                                onClick={(e) => {
                                    setToken(e, originalIndex)
                                }}
                                onMouseOver={(): void => {
                                    setIsTokenFocus(i)
                                }}
                                onMouseOut={(): void => {
                                    setIsTokenFocus(-1)
                                }}
                            >
                                <img src={data.imageUrl} className={styles.tokenLogo} alt="logo"></img>
                                <div className={styles.tokenName}>{data.symbol}</div>
                                {isTokenFocus > -1 && isTokenFocus === i && (
                                    <img
                                        onClick={(): void => setPin(data.address)}
                                        id="quit"
                                        ref={quitRef}
                                        src={QUIT}
                                        className={styles.quit}
                                    ></img>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}

            {!showDisclaimer &&
                // checkArraysEqual(tokenAmounts, new Array(CONFIG.tokenList.length).fill(BigNumber.from(0))) ? (
                // <div className={styles.warning}>
                //     <TiWarningOutline size={16} fill={theme === 'dark' ? '#fff' : '#000'} />
                //     <div>You don't have enough tokens to use Fibrous</div>
                // </div>
                // ) :
                !showDisclaimer && (
                    <div className={styles.search}>
                        <div className={styles.icon}>
                            <img src={SEARCH} alt="search"></img>
                        </div>
                        <input
                            className={styles.input}
                            autoComplete="off"
                            autoCorrect="off"
                            type="text"
                            inputMode="text"
                            pattern="^[0-9]*[.,]?[0-9]*$"
                            minLength={1}
                            maxLength={79}
                            spellCheck="false"
                            placeholder={'Find a token by name or address'}
                            onChange={(e) => setSearch(e.target.value.toLowerCase())}
                        />
                    </div>
                )}

            {!showDisclaimer && (
                <div className={styles.tokensContainer}>
                    <div className={styles.tokensWrapper}>
                        {search &&
                            !apiToken &&
                            !CONFIG.tokenList.some((token) =>
                                [token.symbol, token.name, token.address].some((property) =>
                                    property
                                        .toUpperCase()
                                        .includes(
                                            isValidStarknetAddressOrDomain(search)
                                                ? validateAndParseAddress(search).toUpperCase()
                                                : search.toUpperCase(),
                                        ),
                                ),
                            ) && (
                                <div className={styles.searchResult}>
                                    <div className={styles.searchResultText}>Token not found.</div>
                                </div>
                            )}
                        <div className={styles.tokens}>
                            {apiToken ? (
                                <div
                                    className={styles.token}
                                    onClick={(e): void => {
                                        const apiTokenIndex = sortedTokens.findIndex(
                                            (t) => t.address === apiToken.address,
                                        )
                                        if (apiTokenIndex !== -1) {
                                            setToken(e, apiTokenIndex)
                                        }
                                    }}
                                >
                                    <div className={styles.name}>
                                        <img src={apiToken.imageUrl} className={styles.tokenLogo} alt="logo"></img>
                                        <div className={styles.textWrapper}>
                                            <div className={styles.text}>{apiToken.symbol}</div>
                                            <div className={styles.nameRight}>{apiToken.name}</div>
                                        </div>
                                    </div>
                                    <div className={styles.values} onClick={() => importToken(apiToken)}>
                                        <div className={styles.importButton}>
                                            <button>IMPORT</button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                sortedTokens.map((token: Token) => {
                                    const originalIndex = CONFIG.tokenList.findIndex(
                                        (originalToken) => originalToken.address === token.address,
                                    )
                                    const isPinned = pinnedTokens.includes(token.address)

                                    if (inputKey !== undefined && isThereBatchToken(originalIndex, inputTokens)) {
                                        return <Fragment key={originalIndex}></Fragment>
                                    }
                                    if (search === '') {
                                        return (
                                            <div
                                                className={styles.token}
                                                key={originalIndex}
                                                onClick={(e) => {
                                                    setToken(e, originalIndex)
                                                }}
                                            >
                                                {isLimitOrder
                                                    ? limitOrderInputTokens === originalIndex &&
                                                      type === 'limitOrderOutput' && <div className={styles.ban}></div>
                                                    : inputToken === originalIndex &&
                                                      type === 'output' && <div className={styles.ban}></div>}

                                                <div className={styles.name}>
                                                    <img
                                                        src={token.imageUrl}
                                                        className={styles.tokenLogo}
                                                        alt="logo"
                                                    ></img>
                                                    <div className={styles.textWrapper}>
                                                        <div className={styles.text}>{token.symbol}</div>
                                                        <div className={styles.nameRight}>{token.name}</div>
                                                    </div>
                                                    {token.category === 'unrugged' && (
                                                        <UNRUG
                                                            className={styles.unrugIcon}
                                                            onMouseEnter={(e): void => {
                                                                setTooltip({
                                                                    x: e.clientX,
                                                                    y: e.clientY,
                                                                    visible: true,
                                                                })
                                                                setTooltipIndex(originalIndex)
                                                            }}
                                                            onMouseMove={(e): void => {
                                                                setTooltip({
                                                                    x: e.clientX,
                                                                    y: e.clientY,
                                                                    visible: true,
                                                                })
                                                                setTooltipIndex(originalIndex)
                                                            }}
                                                            onMouseLeave={(): void => {
                                                                setTooltip({ ...tooltip, visible: false })
                                                            }}
                                                            onTouchStart={(e): void => {
                                                                setTooltip({
                                                                    x: e.touches[0].clientX,
                                                                    y: e.touches[0].clientY,
                                                                    visible: true,
                                                                })
                                                                setTooltipIndex(originalIndex)
                                                            }}
                                                            onTouchMove={(e): void => {
                                                                setTooltip({
                                                                    x: e.touches[0].clientX,
                                                                    y: e.touches[0].clientY,
                                                                    visible: true,
                                                                })
                                                                setTooltipIndex(originalIndex)
                                                            }}
                                                            onTouchEnd={(): void => {
                                                                setTooltip({ ...tooltip, visible: false })
                                                                setTooltipIndex(-1)
                                                            }}
                                                        />
                                                    )}
                                                    {!token.verified && token.category !== 'unrugged' && (
                                                        <TiWarningOutline
                                                            className={styles.warningIcon}
                                                            onMouseEnter={(e): void => {
                                                                setTooltip({
                                                                    x: e.clientX,
                                                                    y: e.clientY,
                                                                    visible: true,
                                                                })
                                                                setTooltipIndex(originalIndex)
                                                            }}
                                                            onMouseMove={(e): void => {
                                                                setTooltip({
                                                                    x: e.clientX,
                                                                    y: e.clientY,
                                                                    visible: true,
                                                                })
                                                                setTooltipIndex(originalIndex)
                                                            }}
                                                            onMouseLeave={(): void => {
                                                                setTooltip({ ...tooltip, visible: false })
                                                            }}
                                                            onTouchStart={(e): void => {
                                                                setTooltip({
                                                                    x: e.touches[0].clientX,
                                                                    y: e.touches[0].clientY,
                                                                    visible: true,
                                                                })
                                                                setTooltipIndex(originalIndex)
                                                            }}
                                                            onTouchMove={(e): void => {
                                                                setTooltip({
                                                                    x: e.touches[0].clientX,
                                                                    y: e.touches[0].clientY,
                                                                    visible: true,
                                                                })
                                                                setTooltipIndex(originalIndex)
                                                            }}
                                                            onTouchEnd={(): void => {
                                                                setTooltip({ ...tooltip, visible: false })
                                                                setTooltipIndex(-1)
                                                            }}
                                                        />
                                                    )}

                                                    {tooltipIndex === originalIndex && tooltip.visible && (
                                                        <div
                                                            className={styles.tooltip}
                                                            style={{
                                                                left: `${tooltip.x}px`,
                                                                top: `${tooltip.y}px`,
                                                            }}
                                                        >
                                                            {!token.verified &&
                                                                token.category !== 'unrugged' &&
                                                                'Unverified token'}
                                                            {token.category === 'unrugged' && 'Unrugged'}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={styles.values}>
                                                    <div className={styles.value}>
                                                        {formatValue(
                                                            setFixedNumber(
                                                                utils.formatUnits(
                                                                    getTokenAmount(token.address),
                                                                    token.decimals,
                                                                ),
                                                            ),
                                                        )}
                                                    </div>
                                                    <div
                                                        id="pin"
                                                        ref={pinDivRef}
                                                        className={styles.pin}
                                                        onClick={(): void => setPin(token.address)}
                                                    >
                                                        <img
                                                            id="pinImg"
                                                            src={isPinned ? PINON : PINOFF}
                                                            ref={pinRef}
                                                            alt="Pin/Unpin"
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (
                                        (search.startsWith('0x') &&
                                            token.address.toUpperCase() ===
                                                validateAndParseAddress(search).toUpperCase()) ||
                                        (!search.startsWith('0x') &&
                                            token.symbol.toUpperCase().includes(search.toUpperCase())) ||
                                        token.name.toUpperCase().includes(search.toUpperCase())
                                    ) {
                                        return (
                                            <div
                                                className={styles.token}
                                                key={originalIndex}
                                                onClick={(e) => {
                                                    setToken(e, originalIndex)
                                                }}
                                            >
                                                {isLimitOrder
                                                    ? limitOrderInputTokens === originalIndex &&
                                                      type === 'limitOrderOutput' && <div className={styles.ban}></div>
                                                    : inputToken === originalIndex &&
                                                      type === 'output' && <div className={styles.ban}></div>}

                                                <div className={styles.name}>
                                                    <img
                                                        src={token.imageUrl}
                                                        className={styles.tokenLogo}
                                                        alt="logo"
                                                    ></img>
                                                    <div className={styles.textWrapper}>
                                                        <div className={styles.text}>{token.symbol}</div>
                                                        <div className={styles.nameRight}>{token.name}</div>
                                                    </div>
                                                </div>
                                                <div className={styles.values}>
                                                    <div className={styles.value}>
                                                        {setFixedNumber(
                                                            utils.formatUnits(
                                                                getTokenAmount(token.address),
                                                                token.decimals,
                                                            ),
                                                        )}
                                                    </div>
                                                    <div
                                                        id="pin"
                                                        ref={pinDivRef}
                                                        className={styles.pin}
                                                        onClick={(): void => setPin(token.address)}
                                                    >
                                                        <div
                                                            id="pin"
                                                            ref={pinDivRef}
                                                            className={styles.pin}
                                                            onClick={(): void => setPin(token.address)}
                                                        >
                                                            <img
                                                                id="pinImg"
                                                                src={
                                                                    pinnedTokens.includes(token.address)
                                                                        ? PINON
                                                                        : PINOFF
                                                                }
                                                                ref={pinRef}
                                                            ></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return <Fragment key={originalIndex}></Fragment>
                                    }
                                })
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showDisclaimer && (
                <div className={styles.disclaimer}>
                    <p className={styles.titleDisclaimer}> Import a Token</p>

                    <div className={styles.token}>
                        <div className={styles.name}>
                            <img src={apiToken?.imageUrl} className={styles.tokenLogo} alt="logo"></img>
                            <div className={styles.textWrapper}>
                                <div className={styles.tokenName}>{apiToken?.symbol}</div>
                                {!isMobile && (
                                    <div className={styles.nameRight}>{parseTokenName(apiToken?.name || '')}</div>
                                )}
                            </div>
                            {apiToken!.category === 'unrugged' && (
                                <UNRUG
                                    className={styles.unrugIcon}
                                    onMouseEnter={(e): void => {
                                        setTooltip({
                                            x: e.clientX,
                                            y: e.clientY,
                                            visible: true,
                                        })
                                        // setTooltipIndex(originalIndex)
                                    }}
                                    onMouseMove={(e): void => {
                                        setTooltip({
                                            x: e.clientX,
                                            y: e.clientY,
                                            visible: true,
                                        })
                                        // setTooltipIndex(originalIndex)
                                    }}
                                    onMouseLeave={(): void => {
                                        setTooltip({ ...tooltip, visible: false })
                                    }}
                                    onTouchStart={(e): void => {
                                        setTooltip({
                                            x: e.touches[0].clientX,
                                            y: e.touches[0].clientY,
                                            visible: true,
                                        })
                                        // setTooltipIndex(originalIndex)
                                    }}
                                    onTouchMove={(e): void => {
                                        setTooltip({
                                            x: e.touches[0].clientX,
                                            y: e.touches[0].clientY,
                                            visible: true,
                                        })
                                        // setTooltipIndex(originalIndex)
                                    }}
                                    onTouchEnd={(): void => {
                                        setTooltip({ ...tooltip, visible: false })
                                        setTooltipIndex(-1)
                                    }}
                                />
                            )}
                            {!apiToken!.verified && apiToken!.category !== 'unrugged' && (
                                <TiWarningOutline
                                    className={styles.warningIcon}
                                    onMouseEnter={(e): void => {
                                        setTooltip({
                                            x: e.clientX,
                                            y: e.clientY,
                                            visible: true,
                                        })
                                        // setTooltipIndex(originalIndex)
                                    }}
                                    onMouseMove={(e): void => {
                                        setTooltip({
                                            x: e.clientX,
                                            y: e.clientY,
                                            visible: true,
                                        })
                                        // setTooltipIndex(originalIndex)
                                    }}
                                    onMouseLeave={(): void => {
                                        setTooltip({ ...tooltip, visible: false })
                                    }}
                                    onTouchStart={(e): void => {
                                        setTooltip({
                                            x: e.touches[0].clientX,
                                            y: e.touches[0].clientY,
                                            visible: true,
                                        })
                                        // setTooltipIndex(originalIndex)
                                    }}
                                    onTouchMove={(e): void => {
                                        setTooltip({
                                            x: e.touches[0].clientX,
                                            y: e.touches[0].clientY,
                                            visible: true,
                                        })
                                        // setTooltipIndex(originalIndex)
                                    }}
                                    onTouchEnd={(): void => {
                                        setTooltip({ ...tooltip, visible: false })
                                        setTooltipIndex(-1)
                                    }}
                                />
                            )}

                            {tooltip.visible && (
                                <div
                                    className={styles.tooltip}
                                    style={{
                                        left: `${tooltip.x}px`,
                                        top: `${tooltip.y}px`,
                                    }}
                                >
                                    {!apiToken!.verified && apiToken!.category !== 'unrugged' && 'Unverified token'}
                                    {apiToken!.category === 'unrugged' && 'Unrugged'}
                                </div>
                            )}
                        </div>
                        <div className={styles.address}>
                            <a
                                target={'_blank'}
                                rel="noreferrer"
                                className={styles.tx}
                                href={explorerUrl.concat(
                                    `contract/${validateAndParseAddress(apiToken?.address || '')}`,
                                )}
                            >
                                {parseAddress(validateAndParseAddress(apiToken?.address || ''))}
                            </a>
                            <img
                                src={COPY}
                                alt="copy"
                                onClick={async (): Promise<void> => {
                                    try {
                                        await navigator.clipboard.writeText(
                                            validateAndParseAddress(apiToken?.address || ''),
                                        )
                                        setCopySuccess('Copied!')
                                        setTimeout(() => setCopySuccess(''), 2000)
                                    } catch (err) {
                                        setCopySuccess('Failed to copy')
                                    }
                                }}
                            />
                            {copySuccess && <div className={styles.tooltip}>{copySuccess}</div>}
                        </div>
                    </div>

                    <div className={styles.text}>
                        Any user have the ability to generate various tokens, including counterfeit versions of existing
                        ones. Exercise caution and be aware that certain tokens, along with their technical
                        specifications, might not align with Fibrous services.
                    </div>

                    <div className={styles.checkboxWrapper}>
                        <div className={isCheckboxChecked ? styles.status : styles.statusOff}>
                            <div
                                className={styles.floor}
                                style={{
                                    justifyContent: isCheckboxChecked ? 'end' : 'start',
                                }}
                                onClick={(): void => setIsCheckboxChecked(!isCheckboxChecked)}
                            >
                                <div className={styles.ball}></div>
                            </div>
                        </div>
                        <label
                            className={styles.checkboxLabel}
                            style={{
                                color: isCheckboxChecked ? 'var(--default-text-10)' : 'var(--default-text-13)',
                            }}
                        >
                            {/* <input
                                type="checkbox"
                                name="cb"
                                checked={isCheckboxChecked}
                                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                                className={styles.checkbox}
                            /> */}
                            Accept
                        </label>
                    </div>

                    <button
                        onClick={() => importToken(apiToken as Token)}
                        className={styles.confirm}
                        disabled={!isCheckboxChecked}
                    >
                        IMPORT
                    </button>
                </div>
            )}
        </Modal>
    )
}
export { SelectToken }
