import { autoUpdate, useFloating } from '@floating-ui/react-dom'
import type { Placement, ReferenceType, Strategy } from '@floating-ui/react-dom'

type UsePopperProps = {
    strategy?: 'fixed' | 'absolute'
    topDistance?: number
    leftDistance?: number
    placement?: Placement
}

/**
 * @dev Used for popper dropdowns
 */
export const usePopper = ({
    strategy = 'fixed',
    placement = 'bottom-start',
    topDistance = 0,
    leftDistance = 0,
}: UsePopperProps = {}): {
    reference: (node: ReferenceType | null) => void
    floating: (node: HTMLElement | null) => void
    popperStyles: {
        position: Strategy
        top: number
        left: number
        zIndex: number
    }
} => {
    const {
        x,
        y,
        refs,
        strategy: floatingStrategy,
    } = useFloating({
        placement: placement,
        strategy: strategy,
        whileElementsMounted: autoUpdate,
    })

    const reference = refs.setReference
    const floating = refs.setFloating

    const _top = placement.includes('bottom') ? (y || 0) + topDistance : (y || 0) - topDistance

    const _left = placement.includes('right') ? (x || 0) + leftDistance : (x || 0) - leftDistance

    const popperStyles = {
        position: floatingStrategy,
        top: _top,
        left: _left,
        zIndex: 10,
    }

    return { reference, floating, popperStyles }
}
