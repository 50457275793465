import CHANGE from 'assets/change.svg'
import { CONFIG } from 'config/config'
import { formatUnits } from 'ethers/lib/utils'
import { useGetTokenPrices } from 'hooks/useGetTokenPrices'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import type { Token } from 'types/token'
import { formatValue } from 'utils/formatValue'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './Rate.module.scss'

const Rate = (): JSX.Element => {
    const { getTokenPrices } = useGetTokenPrices()

    const inputTokenIndex = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const outputTokenIndex = useSelector((state: RootState) => state.limitOrder.outputToken)
    const inputValueBN = useSelector((state: RootState) => state.limitOrder.inputValues)
    const outputValueBN = useSelector((state: RootState) => state.limitOrder.outputValue)
    const rate = useSelector((state: RootState) => state.limitOrder.rate)

    const [isGetTokenPrices, setIsGetTokenPrices] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [inputToken, setInputToken] = useState<Token>(CONFIG.tokenList[inputTokenIndex])
    const [outputToken, setOutputToken] = useState<Token>(CONFIG.tokenList[outputTokenIndex])
    const [inputValue, setInputValue] = useState<string>(
        setFixedNumber(formatUnits(inputValueBN, inputToken.decimals), 8),
    )
    const [outputValue, setOutputValue] = useState<string>(
        setFixedNumber(formatUnits(outputValueBN, outputToken.decimals), 8),
    )
    const [exchangeRate, setExchangeRate] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    const [isSupported, setIsSupported] = useState(true)

    useEffect(() => {
        async function fetchPrices(): Promise<void> {
            setLoading(true)
            const prices = await getTokenPrices()

            if (Object.keys(prices).length === 0) {
                setIsGetTokenPrices(!isGetTokenPrices)
            }

            const inputPrice = parseFloat(prices[inputToken.address]) || 0
            const outputPrice = parseFloat(prices[outputToken.address]) || 0

            if (inputPrice !== 0 && outputPrice !== 0) {
                setIsSupported(true)
                setExchangeRate(inputPrice / outputPrice)
                setPrice(inputPrice)
            } else if (Number(inputValue) !== 0) {
                setIsSupported(true)
                setExchangeRate(
                    Number(inputValue) >= 1
                        ? Number(outputValue) / Number(inputValue)
                        : (1 / Number(inputValue)) * Number(outputValue),
                )
                setPrice(0)
            } else {
                setIsSupported(false)
                setExchangeRate(0)
                setPrice(0)
            }
            setLoading(false)
        }

        fetchPrices()
    }, [inputToken, outputToken, inputValue, outputValue, isGetTokenPrices, rate])

    useEffect(() => {
        setInputToken(CONFIG.tokenList[inputTokenIndex])
        setOutputToken(CONFIG.tokenList[outputTokenIndex])
        setInputValue(setFixedNumber(formatUnits(inputValueBN, CONFIG.tokenList[inputTokenIndex].decimals), 8))
        setOutputValue(setFixedNumber(formatUnits(outputValueBN, CONFIG.tokenList[outputTokenIndex].decimals), 8))
    }, [inputTokenIndex, outputTokenIndex, inputValueBN, outputValueBN])

    const handleSwap = (): void => {
        setInputToken(outputToken)
        setOutputToken(inputToken)
        setInputValue(outputValue)
        setOutputValue(inputValue)
    }

    return (
        <div className={styles.wrapper} style={{ opacity: `${loading ? '0.5' : '1'}` }} onClick={handleSwap}>
            <button
                className={styles.changeButton}
                style={{ cursor: `${loading ? 'not-allowed' : 'pointer'}` }}
                disabled={loading}
                onClick={handleSwap}
            >
                <img src={CHANGE} alt="change" />
            </button>
            {isSupported ? (
                <>
                    <div className={styles.inputToken}>1 {inputToken.symbol}</div>=
                    <div className={styles.outputToken}>
                        {exchangeRate < 1 ? formatValue(exchangeRate.toFixed(5)) : formatValue(exchangeRate.toFixed(2))}{' '}
                        {outputToken.symbol}
                    </div>
                    <div
                        style={{
                            display: `${price === 0 ? 'none' : ''}`,
                        }}
                    >
                        ≈
                    </div>
                    <div
                        className={styles.price}
                        style={{
                            display: `${price === 0 ? 'none' : ''}`,
                        }}
                    >
                        {' '}
                        ${formatValue(price.toFixed(2))}
                    </div>
                </>
            ) : (
                <div className={styles.unsupported}>Unavailable</div>
            )}
        </div>
    )
}

export { Rate }
