import { STARKNET_CONTRACTS } from 'constants/addresses'
import type { BigNumber } from 'ethers'
import { ethers } from 'ethers'
import type { Event } from 'starknet'
import { validateAndParseAddress } from 'starknet'
import type { Token } from 'types/token'
import { addressToId } from 'utils/addressToId'

export function getInputTokenIdFromEvents(
    events: Array<Event>,
    address: string | undefined,
    tokenList: Array<Token>,
): number {
    if (address === undefined) {
        return 1
    }
    for (const event of events) {
        if (
            validateAndParseAddress(event.data[0]) === validateAndParseAddress(address) &&
            validateAndParseAddress(event.from_address) === validateAndParseAddress(STARKNET_CONTRACTS.HANDLER_ADDRESS)
        ) {
            if (addressToId(event.data[1], tokenList) !== 1) {
                return addressToId(event.data[1], tokenList)
            }
        }
    }
    return 1
}

export function getOutputTokenIdFromEvents(
    events: Array<Event>,
    address: string | undefined,
    tokenList: Array<Token>,
): number {
    for (const event of events) {
        if (
            validateAndParseAddress(event.from_address) === validateAndParseAddress(STARKNET_CONTRACTS.HANDLER_ADDRESS)
        ) {
            if (event.data[0] === address) {
                return addressToId(event.data[2], tokenList)
            }
        }
    }
    return 0
}

export function getInputAmountFromEvents(
    events: Array<Event>,
    address: string | undefined,
    tokenId: number,
    tokenList: Array<Token>,
): string {
    if (address === undefined) {
        return '0'
    }
    let result: BigNumber = ethers.BigNumber.from(0)
    for (const event of events) {
        if (
            validateAndParseAddress(event.data[0]) === validateAndParseAddress(address) &&
            validateAndParseAddress(event.from_address) === validateAndParseAddress(STARKNET_CONTRACTS.HANDLER_ADDRESS)
        ) {
            if (addressToId(event.data[1], tokenList) !== 1) {
                return ethers.utils.formatUnits(
                    event.data[3].toString(),
                    tokenList[addressToId(event.data[1], tokenList)].decimals,
                )
            } else {
                result = ethers.BigNumber.from(event.data[3])
            }
        }
    }
    return ethers.utils.formatUnits(result.toString(), tokenList[tokenId].decimals)
}

export function getOutputAmountFromEvents(
    events: Array<Event>,
    address: string | undefined,
    tokenId: number,
    tokenList: Array<Token>,
): string {
    if (address === undefined) {
        return '0'
    }
    let result: BigNumber = ethers.BigNumber.from(0)
    for (const event of events) {
        if (
            validateAndParseAddress(event.from_address) ===
                validateAndParseAddress(STARKNET_CONTRACTS.HANDLER_ADDRESS) &&
            validateAndParseAddress(event.data[0]) === validateAndParseAddress(address)
        ) {
            result = result.add(event.data[5])
        }
    }

    return ethers.utils.formatUnits(result.toString(), tokenList[tokenId].decimals)
}
