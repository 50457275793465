import ROCK from 'assets/serviceFeeRock.png'
import { ServiceInfoBar } from 'components'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useBatchSwap } from 'hooks/useBatchSwap'
import { useGetTokenPrices } from 'hooks/useGetTokenPrices'
import type { ModalController } from 'hooks/useModal'
import { useEffect, useState } from 'react'
import type { ChangeEvent, MouseEvent } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setBatchPendingPayload, setBatchPendingStatus } from 'store/slicers/batchPending'
import { setFee, setSlippage } from 'store/slicers/settings'
import { PendingState } from 'types/pending'
import { Button, Modal, Tooltip } from 'ui'
import { clsnm } from 'utils/clsnm'
import { formatValue } from 'utils/formatValue'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './BatchConfirm.module.scss'

const BatchConfirm = ({
    modal,
    pendingsModal,
}: {
    modal: ModalController
    pendingsModal: ModalController
}): JSX.Element => {
    const [isSwapping, setIsSwapping] = useState(false)

    const dispatch = useDispatch()
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })

    const slippage = useSelector((state: RootState) => state.settings.slippage)
    const { batchSwap } = useBatchSwap()
    const batchOutputToken = useSelector((state: RootState) => state.batch.outputToken)
    const rocksHolder = useSelector((state: RootState) => state.starknet.rocksHolder)
    const routes = useSelector((state: RootState) => state.route.routes)
    const protocols = useSelector((state: RootState) => state.route.protocols)
    const batchOutputValue = useSelector((state: RootState) => state.batch.outputValue)
    const batchInputTokens = useSelector((state: RootState) => state.batch.inputTokens)
    const batchInputValues = useSelector((state: RootState) => state.batch.inputValues)

    const routeResult = useSelector((state: RootState) => state.batch.routerResponses)

    const { calculateTokenBalance } = useGetTokenPrices()
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let result = event.target.value
            .replace(/,/g, '. ')
            .replace(/[^.\d]/g, '')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')

        if (result.startsWith('.')) {
            return
        }

        if (/^\d*\.\d{5,}$/.test(result)) {
            return
        }

        if (Number(result) >= 1) {
            result = result.replace(/^0+/, '')
        }

        if (Number(result) > 49) {
            event.target.value = '49'
            dispatch(setSlippage('49'))
        } else {
            dispatch(setSlippage(result))
        }
    }

    const handleOutputPrice = (): string => {
        if (!(batchOutputValue && routeResult)) return ''
        const price = calculateTokenBalance(
            routeResult[0]?.outputToken.price.toString(),
            CONFIG.tokenList[batchOutputToken],
            batchOutputValue,
        )
        const finalPrice = price.toFixed(1).slice(-1) === '0' ? price.toFixed(0) : price.toFixed(1)

        return finalPrice === '0' ? 'Unavailable' : '$' + formatValue(finalPrice)
    }

    const feePercentage = rocksHolder
        ? 0
        : routes && routes.length === 1 && protocols.length === 1 && protocols[0].length === 1
          ? 0.02
          : 0.15

    useEffect(() => {
        dispatch(setFee(feePercentage))
    }, [feePercentage, dispatch])

    const formattedBatchOutputValue = Number(
        utils.formatUnits(BigNumber.from(batchOutputValue), CONFIG.tokenList[batchOutputToken].decimals),
    )

    const afterFeeBatchValue = formattedBatchOutputValue * (1 - feePercentage / 100)
    const afterSlippageBatchValue = setFixedNumber((afterFeeBatchValue * (1 - Number(slippage) / 100)).toString())

    return (
        <Modal isOpen={modal.isOpen} close={modal.close} className={styles.wrapper}>
            <div className={clsnm(styles.tokens)}>
                {batchInputTokens.map((token: number, i: number) => {
                    return (
                        <div className={styles.input} key={i}>
                            <div className={styles.hover}>
                                <img
                                    src={CONFIG.tokenList[token].imageUrl}
                                    height={isMobile ? 14 : 22}
                                    width={isMobile ? 14 : 22}
                                    alt="logo"
                                />
                                <div className={styles.name}>{CONFIG.tokenList[token].symbol}</div>
                                <div className={styles.value}>
                                    {formatValue(
                                        setFixedNumber(
                                            utils.formatUnits(batchInputValues[i], CONFIG.tokenList[token].decimals),
                                        ),
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={styles.values}>
                <div className={styles.valueBar}>
                    <img
                        src={CONFIG.tokenList[batchOutputToken].imageUrl}
                        height={isMobile ? 20 : 25}
                        width={isMobile ? 20 : 25}
                        alt="logo"
                    />
                    <div className={styles.name}>{CONFIG.tokenList[batchOutputToken].symbol}</div>
                    <div className={styles.value}>
                        {formatValue(
                            setFixedNumber(
                                utils.formatUnits(batchOutputValue, CONFIG.tokenList[batchOutputToken].decimals),
                            ),
                        )}
                    </div>
                    {batchOutputValue && routeResult && <div className={styles.price}>{handleOutputPrice()}</div>}
                </div>
            </div>

            <div className={rocksHolder ? clsnm(styles.serviceFee, styles.holder) : clsnm(styles.serviceFee)}>
                <div className={styles.title}>
                    {rocksHolder && (
                        <div className={styles.icon}>
                            <img src={ROCK} alt="rock" />
                        </div>
                    )}
                    <div className={styles.text}>
                        <div className={styles.info}>
                            <span>Service Fee</span>
                            <Tooltip placement="bottom" content={<ServiceInfoBar />} className={styles.toolTip}>
                                <div className={styles.infoIcon}>
                                    <AiOutlineInfoCircle />
                                </div>
                            </Tooltip>
                        </div>
                        {!rocksHolder && <span>Amount After Service Fee</span>}
                    </div>
                </div>
                {!rocksHolder ? (
                    <div className={styles.amount}>
                        <div className={clsnm(styles.fee)}>{feePercentage}%</div>
                        {setFixedNumber(afterFeeBatchValue.toString())} {CONFIG.tokenList[batchOutputToken].symbol}
                    </div>
                ) : (
                    <div className={clsnm(styles.fee)}>{feePercentage}%</div>
                )}
            </div>

            <div className={styles.minimumReceived}>
                <div className={styles.infoPart}>
                    <div className={styles.infos}>
                        <div className={styles.info}>
                            <p>Minimum received after slippage</p>
                            <Tooltip
                                placement="bottom"
                                content={<div>Includes service fee.</div>}
                                className={styles.infoTooltip}
                            >
                                <div className={styles.infoIcon}>
                                    <AiOutlineInfoCircle size={isMobile ? 11 : 16} />
                                </div>
                            </Tooltip>
                        </div>
                        <div className={styles.amount}>
                            {formatValue(setFixedNumber(afterSlippageBatchValue.toString()))}{' '}
                            {CONFIG.tokenList[batchOutputToken].symbol}
                        </div>
                    </div>
                    <div className={styles.slippage}>
                        <input
                            className={styles.slip}
                            value={`${slippage}`}
                            onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                handleChange(value)
                            }}
                            onClick={(event: MouseEvent<HTMLInputElement>): void => {
                                ;(event.target as HTMLInputElement).select()
                            }}
                        />
                        <div className={styles.percent}>
                            <p>%</p>
                        </div>
                    </div>
                </div>
                {Number(slippage) <= 0.1 && (
                    <div className={styles.warningSlippage}>
                        Setting the slippage tolerance too low can cause your swap transaction to fail.
                    </div>
                )}
            </div>

            <Button
                className={styles.swap}
                width={'100%'}
                fontWeight="fw700"
                fontSize="fs18"
                color={'swap'}
                disabled={Number(slippage) === 0 || isSwapping}
                onClick={async (): Promise<void> => {
                    if (!isSwapping) {
                        setIsSwapping(true)
                        dispatch(setBatchPendingPayload(''))
                        dispatch(setBatchPendingStatus(PendingState.PENDING))
                        pendingsModal.open()
                        await batchSwap()
                        setIsSwapping(false)
                    }
                }}
            >
                CONFIRM SWAP
            </Button>
        </Modal>
    )
}

export { BatchConfirm }
