import type { Token } from 'types/token'

export const setBatchTokenAddresses = (
    _tokens: Array<number>,
    tokenList: Array<Token>,
    staticValue?: string,
): string => {
    let result = ''
    for (let i = 0; i < _tokens.length; i++) {
        if (i === 0) {
            result += staticValue ? staticValue : tokenList[_tokens[i]].address
        } else {
            result += `,${staticValue ? staticValue : tokenList[_tokens[i]].address}`
        }
    }
    return result
}
