import { useState, useCallback } from 'react';

export type ModalController = {
    isOpen: boolean;
    open: () => void;
    close: () => void;
};

export const useModal = (): ModalController => {
    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);

    return { isOpen, open, close };
};
