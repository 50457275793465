import { useAccount } from '@starknet-react/core' // useContractWrite
import type BN__default from 'bn.js'
import { CONFIG } from 'config/config'
import { STARKNET_CONTRACTS } from 'constants/addresses'
import { BigNumber, utils } from 'ethers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setInputValue } from 'store/slicers/input'
import { setPendingPayload, setPendingStatus, setPendingsData } from 'store/slicers/pending'
import { setConfirm, setHistoryData } from 'store/slicers/settings'
import { PendingState } from 'types/pending'
import { formatRouterCall } from 'utils/fmtRouterCallV3'
import { parseInputAmountToUint256ExecuteCall } from 'utils/parseAddress'
import { setHistory } from 'utils/setHistory'

type BigNumberish = string | number | BN__default
export const useSwap = (): {
    swap: () => Promise<void>
} => {
    const { address, account } = useAccount()
    const dispatch = useDispatch()

    const slippage = useSelector((state: RootState) => state.settings.slippage)
    const approves = useSelector((state: RootState) => state.settings.approves)
    const inputToken = useSelector((state: RootState) => state.input.token)
    const inputValue = useSelector((state: RootState) => state.input.value)
    const outputToken = useSelector((state: RootState) => state.output.token)
    const outputValue = useSelector((state: RootState) => state.output.value)
    const routeResult = useSelector((state: RootState) => state.route.routeResult)
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const pendingStatus = useSelector((state: RootState) => state.pending.status)
    const fee = useSelector((state: RootState) => state.settings.fee)
    const destinationAddress = useSelector((state: RootState) => state.settings.destinationAddress)

    const [fmtSlippage, setfmtSlippage] = useState<number>(3)
    const [fmtFee, setfmtFee] = useState<number>(0)

    useEffect(() => {
        setfmtSlippage(Number(slippage) / 100)
        if (slippage === '') {
            setfmtSlippage(0.01)
        }
    }, [slippage])

    useEffect(() => {
        setfmtFee(fee / 100)
    }, [fee])

    useEffect(() => {
        dispatch(
            setPendingsData({
                inputToken: inputToken,
                inputValue: utils.formatUnits(BigNumber.from(inputValue), CONFIG.tokenList[inputToken].decimals),
                outputToken: outputToken,
                outputValue: tokenAmounts[outputToken].toString(),
            }),
        )
    }, [inputToken, inputValue, outputToken, tokenAmounts])

    const approveToken = {
        contractAddress: CONFIG.tokenList[inputToken].address,
        entrypoint: 'approve',
        calldata: [
            STARKNET_CONTRACTS.ROUTER_ADDRESS,
            ...parseInputAmountToUint256ExecuteCall(
                address && routeResult.inputAmount ? BigNumber.from(routeResult.inputAmount).toString() : '0',
                0,
            ),
        ],
    }

    const setInput: Array<BigNumberish> = address
        ? formatRouterCall(
              routeResult,
              fmtSlippage,
              destinationAddress ? destinationAddress : address,
              //   address,
              fmtFee,
          )
        : []

    const swapCall = {
        contractAddress: STARKNET_CONTRACTS.ROUTER_ADDRESS,
        entrypoint: 'swap',
        calldata: setInput,
    }

    const calls: Array<any> = approves[inputToken] ? [swapCall] : [approveToken, swapCall]

    // const { writeAsync } = useContractWrite({ calls })

    const swap = async (): Promise<void> => {
        if (!account || !address) return

        const txDetails: any = // await writeAsync()
            await account
                .execute(calls)
                .then((data: any) => {
                    dispatch(setPendingPayload(data.transaction_hash))
                    dispatch(setConfirm(false))
                    const history: string | null = localStorage.getItem(`${address}-history`)
                    if (history === null) {
                        return
                    } else {
                        const historyData = JSON.parse(history)
                        if (historyData.length > 4) {
                            historyData.shift()
                        }
                        dispatch(
                            setHistoryData(
                                JSON.stringify([
                                    ...historyData,
                                    setHistory(
                                        {
                                            inputToken: inputToken,
                                            inputValue: utils.formatUnits(
                                                inputValue,
                                                CONFIG.tokenList[inputToken].decimals,
                                            ),
                                            outputToken: outputToken,
                                            outputValue: utils.formatUnits(
                                                outputValue,
                                                CONFIG.tokenList[outputToken].decimals,
                                            ),
                                        },
                                        data.transaction_hash,
                                        '',
                                    ),
                                ]),
                            ),
                        )
                    }
                })

                .catch((err: any) => {
                    dispatch(setPendingPayload(`Transaction Failed`))
                    dispatch(setPendingStatus(PendingState.FAILED))
                    dispatch(setConfirm(false))
                })
        if (txDetails) {
            dispatch(setInputValue(BigNumber.from(0)))
        } else {
            if (pendingStatus === PendingState.FAILED) return
        }
    }

    return { swap }
}
