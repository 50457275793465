import type { HistoryTXProps } from 'types/history'

export const setBatchHistory = (pendingData: any, link: string, reverted: string) => {
    const history: HistoryTXProps = {
        link: link,
        data: [],
        reverted: reverted,
    }
    try {
        for (let i = 0; i < Number(pendingData.batchInputToken.length) + 1; i++) {
            if (i === pendingData.batchInputToken.length) {
                history.data.push({
                    token: pendingData.batchOutputToken,
                    amount: pendingData.batchOutputValue,
                })
            } else {
                if (pendingData.batchInputToken[i] === undefined) return
                history.data.push({
                    token: pendingData.batchInputToken[i],
                    amount: pendingData.batchInputValue[i],
                })
            }
        }
    } catch (e) {
        console.log(e)
    }
    return history
}

export const setHistory = (pendingData: any, link: string, reverted: string) => {
    const history: HistoryTXProps = {
        link: link,
        data: [],
        reverted: reverted,
    }
    history.data.push({
        token: pendingData.inputToken,
        amount: pendingData.inputValue,
    })
    history.data.push({
        token: pendingData.outputToken,
        amount: pendingData.outputValue,
    })

    return history
}
