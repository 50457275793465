// import { useAccount, useConnect } from '@starknet-react/core'
import { Arena, Disclaimer, Footer, FooterV2, Navbar, Navigator, Settings, Wallet } from 'components'
import { ConnectModal } from 'components/Navbar/ConnectModal/ConnectModal'
import { useModal } from 'hooks/useModal'
import { useModalWithState } from 'hooks/useModalWithState'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setConnectModal } from 'store/slicers/settings'

import styles from './ArenaPage.module.scss'

const ArenaPage = (): JSX.Element => {
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })

    // const { connect, connectors } = useConnect()
    // const { account, address } = useAccount()
    const settingsModal = useModal()
    const walletModal = useModal()
    const disclaimerModal = useModal()

    const connectModalState = useSelector((state: RootState) => state.settings.connectModal)
    const connectModal = useModalWithState(connectModalState, setConnectModal)

    useEffect(() => {
        const disclaimerStatus = localStorage.getItem('disclaimer')
        if (disclaimerStatus !== 'true') {
            disclaimerModal.open()
        }
    }, [])

    // useEffect(() => {
    //     if (!account) {
    //         const lastUsedConnectorId = localStorage.getItem('lastUsedConnector')
    //         if (lastUsedConnectorId) {
    //             const connectorToUse = connectors.find((connector) => connector.id === lastUsedConnectorId)
    //             if (connectorToUse) {
    //                 connect({ connector: connectorToUse })
    //             }
    //         }
    //     }
    // }, [account, address, connect, connectors])

    return (
        <div className={styles.wrapper}>
            <Disclaimer modal={disclaimerModal} />
            <Settings modal={settingsModal} />
            <Wallet modal={walletModal} />

            {!isMobile && <Navigator settingsModal={settingsModal} />}
            <Navbar settingsModal={settingsModal} walletModal={walletModal} />
            <ConnectModal modal={connectModal} />

            <div className={styles.layout}>
                <Arena />
            </div>

            {isMobile ? <Footer /> : <FooterV2 />}
        </div>
    )
}

export { ArenaPage }
