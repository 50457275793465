import { useBlockNumber } from '@starknet-react/core'
import { ReactComponent as DiscordLight } from 'assets/discord-light.svg'
import { ReactComponent as Discord } from 'assets/discord.svg'
import { PendingsV2 } from 'components'
import { History } from 'components/FooterV2/History/History'
import { useModal } from 'hooks/useModal'
import { useTheme } from 'hooks/useTheme'
import { useState } from 'react'
import { FaXTwitter } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import type { RootState } from 'store'
import { clsnm } from 'utils/clsnm'

import { ModalV2 } from '../FooterV2/ModalV2/ModalV2'
import styles from './Footer.module.scss'

const Footer = (): JSX.Element => {
    const pendingsModal = useModal()
    const { theme } = useTheme()
    const location = useLocation()

    const [isModalOpen, setModalOpen] = useState<boolean>(false)

    const pendingStatus = useSelector((state: RootState) => state.pending.status)
    const mobileStatus = useSelector((state: RootState) => state.pending.mobileStatus)

    const isLimitOrder = location.pathname === '/limit-order'

    const { data, isError } = useBlockNumber({
        refetchInterval: 60000,
    })

    const toggleModal = (): void => {
        setTimeout(() => setModalOpen(!isModalOpen), 100)
    }

    return (
        <footer className={styles.footer}>
            <div className={styles.wrapper}>
                <div className={styles.info}>
                    <div className={styles.blockNumber}>
                        <div className={styles.greenBall}></div>
                        <div className={styles.number}>{isError ? '...' : data}</div>
                    </div>
                    <div className={styles.docs}>
                        <div onClick={toggleModal}>Docs & More</div>
                    </div>

                    <div style={{ display: 'none' }}>
                        <PendingsV2 modal={pendingsModal} />
                    </div>

                    {mobileStatus && !isLimitOrder && (
                        <div
                            className={pendingStatus === 0 ? clsnm(styles.pendings, styles.opacity) : styles.pendings}
                            style={{
                                background:
                                    pendingStatus === 0 ? '#FCD34D' : pendingStatus === 1 ? '#38E08C' : '#FF5050',
                            }}
                        >
                            <div className={styles.data}>
                                {pendingStatus === 0 ? 'PENDING' : pendingStatus === 1 ? 'SUCCESSFUL' : 'FAILED'}
                            </div>
                            <div className={styles.ball}></div>
                        </div>
                    )}
                </div>
                <div className={styles.options}>
                    <a
                        href="https://twitter.com/FibrousFinance"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.socialIcon}
                    >
                        <FaXTwitter className={styles.socialIconTwitter} />
                    </a>
                    <a href="https://discord.gg/fibrous" target="_blank" rel="noreferrer" className={styles.socialIcon}>
                        {theme === 'light' ? <DiscordLight /> : <Discord />}
                    </a>
                </div>

                <div style={{ display: 'none' }}>
                    <History />
                </div>
            </div>

            <ModalV2 isOpen={isModalOpen} close={toggleModal} className={styles.modalBody}>
                <a href="https://github.com/Fibrous-Finance" target="_blank" rel="noreferrer">
                    Github
                </a>
                <div className={styles.line}></div>
                <a href="https://medium.com/@fibrous" target="_blank" rel="noreferrer">
                    Medium
                </a>
                <div className={styles.line}></div>
                <a href="mailto:support@fibrous.finance" target="_blank" rel="noreferrer">
                    Contact
                </a>
                <div className={styles.line}></div>
                <a href="https://docs.fibrous.finance" target="_blank" rel="noreferrer">
                    Docs
                </a>
                <div className={styles.line}></div>
                <a href="https://docs.fibrous.finance/faq" target="_blank" rel="noreferrer">
                    FAQ
                </a>
                <div className={styles.line}></div>
                <a
                    href="https://www.youtube.com/playlist?list=PLsxLve21ZBFrqQoCVL-gOASaDEmumoPRV"
                    target="_blank"
                    rel="noreferrer"
                >
                    Tutorial
                </a>
                {/* <div className={styles.line}></div>
                <a href="https://docs.fibrous.finance/privacy" target="_blank" rel="noreferrer">
                    Privacy Policy
                </a>
                <div className={styles.line}></div>
                <a href="https://docs.fibrous.finance/terms" target="_blank" rel="noreferrer">
                    Terms & Conditions
                </a>
                <div className={styles.line}></div> */}
            </ModalV2>
        </footer>
    )
}

export { Footer }
