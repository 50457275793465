import { CONFIG } from 'config/config'
import { ethers } from 'ethers'
import type { BigNumber } from 'ethers'
import { useSelector } from 'react-redux'
import type { Token } from 'types/token'
import type { ApiToken } from 'types/tokenPrice'

import type { RootState } from '../store/index'

export const useGetTokenPrices = (): {
    getTokenPrices: () => Promise<Record<string, string>>
    calculateTokenBalance: (tokenPrice: string, token: Token, tokenAmount: BigNumber) => number
    calculateTotalBalance: (tokenPrices: Record<string, string>) => number
    getTokenPricesAndTotalBalance: () => Promise<{
        tokenPrices: Record<string, string>
        totalBalance: number
    }>
} => {
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const tokens = useSelector((state: RootState) => state.settings.tokens)

    const getTokenPrices = async (): Promise<Record<string, string>> => {
        const data: Array<ApiToken> = tokens
        const prices = data.reduce<Record<string, string>>((acc, token) => {
            acc[token.address] = token.price
            return acc
        }, {})
        return prices
    }

    const calculateTokenBalance = (tokenPrice: string, token: Token, tokenAmount: BigNumber): number => {
        if (!tokenAmount) {
            return 0
        }
        try {
            const tokenValue = ethers.utils.formatUnits(tokenAmount, token.decimals)
            return parseFloat(tokenValue) * parseFloat(tokenPrice)
        } catch (e) {
            console.log(e)
            return 0
        }
    }

    const calculateTotalBalance = (tokenPrices: Record<string, string>): number => {
        try {
            return CONFIG.tokenList.reduce((total, token, i) => {
                const tokenPrice = tokenPrices[token.address] || '0'
                const final = calculateTokenBalance(tokenPrice, token, tokenAmounts[i])
                return total + final
            }, 0)
        } catch (e) {
            console.log(e)
            return 0
        }
    }

    const getTokenPricesAndTotalBalance = async (): Promise<{
        tokenPrices: Record<string, string>
        totalBalance: number
    }> => {
        const tokenPrices = await getTokenPrices()
        const totalBalance = calculateTotalBalance(tokenPrices)
        return { tokenPrices, totalBalance }
    }

    return {
        getTokenPrices,
        calculateTokenBalance,
        calculateTotalBalance,
        getTokenPricesAndTotalBalance,
    }
}
