import { useAccount, useWaitForTransaction } from '@starknet-react/core'
import STARKNETROCKS from 'assets/rockTX.svg'
import { FailedV2 } from 'components/PendingsV2/FailedV2/FailedV2'
import { PendingV2 } from 'components/PendingsV2/PendingV2/PendingV2'
import { SuccessfulV2 } from 'components/PendingsV2/SuccessfulV2/SuccessfulV2'
import { CONFIG } from 'config/config'
import type { ModalController } from 'hooks/useModal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setPendingPayload, setPendingStatus, setPendingsData } from 'store/slicers/pending'
import { setHistoryData } from 'store/slicers/settings'
import type { HistoryTXProps } from 'types/history'
import type { InvokeTransactionReceiptResponse } from 'types/network'
import { PendingState } from 'types/pending'
import { ModalV2 } from 'ui'
import { clsnm } from 'utils/clsnm'
import { getOutputAmountFromEvents } from 'utils/filterEvents'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './PendingsV2.module.scss'

const PendingsV2 = ({ modal }: { modal: ModalController }): JSX.Element => {
    const [renderStatus, setRenderStatus] = useState('pending') // 'successful', 'failed', 'pending'

    const { account, address } = useAccount()
    const dispatch = useDispatch()

    const pendingStatus = useSelector((state: RootState) => state.pending.status)
    const pendingPayload = useSelector((state: RootState) => state.pending.payload)
    const pendingData = useSelector((state: RootState) => state.pending.pendingData)
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const isRocksHolder = useSelector((state: RootState) => state.starknet.rocksHolder)
    const outputToken = useSelector((state: RootState) => state.output.token)
    const historyData = useSelector((state: RootState) => state.settings.historyData)

    const areTokensMatched =
        (pendingData.inputToken === 10 && pendingData.outputToken === 12) ||
        (pendingData.inputToken === 12 && pendingData.outputToken === 10)

    const { data, isError } = useWaitForTransaction({
        hash: pendingPayload,
        watch: true,
        retry: true,
    })

    useEffect(() => {
        if (modal.isOpen === true) return

        dispatch(setPendingPayload(''))
        dispatch(setPendingStatus(PendingState.PENDING))
    }, [modal.isOpen])

    useEffect(() => {
        if (!account || !address) return

        if (['REVERTED', 'SUCCEEDED'].includes((data as InvokeTransactionReceiptResponse)?.execution_status)) {
            const history: string | null = historyData

            const events = (data as InvokeTransactionReceiptResponse)?.events

            if (history === null || !data || !events) {
                return
            } else {
                const historyData = JSON.parse(history)
                const historyItem = historyData.find((item: HistoryTXProps) => item.link === pendingPayload)

                if (historyItem && historyItem.data && historyItem.data.length > 0) {
                    const lastDataItem = historyItem.data[historyItem.data.length - 1]
                    if (!areTokensMatched) {
                        lastDataItem.amount = setFixedNumber(
                            getOutputAmountFromEvents(events, address, outputToken, CONFIG.tokenList),
                        )
                    }
                    historyItem.reverted = (data as InvokeTransactionReceiptResponse)?.execution_status

                    dispatch(setHistoryData(JSON.stringify(historyData)))
                }
            }

            // dispatch(
            //     setPendingsData({
            //         ...pendingData,
            //         outputValue: tokenAmounts[pendingData.outputToken].sub(pendingData.outputValue).toString(),
            //     }),
            // )
        }
    }, [tokenAmounts, pendingStatus, data, pendingPayload])

    useEffect(() => {
        dispatch(setPendingPayload(''))
        modal.close()
    }, [address])

    useEffect(() => {
        if (pendingStatus === PendingState.SUCCESSFUL) {
            const timer = setTimeout(() => {
                modal.close()
            }, 15000)

            return () => clearTimeout(timer)
        }
    }, [pendingStatus])

    useEffect(() => {
        if (
            (data as InvokeTransactionReceiptResponse)?.finality_status === 'ACCEPTED_ON_L2' &&
            (data as InvokeTransactionReceiptResponse)?.execution_status === 'SUCCEEDED'
        ) {
            dispatch(setPendingStatus(PendingState.SUCCESSFUL))
            setRenderStatus('successful')
        } else if (
            (data as InvokeTransactionReceiptResponse)?.execution_status === 'REVERTED' ||
            (data as InvokeTransactionReceiptResponse)?.execution_status === 'REJECTED' ||
            isError ||
            pendingStatus === PendingState.FAILED
        ) {
            dispatch(setPendingStatus(PendingState.FAILED))
            setRenderStatus('failed')
        } else {
            setRenderStatus('pending')
        }
    }, [data, isError, pendingStatus])

    return (
        <ModalV2
            isOpen={modal.isOpen}
            close={modal.close}
            className={
                (data as InvokeTransactionReceiptResponse)?.finality_status &&
                ['RECEIVED', 'ACCEPTED_ON_L2', 'ACCEPTED_ON_L1'].includes(
                    (data as InvokeTransactionReceiptResponse)?.finality_status,
                )
                    ? isRocksHolder
                        ? clsnm(styles.wrapper, styles.holder)
                        : styles.wrapper
                    : isRocksHolder
                      ? clsnm(styles.wrapper, styles.holder)
                      : clsnm(styles.wrapper)
            }
            closeOnClickOutside={false}
        >
            {isRocksHolder && (
                <div className={styles.rock}>
                    <img src={STARKNETROCKS} alt="rock"></img>
                </div>
            )}

            <div className={styles.content}>
                {renderStatus === 'successful' && data ? (
                    <SuccessfulV2 data={data as InvokeTransactionReceiptResponse} />
                ) : renderStatus === 'failed' ? (
                    <FailedV2 />
                ) : (
                    <PendingV2 />
                )}
            </div>
        </ModalV2>
    )
}

export { PendingsV2 }
