import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { PendingState } from 'types/pending'

export type PendingDataType = {
    inputToken: number
    inputValue: string
    outputToken: number
    outputValue: string
}
export interface PendingProps {
    status: PendingState
    mobileStatus: boolean
    payload: string
    pendingData: PendingDataType
}

const initialState: PendingProps = {
    status: PendingState.PENDING,
    mobileStatus: false,
    payload: '',
    pendingData: {
        inputToken: 0,
        inputValue: '',
        outputToken: 1,
        outputValue: '',
    },
}

export const pendingSlice = createSlice({
    name: 'pending',
    initialState,
    reducers: {
        setPendingStatus: (state, action: PayloadAction<PendingState>) => {
            state.status = action.payload
        },
        setMobilePendingStatus: (state, action: PayloadAction<boolean>) => {
            state.mobileStatus = action.payload
        },
        setPendingPayload: (state, action: PayloadAction<string>) => {
            state.payload = action.payload
        },
        setPendingsData: (state, action: PayloadAction<PendingDataType>) => {
            state.pendingData = action.payload
        },
    },
})

export const { setPendingStatus, setPendingPayload, setPendingsData, setMobilePendingStatus } = pendingSlice.actions
export default pendingSlice.reducer
