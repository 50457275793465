import { useStarkAddress } from '@starknet-react/core'
import { useModal } from 'hooks/useModal'
import { useCallback, useEffect, useState } from 'react'
import type { ChangeEvent, KeyboardEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { validateAndParseAddress } from 'starknet'
import type { RootState } from 'store'
import { setDestinationAddress, setDestinationDisclaimerConfirmed, setIsDestinationOpen } from 'store/slicers/settings'
import { useDebounce } from 'use-debounce'
import { isValidStarknetAddress, isValidStarknetAddressOrDomain, isValidStarknetDomain } from 'utils/arenaUtils'
import { clsnm } from 'utils/clsnm'

import styles from './Destination.module.scss'
import { Disclaimer } from './Disclaimer/Disclaimer'
import { Rate } from './Rate/Rate'

const Destination = (): JSX.Element => {
    const [isOn, setIsOn] = useState(false)
    const [destination, setDestination] = useState('')
    const [isInputFocus, setIsInputFocus] = useState(false)
    const [isInvalidInput, setIsInvalidInput] = useState(false)

    const destinationDisclaimerConfirmed = useSelector(
        (state: RootState) => state.settings.destinationDisclaimerConfirmed,
    )

    const dispatch = useDispatch()
    const disclaimerModal = useModal()
    const { data } = useStarkAddress({ name: destination.toLowerCase().endsWith('.stark') ? destination : '' })

    const [debouncedValue] = useDebounce(destination, 750)

    const handleChangedDestination = useCallback((): void => {
        if (!destination || !isValidStarknetAddressOrDomain(destination)) {
            return
        }

        let processedInput = data || destination.toLowerCase()

        if (isValidStarknetAddress(processedInput)) {
            processedInput = validateAndParseAddress(processedInput)
            setDestination(processedInput)
            dispatch(setDestinationAddress(processedInput))
        } else {
            setIsInvalidInput(true)
        }
    }, [dispatch, destination, data])

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setDestination(e.target.value.replace(/\s+/g, '').toLowerCase())
    }

    const handleInputFocus = (): void => setIsInputFocus(true)

    const handleInputBlur = (): void => {
        handleChangedDestination()
        setIsInputFocus(false)
        setIsInvalidInput(!isValidStarknetAddressOrDomain(destination))
    }

    const handleInputKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            handleChangedDestination()
            setIsInvalidInput(!isValidStarknetAddressOrDomain(destination))
        }
    }

    const clear = (): void => {
        setDestination('')
        dispatch(setDestinationAddress(''))
        setIsInvalidInput(false)
    }

    const switchHandler = (): void => {
        dispatch(setIsDestinationOpen(!isOn))
        setIsOn(!isOn)
    }

    useEffect((): void => {
        dispatch(setDestinationAddress(''))
    }, [isInvalidInput])

    useEffect((): void => {
        if (!isOn || !destinationDisclaimerConfirmed) {
            clear()
        }
    }, [isOn, destinationDisclaimerConfirmed])

    useEffect((): void => {
        if (destination) {
            setIsInvalidInput(!isValidStarknetAddressOrDomain(destination))
        }

        if (isValidStarknetAddressOrDomain(destination)) {
            if (isValidStarknetDomain(destination) && !data) {
                setIsInvalidInput(true)
            } else {
                handleChangedDestination()
                setIsInvalidInput(!isValidStarknetAddressOrDomain(destination))
            }
        }
    }, [debouncedValue])

    useEffect(() => {
        if (isOn) {
            disclaimerModal.open()
        } else {
            dispatch(setDestinationDisclaimerConfirmed(false))
        }
    }, [isOn])

    useEffect(() => {
        if (!destinationDisclaimerConfirmed) {
            setIsOn(false)
        } else {
            setIsOn(true)
        }
    }, [destinationDisclaimerConfirmed, disclaimerModal, isOn])

    return (
        <div className={styles.wrapper}>
            <Disclaimer modal={disclaimerModal} />

            <div className={styles.container}>
                <div className={styles.rate}>
                    <Rate />
                </div>
                <div className={styles.switch}>
                    <div className={isOn ? styles.statusOn : styles.statusOff}>
                        <div
                            className={styles.floor}
                            style={{ justifyContent: isOn ? 'end' : 'start' }}
                            onClick={switchHandler}
                        >
                            <div className={styles.ball}></div>
                        </div>
                    </div>
                    <div className={styles.label}>
                        <span className={styles.text} onClick={switchHandler}>
                            Another Destination
                        </span>
                    </div>
                </div>
            </div>
            {destinationDisclaimerConfirmed && (
                <div className={styles.inputWrapper}>
                    <div
                        className={clsnm(
                            styles.input,
                            isInputFocus && styles.focusInput,
                            isInvalidInput && styles.invalidInput,
                        )}
                    >
                        <input
                            type="text"
                            placeholder="Custom Recipient Starknet Address or StarknetID"
                            value={destination}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onClick={(event): void => event.currentTarget.select()}
                            onKeyUp={handleInputKeyUp}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export { Destination }
