import COPY from 'assets/copyDark.svg'
import DARKARROW from 'assets/rightArrow.svg'
import LIGHTARROW from 'assets/rightArrowLight.svg'
import { CONFIG } from 'config/config'
import { useTheme } from 'hooks/useTheme'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import { parseAddress } from 'utils/parseAddress'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './BatchSuccessful.module.scss'

const BatchSuccessful = (): JSX.Element => {
    const { theme } = useTheme()

    const pendingPayload = useSelector((state: RootState) => state.batchPending.payload)
    const pendingData = useSelector((state: RootState) => state.batchPending.pendingData)
    const isRocksHolder = useSelector((state: RootState) => state.starknet.rocksHolder)
    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)

    return (
        <div className={styles.wrapper}>
            <div className={styles.infos}>
                <div className={styles.info}>Transaction Successful!</div>
                <a
                    target={'_blank'}
                    rel="noreferrer"
                    className={styles.tx}
                    href={explorerUrl.concat(`tx/${pendingPayload}`)}
                >
                    <img src={COPY} alt="copy"></img>
                    <div className={styles.text}>TX: {parseAddress(pendingPayload)}</div>
                </a>
            </div>
            <div className={styles.tokens} style={isRocksHolder ? { background: '#691F6F' } : {}}>
                <div className={styles.inputTokens}>
                    <div className={styles.token}>
                        {pendingData.batchInputValue.map((value: string, i: number) => {
                            if (Number(value) <= 0) {
                                return null
                            }
                            return (
                                <div className={styles.inputToken} key={i}>
                                    <img
                                        src={CONFIG.tokenList[pendingData.batchInputToken[i]].imageUrl}
                                        alt="logo"
                                    ></img>
                                    <div className={styles.amount} style={isRocksHolder ? { color: '#FFF' } : {}}>
                                        {setFixedNumber(Number(value).toFixed(4))}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={styles.ok}>
                    <img src={isRocksHolder ? LIGHTARROW : theme === 'dark' ? LIGHTARROW : DARKARROW}></img>
                </div>
                <div className={styles.token}>
                    <img src={CONFIG.tokenList[pendingData.batchOutputToken].imageUrl} alt="icon"></img>
                    <div className={styles.amount} style={isRocksHolder ? { color: '#FFF' } : {}}>
                        {Number(pendingData.batchOutputValue).toFixed(4)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { BatchSuccessful }
