import { BigNumber } from 'ethers'

import { WAITING } from '../types/route'

export const haveEnoughToken = (
    valuesArray: Array<BigNumber>,
    tokensArray: Array<number>,
    amounts: Array<BigNumber>,
): boolean => {
    try {
        let enough = true
        tokensArray.map((token: number, i: number) => {
            if (BigNumber.from(valuesArray[i]).gt(amounts[token])) {
                enough = false
            }
            return null
        })
        return enough
    } catch (e) {
        console.log(e)
    }
    return false
}

export const isThereBatchInput = (valuesArray: Array<BigNumber>): boolean => {
    try {
        const total = valuesArray.reduce((a: BigNumber, b: BigNumber) => a.add(b), BigNumber.from(0))

        return total.gt(0)
    } catch (e) {
        console.log(e)
    }
    return false
}

export const checkButtonDisable = (
    valuesArray: Array<BigNumber>,
    tokensArray: Array<number>,
    isWaiting: WAITING,
    amounts: Array<BigNumber>,
): boolean => {
    return (
        !(isWaiting === WAITING.FALSE) ||
        !isThereBatchInput(valuesArray) ||
        !haveEnoughToken(valuesArray, tokensArray, amounts)
    )
}

export const checkButtonText = (
    valuesArray: Array<BigNumber>,
    tokensArray: Array<number>,
    amounts: Array<BigNumber>,
    account: boolean,
): string => {
    const enough: boolean = haveEnoughToken(valuesArray, tokensArray, amounts)
    return !account ? 'CONNECT WALLET' : enough ? 'SWAP ALL TO' : 'INSUFFICIENT FUNDS'
}
