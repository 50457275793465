import { useAccount, useDisconnect } from '@starknet-react/core'
import ARGENT from 'assets/argent.svg'
import BITGED from 'assets/bitget.svg'
import BRAAVOS from 'assets/braavos.svg'
import COPY from 'assets/copy.svg'
import OKX from 'assets/lightOkx.svg'
import { ReactComponent as LINE } from 'assets/line.svg'
import LOGOUT from 'assets/logout.svg'
import { History } from 'components/FooterV2/History/History'
import { CONFIG } from 'config/config'
import { utils } from 'ethers'
import { useGetTokenPrices } from 'hooks/useGetTokenPrices'
import type { ModalController } from 'hooks/useModal'
import { useTheme } from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { validateAndParseAddress } from 'starknet'
import type { RootState } from 'store'
import type { Token } from 'types/token'
import { Modal } from 'ui'
import { clsnm } from 'utils/clsnm'
import { formatValue } from 'utils/formatValue'
import { parseAddress } from 'utils/parseAddress'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './Wallet.module.scss'

const Wallet = ({ modal }: { modal: ModalController }): JSX.Element => {
    const [activeTab, setActiveTab] = useState<string>('assets')
    const [tokenPrices, setTokenPrices] = useState<Record<string, string>>({})
    const [totalBalance, setTotalBalance] = useState<number>(0)
    const [sortedTokens, setSortedTokens] = useState<typeof CONFIG.tokenList>([])
    const [copySuccess, setCopySuccess] = useState<string>('')

    const { address, connector } = useAccount()
    const { disconnect } = useDisconnect()
    const { theme } = useTheme()
    const { getTokenPricesAndTotalBalance, calculateTotalBalance, calculateTokenBalance } = useGetTokenPrices()
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)

    const isMobile = useMediaQuery({
        query: '(max-width: 650px)',
    })

    const extendedClose = (): void => {
        modal.close()
        setActiveTab('assets')
    }

    const handleDisconnect = (): void => {
        disconnect()
        extendedClose()
    }

    const handlePrices = async (): Promise<void> => {
        const { tokenPrices, totalBalance } = await getTokenPricesAndTotalBalance()
        setTokenPrices(tokenPrices)
        setTotalBalance(totalBalance)
    }

    useEffect(() => {
        handlePrices()

        const fetchAndSortTokens = async (): Promise<void> => {
            const prices = tokenPrices

            const sorted = [...CONFIG.tokenList].sort((a, b) => {
                const tokenAmountA = tokenAmounts[CONFIG.tokenList.findIndex((t) => t.address === a.address)]
                const tokenAmountB = tokenAmounts[CONFIG.tokenList.findIndex((t) => t.address === b.address)]

                const valueA = calculateTokenBalance(prices[a.address] || '0', a, tokenAmountA)
                const valueB = calculateTokenBalance(prices[b.address] || '0', b, tokenAmountB)

                return valueB - valueA
            })
            setSortedTokens(sorted)
        }

        fetchAndSortTokens()
    }, [modal.isOpen, tokenAmounts])

    useEffect(() => {
        const balance = calculateTotalBalance(tokenPrices)
        setTotalBalance(balance)
    }, [modal.isOpen, tokenPrices])

    return (
        <Modal isOpen={modal.isOpen} close={extendedClose} className={styles.wrapper}>
            <div className={styles.card}>
                <div className={styles.logout} onClick={handleDisconnect}>
                    <span>Disconnect</span>
                    <img src={LOGOUT} alt="logout" />
                </div>
                <div className={styles.balance}>
                    <div className={styles.balance__title}>BALANCE</div>
                    <div className={styles.balance__amount}>${formatValue(totalBalance.toFixed(2))}</div>
                    <div className={styles.walletAddress}>
                        <div className={styles.walletAddress__address}>
                            <a href={`${explorerUrl}contract/${address}`} target="_blank" rel="noreferrer">
                                {parseAddress(validateAndParseAddress(address || ''))}
                            </a>
                        </div>
                        <div className={styles.walletAddress__copy}>
                            <img
                                src={COPY}
                                alt="copy"
                                onClick={async (): Promise<void> => {
                                    try {
                                        await navigator.clipboard.writeText(validateAndParseAddress(address || ''))
                                        setCopySuccess('Copied!')
                                        setTimeout(() => setCopySuccess(''), 2000)
                                    } catch (err) {
                                        setCopySuccess('Failed to copy')
                                    }
                                }}
                            />
                            {copySuccess && <div className={styles.tooltip}>{copySuccess}</div>}
                        </div>
                    </div>
                </div>
                {connector && connector?.id === 'braavos' ? (
                    <div className={styles.walletIcon}>
                        <div className={styles.walletIcon__icon}>
                            <img src={BRAAVOS} alt="braavos" />
                        </div>
                        <div className={styles.walletIcon__name}>Braavos</div>
                    </div>
                ) : connector && connector?.id === 'argentX' ? (
                    <div className={styles.walletIcon}>
                        <img src={ARGENT} alt="argent" />
                        <div className={styles.walletIcon__name}>{isMobile ? 'Argent' : 'Argent X'}</div>
                    </div>
                ) : connector && connector?.id === 'okxwallet' ? (
                    <div className={styles.walletIcon}>
                        <div className={styles.walletIcon__icon}>
                            <img src={OKX} alt="okxwallet" />
                        </div>
                        <div className={styles.walletIcon__name}>OKX Wallet</div>
                    </div>
                ) : connector && connector?.id === 'bitkeep' ? (
                    <div className={styles.walletIcon}>
                        <div className={styles.walletIcon__icon}>
                            <img src={BITGED} alt="bitkeep" />
                        </div>
                        <div className={styles.walletIcon__name}>Bitget Wallet</div>
                    </div>
                ) : connector && connector?.id === 'argentMobile' ? (
                    <div className={styles.walletIcon}>
                        <img src={ARGENT} alt="argent" />
                        <div className={styles.walletIcon__name}>Argent Mobile</div>
                    </div>
                ) : (
                    <div className={styles.webWallet}>
                        <div className={styles.walletIcon}>
                            <img src={ARGENT} alt="argent" />
                            <div className={styles.walletIcon__name}>Argent Web Wallet</div>
                        </div>
                        <div
                            className={styles.webWallet__dashboard}
                            onClick={(): null | Window => window.open('https://web.argent.xyz/dashboard', '_blank')}
                        >
                            Open Dashboard &nbsp; <FiExternalLink size={12} />
                        </div>
                    </div>
                )}
            </div>

            <div className={styles.tabs}>
                <button
                    className={
                        activeTab === 'assets' ? clsnm(styles.tabs__tab, styles.tabs__tab_active) : styles.tabs__tab
                    }
                    onClick={(): void => {
                        setActiveTab('assets')
                    }}
                >
                    Assets
                </button>
                <LINE stroke={theme === 'dark' ? '#384A66' : '#CFD8E6'} />
                <button
                    className={
                        activeTab === 'history' ? clsnm(styles.tabs__tab, styles.tabs__tab_active) : styles.tabs__tab
                    }
                    onClick={(): void => {
                        setActiveTab('history')
                    }}
                >
                    History
                </button>
            </div>

            <div className={styles.content}>
                {activeTab === 'assets' ? (
                    <div className={styles.tokens}>
                        {sortedTokens.map((token: Token, index: number) => {
                            const originalIndex = CONFIG.tokenList.findIndex(
                                (originalToken) => originalToken.address === token.address,
                            )
                            return (
                                <div
                                    className={styles.tokenWrapper}
                                    key={originalIndex}
                                    style={{
                                        display:
                                            calculateTokenBalance(
                                                tokenPrices[token.address],
                                                token,
                                                tokenAmounts[originalIndex],
                                            ) > 0.01
                                                ? 'flex'
                                                : 'none',
                                    }}
                                >
                                    <div
                                        className={styles.line}
                                        style={{
                                            display: index === 0 ? 'none' : 'flex',
                                        }}
                                    ></div>

                                    <div className={styles.token}>
                                        <div className={styles.name}>
                                            <img src={token.imageUrl} alt="logo"></img>
                                            <div className={styles.text}>{token.symbol}</div>
                                            <div className={styles.name}> {token.name}</div>
                                        </div>
                                        <div className={styles.values}>
                                            <div className={styles.value}>
                                                {formatValue(
                                                    setFixedNumber(
                                                        utils.formatUnits(tokenAmounts[originalIndex], token.decimals),
                                                    ),
                                                )}
                                            </div>
                                            <div className={styles.price}>
                                                $
                                                {formatValue(
                                                    calculateTokenBalance(
                                                        tokenPrices[token.address],
                                                        token,
                                                        tokenAmounts[originalIndex],
                                                    ).toFixed(2),
                                                ) ?? 'N/a'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <History />
                )}
            </div>
        </Modal>
    )
}

export { Wallet }
