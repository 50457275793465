import axios from 'axios'
import { useEffect, useState } from 'react'

import { CONFIG, EVENTS } from '../config/config'
import type { UserSummary } from '../types/arena'

export const useRankingData = (activeIndex: number): Array<UserSummary> => {
    const [rankingData, setRankingData] = useState<Array<UserSummary>>([])
    useEffect(() => {
        axios
            .get(
                `${CONFIG.event_base_api}swaps?event_type=${EVENTS[activeIndex].eventType}&sort_type=desc&offset=0&limit=100&date_start=${EVENTS[activeIndex].dateStart}&date_end=${EVENTS[activeIndex].dateEnd}`,
            )
            .then((response) => {
                setRankingData(response.data)
            })
    }, [activeIndex])

    return rankingData
}

export const useRankData = (activeIndex: number): Array<UserSummary> => {
    const [rankData, setRankData] = useState<Array<UserSummary>>([])
    useEffect(() => {
        axios
            .get(
                `${CONFIG.event_base_api}swaps?event_type=${EVENTS[activeIndex].eventType}&sort_type=desc&offset=0&limit=10000&date_start=${EVENTS[activeIndex].dateStart}&date_end=${EVENTS[activeIndex].dateEnd}`,
            )
            .then((response) => {
                setRankData(response.data)
            })
    }, [activeIndex])

    return rankData
}

export const useCurrentUserData = (activeIndex: number, address: string | undefined): UserSummary | undefined => {
    const [currentUserData, setCurrentUserData] = useState<UserSummary>()

    useEffect(() => {
        if (address) {
            axios
                .get(
                    `${CONFIG.event_base_api}swaps?event_type=${EVENTS[activeIndex].eventType}&sort_type=desc&offset=0&limit=10&filter=wallet_address&filter_value=${address}&date_start=${EVENTS[activeIndex].dateStart}&date_end=${EVENTS[activeIndex].dateEnd}`,
                )
                .then((response) => {
                    setCurrentUserData(response.data[0])
                })
        }
    }, [activeIndex, address])

    return currentUserData
}

export const useSearchedUserInfo = (
    activeIndex: number,
    lastSearchedAddress: string | null,
): UserSummary | undefined => {
    const [searchedUserInfo, setSearchedUserInfo] = useState<UserSummary>()

    useEffect(() => {
        if (lastSearchedAddress) {
            axios
                .get(
                    `${CONFIG.event_base_api}swaps?event_type=${EVENTS[activeIndex].eventType}&sort_type=desc&offset=0&limit=10&filter=wallet_address&filter_value=${lastSearchedAddress}&date_start=${EVENTS[activeIndex].dateStart}&date_end=${EVENTS[activeIndex].dateEnd}`,
                )
                .then((response) => {
                    setSearchedUserInfo(response.data[0])
                })
        } else {
            setSearchedUserInfo(undefined)
        }
    }, [activeIndex, lastSearchedAddress])

    return searchedUserInfo
}
