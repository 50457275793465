import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { BigNumber } from 'ethers'

export interface InputState {
    token: number
    value: BigNumber
}

const initialState: InputState = {
    token: 1,
    value: BigNumber.from(0),
}

export const inputSlice = createSlice({
    name: 'input',
    initialState,
    reducers: {
        setInputToken: (state, action: PayloadAction<number>) => {
            state.token = action.payload
        },
        setInputValue: (state, action: PayloadAction<BigNumber>) => {
            state.value = action.payload
        },
    },
})

export const { setInputToken, setInputValue } = inputSlice.actions
export default inputSlice.reducer
