import { useAccount, useWaitForTransaction } from '@starknet-react/core'
import STARKNETROCKS from 'assets/rockTX.svg'
import { BatchFailed } from 'components/BatchPendings/BatchFailed/BatchFailed'
import { BatchPending } from 'components/BatchPendings/BatchPending/BatchPending'
import { BatchSuccessful } from 'components/BatchPendings/BatchSuccessful/BatchSuccessful'
import { CONFIG } from 'config/config'
import type { ModalController } from 'hooks/useModal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setBatchPendingPayload, setBatchPendingStatus, setBatchPendingsData } from 'store/slicers/batchPending'
import { setHistoryData } from 'store/slicers/settings'
import type { HistoryTXProps } from 'types/history'
import type { InvokeTransactionReceiptResponse } from 'types/network'
import { PendingState } from 'types/pending'
import { ModalV2 } from 'ui'
import { clsnm } from 'utils/clsnm'
import { getOutputAmountFromEvents } from 'utils/filterEvents'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './BatchPendings.module.scss'

const BatchPendings = ({ modal }: { modal: ModalController }): JSX.Element => {
    const [renderStatus, setRenderStatus] = useState('pending') // 'successful', 'failed', 'pending'

    const dispatch = useDispatch()
    const { account, address } = useAccount()

    const pendingStatus = useSelector((state: RootState) => state.batchPending.status)
    const pendingPayload = useSelector((state: RootState) => state.batchPending.payload)
    const pendingData = useSelector((state: RootState) => state.batchPending.pendingData)
    const isRocksHolder = useSelector((state: RootState) => state.starknet.rocksHolder)
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const historyData = useSelector((state: RootState) => state.settings.historyData)
    const batchOutputToken = useSelector((state: RootState) => state.batch.outputToken)

    const { data, isError } = useWaitForTransaction({
        hash: pendingPayload,
        watch: true,
        retry: true,
    })

    useEffect(() => {
        if (modal.isOpen === true) return

        dispatch(setBatchPendingPayload(''))
        dispatch(setBatchPendingStatus(PendingState.PENDING))
    }, [modal.isOpen])

    useEffect(() => {
        if (!account || !address) return

        if (['REVERTED', 'SUCCEEDED'].includes((data as InvokeTransactionReceiptResponse)?.execution_status)) {
            const history: string | null = historyData

            const events = (data as InvokeTransactionReceiptResponse)?.events

            if (history === null || !data || !events) {
                return
            } else {
                const historyData = JSON.parse(history)
                const historyItem = historyData.find((item: HistoryTXProps) => item.link === pendingPayload)

                if (historyItem && historyItem.data && historyItem.data.length > 0) {
                    const lastDataItem = historyItem.data[historyItem.data.length - 1]
                    lastDataItem.amount = setFixedNumber(
                        getOutputAmountFromEvents(events, address, batchOutputToken, CONFIG.tokenList),
                    )
                    historyItem.reverted = (data as InvokeTransactionReceiptResponse)?.execution_status

                    dispatch(setHistoryData(JSON.stringify(historyData)))
                }
            }

            dispatch(
                setBatchPendingsData({
                    ...pendingData,
                    batchOutputValue: setFixedNumber(
                        getOutputAmountFromEvents(events, address, pendingData.batchOutputToken, CONFIG.tokenList),
                    ),
                }),
            )
        }
    }, [tokenAmounts, pendingStatus, data, pendingPayload])

    useEffect(() => {
        if (
            (data as InvokeTransactionReceiptResponse)?.finality_status === 'ACCEPTED_ON_L2' &&
            (data as InvokeTransactionReceiptResponse)?.execution_status === 'SUCCEEDED'
        ) {
            dispatch(setBatchPendingStatus(PendingState.SUCCESSFUL))
            setRenderStatus('successful')
        } else if (
            (data as InvokeTransactionReceiptResponse)?.execution_status === 'REVERTED' ||
            (data as InvokeTransactionReceiptResponse)?.execution_status === 'REJECTED' ||
            isError ||
            pendingStatus === PendingState.FAILED
        ) {
            dispatch(setBatchPendingStatus(PendingState.FAILED))
            setRenderStatus('failed')
        } else {
            setRenderStatus('pending')
        }
    }, [data, isError, pendingStatus])

    useEffect(() => {
        dispatch(setBatchPendingPayload(''))
        modal.close()
    }, [address])

    useEffect(() => {
        if (pendingStatus === PendingState.SUCCESSFUL) {
            const timer = setTimeout(() => {
                modal.close()
            }, 15000)

            return () => clearTimeout(timer)
        }
    }, [pendingStatus])

    return (
        <ModalV2
            isOpen={modal.isOpen}
            close={modal.close}
            className={isRocksHolder ? clsnm(styles.wrapper, styles.holder) : styles.wrapper}
            closeOnClickOutside={false}
        >
            {isRocksHolder && (
                <div className={styles.rock}>
                    <img src={STARKNETROCKS} alt="rock"></img>
                </div>
            )}

            <div className={styles.content}>
                {renderStatus === 'successful' && data ? (
                    <BatchSuccessful />
                ) : renderStatus === 'failed' ? (
                    <BatchFailed />
                ) : (
                    <BatchPending />
                )}
            </div>
        </ModalV2>
    )
}

export { BatchPendings }
