import Axios from 'axios'
import type { AxiosResponse } from 'axios'
import { CONFIG } from 'config/config'
import { API } from 'restapi/api'
import type { Balance } from 'types/balance'
import type { Token } from 'types/token'
import type { ApiToken } from 'types/tokenPrice'

export const axios = Axios.create({
    baseURL: CONFIG.graph_api,
})

export const apiGetBalances = async (
    address: string,
    tokenAddresses: Array<string>,
): Promise<AxiosResponse<Array<Balance>>> => {
    const payload = {
        tokenAddresses: tokenAddresses,
        walletAddress: address,
    }
    return await axios.post(API.getBalancesRoute(), payload)
}

export const apiGetBalancesUpdated = async (
    address: string,
    tokenAddresses: Array<string>,
): Promise<AxiosResponse<Array<Balance>>> => {
    const payload = {
        tokenAddresses: tokenAddresses,
        walletAddress: address,
    }
    return await axios.post(API.getBalancesUpdatedRoute(), payload)
}

export const apiGetTokenPrices = async (): Promise<AxiosResponse<Array<ApiToken>>> => {
    return await axios.get(API.getTokens())
}

export const apiGetTokens = async (): Promise<AxiosResponse<Array<Token>>> => {
    return await axios.get(API.getTokens())
}
