import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ApiToken } from 'types/tokenPrice'

export interface SettingsState {
    slippage: string
    explorerUrl: string
    isRouterOpen: boolean
    connectModal: boolean
    blockNumber: string
    confirm: boolean
    batchConfirm: boolean
    historyData: string
    liquiditySources: Array<boolean>
    reset: boolean
    approvePermision: boolean
    approves: Array<boolean>
    tokens: Array<ApiToken>
    fee: number
    isDestinationOpen?: boolean
    destinationAddress?: string
    destinationDisclaimerConfirmed: boolean
}

const initialState: SettingsState = {
    slippage: '0.5',
    explorerUrl: 'https://starkscan.co/',
    isRouterOpen: false,
    connectModal: false,
    blockNumber: '94014',
    confirm: false,
    batchConfirm: false,
    historyData: '[]',
    liquiditySources: [true, true, true, true, true, true, true, true, true, true],
    reset: false,
    approvePermision: false,
    approves: [false, false, false, false, false, false, false, false, false, false],
    tokens: [],
    fee: 0.02,
    isDestinationOpen: false,
    destinationAddress: '',
    destinationDisclaimerConfirmed: false,
}
export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSlippage: (state, action: PayloadAction<string>) => {
            state.slippage = action.payload
        },
        setExplorerUrl: (state, action: PayloadAction<string>) => {
            state.explorerUrl = action.payload
        },
        setIsRouterOpen: (state, action: PayloadAction<boolean>) => {
            state.isRouterOpen = action.payload
        },
        setConnectModal: (state, action: PayloadAction<boolean>) => {
            state.connectModal = action.payload
        },
        setBlockNumber: (state, action: PayloadAction<string>) => {
            state.blockNumber = action.payload
        },
        setConfirm: (state, action: PayloadAction<boolean>) => {
            state.confirm = action.payload
        },
        setBatchConfirm: (state, action: PayloadAction<boolean>) => {
            state.batchConfirm = action.payload
        },
        setReset: (state, action: PayloadAction<boolean>) => {
            state.reset = action.payload
        },
        setApprovePermision: (state, action: PayloadAction<boolean>) => {
            state.approvePermision = action.payload
        },
        setHistoryData: (state, action: PayloadAction<string>) => {
            state.historyData = action.payload
        },
        setApproves: (state, action: PayloadAction<Array<boolean>>) => {
            state.approves = action.payload
        },
        setTokens: (state, action: PayloadAction<Array<ApiToken>>) => {
            state.tokens = action.payload
        },
        setAllLiquiditySources: (state, action: PayloadAction<Array<boolean>>) => {
            state.liquiditySources = action.payload
        },
        setLiquiditySources: (state, action: PayloadAction<{ key: number; value: boolean }>) => {
            state.liquiditySources[action.payload.key] = action.payload.value
        },
        setFee: (state, action: PayloadAction<number>) => {
            state.fee = action.payload
        },
        setIsDestinationOpen: (state, action: PayloadAction<boolean>) => {
            state.isDestinationOpen = action.payload
        },
        setDestinationAddress: (state, action: PayloadAction<string>) => {
            state.destinationAddress = action.payload
        },
        setDestinationDisclaimerConfirmed: (state, action: PayloadAction<boolean>) => {
            state.destinationDisclaimerConfirmed = action.payload
        },
    },
})

export const {
    setSlippage,
    setExplorerUrl,
    setIsRouterOpen,
    setConnectModal,
    setBlockNumber,
    setConfirm,
    setBatchConfirm,
    setHistoryData,
    setReset,
    setLiquiditySources,
    setApprovePermision,
    setApproves,
    setTokens,
    setAllLiquiditySources,
    setFee,
    setIsDestinationOpen,
    setDestinationAddress,
    setDestinationDisclaimerConfirmed,
} = settingsSlice.actions
export default settingsSlice.reducer
