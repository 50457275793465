import { useAccount } from '@starknet-react/core'
import ADDINPUT from 'assets/addInput.svg'
import { BatchSwapButton } from 'components/BatchSwap/BatchSwapButton/BatchSwapButton'
import { Input } from 'components/BatchSwap/Input/Input'
import { Output } from 'components/BatchSwap/Output/Output'
import { BigNumber } from 'ethers'
import { useGetBalances } from 'hooks/useGetBalancesFromAPI'
import { useGetPath } from 'hooks/useGetPath'
import type { ModalController } from 'hooks/useModal'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { addBatchInput, setBatchOutputValue } from 'store/slicers/batch'
import { setIsBatchWaiting } from 'store/slicers/batch'
import { PendingState } from 'types/pending'
import { WAITING } from 'types/route'
import { isThereBatchInput } from 'utils/checkButtonDisable'

import styles from './BatchSwap.module.scss'

const BatchSwap = ({ pendingsModal }: { pendingsModal: ModalController }): JSX.Element => {
    const dispatch = useDispatch()
    const { account } = useAccount()

    const { getBalances } = useGetBalances()
    const { getBatchPathOne } = useGetPath()

    const batchOutputToken = useSelector((state: RootState) => state.batch.outputToken)
    const batchInputTokens = useSelector((state: RootState) => state.batch.inputTokens)
    const batchInputValues = useSelector((state: RootState) => state.batch.inputValues)
    const pendingStatus = useSelector((state: RootState) => state.batchPending.status)

    useEffect(() => {
        getBalances(true)
    }, [pendingStatus === PendingState.SUCCESSFUL])

    useEffect(() => {
        const isThere = isThereBatchInput(batchInputValues)
        if (isThere) {
            dispatch(setIsBatchWaiting(WAITING.PENDING))
        } else {
            dispatch(setIsBatchWaiting(WAITING.FALSE))
            dispatch(setBatchOutputValue(BigNumber.from(0)))
        }

        const interval = setInterval(() => {
            if (isThere) {
                getBatchPathOne()
            }
        }, 10000)

        return (): void => clearInterval(interval)
    }, [batchOutputToken, batchInputTokens, batchInputValues, account])

    return (
        <div className={styles.wrapper}>
            {batchInputValues.map((value: BigNumber, i: number) => {
                return <Input key={i} inputKey={i} />
            })}
            {batchInputTokens.length < 3 && (
                <div
                    className={styles.addInput}
                    onClick={(): void => {
                        dispatch(addBatchInput())
                    }}
                >
                    <img src={ADDINPUT} alt="add input" />
                </div>
            )}
            <BatchSwapButton pendingsModal={pendingsModal} />
            <Output />
        </div>
    )
}

export { BatchSwap }
