import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store'
import { setTheme } from 'store/slicers/theme'
import type { Theme } from 'types/theme'

export const useTheme = (): {
    toggleTheme: () => void
    theme: Theme
} => {
    const { theme } = useTypedSelector((state) => state.theme)
    const dispatch = useDispatch()

    const toggleTheme = (): void => {
        if (Array.from(document.body.classList).includes('dark')) {
            document.body.classList.remove('dark')
            document.body.classList.add('light')
            dispatch(setTheme('light'))
            localStorage.setItem('FibrousTheme', 'light')
        } else {
            document.body.classList.remove('light')
            document.body.classList.add('dark')
            dispatch(setTheme('dark'))
            localStorage.setItem('FibrousTheme', 'dark')
        }
    }

    return { toggleTheme, theme }
}
