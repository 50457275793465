import type { ModalController } from 'hooks/useModal'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setDestinationDisclaimerConfirmed, setIsDestinationOpen } from 'store/slicers/settings'
import { Button, Modal } from 'ui'

import styles from './Disclaimer.module.scss'

const Disclaimer = ({ modal }: { modal: ModalController }): JSX.Element => {
    const dispatch = useDispatch()

    const destinationDisclaimerConfirmed = useSelector(
        (state: RootState) => state.settings.destinationDisclaimerConfirmed,
    )

    return (
        <Modal className={styles.wrapper} isOpen={modal.isOpen} close={modal.close} closeOnClickOutside={false}>
            <div className={styles.title}>Receiver Address Disclaimer</div>
            <div className={styles.text}>
                Please do not use this feature to transfer your tokens to an address of a centralized exchange (binance,
                huobi...)
                <br />
                <br />
                It is not a supported feature. Also, please double check the address you fill, any tokens sent to the
                wrong address will be impossible to retrieve.
            </div>

            <div className={styles.limitOrderSettings}>
                <div className={styles.option}>
                    <div className={styles.ammName}>I understand the risks mentioned above</div>
                    <div className={destinationDisclaimerConfirmed ? styles.status : styles.statusOff}>
                        <div
                            className={styles.floor}
                            style={{
                                justifyContent: destinationDisclaimerConfirmed ? 'end' : 'start',
                            }}
                            onClick={(): void => {
                                dispatch(setDestinationDisclaimerConfirmed(!destinationDisclaimerConfirmed))
                                dispatch(setIsDestinationOpen(destinationDisclaimerConfirmed))
                            }}
                        >
                            <div className={styles.ball}></div>
                        </div>
                    </div>
                </div>
            </div>

            <Button
                onClick={(): void => {
                    localStorage.setItem('disclaimer', 'true')
                    modal.close()
                }}
                disabled={!destinationDisclaimerConfirmed}
                className={styles.swap}
                width={'100%'}
                height={'70px'}
                fontWeight="fw500"
                fontSize="fs20"
                color={'swap'}
            >
                Continue
            </Button>
        </Modal>
    )
}

export { Disclaimer }
