// import BATCHSWAP from 'assets/batch-swap-events.svg'
import BITGED from 'assets/bitget.svg'
import CHRISMAS from 'assets/chrismas.svg'
import DARKARGENTMOBILE from 'assets/darkArgentMobile.svg'
import DARKOKX from 'assets/darkOkx.svg'
import DARKARGENTX from 'assets/darkargentx.svg'
import DARKBRAVOOS from 'assets/darkbraavos.svg'
import DARKWEBWALLET from 'assets/darkwebwallet.svg'
import LEADERBOAED from 'assets/leaderboard.svg'
import LIGHTARGENTMOBILE from 'assets/lightArgentMobile.svg'
import LIGHTOKX from 'assets/lightOkx.svg'
import LIGHTARGENTX from 'assets/lightargentx.svg'
import LIGHTBRAVOOS from 'assets/lightbraavos.svg'
import LIGHTWEBWALLET from 'assets/lightwebwallet.svg'
import TXEVENT from 'assets/tx-event-icon.svg'
import VOLUME from 'assets/volume-events.svg'
import type { NetworkName } from 'types/network'
import type { Token } from 'types/token'
import { getMergedTokens } from 'utils/tokensUtils'

type Config = {
    base_api: string
    graph_api: string
    limit_order_api: string
    event_base_api: string
    network: NetworkName
    chain_id: string
    explorer_url: Array<string>
    logo_refresh_url: string
    ekubo_refresh_url: string
    reduced_amount_from_eth: number
    toast_network_error_message: string
    connectorInfos: Array<{
        id: number
        name: string
        mobileName?: string
        darkLogo: string
        lightLogo: string
        mobileDarkLogo?: string
        mobileLightLogo?: string
        installLink: string
    }>
    tokenList: Array<Token>
}

export const CONFIG: Config = {
    base_api: 'https://api.fibrous.finance/',
    // base_api: 'https://pre-product.api.fibrous.finance/',
    // base_api: 'http://localhost:8001/',
    graph_api: 'https://graph.fibrous.finance/',
    limit_order_api: 'https://order.api.fibrous.finance/api/v1/',
    // base_api: 'http://localhost:8001/',
    event_base_api: 'https://stats.fibrous.finance/',
    network: 'SN_MAIN',
    chain_id: '0x534e5f4d41494e',
    explorer_url: ['https://voyager.online/', 'https://starkscan.co/'],
    logo_refresh_url: 'https://app.fibrous.finance',
    ekubo_refresh_url: 'https://app.fibrous.finance/?ekubo',
    reduced_amount_from_eth: 0,
    toast_network_error_message: 'Please switch your network to mainnet!',

    connectorInfos: [
        {
            id: 0,
            name: 'Argent X',
            mobileName: 'Argent',
            darkLogo: DARKARGENTMOBILE,
            lightLogo: LIGHTARGENTMOBILE,
            mobileDarkLogo: DARKARGENTX,
            mobileLightLogo: LIGHTARGENTX,
            installLink: 'https://www.argent.xyz/argent-x/',
        },
        {
            id: 1,
            name: 'Braavos',
            mobileName: 'Braavos',
            darkLogo: DARKBRAVOOS,
            lightLogo: LIGHTBRAVOOS,
            mobileDarkLogo: DARKBRAVOOS,
            mobileLightLogo: LIGHTBRAVOOS,
            installLink: 'https://braavos.app',
        },
        {
            id: 2,
            name: 'OKX Wallet',
            mobileName: 'OKX Wallet',
            darkLogo: DARKOKX,
            lightLogo: LIGHTOKX,
            mobileDarkLogo: DARKOKX,
            mobileLightLogo: LIGHTOKX,
            installLink: 'https://www.okx.com/web3',
        },
        {
            id: 2,
            name: 'Bitget Wallet',
            mobileName: 'Bitget Wallet',
            darkLogo: BITGED,
            lightLogo: BITGED,
            mobileDarkLogo: BITGED,
            mobileLightLogo: BITGED,
            installLink: 'https://web3.bitget.com',
        },
        {
            id: 3,
            name: 'Argent Mobile',
            mobileName: 'Argent Mobile',
            darkLogo: DARKARGENTX,
            lightLogo: LIGHTARGENTX,
            mobileDarkLogo: DARKARGENTX,
            mobileLightLogo: LIGHTARGENTX,
            installLink: 'https://www.argent.xyz/',
        },
        {
            id: 4,
            name: 'Login E-Mail',
            mobileName: 'Login E-Mail',
            darkLogo: DARKWEBWALLET,
            lightLogo: LIGHTWEBWALLET,
            mobileDarkLogo: DARKWEBWALLET,
            mobileLightLogo: LIGHTWEBWALLET,
            installLink: 'https://web.argent.xyz/',
        },
    ],
    get tokenList() {
        return getMergedTokens()
    },
}

export const EVENTS = [
    // {
    //     title: 'STRK Special Event',
    //     description:
    //         'Trade with passion in our STRK Special Event and unlock the chance to win exclusive NFTs - your adventure to glory starts here!',
    //     backgroundColor: '#6d65c7',
    //     icon: LEADERBOAED,
    //     dateStart: '2024-02-21 20:00:00',
    //     dateEnd: '2024-03-18 20:00:00',
    //     eventType: 'volume',
    // },
    {
        title: 'Winter Swappers',
        description: 'Celebrating gladiators renowned for their high swap volumes and exceptional transaction counts.',
        backgroundColor: '#00adb5',
        icon: CHRISMAS,
        dateStart: '2023-12-28 19:30:00',
        dateEnd: '2024-01-12 19:30:00',
        eventType: 'chrismas',
    },
    {
        title: 'Transaction Titan',
        description: 'Celebrating the gladiators who have achieved the highest transaction count.',
        backgroundColor: '#fcd34d',
        icon: TXEVENT,
        dateStart: '2023-11-20 19:30:00',
        dateEnd: '2023-12-05 19:30:00',
        eventType: 'tx_count',
    },
    {
        title: 'Volume Vanguard',
        description: 'Recognizing those with the highest trading volumes.',
        backgroundColor: '#ff8092',
        icon: VOLUME,
        dateStart: '2023-10-29 19:30:00',
        dateEnd: '2023-11-20 19:30:00',
        eventType: 'volume',
    },
    {
        title: 'Soon to be announced',
        description: 'Soon to be announced',
        backgroundColor: '#6d65c7',
        icon: LEADERBOAED,
        dateStart: '2023-09-01 00:00:00',
        dateEnd: '2023-10-31 00:00:00',
        eventType: 'volume',
    },
]
