import { BsDot } from 'react-icons/bs'

import styles from './InfoBar.module.scss'

export const ServiceInfoBar = (): JSX.Element => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.infos}>
                <BsDot />
                <span className={styles.text}>
                    No fees for <span className={styles.rockColor}>StarkRocks</span> holders
                </span>
            </div>
            <div className={styles.infos}>
                <BsDot />
                <span className={styles.text}>0.02% service fee on direct routes</span>
            </div>
            <div className={styles.infos}>
                <BsDot />
                <span className={styles.text}>0.15% service fee otherwise</span>
            </div>
        </div>
    )
}

export const ApproveInfoBar = (): JSX.Element => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.infos}>
                <span className={styles.text}>
                    It allows us to reduce future transaction cost by giving maximum allowance to Fibrous contract at
                    once.
                </span>
            </div>
        </div>
    )
}
