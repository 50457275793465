import { useBlockNumber } from '@starknet-react/core'
import { ReactComponent as DiscordLight } from 'assets/discord-light.svg'
import { ReactComponent as Discord } from 'assets/discord.svg'
import { History } from 'components/FooterV2/History/History'
import { Rates } from 'components/FooterV2/Rates/Rates'
import { useTheme } from 'hooks/useTheme'
import { useEffect, useMemo, useState } from 'react'
import { FaXTwitter } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import type { RootState } from 'store'

import styles from './FooterV2.module.scss'
import { ModalV2 } from './ModalV2/ModalV2'

const FooterV2 = (): JSX.Element => {
    const location = useLocation()
    const { theme } = useTheme()

    const { isLoading, data, isError } = useBlockNumber({
        refetchInterval: 60000,
    })

    const [status, setStatus] = useState<boolean>(false)
    const [rates, setRates] = useState<boolean>(false)
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [prevBlock, setPrevBlock] = useState<number>(0)

    const isBatch = useSelector((state: RootState) => state.batch.isBatch)
    const liquiditySources = useSelector((state: RootState) => state.settings.liquiditySources)

    const toggleModal = (): void => {
        setTimeout(() => setModalOpen(!isModalOpen), 100)
    }

    const liquiditySourcesCount = (source: Array<boolean>): number => {
        const count = source.reduce((count, currentValue) => {
            if (currentValue === true) {
                return count + 1
            } else {
                return count
            }
        }, 0)

        return count
    }

    const shouldShowRates = (): boolean => {
        return (
            !isBatch &&
            location.pathname !== '/arena' &&
            location.pathname !== '/limit-order' &&
            liquiditySourcesCount(liquiditySources) > 1
        )
    }

    const blockNumberStyle = useMemo(() => {
        if (isLoading || data !== prevBlock) {
            setTimeout(() => setPrevBlock(data as number), 2000)
            return styles.loading
        }
        return styles.greenBall
    }, [isLoading, data, prevBlock])

    useEffect(() => {
        if (!status) return
        setRates(false)
    }, [status])

    useEffect(() => {
        if (!rates) return
        setStatus(false)
    }, [rates])

    return (
        <footer className={styles.footer}>
            <div className={styles.wrapper}>
                <div className={styles.options}>
                    <div className={styles.blockNumber}>
                        <div className={blockNumberStyle}></div>
                        <div className={styles.number}>{isError ? 'Error...' : data}</div>
                    </div>

                    <div className={styles.line}></div>

                    <div className={styles.docs}>
                        <div onClick={toggleModal}>Docs & More</div>
                    </div>

                    <div className={styles.line}></div>

                    <a
                        href="https://twitter.com/FibrousFinance"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.social}
                    >
                        <FaXTwitter className={styles.socialIconTwitter} />
                    </a>

                    <a href="https://discord.gg/fibrous" target="_blank" rel="noreferrer" className={styles.social}>
                        {theme === 'light' ? (
                            <DiscordLight className={styles.socialIcon} />
                        ) : (
                            <Discord className={styles.socialIcon} />
                        )}
                    </a>
                </div>

                <div className={styles.infos}>
                    {shouldShowRates() && <Rates rates={rates} setRates={setRates} />}

                    <div style={{ display: 'none' }}>
                        <History />
                    </div>
                </div>
            </div>

            <ModalV2 isOpen={isModalOpen} close={toggleModal} className={styles.modalBody}>
                <a href="https://github.com/Fibrous-Finance" target="_blank" rel="noreferrer">
                    Github
                </a>
                <div className={styles.line}></div>
                <a href="https://medium.com/@fibrous" target="_blank" rel="noreferrer">
                    Medium
                </a>
                <div className={styles.line}></div>
                <a href="mailto:support@fibrous.finance" target="_blank" rel="noreferrer">
                    Contact
                </a>
                <div className={styles.line}></div>
                <a href="https://docs.fibrous.finance" target="_blank" rel="noreferrer">
                    Docs
                </a>
                <div className={styles.line}></div>
                <a href="https://docs.fibrous.finance/faq" target="_blank" rel="noreferrer">
                    FAQ
                </a>
                <div className={styles.line}></div>
                <a
                    href="https://www.youtube.com/playlist?list=PLsxLve21ZBFrqQoCVL-gOASaDEmumoPRV"
                    target="_blank"
                    rel="noreferrer"
                >
                    Tutorial
                </a>
                {/* <div className={styles.line}></div>
                <a href="https://docs.fibrous.finance/privacy" target="_blank" rel="noreferrer">
                    Privacy Policy
                </a>
                <div className={styles.line}></div>
                <a href="https://docs.fibrous.finance/terms" target="_blank" rel="noreferrer">
                    Terms & Conditions
                </a>
                <div className={styles.line}></div> */}
            </ModalV2>
        </footer>
    )
}

export { FooterV2 }
