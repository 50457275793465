import { useAccount } from '@starknet-react/core'
import WALLET from 'assets/blue-wallet.svg'
import ARROW from 'assets/down-arrow2.svg'
import { SelectToken } from 'components/SelectToken/SelectToken'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useGetBalances } from 'hooks/useGetBalancesFromAPI'
import { useModal } from 'hooks/useModal'
import { useEffect, useState } from 'react'
import type { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setIsLimitOrderWaiting, setLimitOrderInputValues } from 'store/slicers/limitOrder'
import { WAITING } from 'types/route'
import { useDebounce } from 'use-debounce'
import { formatValue } from 'utils/formatValue'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './Input.module.scss'

const Input = (): JSX.Element => {
    const dispatch = useDispatch()
    const { account } = useAccount()

    const { getBalances } = useGetBalances()
    const selectInputTokenModal = useModal()

    const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const outputToken = useSelector((state: RootState) => state.limitOrder.outputToken)
    const inputTokens = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const inputValues = useSelector((state: RootState) => state.limitOrder.inputValues)

    const [inputValueState, setInputValueState] = useState<string>('0')
    const [previousInputToken, setPreviousInputToken] = useState<number>(inputTokens)
    const [debouncedValue] = useDebounce(inputValues, 500)

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let result = event.target.value
            .replace(/,/g, '. ')
            .replace(/[^.\d]/g, '')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')

        if (Number(result) >= 1) {
            result = result.replace(/^0+/, '')
        }

        if (result.startsWith('.')) {
            result = '0' + result
        }

        dispatch(setIsLimitOrderWaiting(WAITING.PENDING))

        if (result !== '' && !isNaN(Number(result))) {
            const parsedResult = BigNumber.from(utils.parseUnits(result, CONFIG.tokenList[inputTokens].decimals))
            dispatch(setLimitOrderInputValues(parsedResult))
        }

        setInputValueState(result)
    }

    useEffect(() => {
        setPreviousInputToken(inputTokens)
    }, [inputTokens])

    useEffect(() => {
        if (
            BigNumber.from(
                utils.parseUnits(Number(inputValueState).toString(), CONFIG.tokenList[inputTokens].decimals),
            ).gt(BigNumber.from(inputValues))
        ) {
            setInputValueState(
                setFixedNumber(utils.formatUnits(inputValues, CONFIG.tokenList[inputTokens].decimals), 6),
            )
        }
    }, [debouncedValue, inputTokens, outputToken])

    useEffect(() => {
        if (previousInputToken && inputTokens !== previousInputToken) {
            const sourceDecimals = CONFIG.tokenList[previousInputToken].decimals
            const targetDecimals = CONFIG.tokenList[inputTokens].decimals
            const inputValueInSourceDecimals = utils.parseUnits(inputValueState.toString(), sourceDecimals)
            const adjustedValue = inputValueInSourceDecimals
                .mul(BigNumber.from(10).pow(targetDecimals))
                .div(BigNumber.from(10).pow(sourceDecimals))
            const inputValueFormatted = utils.formatUnits(adjustedValue, targetDecimals)

            try {
                dispatch(setLimitOrderInputValues(adjustedValue))
                setInputValueState(setFixedNumber(inputValueFormatted, 6))
            } catch (error) {
                console.error('Error converting token values:', error)
            }
        }
    }, [inputTokens, previousInputToken])

    return (
        <>
            <SelectToken type="limitOrderInput" modal={selectInputTokenModal} />
            <div className={styles.wrapper}>
                <div className={styles.input}>
                    <div
                        className={styles.inputToken}
                        onClick={(): void => {
                            selectInputTokenModal.open()
                            getBalances(true)
                        }}
                    >
                        <div className={styles.hover}>
                            <img
                                src={CONFIG.tokenList[inputTokens].imageUrl}
                                height={isMobile ? 23 : 24}
                                width={isMobile ? 23 : 24}
                                alt="logo"
                            />
                            <div className={styles.name}>{CONFIG.tokenList[inputTokens].symbol}</div>
                            <img src={ARROW} alt="arrow" className={styles.arrow} />
                        </div>
                    </div>
                    <div className={styles.swapInput}>
                        <input
                            className={styles.swapInput}
                            autoComplete="off"
                            autoCorrect="off"
                            type="text"
                            inputMode="decimal"
                            pattern="^[0-9]*[.,]?[0-9]*$"
                            minLength={1}
                            maxLength={79}
                            spellCheck="false"
                            placeholder={'0.0'}
                            value={inputValueState}
                            onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                handleChange(value)
                            }}
                            onFocus={(): void => {
                                if (Number(inputValueState) === 0) {
                                    setInputValueState('')
                                }
                            }}
                            onBlur={(): void => {
                                if (inputValueState === '') {
                                    setInputValueState('0')
                                }
                            }}
                        />
                    </div>

                    <div className={styles.balance}>
                        <div
                            className={styles.wallet}
                            onClick={(): void => {
                                if (!account) return
                                dispatch(setLimitOrderInputValues(BigNumber.from(tokenAmounts[inputTokens])))

                                setInputValueState(
                                    setFixedNumber(
                                        utils.formatUnits(
                                            tokenAmounts[inputTokens],
                                            CONFIG.tokenList[inputTokens].decimals,
                                        ),
                                        6,
                                    ),
                                )
                            }}
                        >
                            <img src={WALLET} alt="logo"></img>
                            {formatValue(
                                setFixedNumber(
                                    utils.formatUnits(
                                        tokenAmounts[inputTokens],
                                        CONFIG.tokenList[inputTokens].decimals,
                                    ),
                                    4,
                                ),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Input }
