import COPY from 'assets/copyDark.svg'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import { isValidStarknetAddressOrDomain } from 'utils/arenaUtils'
import { parseAddress } from 'utils/parseAddress'

import styles from './FailedV2.module.scss'

const FailedV2 = (): JSX.Element => {
    const pendingPayload = useSelector((state: RootState) => state.pending.payload)
    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)

    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>Transaction Failed!</div>
            <div className={styles.tx}>
                {isValidStarknetAddressOrDomain(pendingPayload) ? (
                    <a
                        target={'_blank'}
                        rel="noreferrer"
                        className={styles.tx}
                        href={explorerUrl.concat(`tx/${pendingPayload}`)}
                    >
                        <div className={styles.urlWrapper}>
                            <img src={COPY} alt="copy"></img>
                            <span className={styles.url}>Tx: {parseAddress(pendingPayload)}</span>
                        </div>
                    </a>
                ) : (
                    pendingPayload
                )}
            </div>
        </div>
    )
}

export { FailedV2 }
