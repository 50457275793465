import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { BigNumber } from 'ethers'
import { PendingState } from 'types/pending'

import { WAITING } from '../../types/route'

export interface LimitOrderState {
    isLimitOrder: boolean
    inputTokens: number
    inputValues: BigNumber
    outputToken: number
    outputValue: BigNumber
    rate: BigNumber
    exchangeRateValue: number
    isRateSwitched: boolean
    isOutputFocused: boolean
    isRateLocked: boolean
    expiryDate: number
    isExpiryNever: boolean
    isLimitOrderWaiting: WAITING
    isOrderClicked: boolean
    isPartialFill: boolean
    isUseSolver: boolean
    isSpendMax: boolean
    costumSpendAmount: BigNumber
    isChartOpen: boolean
    nonce: number
    isSigned: boolean
    isApprove: boolean
    isCanceled: boolean
    isClear: boolean

    status: PendingState
    payload: string
    pendingData: string
}

const initialState: LimitOrderState = {
    isLimitOrder: false,
    inputTokens: 1,
    inputValues: BigNumber.from(0),
    outputToken: 3,
    outputValue: BigNumber.from(0),
    isLimitOrderWaiting: WAITING.TRUE,
    rate: BigNumber.from(0),
    exchangeRateValue: 0,
    isRateSwitched: false,
    isOutputFocused: false,
    isRateLocked: false,
    expiryDate: 0,
    isExpiryNever: false,
    isOrderClicked: false,
    isPartialFill: true,
    isUseSolver: false,
    isSpendMax: false,
    costumSpendAmount: BigNumber.from(0),
    isChartOpen: true,
    nonce: 0,
    isSigned: false,
    isApprove: false,
    isCanceled: false,
    isClear: false,

    status: PendingState.PENDING,
    payload: '',
    pendingData: '',
}

export const limitOrderSlice = createSlice({
    name: 'limitOrder',
    initialState,
    reducers: {
        setIsLimitOrder: (state, action: PayloadAction<boolean>) => {
            state.isLimitOrder = action.payload
        },
        setLimitOrderInputTokens: (state, action: PayloadAction<number>) => {
            state.inputTokens = action.payload
        },
        setLimitOrderInputValues: (state, action: PayloadAction<BigNumber>) => {
            state.inputValues = action.payload
        },
        setLimitOrderOutputToken: (state, action: PayloadAction<number>) => {
            state.outputToken = action.payload
        },
        setLimitOrderOutputValue: (state, action: PayloadAction<BigNumber>) => {
            state.outputValue = action.payload
        },
        setIsLimitOrderWaiting: (state, action: PayloadAction<WAITING>) => {
            state.isLimitOrderWaiting = action.payload
        },
        setRate: (state, action: PayloadAction<BigNumber>) => {
            state.rate = action.payload
        },
        setExchangeRateValue: (state, action: PayloadAction<number>) => {
            state.exchangeRateValue = action.payload
        },
        setIsRateSwitched: (state, action: PayloadAction<boolean>) => {
            state.isRateSwitched = action.payload
        },
        setIsOutputFocused: (state, action: PayloadAction<boolean>) => {
            state.isOutputFocused = action.payload
        },
        setIsRateLocked: (state, action: PayloadAction<boolean>) => {
            state.isRateLocked = action.payload
        },
        setExpiryDate: (state, action: PayloadAction<number>) => {
            state.expiryDate = action.payload
        },
        setIsExpiryNever: (state, action: PayloadAction<boolean>) => {
            state.isExpiryNever = action.payload
        },
        setIsOrderClicked: (state, action: PayloadAction<boolean>) => {
            state.isOrderClicked = action.payload
        },
        setIsPartialFill: (state, action: PayloadAction<boolean>) => {
            state.isPartialFill = action.payload
        },
        setIsUseSolver: (state, action: PayloadAction<boolean>) => {
            state.isUseSolver = action.payload
        },
        setIsSpendMax: (state, action: PayloadAction<boolean>) => {
            state.isSpendMax = action.payload
        },
        setCostumSpendAmount: (state, action: PayloadAction<BigNumber>) => {
            state.costumSpendAmount = action.payload
        },
        setIsChartOpen: (state, action: PayloadAction<boolean>) => {
            state.isChartOpen = action.payload
        },
        setNonce: (state, action: PayloadAction<number>) => {
            state.nonce = action.payload
        },
        setIsSigned: (state, action: PayloadAction<boolean>) => {
            state.isSigned = action.payload
        },
        setIsApprove: (state, action: PayloadAction<boolean>) => {
            state.isApprove = action.payload
        },
        setIsCanceled: (state, action: PayloadAction<boolean>) => {
            state.isCanceled = action.payload
        },
        setIsClear: (state, action: PayloadAction<boolean>) => {
            state.isClear = action.payload
        },

        setPendingStatus: (state, action: PayloadAction<PendingState>) => {
            state.status = action.payload
        },
        setPendingPayload: (state, action: PayloadAction<string>) => {
            state.payload = action.payload
        },
        setPendingsData: (state, action: PayloadAction<string>) => {
            state.pendingData = action.payload
        },
    },
})

export const {
    setIsLimitOrder,
    setLimitOrderInputTokens,
    setLimitOrderInputValues,
    setLimitOrderOutputToken,
    setLimitOrderOutputValue,
    setIsLimitOrderWaiting,
    setRate,
    setExchangeRateValue,
    setIsRateSwitched,
    setIsOutputFocused,
    setIsRateLocked,
    setExpiryDate,
    setIsExpiryNever,
    setIsOrderClicked,
    setIsPartialFill,
    setIsUseSolver,
    setIsSpendMax,
    setCostumSpendAmount,
    setIsChartOpen,
    setNonce,
    setIsSigned,
    setIsApprove,
    setIsCanceled,
    setIsClear,

    setPendingStatus,
    setPendingPayload,
    setPendingsData,
} = limitOrderSlice.actions
export default limitOrderSlice.reducer
