import { CONFIG } from 'config/config'
import { utils } from 'ethers'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import { useDebounce } from 'use-debounce'

import styles from './Alert.module.scss'

const Alert = (): JSX.Element => {
    const [percentage, setPercentage] = useState(0)
    const [debouncedPercentage] = useDebounce(percentage, 100)

    const inputToken = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const outputToken = useSelector((state: RootState) => state.limitOrder.outputToken)
    const rate = useSelector((state: RootState) => state.limitOrder.rate)
    const exchangeRate = useSelector((state: RootState) => state.limitOrder.exchangeRateValue)

    const rateNumeric = parseFloat(utils.formatUnits(rate, CONFIG.tokenList[outputToken].decimals)).toString()

    useEffect(() => {
        const rateNum = parseFloat(rateNumeric)
        const exchangeRateNum = exchangeRate

        if (!isNaN(rateNum) && !isNaN(exchangeRateNum) && exchangeRateNum !== 0) {
            const percentageDecrease = ((exchangeRateNum - rateNum) / exchangeRateNum) * 100

            if (rateNum < exchangeRateNum) {
                setPercentage(percentageDecrease)
            } else {
                setPercentage(0)
            }
        }
    }, [rateNumeric, exchangeRate])

    return (
        <div className={styles.alertContainer} style={{ display: debouncedPercentage >= 5 ? 'flex' : 'none' }}>
            <div className={styles.alert}>
                <span>
                    Limit price is <span className={styles.alertValue}>{debouncedPercentage.toFixed(2)}% </span>lower
                    than the market. You will be selling your{' '}
                    <span className={styles.alertValue}>{CONFIG.tokenList[inputToken].symbol}</span> exceedingly cheap.
                </span>
            </div>
        </div>
    )
}

export { Alert }
