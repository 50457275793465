import { BigNumber } from 'ethers'

export const setAmounts = (values: Array<BigNumber>): string => {
    let result = ''
    for (let i = 0; i < values.length; i++) {
        if (i === 0) {
            result += BigNumber.from(values[i])._hex
        } else {
            result += `,${BigNumber.from(values[i])._hex}`
        }
    }
    return result
}
