import LIGHTARROW from 'assets/rightArrow.svg'
import DARKARROW from 'assets/rightArrowLight.svg'
import { CONFIG } from 'config/config'
import { AMMS } from 'constants/amms'
import { BigNumber } from 'ethers'
import { useGetPath } from 'hooks/useGetPath'
import type { ModalController } from 'hooks/useModal'
import { useTheme } from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import type { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setIsChartOpen, setIsPartialFill } from 'store/slicers/limitOrder'
//setIsUseSolver
import { setExplorerUrl, setLiquiditySources, setSlippage } from 'store/slicers/settings'
import type { AMM } from 'types/amm'
import { Modal } from 'ui'
import { clsnm } from 'utils/clsnm'

import styles from './Settings.module.scss'

const Settings = ({ modal }: { modal: ModalController }): JSX.Element => {
    const dispatch = useDispatch()
    const { theme } = useTheme()
    const { getPath } = useGetPath()

    const slippage = useSelector((state: RootState) => state.settings.slippage)
    const explorerUrl = useSelector((state: RootState) => state.settings.explorerUrl)
    const liquiditySources = useSelector((state: RootState) => state.settings.liquiditySources)
    const inputValue = useSelector((state: RootState) => state.input.value)
    const isPartialFill = useSelector((state: RootState) => state.limitOrder.isPartialFill)
    const isUseSolver = useSelector((state: RootState) => state.limitOrder.isUseSolver)
    const isChartOpen = useSelector((state: RootState) => state.limitOrder.isChartOpen)

    const [isCustom, setIsCustom] = useState<boolean>(false)
    const [liquiditySource, setLiquiditySource] = useState<boolean>(false)
    const [activeButton, setActiveButton] = useState<boolean>(true) // true - global, false - limit order

    const extendedClose = (): void => {
        modal.close()
    }

    useEffect(() => {
        const storedSlippage = localStorage.getItem('slippage')
        if (storedSlippage) {
            dispatch(setSlippage(storedSlippage))
        }
    }, [dispatch])

    useEffect(() => {
        const storedExplorerUrl = localStorage.getItem('explorerUrl')
        if (storedExplorerUrl) {
            dispatch(setExplorerUrl(storedExplorerUrl))
        }
    }, [dispatch])

    useEffect(() => {
        if (!(slippage === '0.1' || slippage === '0.5' || slippage === '1')) {
            setIsCustom(true)
        }
    }, [slippage])

    useEffect(() => {
        if (BigNumber.from(inputValue).gt(0)) {
            getPath()
        }
    }, [liquiditySources])

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let result = event.target.value
            .replace(/,/g, '. ')
            .replace(/[^.\d]/g, '')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')

        if (result.startsWith('.')) {
            result = '0' + result
        }

        if (/^\d*\.\d{5,}$/.test(result)) {
            return
        }

        if (Number(result) >= 1) {
            result = result.replace(/^0+/, '')
        }

        if (Number(result) > 49) {
            event.target.value = '49'
            dispatch(setSlippage('49'))
        } else {
            event.target.value = result
            dispatch(setSlippage(result))
            localStorage.setItem('slippage', result)
        }
    }

    const sortedAMMSIndices = AMMS.map((amm: AMM, index) => ({
        index,
        name: amm.name,
    }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => item.index)

    return (
        <Modal
            isOpen={modal.isOpen}
            close={extendedClose}
            className={styles.wrapper}
            backgroundColor={theme === 'dark' ? '#0B182B' : '#FFF'}
        >
            <div className={styles.title}>Settings</div>
            <div className={styles.settingsSwitchWrapper}>
                <div className={styles.settingsSwitch}>
                    <div
                        className={!activeButton ? styles.button : styles.activeButton}
                        onClick={(): void => {
                            setActiveButton(true)
                        }}
                    >
                        Global
                    </div>
                    <div
                        className={activeButton ? styles.button : styles.activeButton}
                        onClick={(): void => {
                            setActiveButton(false)
                        }}
                    >
                        Limit Order
                    </div>
                </div>
            </div>

            {activeButton ? (
                <>
                    <div className={styles.slippage}>
                        <div className={styles.text}>Slippage Tolerance</div>
                        <div className={styles.amount}>
                            <div
                                className={slippage === '0.1' ? styles.thisOne : styles.text}
                                onClick={(): void => {
                                    dispatch(setSlippage('0.1'))
                                    localStorage.setItem('slippage', '0.1')
                                    setIsCustom(false)
                                }}
                            >
                                0.1%
                            </div>
                            <div
                                className={slippage === '0.5' ? styles.thisOne : styles.text}
                                onClick={(): void => {
                                    dispatch(setSlippage('0.5'))
                                    localStorage.setItem('slippage', '0.5')
                                    setIsCustom(false)
                                }}
                            >
                                0.5%
                            </div>
                            <div
                                className={slippage === '1' ? styles.thisOne : styles.text}
                                onClick={(): void => {
                                    dispatch(setSlippage('1'))
                                    localStorage.setItem('slippage', '1')
                                    setIsCustom(false)
                                }}
                            >
                                1.0%
                            </div>
                            <input
                                className={clsnm(
                                    styles.input,
                                    slippage === '1' || slippage === '0.1' || slippage === '0.5'
                                        ? styles.text
                                        : styles.thisOne,
                                )}
                                inputMode="decimal"
                                placeholder={'custom'}
                                value={!isCustom ? '' : slippage}
                                onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                    handleChange(value)
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.explorer}>
                        <div className={styles.text}>Explorer</div>
                        <div className={styles.select}>
                            <div
                                className={explorerUrl === CONFIG.explorer_url[0] ? styles.selected : styles.option}
                                onClick={(): void => {
                                    dispatch(setExplorerUrl(CONFIG.explorer_url[0]))
                                    localStorage.setItem('explorerUrl', CONFIG.explorer_url[0])
                                }}
                            >
                                Voyager
                            </div>
                            <div
                                className={explorerUrl === CONFIG.explorer_url[1] ? styles.selected : styles.option}
                                onClick={(): void => {
                                    dispatch(setExplorerUrl(CONFIG.explorer_url[1]))
                                    localStorage.setItem('explorerUrl', CONFIG.explorer_url[1])
                                }}
                            >
                                Starkscan
                            </div>
                        </div>
                    </div>

                    <div className={styles.enabledApps}>
                        <div className={styles.title} onClick={(): void => setLiquiditySource(!liquiditySource)}>
                            <div className={styles.text}>Liquidity Sources</div>
                            <div className={styles.result}>
                                {liquiditySources.filter((value) => value).length}
                                <img
                                    src={theme === 'dark' ? DARKARROW : LIGHTARROW}
                                    alt="arrow"
                                    className={liquiditySource ? styles.arrowDown : styles.arrow}
                                />
                            </div>
                        </div>
                        {liquiditySource && (
                            <div className={styles.optionsWrapper}>
                                {sortedAMMSIndices.map((originalIndex) => {
                                    const amm = AMMS[originalIndex]

                                    return (
                                        <div className={styles.option} key={originalIndex}>
                                            <div className={styles.ammName}>{amm.name}</div>
                                            <div
                                                className={
                                                    liquiditySources[originalIndex]
                                                        ? liquiditySources.filter((value) => value).length === 1 &&
                                                          liquiditySources[originalIndex]
                                                            ? styles.disable
                                                            : styles.status
                                                        : styles.statusOff
                                                }
                                            >
                                                <div
                                                    className={styles.floor}
                                                    style={{
                                                        justifyContent: liquiditySources[originalIndex]
                                                            ? 'end'
                                                            : 'start',
                                                    }}
                                                    onClick={(): void => {
                                                        if (
                                                            liquiditySources.filter((value) => value).length === 1 &&
                                                            liquiditySources[originalIndex]
                                                        )
                                                            return
                                                        const setObject = {
                                                            key: originalIndex,
                                                            value: !liquiditySources[originalIndex],
                                                        }
                                                        dispatch(setLiquiditySources(setObject))
                                                    }}
                                                >
                                                    <div className={styles.ball}></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.limitOrderSettings}>
                        <div className={styles.option}>
                            <div className={styles.ammName}>Use Solver</div>
                            <div className={styles.disable}>
                                <div
                                    className={styles.floor}
                                    style={{
                                        justifyContent: isUseSolver ? 'end' : 'start',
                                    }}
                                    onClick={(): void => {
                                        // dispatch(setIsUseSolver(!isUseSolver))
                                    }}
                                >
                                    <div className={styles.ball}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.limitOrderSettings}>
                        <div className={styles.option}>
                            <div className={styles.ammName}>Partial Fill</div>
                            <div className={isPartialFill ? styles.status : styles.statusOff}>
                                <div
                                    className={styles.floor}
                                    style={{
                                        justifyContent: isPartialFill ? 'end' : 'start',
                                    }}
                                    onClick={(): void => {
                                        dispatch(setIsPartialFill(!isPartialFill))
                                    }}
                                >
                                    <div className={styles.ball}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.limitOrderSettings}>
                        <div className={styles.option}>
                            <div className={styles.ammName}>Show Chart</div>
                            <div className={isChartOpen ? styles.status : styles.statusOff}>
                                <div
                                    className={styles.floor}
                                    style={{
                                        justifyContent: isChartOpen ? 'end' : 'start',
                                    }}
                                    onClick={(): void => {
                                        dispatch(setIsChartOpen(!isChartOpen))
                                    }}
                                >
                                    <div className={styles.ball}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Modal>
    )
}

export { Settings }
