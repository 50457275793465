import { useConnect } from '@starknet-react/core'
import { CONFIG } from 'config/config'
import type { ModalController } from 'hooks/useModal'
import { useTheme } from 'hooks/useTheme'
import { Fragment, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Modal } from 'ui'

import styles from './ConnectModal.module.scss'

const ConnectModal = ({ modal }: { modal: ModalController }): JSX.Element => {
    const { theme } = useTheme()
    const { connect, connectors } = useConnect()

    const [isArgentApp, setIsArgentApp] = useState(false)

    const isMobile = useMediaQuery({
        query: '(max-width: 650px)',
    })

    useEffect(() => {
        if (isMobile && connectors[0]?.available()) {
            setIsArgentApp(true)
        }
    }, [isMobile, connectors])

    return (
        <Modal closeOnClickOutside={true} isOpen={modal.isOpen} close={modal.close} className={styles.wrapper}>
            <div className={styles.list}>
                {connectors.map((connector, i: number) => {
                    if (isArgentApp && isMobile && i === 4) {
                        return null
                    }

                    return (
                        <Fragment key={i}>
                            <div
                                className={`${styles.option} ${!connector.available() ? styles.unavailable : ''}`}
                                onClick={(): void => {
                                    if (connector.available()) {
                                        try {
                                            connect({ connector })
                                            modal.close()
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    } else {
                                        window.open(CONFIG.connectorInfos[i].installLink, '_blank')
                                    }
                                }}
                            >
                                <div className={styles.optionInner}>
                                    <img
                                        src={
                                            isArgentApp && isMobile
                                                ? theme === 'dark'
                                                    ? CONFIG.connectorInfos[i]?.mobileDarkLogo
                                                    : CONFIG.connectorInfos[i]?.mobileLightLogo
                                                : theme === 'dark'
                                                  ? CONFIG.connectorInfos[i].darkLogo
                                                  : CONFIG.connectorInfos[i].lightLogo
                                        }
                                        alt="icon"
                                    ></img>

                                    {i < 4 && (
                                        <div className={styles.name}>
                                            {isArgentApp && isMobile
                                                ? CONFIG.connectorInfos[i]?.mobileName
                                                : CONFIG.connectorInfos[i].name}
                                        </div>
                                    )}
                                    {i === 4 && <div className={styles.name}>Argent Mobile</div>}
                                    {i === 5 && (
                                        <div className={styles.webWallet}>
                                            <div className={styles.title}>Login E-Mail</div>
                                            <div className={styles.powered}>Powered by Argent</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {i !== 5 && <div className={styles.line}></div>}
                        </Fragment>
                    )
                })}
            </div>
        </Modal>
    )
}

export { ConnectModal }
