import CHANGE from 'assets/change.svg'
import ARROW from 'assets/down-arrow2.svg'
// import LOCK from 'assets/lock.svg'
import REFRESH from 'assets/refresh.svg'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useGetTokenPrices } from 'hooks/useGetTokenPrices'
import { useGetTokens } from 'hooks/useGetTokens'
import { useEffect, useState } from 'react'
import type { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import {
    setExchangeRateValue,
    setExpiryDate,
    setIsExpiryNever,
    setIsRateSwitched,
    setRate,
} from 'store/slicers/limitOrder'
import type { Token } from 'types/token'
import { clsnm } from 'utils/clsnm'
import { setFixedNumber } from 'utils/setFixedNumber'

import { ModalV2 } from './ModalV2/ModalV2'
import styles from './RateandExpiry.module.scss'

const RateandExpiry = (): JSX.Element => {
    const { getTokenPrices } = useGetTokenPrices()
    const { getTokens } = useGetTokens()
    const dispatch = useDispatch()

    const inputTokenIndex = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const outputTokenIndex = useSelector((state: RootState) => state.limitOrder.outputToken)
    const inputValueBN = useSelector((state: RootState) => state.limitOrder.inputValues)
    const outputValueBN = useSelector((state: RootState) => state.limitOrder.outputValue)
    const rate = useSelector((state: RootState) => state.limitOrder.rate)
    const isOrderClicked = useSelector((state: RootState) => state.limitOrder.isOrderClicked)

    const isOutputFocused = useSelector((state: RootState) => state.limitOrder.isOutputFocused)
    const isRateSwitched = useSelector((state: RootState) => state.limitOrder.isRateSwitched)

    const [inputToken, setInputToken] = useState<Token>(CONFIG.tokenList[inputTokenIndex])
    const [outputToken, setOutputToken] = useState<Token>(CONFIG.tokenList[outputTokenIndex])
    const [isChange, setIsChange] = useState<boolean>(false)
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [expiry, setExpiry] = useState<string>('hours')
    const [expiryValue, setExpiryValue] = useState<string>('10')
    const [exchangeRate, setExchangeRate] = useState<string>('0')
    const [showExchangeRate, setShowExchangeRate] = useState<string>('0')
    const [isGetTokenPrices, setIsGetTokenPrices] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>(
        setFixedNumber(formatUnits(inputValueBN, inputToken.decimals), 8),
    )
    const [outputValue, setOutputValue] = useState<string>(
        setFixedNumber(formatUnits(outputValueBN, outputToken.decimals), 8),
    )

    const toggleModal = (): void => {
        setTimeout(() => setModalOpen(!isModalOpen), 100)
    }

    const convertDateToUnixTimestamp = (
        date: { minutes?: number; hours?: number; days?: number; months?: number; Never?: boolean } = {},
    ): number => {
        const currentTime = Math.floor(new Date().getTime() / 1000)
        if (date.Never) {
            return currentTime + 12 * 30 * 24 * 3600
        } else {
            const { minutes = 0, hours = 0, days = 0, months = 0 } = date
            return currentTime + minutes * 60 + hours * 3600 + days * 24 * 3600 + months * 30 * 24 * 3600
        }
    }

    const handleChanger = (): void => {
        setIsChange(!isChange)
        dispatch(setIsRateSwitched(!isChange))
        const rateNumeric = parseFloat(exchangeRate)

        setShowExchangeRate(
            isChange
                ? Number(exchangeRate) % 1 !== 0
                    ? Number(exchangeRate).toFixed(6)
                    : exchangeRate.toString()
                : !isNaN(rateNumeric) && rateNumeric !== 0
                  ? String((1 / rateNumeric) % 1 !== 0 ? (1 / rateNumeric).toFixed(6) : (1 / rateNumeric).toString())
                  : '0',
        )
    }

    const handleRefresh = (): void => {
        getTokens()
        setIsGetTokenPrices(!isGetTokenPrices)
    }

    const handleRateChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let result = event.target.value
            .replace(/,/g, '.')
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1')

        if (result.startsWith('.')) {
            result = '0' + result
        }

        if (!isNaN(parseFloat(result)) && parseFloat(result) !== 0) {
            const rate = isRateSwitched ? 1 / Number(result) : Number(result)

            let rateStr = rate.toFixed(Number(CONFIG.tokenList[outputTokenIndex].decimals))
            rateStr = parseFloat(rateStr).toString()

            const decimals = Number(CONFIG.tokenList[outputTokenIndex].decimals)
            const parsedRate = BigNumber.from(utils.parseUnits(rateStr, decimals))

            dispatch(setRate(parsedRate))
            setExchangeRate(rateStr)
            setShowExchangeRate(result)
        } else {
            setShowExchangeRate(result)
        }
    }

    const handleExpiryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        const result = event.target.value.replace(/[^0-9]/g, '')

        if (result === '0') {
            setExpiry('Never')
            setExpiryValue('10')
        } else {
            setExpiryValue(result)
        }
    }

    useEffect(() => {
        if (expiry === 'Never') {
            dispatch(setExpiryDate(convertDateToUnixTimestamp({ Never: true })))
            dispatch(setIsExpiryNever(true))
        } else {
            dispatch(setExpiryDate(convertDateToUnixTimestamp({ [expiry]: Number(expiryValue) })))
            dispatch(setIsExpiryNever(false))
        }
    }, [expiry, expiryValue, isOrderClicked])

    useEffect(() => {
        async function fetchPrices(): Promise<void> {
            const prices = await getTokenPrices()

            if (Object.keys(prices).length === 0) {
                setIsGetTokenPrices(!isGetTokenPrices)
            }

            const inputPrice = parseFloat(prices[inputToken.address]) || 0
            const outputPrice = parseFloat(prices[outputToken.address]) || 0
            let rate = 0

            if (inputPrice !== 0 && outputPrice !== 0) {
                rate = inputPrice / outputPrice
                setExchangeRate(String(rate))
                dispatch(setExchangeRateValue(rate))
                setShowExchangeRate(String(isRateSwitched ? (1 / rate).toFixed(6) : rate.toFixed(6)))
            } else if (Number(inputValue) !== 0) {
                ;(rate =
                    Number(inputValue) >= 1
                        ? Number(outputValue) / Number(inputValue)
                        : (1 / Number(inputValue)) * Number(outputValue)),
                    setExchangeRate(String(rate))
                dispatch(setExchangeRateValue(rate))
                setShowExchangeRate(String(isRateSwitched ? (1 / rate).toFixed(6) : rate.toFixed(6)))
            } else {
                setExchangeRate('0')
                setShowExchangeRate('0')
            }
        }

        fetchPrices()
    }, [inputToken, outputToken, isGetTokenPrices])

    useEffect(() => {
        setInputToken(CONFIG.tokenList[inputTokenIndex])
        setOutputToken(CONFIG.tokenList[outputTokenIndex])
        setInputValue(setFixedNumber(formatUnits(inputValueBN, CONFIG.tokenList[inputTokenIndex].decimals), 8))
        setOutputValue(setFixedNumber(formatUnits(outputValueBN, CONFIG.tokenList[outputTokenIndex].decimals), 8))
    }, [inputTokenIndex, outputTokenIndex, inputValueBN, outputValueBN])

    useEffect(() => {
        const rateNumeric = parseFloat(exchangeRate).toFixed(6).toString()

        if (!isNaN(parseFloat(rateNumeric)) && rateNumeric.trim() !== '') {
            const parsedRate = BigNumber.from(
                utils.parseUnits(rateNumeric, CONFIG.tokenList[outputTokenIndex].decimals),
            )
            dispatch(setRate(parsedRate))
        } else {
            dispatch(setRate(BigNumber.from(0)))
        }
    }, [exchangeRate])

    useEffect(() => {
        if (isOutputFocused) {
            const rateNumeric = parseFloat(
                utils.formatUnits(rate, CONFIG.tokenList[outputTokenIndex].decimals),
            ).toString()
            setExchangeRate(rateNumeric)
            setShowExchangeRate(rateNumeric)
        }
    }, [rate])

    useEffect(() => {
        setModalOpen(false)
    }, [expiry])

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.rate}>
                    <div className={styles.sectionUpper}>
                        <div className={styles.title}>{isChange ? outputToken.symbol : inputToken.symbol} at rate</div>
                        <div className={styles.settings}>
                            <button onClick={handleRefresh} className={styles.button}>
                                <img className={styles.refresh} src={REFRESH} alt="refresh" />
                            </button>
                            <button onClick={handleChanger} className={styles.button}>
                                <img className={styles.change} src={CHANGE} alt="change" />
                            </button>
                            {/* <img className={styles.lock} src={LOCK} alt="lock" /> */}
                        </div>
                    </div>
                    <div className={styles.sectionLower}>
                        <div className={styles.value}>
                            <div className={styles.swapInput}>
                                <input
                                    className={styles.swapInput}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    type="text"
                                    inputMode="decimal"
                                    pattern="^[0-9]*[.,]?[0-9]*$"
                                    minLength={1}
                                    maxLength={79}
                                    spellCheck="false"
                                    placeholder={''}
                                    value={showExchangeRate}
                                    onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                        handleRateChange(value)
                                    }}
                                    onFocus={(): void => {
                                        if (Number(exchangeRate) === 0) {
                                            setExchangeRate('')
                                            setShowExchangeRate('')
                                        }
                                    }}
                                    onBlur={(): void => {
                                        if (exchangeRate === '') {
                                            setExchangeRate('0')
                                            setShowExchangeRate('0')
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.currency}>{isChange ? inputToken.symbol : outputToken.symbol}</div>
                    </div>
                </div>
                <div className={styles.expiry}>
                    <div className={styles.sectionUpper}>
                        <div className={styles.title}>Expires in</div>
                    </div>
                    <div className={styles.sectionLower}>
                        <div
                            className={styles.timeInput}
                            style={{
                                display: expiry === 'Never' ? 'none' : '',
                            }}
                        >
                            <input
                                autoComplete="off"
                                autoCorrect="off"
                                type="decimal"
                                inputMode="decimal"
                                pattern="^[0-9]*$"
                                minLength={1}
                                maxLength={10}
                                spellCheck="false"
                                placeholder={''}
                                value={expiryValue}
                                onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                    handleExpiryChange(value)
                                }}
                                onFocus={(): void => {
                                    if (expiryValue === '10') {
                                        setExpiryValue('')
                                    }
                                }}
                                onBlur={(): void => {
                                    if (expiryValue === '') {
                                        setExpiryValue('10')
                                    }
                                }}
                            />
                        </div>
                        <div className={expiry === 'Never' ? styles.never : styles.unitWrapper} onClick={toggleModal}>
                            <div
                                className={styles.unit}
                                style={{
                                    padding: expiry === 'Never' ? '0' : '',
                                }}
                            >
                                {expiry}
                            </div>
                            <div className={styles.arrowWrapper}>
                                <img
                                    src={ARROW}
                                    alt="arrow"
                                    className={clsnm(styles.arrow, isModalOpen && styles.arrowUp)}
                                />
                            </div>
                        </div>
                    </div>
                    <ModalV2 isOpen={isModalOpen} close={toggleModal} className={styles.modalBody}>
                        <div className={styles.timeWrapper}>
                            <button
                                onClick={() => {
                                    setExpiry('Never'), toggleModal()
                                }}
                                className={clsnm(expiry === 'Never' && styles.activeButton)}
                            >
                                Never
                            </button>
                            <button
                                onClick={() => {
                                    setExpiry('minutes'), toggleModal()
                                }}
                                className={clsnm(expiry === 'minutes' && styles.activeButton)}
                            >
                                minutes
                            </button>
                            <button
                                onClick={() => {
                                    setExpiry('hours'), toggleModal()
                                }}
                                className={clsnm(expiry === 'hours' && styles.activeButton)}
                            >
                                hours
                            </button>
                            <button
                                onClick={() => {
                                    setExpiry('days'), toggleModal()
                                }}
                                className={clsnm(expiry === 'days' && styles.activeButton)}
                            >
                                days
                            </button>
                            <button
                                onClick={() => {
                                    setExpiry('months'), toggleModal()
                                }}
                                className={clsnm(expiry === 'months' && styles.activeButton)}
                            >
                                months
                            </button>
                        </div>
                    </ModalV2>
                </div>
            </div>
        </div>
    )
}

export { RateandExpiry }
