import type { BigNumber } from 'ethers'

export function removeStringFromArray(datas: Array<BigNumber>, removeIndex: number): Array<BigNumber> {
    const newArray: Array<BigNumber> = []
    for (let i = 0; i < datas.length; i++) {
        if (removeIndex !== i) {
            newArray.push(datas[i])
        }
    }
    return newArray
}

export function removeNumberFromArray(datas: Array<number>, removeIndex: number): Array<number> {
    const newArray: Array<number> = []
    for (let i = 0; i < datas.length; i++) {
        if (removeIndex !== i) {
            newArray.push(datas[i])
        }
    }
    return newArray
}
