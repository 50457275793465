import type { Token } from 'types/token'

export function setBatchToken(batchInputTokens: Array<number>, outputToken: number, tokenList: Array<Token>): number {
    let token: number = tokenList.length
    for (let i = 0; i < tokenList.length; i++) {
        let state = true
        for (let u = 0; u < batchInputTokens.length; u++) {
            if (batchInputTokens[u] === i) {
                state = false
            }
        }
        if (state && outputToken !== i) {
            token = i
            break
        }
    }
    return token
}

export const isThereBatchToken = (inputKey: number, inputTokens: Array<number>): boolean => {
    let isThere = false
    for (let i = 0; i < inputTokens.length; i++) {
        if (inputTokens[i] === inputKey) {
            isThere = true
        }
    }
    return isThere
}
