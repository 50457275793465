import { useAccount, useNetwork } from '@starknet-react/core'
import { LimitOrderConfirm } from 'components'
import { CONFIG } from 'config/config'
import { BigNumber } from 'ethers'
import { useGetApprove } from 'hooks/useGetApprove'
import type { ModalController } from 'hooks/useModal'
import { useModalWithState } from 'hooks/useModalWithState'
import { useSigning } from 'hooks/useSigning'
import { useEffect, useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setIsCanceled, setIsOrderClicked } from 'store/slicers/limitOrder'
import { setPendingPayload, setPendingStatus } from 'store/slicers/pending'
import { setApprovePermision, setConfirm, setConnectModal } from 'store/slicers/settings'
import { PendingState } from 'types/pending'
import { Button } from 'ui'
import { clsnm } from 'utils/clsnm'
import { isAllFalse } from 'utils/isAllFalse'

import styles from './SwapButton.module.scss'

const SwapButton = ({ pendingsModal }: { pendingsModal: ModalController }): JSX.Element => {
    const [isOrdering, setOrdering] = useState(false)

    const dispatch = useDispatch()
    const { account } = useAccount()
    const { chain } = useNetwork()
    const { signing } = useSigning()
    const { getApprove } = useGetApprove()

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    const confirm = useSelector((state: RootState) => state.settings.confirm)
    const approvePermission = useSelector((state: RootState) => state.settings.approvePermision)
    const confirmModal = useModalWithState(confirm, setConfirm)
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const inputToken = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const inputValue = useSelector((state: RootState) => state.limitOrder.inputValues)
    const liquiditySources = useSelector((state: RootState) => state.settings.liquiditySources)
    const isOrderClicked = useSelector((state: RootState) => state.limitOrder.isOrderClicked)
    const isApprove = useSelector((state: RootState) => state.limitOrder.isApprove)
    const pendingStatus = useSelector((state: RootState) => state.limitOrder.status)

    useEffect(() => {
        getApprove()
    }, [pendingStatus])

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <LimitOrderConfirm modal={confirmModal} pendingsModal={pendingsModal} />
            <div
                className={
                    BigNumber.from(inputValue).eq(0)
                        ? clsnm(styles.swapButton, styles.disabled)
                        : BigNumber.from(inputValue).gt(tokenAmounts[inputToken])
                          ? clsnm(styles.swapButton, styles.error)
                          : styles.swapButton
                }
            >
                <Button
                    className={styles.swap}
                    width={'100%'}
                    height={isMobile ? '70px' : '70px'}
                    color={'swap'}
                    disabled={
                        account &&
                        (isOrdering ||
                            BigNumber.from(inputValue).eq(0) ||
                            BigNumber.from(inputValue).gt(tokenAmounts[inputToken]) ||
                            isAllFalse(liquiditySources))
                    }
                    onClick={async (): Promise<void> => {
                        if (!account) {
                            dispatch(setConnectModal(true))
                        } else {
                            if (chain.testnet) {
                                toast.error(`${CONFIG.toast_network_error_message}`)
                            } else {
                                if (approvePermission === true) {
                                    dispatch(setApprovePermision(false))
                                }
                                if (isApprove) {
                                    confirmModal.open()
                                } else {
                                    setOrdering(true)
                                    await signing()
                                    dispatch(setPendingPayload(''))
                                    dispatch(setPendingStatus(PendingState.PENDING))
                                    dispatch(setIsCanceled(false))
                                    pendingsModal.open()
                                    setOrdering(false)
                                }
                                dispatch(setIsOrderClicked(!isOrderClicked))
                            }
                        }
                    }}
                >
                    <span>
                        {account
                            ? BigNumber.from(inputValue).gt(tokenAmounts[inputToken])
                                ? 'INSUFFICIENT FUNDS'
                                : isApprove
                                  ? 'REVIEW ORDER'
                                  : 'APPROVE ORDER'
                            : 'CONNECT WALLET'}
                    </span>
                </Button>
            </div>
        </>
    )
}

export { SwapButton }
