import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { BigNumber } from 'ethers'

export interface OutputState {
    token: number
    value: BigNumber
}

const initialState: OutputState = {
    token: 10,
    value: BigNumber.from(0),
}

export const outputSlice = createSlice({
    name: 'output',
    initialState,
    reducers: {
        setOutputToken: (state, action: PayloadAction<number>) => {
            state.token = action.payload
        },
        setOutputValue: (state, action: PayloadAction<BigNumber>) => {
            state.value = action.payload
        },
    },
})

export const { setOutputToken, setOutputValue } = outputSlice.actions
export default outputSlice.reducer
