export declare type Token = {
    address: string
    decimals: number
    isBase: boolean
    isNative: boolean
    name: string
    price: number
    symbol: string
}

// export declare type Pool = {}

export enum WAITING {
    FALSE,
    PENDING,
    TRUE,
    ERROR,
}

export declare enum Protocol {
    AMM1,
    AMM2,
    AMM3,
    AMM4,
}
export type Percent = `${string}%`

export declare type Swap = {
    protocol: Protocol
    poolId: string
    poolAddress: string
    fromTokenAddress: string
    toTokenAddress: string
    percent: Percent
    ekuboData: {
        current_tick: string
        tick_sign: string
        fee: string
        tick_spacing: string
        sqrt_price_low: string
        sqrt_price_high: string
        pool_key: string
    }
}

export declare type Route = {
    percent: Percent
    swaps: Array<Array<Swap>>
}
export declare type RouterResponse = {
    success: boolean
    inputToken: Token
    inputAmount: string
    outputToken: Token
    outputAmount: string
    estimatedGasUsed: string
    route: Array<Route>
    time: number
    bestQuotesByProtocols: Array<string>
    initial: boolean
}

export type CairoSwap = [string, string, string, string, string]

export type PathInfo = {
    [key: string]: { protocols: Array<number>; percents: Array<string> }
}

export type InfoStoreType = {
    pathIndex: number
    tokenAddress: string
    protocols?: Array<number>
}

export const ExampleRouteResult: RouterResponse = {
    success: false,
    inputToken: {
        address: '',
        decimals: 18,
        isBase: true,
        isNative: false,
        name: '',
        price: 1,
        symbol: '',
    },
    inputAmount: '0',
    outputToken: {
        address: '',
        decimals: 18,
        isBase: true,
        isNative: true,
        name: '',
        price: 1,
        symbol: '',
    },
    outputAmount: '0',
    time: 0,
    estimatedGasUsed: '0',
    route: [],
    bestQuotesByProtocols: ['0', '0', '0', '0', '0'],
    initial: false,
}
