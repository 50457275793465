import { useAccount } from '@starknet-react/core'
import {
    BatchPendings,
    BatchSwap,
    Disclaimer,
    Ekubo,
    Footer,
    FooterV2,
    Navbar,
    Navigator,
    PendingsV2,
    Route,
    Settings,
    Swap,
    Wallet,
} from 'components'
import { MobileRoute } from 'components/MobileRoute/MobileRoute'
import { ConnectModal } from 'components/Navbar/ConnectModal/ConnectModal'
import { CONFIG } from 'config/config'
import { useModal } from 'hooks/useModal'
import { useModalWithState } from 'hooks/useModalWithState'
import { useReset } from 'hooks/useReset'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useSearchParams } from 'react-router-dom'
import type { RootState } from 'store'
import { setInputToken } from 'store/slicers/input'
import { setOutputToken } from 'store/slicers/output'
import { setConnectModal } from 'store/slicers/settings'
import { clsnm } from 'utils/clsnm'

import styles from './SwapPage.module.scss'

const SwapPage = (): JSX.Element => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const isPc = useMediaQuery({ query: '(min-width: 1050px)' })
    const whichFooter = useMediaQuery({ query: '(max-width: 790px)' })

    const dispatch = useDispatch()
    const [, setSearchParams] = useSearchParams()
    const { resetInputs } = useReset()

    // const { connect, connectors } = useConnect()
    const { account } = useAccount() // address
    const location = useLocation()
    const preloaded = useRef(false)

    const settingsModal = useModal()
    const walletModal = useModal()
    const disclaimerModal = useModal()
    const ekuboModal = useModal()
    const pendingsModal = useModal()

    const isRouteOpen = useSelector((state: RootState) => state.route.isRouteOpen)
    const isProgress = useSelector((state: RootState) => state.route.isProgress)
    const isBatchSwap = useSelector((state: RootState) => state.batch.isBatch)
    const connectModalState = useSelector((state: RootState) => state.settings.connectModal)
    const isDestinationOpen = useSelector((state: RootState) => state.settings.isDestinationOpen)
    const inputTokenId = useSelector((state: RootState) => state.input.token)
    const outputTokenId = useSelector((state: RootState) => state.output.token)

    const connectModal = useModalWithState(connectModalState, setConnectModal)

    useEffect(() => {
        if (isBatchSwap) {
            setSearchParams({})
            return
        }

        const params = new URLSearchParams(location.search)
        const tokenIn = params.get('tokenIn')
        const tokenOut = params.get('tokenOut')

        const updateSearchParams = (tokenInAddress: string | null, tokenOutAddress: string | null): void => {
            setSearchParams({
                tokenIn: tokenInAddress || CONFIG.tokenList[inputTokenId].address,
                tokenOut: tokenOutAddress || CONFIG.tokenList[outputTokenId].address,
            })
        }

        const dispatchTokenUpdate = (address: string, isInput: boolean): void => {
            const tokenId = CONFIG.tokenList.findIndex((token) => token.address === address)

            if (tokenId !== -1) {
                const action = isInput ? setInputToken : setOutputToken
                dispatch(action(tokenId))
            } else {
                handleTokenNotFound(isInput)
            }
        }

        const handleTokenNotFound = (isInput: boolean): void => {
            if (isInput) {
                const defaultInputTokenId = outputTokenId !== 1 ? 1 : 3
                dispatch(setInputToken(defaultInputTokenId))
            } else {
                dispatch(setInputToken(1))
                dispatch(setOutputToken(10))
            }
        }

        if (params.get('ekubo') === '') {
            ekuboModal.open()
        }

        // **// KERMO
        //  * 1. if tokenIn not exist set default tokenIn, if tokenOut exist set token out that is coming from url param
        //  * 2. if tokenIn exist and tokenOut not exist set tokenIn that is coming from url param
        //  * 3. if tokenIn and tokenOut not exist set default tokenIn and tokenOut
        //  * 4. if tokenIn and tokenOut exist set tokenIn and tokenOut that is coming from url param
        //  * 5. if tokenIn and tokenOut are the same, handle as token not found
        // **//

        if (tokenIn && tokenOut && tokenIn === tokenOut) {
            handleTokenNotFound(true)
            handleTokenNotFound(false)
        } else if (!tokenIn && tokenOut) {
            updateSearchParams(null, tokenOut)
            dispatchTokenUpdate(tokenOut, false)
        } else if (tokenIn && !tokenOut) {
            updateSearchParams(tokenIn, null)
            dispatchTokenUpdate(tokenIn, true)
        } else if (!tokenIn && !tokenOut) {
            updateSearchParams(null, null)
        } else if (tokenIn && tokenOut) {
            updateSearchParams(tokenIn, tokenOut)
            dispatchTokenUpdate(tokenIn, true)
            dispatchTokenUpdate(tokenOut, false)
        }
    }, [location.search, isBatchSwap])

    useEffect(() => {
        const disclaimerStatus = localStorage.getItem('disclaimer')
        if (disclaimerStatus !== 'true') {
            disclaimerModal.open()
        }
        setTimeout(function () {
            preloaded.current = true
        }, 2000)
    }, [])

    useEffect(() => {
        pendingsModal.close()
        resetInputs()
    }, [isBatchSwap])

    // useEffect(() => {
    //     if (!account) {
    //         const lastUsedConnectorId = localStorage.getItem('lastUsedConnector')
    //         if (lastUsedConnectorId) {
    //             const connectorToUse = connectors.find((connector) => connector.id === lastUsedConnectorId)
    //             if (connectorToUse) {
    //                 connect({ connector: connectorToUse })
    //             }
    //         }
    //     }
    // }, [account, address, connect, connectors])

    return (
        <div
            className={styles.wrapper}
            style={{
                maxHeight: (isBatchSwap || isRouteOpen || isDestinationOpen) && isMobile ? '100%' : '',
            }}
        >
            {account && isPc && !isBatchSwap && <PendingsV2 modal={pendingsModal} />}
            {account && isPc && isBatchSwap && <BatchPendings modal={pendingsModal} />}

            <Disclaimer modal={disclaimerModal} />
            <Ekubo modal={ekuboModal} />
            <Settings modal={settingsModal} />
            <Wallet modal={walletModal} />

            {!isMobile && <Navigator settingsModal={settingsModal} />}
            <Navbar settingsModal={settingsModal} walletModal={walletModal} />
            <ConnectModal modal={connectModal} />
            {isBatchSwap ? (
                <div className={styles.layout}>
                    <BatchSwap pendingsModal={pendingsModal} />
                </div>
            ) : (
                <div className={preloaded.current ? styles.layout : clsnm(styles.layout, styles.preload)}>
                    <Swap pendingsModal={pendingsModal} />
                    {!isMobile ? (
                        <div
                            className={
                                isRouteOpen
                                    ? clsnm(styles.layoutDiv, styles.opening)
                                    : clsnm(styles.layoutDiv, isProgress ? styles.closing : styles.closingNotAnimated)
                            }
                            style={{
                                display: isRouteOpen ? 'flex' : 'none',
                            }}
                        >
                            <Route />
                        </div>
                    ) : isRouteOpen ? (
                        <div
                            className={
                                isRouteOpen
                                    ? clsnm(styles.layoutDiv, styles.opening)
                                    : clsnm(styles.layoutDiv, isProgress ? styles.closing : styles.closingNotAnimated)
                            }
                            style={{
                                display: isRouteOpen ? 'flex' : 'none',
                            }}
                        >
                            <MobileRoute />
                        </div>
                    ) : null}
                </div>
            )}
            {whichFooter ? <Footer /> : <FooterV2 />}
        </div>
    )
}

export { SwapPage }
