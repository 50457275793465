import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import 'swiper/css'
import 'swiper/css/pagination'
import { Mousewheel, Pagination, Parallax } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { clsnm } from 'utils/clsnm'

import { EVENTS } from '../../../config/config'
import styles from './EventSlider.module.scss'

interface EventSliderProps {
    onSlideChange: (index: number) => void
}

const EventSlider: React.FC<EventSliderProps> = ({ onSlideChange }) => {
    const isMobile = useMediaQuery({
        query: '(max-width: 900px)',
    })

    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [tooltip, setTooltip] = useState<{
        x: number
        y: number
        visible: boolean
    }>({ x: 0, y: 0, visible: false })

    const [days, setDays] = useState<number>(0)
    const [hours, setHours] = useState<number>(0)
    const [minutes, setMinutes] = useState<number>(0)

    const targetDate = new Date(Date.UTC(2023, 12, 12, 19, 30, 0))

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date().getTime()
            const distance = targetDate.getTime() - now

            if (distance <= 0) {
                clearInterval(intervalId)
            } else {
                setDays(Math.floor(distance / (1000 * 60 * 60 * 24)))
                setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
                setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
            }
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])

    return (
        <section className={styles.eventSlider}>
            <Swiper
                style={
                    {
                        '--swiper-pagination-color': '#ffffff',
                    } as React.CSSProperties
                }
                direction={'vertical'}
                slidesPerView={isMobile ? 1 : 3}
                spaceBetween={0}
                mousewheel={true}
                speed={600}
                parallax={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Parallax, Mousewheel, Pagination]}
                className={styles.swiper}
            >
                {EVENTS.map((event, index) => (
                    <SwiperSlide key={index} className={styles.slide}>
                        <div
                            key={index}
                            className={clsnm(
                                styles.eventCard,
                                index === activeIndex && styles.activeEvent,
                                event.title === 'Soon to be announced' && styles.notAnnouncedYet,
                            )}
                            style={{
                                background: event.backgroundColor,
                            }}
                            onClick={(): void => {
                                if (event.title !== 'Soon to be announced') {
                                    setActiveIndex(index)
                                    onSlideChange(index)
                                }
                            }}
                            onMouseEnter={(e): void => {
                                if (event.title === 'Soon to be announced') {
                                    setTooltip({
                                        x: e.clientX,
                                        y: e.clientY,
                                        visible: true,
                                    })
                                }
                            }}
                            onMouseMove={(e): void => {
                                if (event.title === 'Soon to be announced') {
                                    setTooltip({
                                        x: e.clientX,
                                        y: e.clientY,
                                        visible: true,
                                    })
                                }
                            }}
                            onMouseLeave={(): void => {
                                setTooltip({ ...tooltip, visible: false })
                            }}
                            onTouchStart={(e): void => {
                                if (event.title === 'Soon to be announced') {
                                    setTooltip({
                                        x: e.touches[0].clientX,
                                        y: e.touches[0].clientY,
                                        visible: true,
                                    })
                                }
                            }}
                            onTouchMove={(e): void => {
                                if (event.title === 'Soon to be announced') {
                                    setTooltip({
                                        x: e.touches[0].clientX,
                                        y: e.touches[0].clientY,
                                        visible: true,
                                    })
                                }
                            }}
                            onTouchEnd={(): void => {
                                setTooltip({ ...tooltip, visible: false })
                            }}
                        >
                            <div className={styles.icon}>
                                <img src={event.icon} alt={`${event.title} icon`} />
                            </div>
                            <p className={styles.eventTitle}>{event.title}</p>
                            {/* {index < 1 && (
                                <div className={styles.eventDate}>
                                    - {days}D {hours}H {minutes}M -
                                </div>
                            )} */}
                            {(index <= 2) && <div className={styles.eventDate}>- Finished -</div>}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {tooltip.visible && (
                <div className={styles.tooltip} style={{ left: `${tooltip.x}px`, top: `${tooltip.y}px` }}>
                    Soon
                </div>
            )}
        </section>
    )
}

export default EventSlider
