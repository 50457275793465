import CRMIMAGE from 'assets/tokens/CRM.png'
import DAIIMAGE from 'assets/tokens/DAI.png'
import EKUBOIMAGE from 'assets/tokens/EKUBO.svg'
import ETHIMAGE from 'assets/tokens/ETH.png'
import FRAXIMAGE from 'assets/tokens/FRAX.png'
import FXSIMAGE from 'assets/tokens/FXS.png'
import LORDSIMAGE from 'assets/tokens/LORDS.png'
import LUSDIMAGE from 'assets/tokens/LUSD.svg'
import NSTRIMAGE from 'assets/tokens/NSTR.svg'
import RIMAGE from 'assets/tokens/R.png'
import RETHIMAGE from 'assets/tokens/RETH.png'
import STRK from 'assets/tokens/STRK.png'
import SWAYIMAGE from 'assets/tokens/SWAY.svg'
import UNIIMAGE from 'assets/tokens/UNI.png'
// import UNOIMAGE from 'assets/tokens/UNO.svg'
// import NOIMAGE from 'assets/tokens/UNTOKEN.png'
import USDCIMAGE from 'assets/tokens/USDC.png'
import USDTIMAGE from 'assets/tokens/USDT.png'
import WBTCIMAGE from 'assets/tokens/WBTC.svg'
import WSTETHIMAGE from 'assets/tokens/WSTETH.png'
import ZENDIMAGE from 'assets/tokens/ZEND.png'
import sfrxETHIMAGE from 'assets/tokens/sfrxETH.png'
import stEURIMAGE from 'assets/tokens/stEUR.png'
import stUSDIMAGE from 'assets/tokens/stUSD.png'
import { Token } from 'types/token'

export const STRKTOKEN = new Token({
    address: '0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d',
    name: 'Starknet',
    symbol: 'STRK',
    decimals: '18',
    imageUrl: STRK,
    valuable: false,
    verified: true,
    category: '',
})

export const VSTRKTOKEN = new Token({
    address: '0x0782f0ddca11d9950bc3220e35ac82cf868778edb67a5e58b39838544bc4cd0f',
    name: 'Starknet Voting Token',
    symbol: 'vSTRK',
    decimals: '18',
    imageUrl: STRK,
    valuable: false,
    verified: true,
    category: '',
})

export const DAIv0TOKEN = new Token({
    address: '0x00da114221cb83fa859dbdb4c44beeaa0bb37c7537ad5ae66fe5e0efd20e6eb3',
    name: 'Dai Stablecoin v0',
    symbol: 'DAIv0',
    decimals: '18',
    imageUrl: DAIIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const DAITOKEN = new Token({
    address: '0x05574eb6b8789a91466f902c380d978e472db68170ff82a5b650b95a58ddf4ad',
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: '18',
    imageUrl: DAIIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const ETHTOKEN = new Token({
    address: '0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7',
    name: 'Ether',
    symbol: 'ETH',
    decimals: '18',
    imageUrl: ETHIMAGE,
    valuable: true,
    verified: true,
    category: '',
})

export const USDTTOKEN = new Token({
    address: '0x068f5c6a61780768455de69077e07e89787839bf8166decfbf92b645209c0fb8',
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: '6',
    imageUrl: USDTIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const USDCTOKEN = new Token({
    address: '0x053c91253bc9682c04929ca02ed00b3e423f6710d2ee7e0d5ebb06f3ecf368a8',
    name: 'USDCoin',
    symbol: 'USDC',
    decimals: '6',
    imageUrl: USDCIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const WBTCTOKEN = new Token({
    address: '0x03fe2b97c1fd336e750087d68b9b867997fd64a2661ff3ca5a7c771641e8e7ac',
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: '8',
    imageUrl: WBTCIMAGE,
    valuable: true,
    verified: true,
    category: '',
})

export const WSTETHTOKEN = new Token({
    address: '0x042b8f0484674ca266ac5d08e4ac6a3fe65bd3129795def2dca5c34ecc5f96d2',
    name: 'Wrapped stETH',
    symbol: 'wstETH',
    decimals: '18',
    imageUrl: WSTETHIMAGE,
    valuable: true,
    verified: true,
    category: '',
})

export const LORDSTOKEN = new Token({
    address: '0x0124aeb495b947201f5fac96fd1138e326ad86195b98df6dec9009158a533b49',
    name: 'LORDS',
    symbol: 'LORDS',
    decimals: '18',
    imageUrl: LORDSIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const RETHTOKEN = new Token({
    address: '0x0319111a5037cbec2b3e638cc34a3474e2d2608299f3e62866e9cc683208c610',
    name: 'Rocket Pool ETH',
    symbol: 'rETH',
    decimals: '18',
    imageUrl: RETHIMAGE,
    valuable: true,
    verified: true,
    category: '',
})

export const LUSDTOKEN = new Token({
    address: '0x070a76fd48ca0ef910631754d77dd822147fe98a569b826ec85e3c33fde586ac',
    name: 'Liquity USD',
    symbol: 'LUSD',
    decimals: '18',
    imageUrl: LUSDIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const UNITOKEN = new Token({
    address: '0x049210ffc442172463f3177147c1aeaa36c51d152c1b0630f2364c300d4f48ee',
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: '18',
    imageUrl: UNIIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const ZENDTOKEN = new Token({
    address: '0x00585c32b625999e6e5e78645ff8df7a9001cf5cf3eb6b80ccdd16cb64bd3a34',
    name: 'zkLend Token',
    symbol: 'ZEND',
    decimals: '18',
    imageUrl: ZENDIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

// export const UNOTOKEN = new Token({
//     address: '0x0719b5092403233201aa822ce928bd4b551d0cdb071a724edd7dc5e5f57b7f34',
//     name: 'UNO',
//     symbol: 'UNO',
//     decimals: '18',
//     imageUrl: UNOIMAGE,
//     valuable: false,
//     verified: true,
//     category: '',
// })

export const FRAXTOKEN = new Token({
    address: '0x009c6b4fb13dfaa025c1383ed6190af8ed8cbb09d9588a3bb020feb152442406',
    name: 'Frax',
    symbol: 'FRAX',
    decimals: '18',
    imageUrl: FRAXIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const FXSTOKEN = new Token({
    address: '0x0058efd0e73c33a848ffaa88738d128ebf0af98ea78cf3c14dc757bb02d39ffb',
    name: 'Frax Share',
    symbol: 'FXS',
    decimals: '18',
    imageUrl: FXSIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const RTOKEN = new Token({
    address: '0x01fa2fb85f624600112040e1f3a848f53a37ed5a7385810063d5fe6887280333',
    name: 'R Stablecoin',
    symbol: 'R',
    decimals: '18',
    imageUrl: RIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const sfrxETHTOKEN = new Token({
    address: '0x04578fffc279e61b5cb0267a5f8e24b6089d40f93158fbbad2cb23b8622c9233',
    name: 'Staked Frax Ether',
    symbol: 'sfrxETH',
    decimals: '18',
    imageUrl: sfrxETHIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const stEURTOKEN = new Token({
    address: '0x03d66fa7694b3ea555e9e11c8445099153902d6c574140c329a427472c1c6bc2',
    name: 'Staked agEUR',
    symbol: 'stEUR',
    decimals: '18',
    imageUrl: stEURIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const stUSDTOKEN = new Token({
    address: '0x03b992772a23dfd3280501e6446c5e3d1f4e47b79a7160b010efa28b22fe6476',
    name: 'Staked agUSD',
    symbol: 'stUSD',
    decimals: '18',
    imageUrl: stUSDIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const EKUBOTOKEN = new Token({
    address: '0x075afe6402ad5a5c20dd25e10ec3b3986acaa647b77e4ae24b0cbc9a54a27a87',
    name: 'Ekubo Protocol',
    symbol: 'EKUBO',
    decimals: '18',
    imageUrl: EKUBOIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const NSTRTOKEN = new Token({
    address: '0x00c530f2c0aa4c16a0806365b0898499fba372e5df7a7172dc6fe9ba777e8007',
    name: 'Nostra',
    symbol: 'NSTR',
    decimals: '18',
    imageUrl: NSTRIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const SWAYTOKEN = new Token({
    address: '0x004878d1148318a31829523ee9c6a5ee563af6cd87f90a30809e5b0d27db8a9b',
    name: 'Standard Weighted Adalian Yield',
    symbol: 'SWAY',
    decimals: '6',
    imageUrl: SWAYIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const CRMTOKEN = new Token({
    address: '0x051c4b1fe3bf6774b87ad0b15ef5d1472759076e42944fff9b9f641ff13e5bbe',
    name: 'Carmine Token',
    symbol: 'CRM',
    decimals: '18',
    imageUrl: CRMIMAGE,
    valuable: false,
    verified: true,
    category: '',
})

export const tokens = [
    DAITOKEN,
    ETHTOKEN,
    USDTTOKEN,
    USDCTOKEN,
    WBTCTOKEN,
    WSTETHTOKEN,
    LORDSTOKEN,
    UNITOKEN,
    RETHTOKEN,
    LUSDTOKEN,
    STRKTOKEN,
    ZENDTOKEN,
    VSTRKTOKEN,
    DAIv0TOKEN,
    // UNOTOKEN,
    CRMTOKEN,
    FRAXTOKEN,
    FXSTOKEN,
    RTOKEN,
    sfrxETHTOKEN,
    stEURTOKEN,
    stUSDTOKEN,
    EKUBOTOKEN,
    NSTRTOKEN,
    SWAYTOKEN,
]
