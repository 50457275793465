export const isAllFalse = (values: Array<boolean>): boolean => {
    let result = 0

    values.forEach((value) => {
        if (value === true) {
            result++
        }
    })
    return result === 0
}
