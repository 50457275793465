import { useAccount, useContractWrite } from '@starknet-react/core'
import type BN__default from 'bn.js'
import { CONFIG } from 'config/config'
import { STARKNET_CONTRACTS } from 'constants/addresses'
import { BigNumber, utils } from 'ethers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setInputValue } from 'store/slicers/input'
import { setPendingPayload, setPendingStatus, setPendingsData } from 'store/slicers/pending'
import { setConfirm, setHistoryData } from 'store/slicers/settings'
import { PendingState } from 'types/pending'
import { parseInputAmountToUint256ExecuteCall } from 'utils/parseAddress'
import { setHistory } from 'utils/setHistory'

type BigNumberish = string | number | BN__default
export const useUnlock = (): {
    unlock: () => Promise<void>
} => {
    const { address, account } = useAccount()
    const dispatch = useDispatch()

    const inputToken = useSelector((state: RootState) => state.input.token)
    const inputValue = useSelector((state: RootState) => state.input.value)
    const outputToken = useSelector((state: RootState) => state.output.token)
    const outputValue = useSelector((state: RootState) => state.output.value)
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const pendingStatus = useSelector((state: RootState) => state.pending.status)

    useEffect(() => {
        dispatch(
            setPendingsData({
                inputToken: inputToken,
                inputValue: utils.formatUnits(BigNumber.from(inputValue), CONFIG.tokenList[inputToken].decimals),
                outputToken: outputToken,
                outputValue: utils.formatUnits(BigNumber.from(inputValue), CONFIG.tokenList[inputToken].decimals),
            }),
        )
    }, [inputToken, inputValue, outputToken, tokenAmounts])

    const unlockCall = {
        contractAddress: STARKNET_CONTRACTS.UNLOCK,
        entrypoint: 'unlock',
        calldata: [
            // STARKNET_CONTRACTS.ROUTER_ADDRESS,
            ...parseInputAmountToUint256ExecuteCall(address ? BigNumber.from(outputValue).toString() : '0', 0),
        ],
    }

    const calls: Array<any> = [unlockCall]

    // const { writeAsync } = useContractWrite({ calls })

    const unlock = async (): Promise<void> => {
        if (!account || !address) return

        const txDetails: any = // await writeAsync()
            await account
                .execute(calls)
                .then((data: any) => {
                    dispatch(setPendingPayload(data.transaction_hash))
                    dispatch(setConfirm(false))
                    const history: string | null = localStorage.getItem(`${address}-history`)
                    if (history === null) {
                        return
                    } else {
                        const historyData = JSON.parse(history)
                        if (historyData.length > 4) {
                            historyData.shift()
                        }
                        dispatch(
                            setHistoryData(
                                JSON.stringify([
                                    ...historyData,
                                    setHistory(
                                        {
                                            inputToken: inputToken,
                                            inputValue: utils.formatUnits(
                                                inputValue,
                                                CONFIG.tokenList[inputToken].decimals,
                                            ),
                                            outputToken: outputToken,
                                            outputValue: utils.formatUnits(
                                                outputValue,
                                                CONFIG.tokenList[outputToken].decimals,
                                            ),
                                        },
                                        data.transaction_hash,
                                        '',
                                    ),
                                ]),
                            ),
                        )
                    }
                })

                .catch((err: any) => {
                    console.error('Failed to lock and delegate:', err)
                    dispatch(setPendingPayload(`Transaction Failed`))
                    dispatch(setPendingStatus(PendingState.FAILED))
                    dispatch(setConfirm(false))
                })
        if (txDetails) {
            dispatch(setInputValue(BigNumber.from(0)))
        } else {
            if (pendingStatus === PendingState.FAILED) return
        }
    }

    return {
        unlock,
    }
}
