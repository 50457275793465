import CHANCE from 'assets/chance.svg'
import { ReactComponent as COPY } from 'assets/destination-copy.svg'
import ROCK from 'assets/serviceFeeRock.png'
import { ServiceInfoBar } from 'components'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useGetTokenPrices } from 'hooks/useGetTokenPrices'
import { useLockDelegate } from 'hooks/useLockDelegate'
import type { ModalController } from 'hooks/useModal'
import { useSwap } from 'hooks/useSwap'
import { useUnlock } from 'hooks/useUnlock'
import { useEffect, useState } from 'react'
import type { ChangeEvent, MouseEvent } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import ReactLoading from 'react-loading'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setMobilePendingStatus, setPendingPayload, setPendingStatus } from 'store/slicers/pending'
import { setFee, setSlippage } from 'store/slicers/settings'
import { PendingState } from 'types/pending'
import { WAITING } from 'types/route'
import { Button, Modal, Tooltip } from 'ui'
import { clsnm } from 'utils/clsnm'
import { formatValue } from 'utils/formatValue'
import { parseAddress } from 'utils/parseAddress'
import { shortenNumber } from 'utils/parseValues'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './Confirm.module.scss'

const Confirm = ({ modal, pendingsModal }: { modal: ModalController; pendingsModal: ModalController }): JSX.Element => {
    const [isSwapping, setIsSwapping] = useState(false)

    const dispatch = useDispatch()
    const { swap } = useSwap()
    const { lockDelegate } = useLockDelegate()
    const { unlock } = useUnlock()
    const { calculateTokenBalance } = useGetTokenPrices()

    const slippage = useSelector((state: RootState) => state.settings.slippage)
    const inputToken = useSelector((state: RootState) => state.input.token)
    const outputToken = useSelector((state: RootState) => state.output.token)
    const outputValue = useSelector((state: RootState) => state.output.value)
    const inputValue = useSelector((state: RootState) => state.input.value)
    const rocksHolder = useSelector((state: RootState) => state.starknet.rocksHolder)
    const routeResult = useSelector((state: RootState) => state.route.routeResult)
    const protocols = useSelector((state: RootState) => state.route.protocols)
    const routes = useSelector((state: RootState) => state.route.routes)
    const isRouterWait = useSelector((state: RootState) => state.route.waiting)
    const destinationAddress = useSelector((state: RootState) => state.settings.destinationAddress)

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let result = event.target.value
            .replace(/,/g, '. ')
            .replace(/[^.\d]/g, '')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')

        if (result.startsWith('.')) {
            return
        }

        if (/^\d*\.\d{5,}$/.test(result)) {
            return
        }

        if (Number(result) >= 1) {
            result = result.replace(/^0+/, '')
        }

        if (Number(result) > 49) {
            event.target.value = '49'
            dispatch(setSlippage('49'))
        } else {
            dispatch(setSlippage(result))
        }
    }

    const handleInputPrice = (): string => {
        const priceValue = routeResult.inputToken.price.toString()
        if (priceValue === '0') {
            return 'Unavailable'
        }

        const price = calculateTokenBalance(priceValue, CONFIG.tokenList[inputToken], inputValue)
        const finalPrice = price.toFixed(1).slice(-1) === '0' ? price.toFixed(0) : price.toFixed(2)

        return '$' + formatValue(finalPrice)
    }

    const handleOutputPrice = (): string => {
        const priceValue = routeResult.outputToken.price.toString()
        if (priceValue === '0') {
            return 'Unavailable'
        }

        const price = calculateTokenBalance(priceValue, CONFIG.tokenList[outputToken], outputValue)
        const finalPrice = price.toFixed(1).slice(-1) === '0' ? price.toFixed(0) : price.toFixed(2)

        return '$' + formatValue(finalPrice)
    }

    const feePercentage = rocksHolder
        ? 0
        : routes && routes.length === 1 && protocols.length === 1 && protocols[0].length === 1
          ? 0.02
          : 0.15

    useEffect(() => {
        dispatch(setFee(feePercentage))
    }, [feePercentage])

    const formattedOutputValue = Number(
        utils.formatUnits(BigNumber.from(outputValue), CONFIG.tokenList[outputToken].decimals),
    )

    const afterFeeValue = formattedOutputValue * (1 - feePercentage / 100)
    const afterSlippageValue = setFixedNumber((afterFeeValue * (1 - Number(slippage) / 100)).toString())

    return (
        <Modal isOpen={modal.isOpen} close={modal.close} className={styles.wrapper}>
            <div className={clsnm(styles.tokens)}>
                <div className={styles.input}>
                    <div className={styles.hover}>
                        <img
                            src={CONFIG.tokenList[inputToken].imageUrl}
                            height={isMobile ? 16 : 24}
                            width={isMobile ? 16 : 24}
                            alt="logo"
                        />
                        <div className={styles.name}>{CONFIG.tokenList[inputToken].symbol}</div>
                    </div>
                </div>
                <div className={styles.ok}>
                    <img src={CHANCE} alt="change" />
                </div>
                <div className={styles.output}>
                    <div className={styles.hover}>
                        <img
                            src={CONFIG.tokenList[outputToken].imageUrl}
                            height={isMobile ? 16 : 24}
                            width={isMobile ? 16 : 24}
                            alt="logo"
                        />
                        <div className={styles.name}>{CONFIG.tokenList[outputToken].symbol}</div>
                    </div>
                </div>
            </div>
            <div className={styles.values}>
                <div className={styles.value}>
                    <div className={styles.amount}>
                        {shortenNumber(
                            formatValue(
                                setFixedNumber(utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals)),
                            ),
                        )}
                    </div>
                    <div className={styles.price}>{BigNumber.from(inputValue).eq(0) ? '$0' : handleInputPrice()}</div>
                </div>
                <div className={styles.value}>
                    <div className={styles.amount}>
                        {isRouterWait === WAITING.PENDING ? (
                            <ReactLoading type={'spin'} color={'#00ADB5'} height={30} width={30} />
                        ) : isRouterWait === WAITING.TRUE ? (
                            '0'
                        ) : !BigNumber.from(inputValue).eq(0) ? (
                            shortenNumber(
                                formatValue(
                                    Number(
                                        utils.formatUnits(
                                            BigNumber.from(outputValue),
                                            CONFIG.tokenList[outputToken].decimals,
                                        ),
                                    ).toFixed(BigNumber.from(outputValue).gt(0) ? 4 : 0),
                                ),
                            )
                        ) : (
                            '0'
                        )}
                    </div>
                    <div className={styles.price}>
                        {!(isRouterWait === WAITING.FALSE) || BigNumber.from(inputValue).lte(0)
                            ? '$0'
                            : handleOutputPrice()}
                    </div>
                </div>
            </div>

            <div className={rocksHolder ? clsnm(styles.serviceFee, styles.holder) : clsnm(styles.serviceFee)}>
                <div className={styles.title}>
                    {rocksHolder && (
                        <div className={styles.icon}>
                            <img src={ROCK} alt="rock" />
                        </div>
                    )}
                    <div className={styles.text}>
                        <div className={styles.info}>
                            <span>Service Fee</span>
                            <Tooltip placement="bottom" content={<ServiceInfoBar />} className={styles.toolTip}>
                                <div className={styles.infoIcon}>
                                    <AiOutlineInfoCircle />
                                </div>
                            </Tooltip>
                        </div>
                        {!rocksHolder && <span>Amount After Service Fee</span>}
                    </div>
                </div>
                {!rocksHolder ? (
                    <div className={styles.amount}>
                        <div className={clsnm(styles.fee)}>{feePercentage}%</div>
                        {setFixedNumber(afterFeeValue.toString())} {CONFIG.tokenList[outputToken].symbol}
                    </div>
                ) : (
                    <div className={clsnm(styles.fee)}>{feePercentage}%</div>
                )}
            </div>

            <div
                className={styles.minimumReceived}
                style={{
                    marginBottom: destinationAddress ? '0' : '35px',
                }}
            >
                <div className={styles.infoPart}>
                    <div className={styles.infos}>
                        <div className={styles.info}>
                            <p>Minimum received after slippage</p>
                            <Tooltip
                                placement="bottom"
                                content={<div>Includes service fee.</div>}
                                className={styles.infoTooltip}
                            >
                                <div className={styles.infoIcon}>
                                    <AiOutlineInfoCircle size={isMobile ? 11 : 16} />
                                </div>
                            </Tooltip>
                        </div>
                        <div className={styles.amount}>
                            {formatValue(setFixedNumber(afterSlippageValue.toString()))}{' '}
                            {CONFIG.tokenList[outputToken].symbol}
                        </div>
                    </div>
                    <div className={styles.slippage}>
                        <input
                            className={styles.slip}
                            value={`${slippage}`}
                            onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                handleChange(value)
                            }}
                            onClick={(event: MouseEvent<HTMLInputElement>): void => {
                                ;(event.target as HTMLInputElement).select()
                            }}
                        />

                        <div className={styles.percent}>
                            <p>%</p>
                        </div>
                    </div>
                </div>
                {Number(slippage) <= 0.1 && (
                    <div className={styles.warningSlippage}>
                        Setting the slippage tolerance too low can cause your swap transaction to fail.
                    </div>
                )}
            </div>

            {destinationAddress && (
                <div className={styles.destination}>
                    <div className={styles.infoPart}>! Receiver’s address is:</div>
                    <div className={styles.destinationAddress}>
                        {parseAddress(destinationAddress || '')}
                        <COPY
                            onClick={async (): Promise<void> => {
                                await navigator.clipboard.writeText(destinationAddress || '')
                            }}
                            className={styles.copy}
                        />
                    </div>
                </div>
            )}

            <Button
                className={styles.swap}
                width={'100%'}
                color={'swap'}
                disabled={Number(slippage) === 0 || isSwapping}
                onClick={async (): Promise<void> => {
                    if (!isSwapping) {
                        setIsSwapping(true)
                        dispatch(setPendingPayload(''))
                        dispatch(setPendingStatus(PendingState.PENDING))
                        dispatch(setMobilePendingStatus(true))
                        pendingsModal.open()

                        if (inputToken === 10 && outputToken === 12) {
                            await lockDelegate()
                        } else if (inputToken === 12 && outputToken === 10) {
                            await unlock()
                        } else {
                            await swap()
                        }

                        setIsSwapping(false)
                    }
                }}
            >
                <span className={styles.text}>CONFIRM SWAP</span>
            </Button>
        </Modal>
    )
}

export { Confirm }
