import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useEffect } from 'react'
import type { ReactNode } from 'react'
import { clsnm } from 'utils/clsnm'

import styles from './ModalV2.module.scss'

type ModalProps = {
    children: ReactNode
    isOpen: boolean
    close: () => void
    closeOnClickOutside?: boolean
    className?: string
    width?: string
}

const ModalV2 = ({
    children,
    isOpen,
    close,
    closeOnClickOutside = true,
    className,
    width,
}: ModalProps): JSX.Element | null => {
    const outsideRef = useOnClickOutside<HTMLDivElement>(() => {
        if (closeOnClickOutside) {
            setTimeout(() => close(), 100)
        }
    })

    useEffect(() => {
        document.body.style.overflowY = 'auto'
    }, [isOpen])

    return isOpen ? (
        <div
            ref={outsideRef}
            className={clsnm(styles.body, className)}
            style={{
                width: width,
            }}
        >
            {children}
        </div>
    ) : null
}

export { ModalV2 }
