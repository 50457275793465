import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { PendingState } from 'types/pending'

export type PendingDataType = {
    batchInputToken: Array<number>
    batchInputValue: Array<string>
    batchOutputToken: number
    batchOutputValue: string
}
export interface PendingProps {
    status: PendingState
    payload: string
    pendingData: PendingDataType
}

const initialState: PendingProps = {
    status: PendingState.PENDING,
    payload: '',
    pendingData: {
        batchInputToken: [0, 1],
        batchInputValue: ['', ''],
        batchOutputToken: 1,
        batchOutputValue: '',
    },
}

export const batchPendingSlice = createSlice({
    name: 'pending',
    initialState,
    reducers: {
        setBatchPendingStatus: (state, action: PayloadAction<PendingState>) => {
            state.status = action.payload
        },
        setBatchPendingPayload: (state, action: PayloadAction<string>) => {
            state.payload = action.payload
        },
        setBatchPendingsData: (state, action: PayloadAction<PendingDataType>) => {
            state.pendingData = action.payload
        },
    },
})

export const { setBatchPendingStatus, setBatchPendingPayload, setBatchPendingsData } = batchPendingSlice.actions
export default batchPendingSlice.reducer
