import ARROW from 'assets/down-arrow.svg'
// import GASIMAGE from 'assets/gas.svg'
import SLIPPAGEIMAGE from 'assets/slippage-setting.svg'
import { useEffect, useState } from 'react'
import type { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setSlippage } from 'store/slicers/settings'
import { clsnm } from 'utils/clsnm'

import styles from './Info.module.scss'
import { ModalV2 } from './ModalV2/ModalV2'

const Info = (): JSX.Element => {
    const dispatch = useDispatch()

    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>('')
    const [isCustom, setIsCustom] = useState<boolean>(false)

    const slippage = useSelector((state: RootState) => state.settings.slippage)

    const toggleModal = (): void => {
        setTimeout(() => setModalOpen(!isModalOpen), 100)
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let input = event.target.value

        input = input
            .replace(/,/g, '.')
            .replace(/[^.\d]/g, '')
            .replace(/\.{2,}/g, '.')

        if (input === '' || Number(input) === 0 || input === '0.' || /^\d*\.\d{4,}$/.test(input)) {
            return
        }

        if (input.startsWith('.')) {
            input = '0' + input
        }

        if (Number(input) >= 1) {
            input = input.replace(/^0+/, '')
        }

        if (Number(input) > 49) {
            input = '49'
        }

        event.target.value = input
        dispatch(setSlippage(input))
        localStorage.setItem('slippage', input)
    }

    const showingValue = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()

        let input = event.target.value

        input = input
            .replace(/,/g, '.')
            .replace(/[^.\d]/g, '')
            .replace(/\.{2,}/g, '.')
            .replace(/^(0+)$/, '0')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')

        if (input.startsWith('.') || /^\d*\.\d{4,}$/.test(input)) {
            return
        }

        setInputValue(input)
    }

    useEffect(() => {
        if (!isCustom || isModalOpen === false) {
            setInputValue('')
        }
    }, [isCustom, toggleModal])

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {/* <div className={styles.gasEstimate}>
                    <img src={GASIMAGE} alt="gas" />
                    <div className={styles.gasEstimateValue}>$0.56</div>
                </div> */}
                <ModalV2 isOpen={isModalOpen} close={toggleModal} className={styles.modalBody}>
                    <div className={styles.slippageModal}>
                        <div className={styles.amount}>
                            <div
                                className={slippage === '0.1' ? styles.thisOne : styles.text}
                                onClick={(): void => {
                                    dispatch(setSlippage('0.1'))
                                    localStorage.setItem('slippage', '0.1')
                                    setIsCustom(false)
                                }}
                            >
                                0.1%
                            </div>
                            <div
                                className={slippage === '0.5' ? styles.thisOne : styles.text}
                                onClick={(): void => {
                                    dispatch(setSlippage('0.5'))
                                    localStorage.setItem('slippage', '0.5')
                                    setIsCustom(false)
                                }}
                            >
                                0.5%
                            </div>
                            <div
                                className={slippage === '1' ? styles.thisOne : styles.text}
                                onClick={(): void => {
                                    dispatch(setSlippage('1'))
                                    localStorage.setItem('slippage', '1')
                                    setIsCustom(false)
                                }}
                            >
                                1.0%
                            </div>
                            <input
                                className={clsnm(
                                    styles.input,
                                    slippage === '1' || slippage === '0.1' || slippage === '0.5'
                                        ? styles.text
                                        : styles.thisOne,
                                )}
                                inputMode="decimal"
                                placeholder={'custom'}
                                value={!isCustom ? '' : inputValue}
                                onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                    handleChange(value)
                                    showingValue(value)
                                }}
                                onFocus={(): void => {
                                    setIsCustom(true)
                                }}
                            />
                        </div>
                    </div>
                </ModalV2>
                <div className={styles.slippage} onClick={toggleModal}>
                    <img
                        className={styles.arrow}
                        style={{ transform: isModalOpen ? 'rotate(-90deg)' : 'rotate(0deg)' }}
                        src={ARROW}
                        alt="arrow"
                    />
                    <img className={styles.setting} src={SLIPPAGEIMAGE} alt="slippage" />
                    <span className={styles.slippageValue}>{slippage}%</span>
                </div>
            </div>
        </div>
    )
}

export { Info }
