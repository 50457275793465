import { useAccount, useNetwork } from '@starknet-react/core'
import { Confirm } from 'components'
import { CONFIG } from 'config/config'
import { BigNumber } from 'ethers'
import type { ModalController } from 'hooks/useModal'
import { useModalWithState } from 'hooks/useModalWithState'
import { Toaster, toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setApprovePermision, setConfirm, setConnectModal } from 'store/slicers/settings'
import { WAITING } from 'types/route'
import { Button } from 'ui'
import { clsnm } from 'utils/clsnm'
import { isAllFalse } from 'utils/isAllFalse'

import styles from './SwapButton.module.scss'

const SwapButton = ({ pendingsModal }: { pendingsModal: ModalController }): JSX.Element => {
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })
    const dispatch = useDispatch()
    const { account } = useAccount()
    const { chain } = useNetwork()

    const confirm = useSelector((state: RootState) => state.settings.confirm)
    const approvePermission = useSelector((state: RootState) => state.settings.approvePermision)
    const confirmModal = useModalWithState(confirm, setConfirm)
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const inputToken = useSelector((state: RootState) => state.input.token)
    const inputValue = useSelector((state: RootState) => state.input.value)
    const isRouterWait = useSelector((state: RootState) => state.route.waiting)
    const liquiditySources = useSelector((state: RootState) => state.settings.liquiditySources)
    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <Confirm modal={confirmModal} pendingsModal={pendingsModal} />
            <div
                className={
                    BigNumber.from(inputValue).eq(0)
                        ? clsnm(styles.swapButton, styles.disabled)
                        : BigNumber.from(inputValue).gt(tokenAmounts[inputToken])
                          ? clsnm(styles.swapButton, styles.error)
                          : styles.swapButton
                }
            >
                <Button
                    className={styles.swap}
                    width={'100%'}
                    height={isMobile ? '70px' : '70px'}
                    color={'swap'}
                    disabled={
                        account &&
                        (BigNumber.from(inputValue).eq(0) ||
                            !(isRouterWait === WAITING.FALSE) ||
                            BigNumber.from(inputValue).gt(tokenAmounts[inputToken]) ||
                            isAllFalse(liquiditySources))
                    }
                    onClick={(): void => {
                        if (!account) {
                            dispatch(setConnectModal(true))
                        } else {
                            if (chain.testnet) {
                                toast.error(`${CONFIG.toast_network_error_message}`)
                            } else {
                                if (approvePermission === true) {
                                    dispatch(setApprovePermision(false))
                                }
                                confirmModal.open()
                            }
                        }
                    }}
                >
                    <span>
                        {account
                            ? BigNumber.from(inputValue).gt(tokenAmounts[inputToken])
                                ? 'INSUFFICIENT FUNDS'
                                : 'SWAP'
                            : 'CONNECT WALLET'}
                    </span>
                </Button>
            </div>
        </>
    )
}

export { SwapButton }
