export interface IAMMInterface {
    name: string
    label: string
}

export class AMM implements IAMMInterface {
    name: string
    label: string

    constructor({ name, label }: IAMMInterface) {
        this.name = name
        this.label = label
    }
}
