import { Alert } from 'components/LimitOrder/SwapOrder/Alert/Alert'
import { Input } from 'components/LimitOrder/SwapOrder/Input/Input'
import { Output } from 'components/LimitOrder/SwapOrder/Output/Output'
import { RateWrapper } from 'components/LimitOrder/SwapOrder/RateWrapper/RateWrapper'
import { RateandExpiry } from 'components/LimitOrder/SwapOrder/RateandExpiry/RateandExpiry'
import { SwapButton } from 'components/LimitOrder/SwapOrder/SwapButton/SwapButton'
import { SwapSeparator } from 'components/LimitOrder/SwapOrder/SwapSeparator/SwapSeparator'
import type { ModalController } from 'hooks/useModal'

import styled from './SwapOrder.module.scss'

const SwapOrder = ({ pendingsModal }: { pendingsModal: ModalController }): JSX.Element => {
    return (
        <div className={styled.wrapper}>
            <Input />
            <SwapSeparator />
            <Output />
            <RateandExpiry />
            <Alert />
            <SwapButton pendingsModal={pendingsModal} />
            <RateWrapper />
        </div>
    )
}

export default SwapOrder
