/* eslint-disable  @typescript-eslint/no-explicit-any */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { CONFIG } from 'config/config'
import { BigNumber } from 'ethers'
import type { Token } from 'types/token'

export interface ThemeState {
    domain: any
    tokenAmounts: Array<BigNumber>
    rocksHolder: boolean
}

const initialState: ThemeState = {
    domain: null,
    tokenAmounts: new Array(CONFIG.tokenList.length).fill(BigNumber.from(0)),
    rocksHolder: false,
}

export const starknetSlice = createSlice({
    name: 'starknet',
    initialState,
    reducers: {
        setStarknetDomain: (state, action: PayloadAction<any>) => {
            state.domain = action.payload
        },
        setTokenAmounts: (state, action: PayloadAction<Array<BigNumber>>) => {
            state.tokenAmounts = action.payload
        },
        setTokenAmount: (state, action: PayloadAction<{ token: number; amount: BigNumber }>) => {
            state.tokenAmounts[action.payload.token] = action.payload.amount
        },
        setRocksHolder: (state, action: PayloadAction<boolean>) => {
            state.rocksHolder = action.payload
        },
        updateTokenList: (state, action: PayloadAction<{ newTokenList: Array<Token> }>) => {
            state.tokenAmounts = action.payload.newTokenList.map(
                (token, index) => state.tokenAmounts[index] || BigNumber.from(0),
            )
        },
    },
})

export const { setStarknetDomain, setTokenAmount, setTokenAmounts, setRocksHolder, updateTokenList } =
    starknetSlice.actions
export default starknetSlice.reducer
