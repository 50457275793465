import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PathInfo, RouterResponse } from 'types/route'
import { ExampleRouteResult, WAITING } from 'types/route'

export interface RouteState {
    routeResult: RouterResponse
    path: Array<Array<string>>
    pathInfo: Array<Array<PathInfo>>
    routes: Array<string>
    protocols: Array<string>
    routeTime: number
    waiting: WAITING
    isRouteOpen: boolean
    isProgress: boolean
}

const initialState: RouteState = {
    routeResult: ExampleRouteResult,
    path: [],
    pathInfo: [
        [
            {
                '0x00da114221cb83fa859dbdb4c44beeaa0bb37c7537ad5ae66fe5e0efd20e6eb3': {
                    protocols: [3],
                    percents: ['100'],
                },
            },
        ],
    ],
    routes: [],
    protocols: [],
    routeTime: 0,
    waiting: WAITING.TRUE,
    isRouteOpen: false,
    isProgress: false,
}

export const routeSlice = createSlice({
    name: 'route',
    initialState,
    reducers: {
        setRouteResult: (state, action: PayloadAction<RouterResponse>) => {
            state.routeResult = action.payload
        },
        setRouteTime: (state, action: PayloadAction<number>) => {
            state.routeTime = action.payload
        },
        setPath: (state, action: PayloadAction<Array<Array<string>>>) => {
            state.path = action.payload
        },
        setPathInfo: (state, action: PayloadAction<Array<Array<PathInfo>>>) => {
            state.pathInfo = action.payload
        },
        setRoutes: (state, action: PayloadAction<Array<string>>) => {
            state.routes = action.payload
        },
        setProtocols: (state, action: PayloadAction<Array<string>>) => {
            state.protocols = action.payload
        },
        setWaiting: (state, action: PayloadAction<WAITING>) => {
            state.waiting = action.payload
        },
        setIsRouteOpen: (state, action: PayloadAction<boolean>) => {
            state.isRouteOpen = action.payload
        },
        setIsProgress: (state, action: PayloadAction<boolean>) => {
            state.isProgress = action.payload
        },
    },
})

export const {
    setRouteResult,
    setRouteTime,
    setPath,
    setPathInfo,
    setRoutes,
    setProtocols,
    setWaiting,
    setIsRouteOpen,
    setIsProgress,
} = routeSlice.actions
export default routeSlice.reducer
