import { useAccount } from '@starknet-react/core'
// useConnect
import {
    Footer,
    FooterV2,
    LimitOrder,
    LimitOrderDisclaimer,
    LimitOrderPendings,
    Navbar,
    Navigator,
    Settings,
    Wallet,
} from 'components'
import { ConnectModal } from 'components/Navbar/ConnectModal/ConnectModal'
import { CONFIG } from 'config/config'
import { useModal } from 'hooks/useModal'
import { useModalWithState } from 'hooks/useModalWithState'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'
import type { RootState } from 'store'
import { setLimitOrderInputTokens, setLimitOrderOutputToken } from 'store/slicers/limitOrder'
import { setConnectModal } from 'store/slicers/settings'

import styles from './LimitOrderPage.module.scss'

const LimitOrderPage = (): JSX.Element => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const isPc = useMediaQuery({ query: '(min-width: 1050px)' })
    const whichFooter = useMediaQuery({ query: '(max-width: 790px)' })

    const dispatch = useDispatch()
    // const { connect, connectors } = useConnect()
    const { account } = useAccount() // address
    const [, setSearchParams] = useSearchParams()

    const settingsModal = useModal()
    const walletModal = useModal()
    const disclaimerModal = useModal()
    const pendingsModal = useModal()

    const connectModalState = useSelector((state: RootState) => state.settings.connectModal)
    const inputTokenId = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const outputTokenId = useSelector((state: RootState) => state.limitOrder.outputToken)

    const connectModal = useModalWithState(connectModalState, setConnectModal)

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const tokenIn = params.get('tokenIn')
        const tokenOut = params.get('tokenOut')

        const updateSearchParams = (tokenInAddress: string | null, tokenOutAddress: string | null): void => {
            setSearchParams({
                tokenIn: tokenInAddress || CONFIG.tokenList[inputTokenId].address,
                tokenOut: tokenOutAddress || CONFIG.tokenList[outputTokenId].address,
            })
        }

        const dispatchTokenUpdate = (address: string, isInput: boolean): void => {
            const tokenId = CONFIG.tokenList.findIndex((token) => token.address === address)

            if (tokenId !== -1) {
                const action = isInput ? setLimitOrderInputTokens : setLimitOrderOutputToken
                dispatch(action(tokenId))
            } else {
                handleTokenNotFound(isInput)
            }
        }

        const handleTokenNotFound = (isInput: boolean): void => {
            if (isInput) {
                const defaultInputTokenId = outputTokenId !== 1 ? 1 : 3
                dispatch(setLimitOrderInputTokens(defaultInputTokenId))
            } else {
                dispatch(setLimitOrderInputTokens(3))
                dispatch(setLimitOrderOutputToken(1))
            }
        }

        if (!tokenIn && tokenOut) {
            updateSearchParams(null, tokenOut)
            dispatchTokenUpdate(tokenOut, false)
        } else if (tokenIn && !tokenOut) {
            updateSearchParams(tokenIn, null)
            dispatchTokenUpdate(tokenIn, true)
        } else if (!tokenIn && !tokenOut) {
            updateSearchParams(null, null)
        } else if (tokenIn && tokenOut) {
            updateSearchParams(tokenIn, tokenOut)
            dispatchTokenUpdate(tokenIn, true)
            dispatchTokenUpdate(tokenOut, false)
        }
    }, [location.search])

    useEffect(() => {
        const disclaimerStatus = localStorage.getItem('LimitOrderDisclaimer')
        if (disclaimerStatus !== 'true') {
            disclaimerModal.open()
        }
    }, [])

    // useEffect(() => {
    //     if (!account) {
    //         const lastUsedConnectorId = localStorage.getItem('lastUsedConnector')
    //         if (lastUsedConnectorId) {
    //             const connectorToUse = connectors.find((connector) => connector.id === lastUsedConnectorId)
    //             if (connectorToUse) {
    //                 connect({ connector: connectorToUse })
    //             }
    //         }
    //     }
    // }, [account, address, connect, connectors])

    return (
        <div className={styles.wrapper}>
            {account && (
                <div
                    style={{
                        display: isMobile ? 'none' : '',
                    }}
                >
                    <LimitOrderPendings modal={pendingsModal} />
                </div>
            )}

            <LimitOrderDisclaimer modal={disclaimerModal} />
            <Settings modal={settingsModal} />
            <Wallet modal={walletModal} />

            {!isMobile && <Navigator settingsModal={settingsModal} />}
            <Navbar settingsModal={settingsModal} walletModal={walletModal} />
            <ConnectModal modal={connectModal} />

            <div className={styles.content}>
                <LimitOrder pendingsModal={pendingsModal} />
            </div>

            {whichFooter ? <Footer /> : <FooterV2 />}
        </div>
    )
}

export { LimitOrderPage }
