import CHANCE from 'assets/chance.svg'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useGetTokenPrices } from 'hooks/useGetTokenPrices'
import type { ModalController } from 'hooks/useModal'
import { useSigning } from 'hooks/useSigning'
import { useEffect, useState } from 'react'
import type { ChangeEvent } from 'react'
import ReactLoading from 'react-loading'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import type { RootState } from 'store'
import { setCostumSpendAmount, setIsCanceled, setIsSpendMax } from 'store/slicers/limitOrder'
import { setMobilePendingStatus, setPendingPayload, setPendingStatus } from 'store/slicers/pending'
import { PendingState } from 'types/pending'
import { WAITING } from 'types/route'
import { Button, Modal } from 'ui'
import { clsnm } from 'utils/clsnm'
import { formatValue } from 'utils/formatValue'
import { shortenNumber } from 'utils/parseValues'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './LimitOrderConfirm.module.scss'

const LimitOrderConfirm = ({
    modal,
    pendingsModal,
}: {
    modal: ModalController
    pendingsModal: ModalController
}): JSX.Element => {
    const dispatch = useDispatch()
    const { signing } = useSigning()
    const { getTokenPricesAndTotalBalance, calculateTokenBalance } = useGetTokenPrices()

    const inputToken = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const outputToken = useSelector((state: RootState) => state.limitOrder.outputToken)
    const outputValue = useSelector((state: RootState) => state.limitOrder.outputValue)
    const inputValue = useSelector((state: RootState) => state.limitOrder.inputValues)
    const rate = useSelector((state: RootState) => state.limitOrder.rate)
    const expiryUnix = useSelector((state: RootState) => state.limitOrder.expiryDate)
    const isExpiryNever = useSelector((state: RootState) => state.limitOrder.isExpiryNever)
    const isSpendMax = useSelector((state: RootState) => state.limitOrder.isSpendMax)

    const [isSwapping, setIsSwapping] = useState(false)
    const [unitPrice, setUnitPrice] = useState<string>('')
    const [expiryDate, setExpiryDate] = useState<string>('')
    const [tokenPrices, setTokenPrices] = useState<Record<string, string>>({})
    const [showCustomValue, setShowCustomValue] = useState<string>('')

    const isRouterWait = useSelector((state: RootState) => state.route.waiting)

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    const handlePrices = async (): Promise<void> => {
        const { tokenPrices } = await getTokenPricesAndTotalBalance()
        setTokenPrices(tokenPrices)
    }

    const handleInputPrice = (): string => {
        const priceValue = tokenPrices[CONFIG.tokenList[inputToken].address]
        if (priceValue === '0') {
            return 'Unavailable'
        }
        const price = calculateTokenBalance(priceValue, CONFIG.tokenList[inputToken], inputValue)
        const finalPrice = price.toFixed(1).slice(-1) === '0' ? price.toFixed(0) : price.toFixed(2)
        return '$' + formatValue(finalPrice)
    }

    const handleOutputPrice = (): string => {
        const priceValue = tokenPrices[CONFIG.tokenList[outputToken].address]
        if (priceValue === '0') {
            return 'Unavailable'
        }
        const price = calculateTokenBalance(priceValue, CONFIG.tokenList[outputToken], outputValue)
        const finalPrice = price.toFixed(1).slice(-1) === '0' ? price.toFixed(0) : price.toFixed(2)
        return '$' + formatValue(finalPrice)
    }

    const calculateExpiryDate = (unixTimestamp: number): string => {
        const now = new Date()
        const expiryDate = new Date(unixTimestamp * 1000)

        const difference = expiryDate.getTime() - now.getTime()

        if (difference <= 0) {
            return 'Expired'
        }

        if (isExpiryNever) {
            return 'Never'
        }

        const totalSeconds = Math.ceil(difference / 1000)
        const days = Math.floor(totalSeconds / (60 * 60 * 24))
        const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60))
        const minutes = Math.floor((totalSeconds % (60 * 60)) / 60)
        const seconds = totalSeconds % 60

        if (days > 0 || totalSeconds >= 60 * 60 * 24) {
            return `${days + (hours > 0 || minutes > 0 || seconds > 0 ? 1 : 0)} day${days > 0 ? 's' : ''}`
        } else if (hours > 0 || totalSeconds >= 60 * 60) {
            return `${hours + (minutes > 0 || seconds > 0 ? 1 : 0)} hour${hours > 0 ? 's' : ''}`
        } else if (minutes > 0 || totalSeconds >= 60) {
            return `${minutes + (seconds > 0 ? 1 : 0)} min${minutes > 0 ? 's' : ''}`
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''}`
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        let result = event.target.value
            .replace(/,/g, '. ')
            .replace(/[^.\d]/g, '')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')
        if (Number(result) >= 1) {
            result = result.replace(/^0+/, '')
        }

        if (result !== showCustomValue) {
            if (result === '') {
                dispatch(setCostumSpendAmount(BigNumber.from(0)))
            } else {
                dispatch(
                    setCostumSpendAmount(
                        BigNumber.from(utils.parseUnits(result.toString(), CONFIG.tokenList[inputToken].decimals)),
                    ),
                )
            }
        }
        setShowCustomValue(result)
    }

    useEffect(() => {
        handlePrices()

        const rateValueFormatted = utils.formatUnits(rate, CONFIG.tokenList[outputToken].decimals)
        setUnitPrice(rateValueFormatted)

        if (expiryUnix > 0) {
            setExpiryDate(calculateExpiryDate(expiryUnix))
        }

        setShowCustomValue(
            shortenNumber(
                formatValue(setFixedNumber(utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals), 6)),
            ),
        )
    }, [modal.isOpen])

    useEffect(() => {
        setIsSwapping(false)
    }, [modal.isOpen])

    return (
        <Modal isOpen={modal.isOpen} close={modal.close} className={styles.wrapper}>
            <div className={clsnm(styles.tokens)}>
                <div className={styles.input}>
                    <div className={styles.hover}>
                        <img
                            src={CONFIG.tokenList[inputToken].imageUrl}
                            height={isMobile ? 16 : 24}
                            width={isMobile ? 16 : 24}
                            alt="logo"
                        />
                        <div className={styles.name}>{CONFIG.tokenList[inputToken].symbol}</div>
                    </div>
                </div>
                <div className={styles.ok}>
                    <img src={CHANCE} alt="change" />
                </div>
                <div className={styles.output}>
                    <div className={styles.hover}>
                        <img
                            src={CONFIG.tokenList[outputToken].imageUrl}
                            height={isMobile ? 16 : 24}
                            width={isMobile ? 16 : 24}
                            alt="logo"
                        />
                        <div className={styles.name}>{CONFIG.tokenList[outputToken].symbol}</div>
                    </div>
                </div>
            </div>
            <div className={styles.values}>
                <div className={styles.value}>
                    <div className={styles.amount}>
                        {shortenNumber(
                            formatValue(
                                setFixedNumber(
                                    utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals),
                                    BigNumber.from(inputValue).gt(1) ? 4 : 6,
                                ),
                            ),
                        )}
                    </div>
                    <div className={styles.price}>{BigNumber.from(inputValue).eq(0) ? '$0' : handleInputPrice()}</div>
                </div>
                <div className={styles.value}>
                    <div className={styles.amount}>
                        {isRouterWait === WAITING.PENDING ? (
                            <ReactLoading type={'spin'} color={'#00ADB5'} height={30} width={30} />
                        ) : !BigNumber.from(inputValue).eq(0) ? (
                            shortenNumber(
                                formatValue(
                                    utils.formatUnits(
                                        BigNumber.from(outputValue),
                                        CONFIG.tokenList[outputToken].decimals,
                                    ),
                                ),
                                BigNumber.from(outputValue).gt(1) ? 4 : 6,
                            )
                        ) : (
                            '0'
                        )}
                    </div>
                    <div className={styles.price}>{BigNumber.from(inputValue).lte(0) ? '$0' : handleOutputPrice()}</div>
                </div>
            </div>

            <div className={styles.settingsWrapper}>
                <div className={styles.setting}>
                    <div className={styles.spendingCap}>Spending Cap :</div>
                    <button
                        className={clsnm(styles.spendingButton, isSpendMax && styles.active)}
                        onClick={() => dispatch(setIsSpendMax(true))}
                    >
                        CUSTOM
                    </button>
                    <button
                        className={clsnm(styles.spendingButton, !isSpendMax && styles.active)}
                        onClick={() => dispatch(setIsSpendMax(false))}
                    >
                        DEFAULT
                    </button>
                </div>
                <div className={styles.expiry}>
                    <div className={styles.title}>Expires :</div>
                    <div className={styles.date}>{expiryUnix > 0 ? expiryDate.toLocaleString() : 'No Expiry'}</div>
                </div>
            </div>

            <div className={styles.spendingWrapper}>
                <div className={styles.spendingAmount} style={{ width: isSpendMax ? '60%' : '' }}>
                    {isSpendMax ? (
                        <div className={styles.customInput}>
                            <input
                                autoComplete="off"
                                autoCorrect="off"
                                type="decimal"
                                inputMode="decimal"
                                pattern="^[0-9]*$"
                                minLength={1}
                                maxLength={10}
                                spellCheck="false"
                                placeholder={''}
                                value={showCustomValue}
                                onChange={(value: ChangeEvent<HTMLInputElement>): void => {
                                    handleChange(value)
                                }}
                            />
                        </div>
                    ) : (
                        shortenNumber(
                            formatValue(
                                setFixedNumber(utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals), 6),
                            ),
                        )
                    )}
                </div>
                <div className={styles.spendingInfo}>
                    {!isSpendMax ? (
                        <div>
                            <div className={styles.info}>
                                <span className={styles.dot}></span>
                                Approval on each order
                            </div>
                            <div className={styles.info}>
                                <span className={styles.dot}></span>
                                Pay gas on every trade
                            </div>
                        </div>
                    ) : (
                        <div className={styles.info}>
                            <span className={styles.dot}></span>
                            Save on future gas fees
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.priceSummary}>
                <div className={styles.tokensPart}>
                    <div className={styles.tokenInfo}>
                        <div className={styles.token}>1 {CONFIG.tokenList[inputToken].symbol} price</div>
                        <div className={styles.token}>1 {CONFIG.tokenList[outputToken].symbol} price</div>
                    </div>
                </div>

                <div className={styles.infoPart}>
                    <div className={styles.infos}>
                        <div className={styles.amount}>
                            <span className={styles.price}>
                                ~$
                                {(
                                    Number(unitPrice) * Number(tokenPrices[CONFIG.tokenList[outputToken].address])
                                ).toFixed(Number(tokenPrices[CONFIG.tokenList[inputToken].address]) > 1 ? 2 : 6)}
                            </span>
                            <span>
                                {unitPrice} {CONFIG.tokenList[outputToken].symbol}
                            </span>
                        </div>
                        <div className={styles.amount}>
                            <span className={styles.price}>
                                ~$
                                {(
                                    (1 / Number(unitPrice)) *
                                    Number(tokenPrices[CONFIG.tokenList[inputToken].address])
                                ).toFixed(Number(tokenPrices[CONFIG.tokenList[outputToken].address]) > 1 ? 2 : 6)}
                            </span>
                            <span>
                                {(1 / Number(unitPrice)).toFixed(6)} {CONFIG.tokenList[inputToken].symbol}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <Button
                className={styles.swap}
                width={'100%'}
                color={'swap'}
                disabled={isSwapping}
                onClick={async (): Promise<void> => {
                    if (!isSwapping) {
                        setIsSwapping(true)
                        dispatch(setPendingPayload(''))
                        dispatch(setPendingStatus(PendingState.PENDING))
                        dispatch(setMobilePendingStatus(true))
                        pendingsModal.open()
                        dispatch(setIsCanceled(false))
                        await signing()

                        // setIsSwapping(false)
                    }
                }}
            >
                <span className={styles.text}>CONFIRM ORDER</span>
            </Button>
        </Modal>
    )
}

export { LimitOrderConfirm }
