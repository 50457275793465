import { ReactComponent as CLOSE } from 'assets/chart-close.svg'
import { ReactComponent as OPEN } from 'assets/chart-open.svg'
import { ReactComponent as ARROW } from 'assets/down-arrow3.svg'
import axios from 'axios'
import { CONFIG } from 'config/config'
import { useTheme } from 'hooks/useTheme'
import type { CandlestickData, IChartApi } from 'lightweight-charts'
import { CrosshairMode, createChart } from 'lightweight-charts'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setIsChartOpen } from 'store/slicers/limitOrder'
import type { CryptoCompareOHLC } from 'types/charts'
import { useDebounce } from 'use-debounce'
import { clsnm } from 'utils/clsnm'

import { ModalV2 } from '../SwapOrder/RateandExpiry/ModalV2/ModalV2'
import styles from './Charts.module.scss'

interface ChartsProps {}

const Charts: React.FC<ChartsProps> = ({}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [period, setPeriod] = useState<string>('histohour')
    const [isError, setIsError] = useState<boolean>(false)

    const dispatch = useDispatch()
    const { theme } = useTheme()

    const isChartOpen = useSelector((state: RootState) => state.limitOrder.isChartOpen)
    const inputTokenIndex = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const outputTokenIndex = useSelector((state: RootState) => state.limitOrder.outputToken)

    const chartContainerRef = useRef<HTMLDivElement>(null)

    const [debouncedWidth] = useDebounce(windowWidth, 200)

    const toggleModal = (): void => {
        setTimeout(() => setModalOpen(!isModalOpen), 100)
    }

    const fetchOHLCData = async (): Promise<Array<CandlestickData>> => {
        const url = `https://min-api.cryptocompare.com/data/v2/${period}`
        const params = {
            fsym: CONFIG.tokenList[inputTokenIndex].symbol.toUpperCase(),
            tsym: CONFIG.tokenList[outputTokenIndex].symbol.toUpperCase(),
            limit: 180,
            api_key: '8d0ae615afd620238e3a1ba1f3b244ceeaba1f67da176c0c00dad894db20236a',
        }

        try {
            const response = await axios.get(url, { params })
            const data = response.data.Data.Data
            setIsError(false)

            return data.map((item: CryptoCompareOHLC) => ({
                time: item.time,
                open: item.open,
                high: item.high,
                low: item.low,
                close: item.close,
            }))
        } catch (error) {
            console.error('Error fetching OHLC data:', error)
            setIsError(true)
            return []
        }
    }

    useEffect(() => {
        const updateWidth = (): void => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', updateWidth)

        return () => {
            window.removeEventListener('resize', updateWidth)
        }
    }, [])

    useEffect(() => {
        setIsError(false)
        setPeriod('histohour')
    }, [inputTokenIndex, outputTokenIndex])

    useEffect(() => {
        let chart: IChartApi | null = null

        const initChart = async (): Promise<void> => {
            if (!chartContainerRef.current) return

            if (chart) {
                chart.remove()
                chart = null
            }

            chart = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: 300,
                layout: {
                    background: { color: theme === 'dark' ? '#0b182b' : '#f3f5f8' },
                    textColor: theme === 'dark' ? '#6b7d99' : '#292d32',
                },
                grid: {
                    vertLines: {
                        color: theme === 'dark' ? '#121F33' : '#e7ebf2',
                    },
                    horzLines: {
                        color: theme === 'dark' ? '#121F33' : '#e7ebf2',
                    },
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
                rightPriceScale: {
                    borderColor: theme === 'dark' ? '#121F33' : '#e7ebf2',
                },
                timeScale: {
                    borderColor: theme === 'dark' ? '#121F33' : '#e7ebf2',
                    timeVisible: period === 'histohour',
                    secondsVisible: false,
                },
            })

            const candleSeries = chart.addCandlestickSeries()

            try {
                const ohlcData = await fetchOHLCData()
                candleSeries.setData(ohlcData)
            } catch (error) {
                console.error('Failed to fetch or set OHLC data:', error)
            }
        }

        initChart()

        return () => {
            if (chart) {
                chart.remove()
                chart = null
            }
        }
    }, [isChartOpen, debouncedWidth, theme, inputTokenIndex, outputTokenIndex, period, isError])

    return (
        <div className={clsnm(styles.wrapper)}>
            <div className={styles.settingsWrapper}>
                {/* <div className={styles.beta}>BETA</div> */}
                <div
                    className={clsnm(styles.settings, (!isChartOpen || !isError) && styles.hidden)}
                    onClick={toggleModal}
                >
                    <div className={styles.unitWrapper}>
                        <div className={styles.unit}>
                            {period === 'histohour'
                                ? '1H'
                                : period === 'histoday'
                                  ? '1D'
                                  : period === '1W'
                                    ? '1W'
                                    : '1M'}
                        </div>
                        <div className={styles.arrowWrapper}>
                            <ARROW onClick={toggleModal} className={clsnm(isModalOpen && styles.arrowUp)} />
                        </div>
                    </div>
                    <ModalV2 isOpen={isModalOpen} close={toggleModal} className={styles.modalBody}>
                        <div className={styles.timeWrapper}>
                            <button
                                onClick={() => {
                                    setPeriod('histohour'), toggleModal()
                                }}
                                className={clsnm(period === 'histohour' && styles.activeButton)}
                            >
                                1H
                            </button>
                            <button
                                onClick={() => {
                                    setPeriod('histoday'), toggleModal()
                                }}
                                className={clsnm(period === 'histoday' && styles.activeButton)}
                            >
                                1D
                            </button>
                            <button
                                onClick={() => {
                                    setPeriod('1W'), toggleModal()
                                }}
                                className={clsnm(period === '1W' && styles.activeButton)}
                            >
                                1W
                            </button>
                            <button
                                onClick={() => {
                                    setPeriod('1M'), toggleModal()
                                }}
                                className={clsnm(period === '1M' && styles.activeButton)}
                            >
                                1M
                            </button>
                        </div>
                    </ModalV2>
                </div>

                <div className={styles.close}>
                    {isChartOpen ? (
                        <CLOSE
                            onClick={() => {
                                dispatch(setIsChartOpen(!isChartOpen))
                            }}
                        />
                    ) : (
                        <OPEN
                            onClick={() => {
                                dispatch(setIsChartOpen(!isChartOpen))
                            }}
                        />
                    )}
                </div>
            </div>

            <div className={clsnm(styles.chartWrapper, !isChartOpen && styles.hidden)}>
                {!isError ? (
                    <div className={styles.content}>
                        <span className={styles.notAvailable}>Chart not available</span>
                    </div>
                ) : (
                    <div ref={chartContainerRef} className={clsnm(styles.content, styles.chartContainer)}></div>
                )}
            </div>
        </div>
    )
}

export default Charts
