import { useAccount, useStarkName } from '@starknet-react/core'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setStarknetDomain } from 'store/slicers/starknet'

type UseGetStarknameReturn = {
    getStarkname: () => void
}

export const useGetStarkname = (): UseGetStarknameReturn => {
    const { account, address } = useAccount()
    const { data, isLoading, isError } = useStarkName({ address })
    const dispatch = useDispatch()

    const setDomain = async (): Promise<void> => {
        if (account && data && !isLoading && !isError) {
            try {
                const name = data
                dispatch(setStarknetDomain(name || null))
            } catch (e) {
                console.error('error: ', e)
                dispatch(setStarknetDomain(null))
            }
        } else {
            dispatch(setStarknetDomain(null))
        }
    }

    useEffect(() => {
        setDomain()
    }, [data, isLoading, isError])

    const getStarkname = (): void => {
        setDomain()
    }

    return { getStarkname }
}
