import { useAccount } from '@starknet-react/core'
import { ReactComponent as ARROW } from 'assets/arrow-down.svg'
import CLEAR from 'assets/clear.svg'
import CANCEL from 'assets/order-cancel.svg'
import { CONFIG } from 'config/config'
import { useTheme } from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setIsCanceled, setIsClear, setNonce } from 'store/slicers/limitOrder'
import { setPendingPayload, setPendingStatus } from 'store/slicers/pending'
import { PendingState } from 'types/pending'
import type { Token } from 'types/token'
import { clsnm } from 'utils/clsnm'

import { useCancelOrder } from '../../../hooks/limitOrderUtils/hooks/cancelOrder'
import { useCancelOrders } from '../../../hooks/limitOrderUtils/hooks/cancelOrders'
import { getOrders } from '../../../hooks/limitOrderUtils/hooks/getOrders'
import { getUserOrderHistory } from '../../../hooks/limitOrderUtils/hooks/getUserOrderHistory'
import type { ModalController } from '../../../hooks/useModal'
import styles from './Orders.module.scss'

interface Order {
    id: string
    created_at: number
    expiration: number
}

const Orders = ({ pendingsModal }: { pendingsModal: ModalController }): JSX.Element => {
    const [active, setActive] = useState(true)
    const [inActiveShort, setInActiveShort] = useState(false) // true for sorting by 'created_at', false for 'expiration'
    const [shortDescending, setShortDescending] = useState(true)
    const [arrowUp, setArrowUp] = useState(false)
    const [orders, setOrders] = useState<Array<any>>([])
    const [orderHistory, setOrderHistory] = useState<Array<any>>([])
    const [, setIsLoading] = useState(true)

    const dispatch = useDispatch()
    const { address } = useAccount()
    const { theme } = useTheme()
    const { cancelOrders } = useCancelOrders(orders.map((order) => order.nonce))
    const { cancelOrder } = useCancelOrder()

    const isChartOpen = useSelector((state: RootState) => state.limitOrder.isChartOpen)
    const nonce = useSelector((state: RootState) => state.limitOrder.nonce)
    const isSigned = useSelector((state: RootState) => state.limitOrder.isSigned)
    const isCanceled = useSelector((state: RootState) => state.limitOrder.isCanceled)
    const pendingStatus = useSelector((state: RootState) => state.limitOrder.status)

    const findTokenByAddress = (address: string): Token | undefined => {
        return CONFIG.tokenList.find((token) => token.address.toLowerCase() === address.toLowerCase())
    }

    const formatTokenAmount = (rawAmount: string, decimals: number): string => {
        const amountBN = BigInt(rawAmount)
        const divisor = BigInt(10 ** decimals)
        const integerPart = amountBN / divisor
        const decimalPart = amountBN % divisor

        const decimalString = decimalPart.toString().padStart(decimals, '0')

        if (decimalPart > 0) {
            const formattedDecimal = parseFloat(`0.${decimalString}`).toFixed(6)
            const trimmedDecimal = parseFloat(formattedDecimal).toString()
            if (trimmedDecimal === '0') {
                return integerPart.toString()
            }
            return `${integerPart}.${trimmedDecimal.split('.')[1]}`
        } else {
            return integerPart.toString()
        }
    }

    const convertUnixTimestampToDateAndTime = (timestamp: number | string): { date: string; time: string } => {
        // Determine the input type and convert to Date accordingly
        const date = typeof timestamp === 'number' ? new Date(timestamp * 1000) : new Date(timestamp)
        const formattedDate = date
            .toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
            .replace(/ /g, ' ')
        const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false })
        return { date: formattedDate, time: formattedTime }
    }

    const calculateFillPercentage = (makerAmount: string, remainingMakerAmount: string): number => {
        const makerAmountBN = Number(makerAmount)
        const remainingMakerAmountBN = Number(remainingMakerAmount)

        const filledAmountBN = makerAmountBN - remainingMakerAmountBN

        const fillPercentage = (filledAmountBN / makerAmountBN) * 100

        return parseFloat(fillPercentage.toFixed(2))
    }

    useEffect(() => {
        const fetchAndSortOrders = async (): Promise<void> => {
            setIsLoading(true)
            try {
                let fetchedOrders = []
                if (active && address) {
                    const response = await getOrders(address)
                    if (response.status === 'success' && response.code === 200) {
                        fetchedOrders = response.data
                    } else {
                        console.error('Error fetching orders:', response)
                    }
                } else if (!active && address) {
                    const historyResponse = await getUserOrderHistory(address)
                    if (historyResponse && historyResponse.status === 'success') {
                        fetchedOrders = historyResponse.data
                    } else {
                        console.error('Error fetching order history:', historyResponse)
                    }
                }
                const sortedOrders = fetchedOrders.sort((a: Order, b: Order) => {
                    const key = inActiveShort ? 'created_at' : 'expiration'

                    let dateA: number, dateB: number

                    if (key === 'created_at') {
                        dateA = new Date(a[key]).getTime()
                        dateB = new Date(b[key]).getTime()
                    } else {
                        dateA = new Date(a[key] * 1000).getTime()
                        dateB = new Date(b[key] * 1000).getTime()
                    }

                    return shortDescending ? dateB - dateA : dateA - dateB
                })

                if (active) {
                    setOrders(sortedOrders)
                } else {
                    setOrderHistory(sortedOrders)
                }
            } catch (error) {
                console.error('Failed to fetch and sort data:', error)
            } finally {
                setIsLoading(false)
            }
        }

        fetchAndSortOrders()

        const intervalId = setInterval(fetchAndSortOrders, 10000)

        return () => clearInterval(intervalId)
    }, [address, active, inActiveShort, shortDescending, isSigned, nonce, isCanceled, pendingStatus])

    return (
        <div className={styles.wrapper}>
            <div className={styles.header} style={{ marginTop: isChartOpen ? '20px' : '0' }}>
                <div className={styles.title}>Limit Orders</div>
                <div className={styles.filter}>
                    <div className={clsnm(styles.filterItem, active && styles.active)} onClick={() => setActive(true)}>
                        Active Orders
                    </div>
                    <div className={styles.filterDivider}></div>
                    <div
                        className={clsnm(styles.filterItem, !active && styles.active)}
                        onClick={() => setActive(false)}
                    >
                        Order History
                    </div>
                </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.body}>
                <div className={styles.tableContainer}>
                    <table className={styles.userTable}>
                        <thead>
                            <tr className={styles.tableHeader}>
                                <th className={styles.theadToken}>You Pay</th>
                                <th className={styles.theadToken}>Your Receive</th>
                                <th className={styles.thead}>
                                    <span className={styles.orderRateTitle}>Order Rates</span>
                                </th>
                                <th className={styles.thead}>
                                    <span
                                        className={!inActiveShort ? styles.inactive : ''}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setInActiveShort(true)
                                            if (inActiveShort) {
                                                setArrowUp(!arrowUp)
                                                setShortDescending(!shortDescending)
                                            }
                                        }}
                                    >
                                        Created
                                    </span>
                                    /
                                    <span
                                        className={inActiveShort ? styles.inactive : ''}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setInActiveShort(false)
                                            if (!inActiveShort) {
                                                setArrowUp(!arrowUp)
                                                setShortDescending(!shortDescending)
                                            }
                                        }}
                                    >
                                        Expiration
                                    </span>
                                    <ARROW
                                        className={clsnm(styles.arrow, arrowUp && styles.arrowUp)}
                                        onClick={() => {
                                            setArrowUp(!arrowUp), setShortDescending(!shortDescending)
                                        }}
                                    />
                                </th>
                                <th className={styles.theadStatus}>Status</th>
                                <th className={styles.theadEnd}>
                                    {active && (
                                        <span
                                            className={styles.clear}
                                            onClick={async () => {
                                                await cancelOrders()
                                                dispatch(setPendingPayload(''))
                                                dispatch(setIsCanceled(true))
                                                dispatch(setIsClear(true))
                                                dispatch(setPendingStatus(PendingState.PENDING))
                                                pendingsModal.open()
                                            }}
                                        >
                                            <img
                                                src={CLEAR}
                                                alt="clear"
                                                style={{ filter: theme === 'dark' ? 'invert(0)' : 'invert(1)' }}
                                            />
                                            Clear
                                        </span>
                                    )}
                                </th>
                            </tr>
                        </thead>
                        <tbody className={styles.tableBody}>
                            {active
                                ? orders.map((order) => {
                                      const makerToken = findTokenByAddress(order.maker_asset)
                                      const takerToken = findTokenByAddress(order.taker_asset)

                                      if (!makerToken || !takerToken) return null

                                      const makerDecimals = parseInt(makerToken.decimals, 10)
                                      const takerDecimals = parseInt(takerToken.decimals, 10)

                                      const fillPercentage = calculateFillPercentage(
                                          order.maker_amount,
                                          order.remaining_maker_amount,
                                      )

                                      return (
                                          <tr
                                              className={styles.tableRow}
                                              key={order.id}
                                              onMouseEnter={() => dispatch(setNonce(order.nonce))}
                                          >
                                              <td className={styles.cellToken}>
                                                  <div className={styles.token}>
                                                      <div className={styles.tokenLogoContainer}>
                                                          <img
                                                              src={makerToken.imageUrl}
                                                              className={styles.tokenLogo}
                                                              alt="logo"
                                                          ></img>
                                                      </div>
                                                      <div className={styles.tokenInfo}>
                                                          <span className={styles.tokenName}> {makerToken.symbol}</span>
                                                          <span className={styles.tokenAmount}>
                                                              {formatTokenAmount(order.maker_amount, makerDecimals)}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td className={styles.cellToken}>
                                                  <div className={styles.token}>
                                                      <div className={styles.tokenLogoContainer}>
                                                          <img
                                                              src={takerToken.imageUrl}
                                                              className={styles.tokenLogo}
                                                              alt="logo"
                                                          ></img>
                                                      </div>
                                                      <div className={styles.tokenInfo}>
                                                          <span className={styles.tokenName}> {takerToken.symbol}</span>
                                                          <span className={styles.tokenAmount}>
                                                              {formatTokenAmount(order.taker_amount, takerDecimals)}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td className={styles.cell}>
                                                  <div className={styles.orderRate}>
                                                      <span className={styles.rateWrapper}>
                                                          1 {makerToken.symbol} ={' '}
                                                          <span className={styles.rate}>
                                                              {parseFloat(
                                                                  formatTokenAmount(order.order_price, takerDecimals),
                                                              )}
                                                          </span>{' '}
                                                          {takerToken.symbol}
                                                      </span>
                                                      <span className={styles.rateWrapper}>
                                                          1 {takerToken.symbol} ={' '}
                                                          <span className={styles.rate}>
                                                              {(
                                                                  1 /
                                                                  parseFloat(
                                                                      formatTokenAmount(
                                                                          order.order_price,
                                                                          takerDecimals,
                                                                      ),
                                                                  )
                                                              ).toFixed(6)}
                                                          </span>{' '}
                                                          {makerToken.symbol}
                                                      </span>
                                                  </div>
                                              </td>
                                              <td className={styles.cell}>
                                                  <div className={styles.dateWrapper}>
                                                      <div className={styles.date}>
                                                          {convertUnixTimestampToDateAndTime(order.created_at).date}{' '}
                                                          <span className={styles.time}>
                                                              {convertUnixTimestampToDateAndTime(order.created_at).time}
                                                          </span>
                                                      </div>
                                                      <div className={styles.date}>
                                                          {convertUnixTimestampToDateAndTime(order.expiration).date}{' '}
                                                          <span className={styles.time}>
                                                              {convertUnixTimestampToDateAndTime(order.expiration).time}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td className={styles.cellStatus}>
                                                  <div className={styles.status}>
                                                      <div className={styles.filled}>Filled: {fillPercentage}%</div>
                                                      <div className={styles.progressContainer}>
                                                          <div className={styles.filledBar}>
                                                              <div
                                                                  className={styles.fill}
                                                                  style={{
                                                                      width: `${fillPercentage}px`,
                                                                  }}
                                                              ></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td className={styles.cellEnd}>
                                                  <span className={styles.cancel}>
                                                      <img
                                                          src={CANCEL}
                                                          alt="cancel"
                                                          onClick={async () => {
                                                              dispatch(setNonce(order.nonce))

                                                              setTimeout(async () => {
                                                                  await cancelOrder()
                                                                  dispatch(setPendingPayload(''))
                                                                  dispatch(setIsCanceled(true))
                                                                  dispatch(setIsClear(false))
                                                                  dispatch(setPendingStatus(PendingState.PENDING))
                                                                  pendingsModal.open()
                                                              }, 200)
                                                          }}
                                                          style={{ cursor: 'pointer' }}
                                                      />
                                                  </span>
                                              </td>
                                          </tr>
                                      )
                                  })
                                : orderHistory.map((order) => {
                                      const makerToken = findTokenByAddress(order.maker_asset)
                                      const takerToken = findTokenByAddress(order.taker_asset)

                                      if (!makerToken || !takerToken) return null

                                      const makerDecimals = parseInt(makerToken.decimals, 10)
                                      const takerDecimals = parseInt(takerToken.decimals, 10)

                                      const fillPercentage = calculateFillPercentage(
                                          order.maker_amount,
                                          order.remaining_maker_amount,
                                      )

                                      return (
                                          <tr className={styles.tableRow} key={order.id}>
                                              <td className={styles.cellToken}>
                                                  <div className={styles.token}>
                                                      <div className={styles.tokenLogoContainer}>
                                                          <img
                                                              src={makerToken.imageUrl}
                                                              className={styles.tokenLogo}
                                                              alt="logo"
                                                          ></img>
                                                      </div>
                                                      <div className={styles.tokenInfo}>
                                                          <span className={styles.tokenName}> {makerToken.symbol}</span>
                                                          <span className={styles.tokenAmount}>
                                                              {formatTokenAmount(order.maker_amount, makerDecimals)}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td className={styles.cellToken}>
                                                  <div className={styles.token}>
                                                      <div className={styles.tokenLogoContainer}>
                                                          <img
                                                              src={takerToken.imageUrl}
                                                              className={styles.tokenLogo}
                                                              alt="logo"
                                                          ></img>
                                                      </div>
                                                      <div className={styles.tokenInfo}>
                                                          <span className={styles.tokenName}> {takerToken.symbol}</span>
                                                          <span className={styles.tokenAmount}>
                                                              {formatTokenAmount(order.taker_amount, takerDecimals)}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td className={styles.cell}>
                                                  <div className={styles.orderRate}>
                                                      <span className={styles.rateWrapper}>
                                                          1 {makerToken.symbol} ={' '}
                                                          <span className={styles.rate}>
                                                              {parseFloat(
                                                                  formatTokenAmount(order.order_price, takerDecimals),
                                                              )}
                                                          </span>{' '}
                                                          {takerToken.symbol}
                                                      </span>
                                                      <span className={styles.rateWrapper}>
                                                          1 {takerToken.symbol} ={' '}
                                                          <span className={styles.rate}>
                                                              {(
                                                                  1 /
                                                                  parseFloat(
                                                                      formatTokenAmount(
                                                                          order.order_price,
                                                                          takerDecimals,
                                                                      ),
                                                                  )
                                                              ).toFixed(6)}
                                                          </span>{' '}
                                                          {makerToken.symbol}
                                                      </span>
                                                  </div>
                                              </td>
                                              <td className={styles.cell}>
                                                  <div className={styles.dateWrapper}>
                                                      <div className={styles.date}>
                                                          {convertUnixTimestampToDateAndTime(order.created_at).date}{' '}
                                                          <span className={styles.time}>
                                                              {convertUnixTimestampToDateAndTime(order.created_at).time}
                                                          </span>
                                                      </div>
                                                      <div className={styles.date}>
                                                          {convertUnixTimestampToDateAndTime(order.expiration).date}{' '}
                                                          <span className={styles.time}>
                                                              {convertUnixTimestampToDateAndTime(order.expiration).time}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td className={styles.cellStatus}>
                                                  <div className={styles.filledExpired}>
                                                      <div className={styles.filled}>Filled: {fillPercentage}%</div>
                                                  </div>
                                              </td>
                                              <td className={styles.cellEndExpired}>
                                                  <span className={styles.statusExpired}>{order.order_status}</span>
                                              </td>
                                          </tr>
                                      )
                                  })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Orders
