export enum PendingState {
    PENDING,
    SUCCESSFUL,
    FAILED,
}

export enum TXStatus {
    'NOT_RECEIVED',
    'RECEIVED',
    'ACCEPTED_ON_L2',
    'ACCEPTED_ON_L1',
    'REJECTED',
    'REVERTED',
}
