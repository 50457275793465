import ROUTERDARK from 'assets/router-dark.svg'
import { ReactComponent as RouterIcon } from 'assets/trial.svg'
import { CONFIG } from 'config/config'
import { BigNumber, utils } from 'ethers'
import { useGetTokenPrices } from 'hooks/useGetTokenPrices'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setIsProgress, setIsRouteOpen } from 'store/slicers/route'
import { WAITING } from 'types/route'
import { formatValue } from 'utils/formatValue'
import { shortenNumber } from 'utils/parseValues'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './Indicator.module.scss'

const Indicator = (): JSX.Element => {
    const dispatch = useDispatch()

    const routeResult = useSelector((state: RootState) => state.route.routeResult)
    const isRouteOpen = useSelector((state: RootState) => state.route.isRouteOpen)
    const inputToken = useSelector((state: RootState) => state.input.token)
    const inputValue = useSelector((state: RootState) => state.input.value)

    const outputToken = useSelector((state: RootState) => state.output.token)
    const outputValue = useSelector((state: RootState) => state.output.value)

    const isRouterWait = useSelector((state: RootState) => state.route.waiting)
    const { calculateTokenBalance } = useGetTokenPrices()

    const handleInputValue = (): string => {
        return setFixedNumber(utils.formatUnits(inputValue, CONFIG.tokenList[inputToken].decimals))
    }

    const handleOutputValue = (): string => {
        const value = Number(
            utils.formatUnits(BigNumber.from(outputValue), CONFIG.tokenList[outputToken].decimals),
        ).toFixed(4)
        const finalValue = value.slice(-1) === '0' ? Number(value).toFixed(4) : value

        return shortenNumber(formatValue(finalValue))
    }

    const handleInputPrice = (): string => {
        const priceValue = routeResult.inputToken.price.toString()
        if (priceValue === '0') {
            return 'Unavailable'
        }

        const price = calculateTokenBalance(priceValue, CONFIG.tokenList[inputToken], inputValue)
        const finalPrice = price.toFixed(1).slice(-1) === '0' ? price.toFixed(0) : price.toFixed(2)

        return '$' + formatValue(finalPrice)
    }

    const handleOutputPrice = (): string => {
        const priceValue = routeResult.outputToken.price.toString()
        if (priceValue === '0') {
            return 'Unavailable'
        }

        const price = calculateTokenBalance(priceValue, CONFIG.tokenList[outputToken], outputValue)
        const finalPrice = price.toFixed(1).slice(-1) === '0' ? price.toFixed(0) : price.toFixed(2)

        return '$' + formatValue(finalPrice)
    }

    const calculateSlippage = (): string => {
        const inputPriceUnavailable =
            routeResult.inputToken.price == 0 ||
            routeResult.inputToken.price == null ||
            routeResult.inputToken.price == undefined

        const outputPriceUnavailable =
            routeResult.outputToken.price == 0 ||
            routeResult.outputToken.price == null ||
            routeResult.outputToken.price == undefined

        if (BigNumber.from(inputValue).eq(0)) {
            return '0%'
        }

        if (isRouterWait === WAITING.ERROR) {
            return 'Insufficient liquidity !'
        }

        if (inputPriceUnavailable || outputPriceUnavailable) {
            return 'Unavailable'
        }

        const outputAmount =
            (inputToken === 10 && outputToken === 12) || (inputToken === 12 && outputToken === 10)
                ? Number(outputValue)
                : Number(routeResult.outputAmount)

        if (Number(inputValue) > 0) {
            return (
                (
                    (((outputAmount / 10 ** routeResult.outputToken.decimals) * routeResult.outputToken.price) /
                        ((Number(routeResult.inputAmount) / 10 ** routeResult.inputToken.decimals) *
                            routeResult.inputToken.price) -
                        1) *
                    100
                ).toFixed(2) + '%'
            )
        } else {
            return '0%'
        }
    }

    const setColor = (): { color: string } | undefined => {
        const outputAmount =
            (inputToken === 10 && outputToken === 12) || (inputToken === 12 && outputToken === 10)
                ? Number(outputValue)
                : Number(routeResult.outputAmount)

        const rate: number =
            ((outputAmount / 10 ** routeResult.outputToken.decimals) * routeResult.outputToken.price) /
            ((Number(routeResult.inputAmount) / 10 ** routeResult.inputToken.decimals) * routeResult.inputToken.price)
        if (BigNumber.from(inputValue).eq(0)) {
            return { color: '#6b7d99' }
        }
        if (isRouterWait === WAITING.ERROR) {
            return { color: '#FB923C' }
        }
        if (calculateSlippage() === 'Unavailable') {
            return { color: '#6b7d99' }
        }
        if (rate > 1) {
            return { color: '#00ADB5' }
        } else if (rate === 1) {
            return { color: 'rgba(26, 84, 171, 1)' }
        } else if (rate > 0.99) {
            return { color: '#FFB072' }
        } else if (rate > 0.97) {
            return { color: '#FF6B5A' }
        } else if (rate > 0.95) {
            return { color: '#FF5E55' }
        } else if (0.95 >= rate) {
            return { color: '#FF5050' }
        } else {
            return { color: 'rgba(26, 84, 171, 1)' }
        }
    }

    return (
        <>
            <div className={styles.indicator}>
                <div className={styles.token}>
                    <img src={CONFIG.tokenList[inputToken].imageUrl} alt="logo" className={styles.logo}></img>
                    <div className={styles.values}>
                        <div className={styles.value}>
                            {shortenNumber(formatValue(BigNumber.from(inputValue).eq(0) ? '0' : handleInputValue()))}
                        </div>
                        <div className={styles.price}>
                            {BigNumber.from(inputValue).eq(0) ? '$0' : handleInputPrice()}
                        </div>
                    </div>
                </div>
                <div className={styles.progress}>
                    {isRouteOpen ? (
                        <img
                            src={ROUTERDARK}
                            className={!isRouteOpen ? styles.colorful : styles.dark}
                            onClick={() => {
                                dispatch(setIsRouteOpen(!isRouteOpen))
                                dispatch(setIsProgress(true))
                            }}
                            alt="router"
                        ></img>
                    ) : (
                        <RouterIcon
                            onClick={() => {
                                dispatch(setIsRouteOpen(!isRouteOpen))
                                dispatch(setIsProgress(true))
                            }}
                            className={isRouterWait === WAITING.TRUE ? styles.progressIcon : styles.progressIconOn}
                        />
                    )}

                    <div className={styles.rate} style={setColor()}>
                        {calculateSlippage()}
                    </div>
                </div>
                <div className={styles.token}>
                    <img src={CONFIG.tokenList[outputToken].imageUrl} alt="logo" className={styles.logo}></img>
                    <div className={styles.values}>
                        <div className={styles.value}>
                            {!(isRouterWait === WAITING.FALSE) || BigNumber.from(inputValue).eq(0)
                                ? '0'
                                : handleOutputValue()}
                        </div>
                        <div className={styles.price}>
                            {!(isRouterWait === WAITING.FALSE) || BigNumber.from(inputValue).eq(0)
                                ? '$0'
                                : handleOutputPrice()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Indicator }
