import { ReactComponent as ExportIcon } from 'assets/export.svg'
import React from 'react'
import type { Event } from 'types/arena'

import styles from './EventDescription.module.scss'

type EventDescriptionProps = {
    currentEvent: Event
}

const EventDescription: React.FC<EventDescriptionProps> = ({ currentEvent }) => {
    return (
        <div className={styles.titleBox}>
            <div className={styles.icon} style={{ backgroundColor: currentEvent.backgroundColor }}>
                <img src={currentEvent.icon} alt="current event icon" />
            </div>
            <p className={styles.description}>
                {currentEvent.description}
                <a href="https://docs.fibrous.finance/advanced-features/fibrous-arena" target="_blank" rel="noreferrer">
                    Click here to learn more
                    <ExportIcon className={styles.exportIcon} />
                </a>
            </p>
        </div>
    )
}

export default EventDescription
