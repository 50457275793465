import { AMMS } from 'constants/amms'

export const getExcludedAMMs = (excludeAMMs: Array<boolean>): string => {
    let result = '&excludeProtocols='

    excludeAMMs.forEach((amm: boolean, i: number) => {
        if (!amm) {
            if (result === '&excludeProtocols=') {
                result += `${AMMS[i].label}`
            } else {
                result += `,${AMMS[i].label}`
            }
        }
    })
    if (result === '&excludeProtocols=') result = ''
    return result
}
