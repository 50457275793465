import { useAccount } from '@starknet-react/core'
import { CONFIG } from 'config/config'
import { ethers } from 'ethers'
import { useDispatch } from 'react-redux'
import { apiGetBalances, apiGetBalancesUpdated } from 'restapi'
import { setTokenAmount } from 'store/slicers/starknet'
import type { Balance } from 'types/balance'

export const useGetBalances = (): {
    getBalance: (_accountAddress: string, _tokenAddresses: Array<string>, _useUpdatedApi: boolean) => Promise<void>
    getBalances: (_useUpdatedApi: boolean) => Promise<void>
} => {
    const { address } = useAccount()
    const dispatch = useDispatch()

    const getBalance = async (
        accountAddress: string,
        tokenAddresses: Array<string>,
        useUpdatedApi: boolean,
    ): Promise<void> => {
        if (!address) {
            return
        }

        try {
            const response = await (useUpdatedApi
                ? apiGetBalancesUpdated(accountAddress, tokenAddresses)
                : apiGetBalances(accountAddress, tokenAddresses))
            const balances: Array<Balance> = response.data

            for (const tokenAddress of tokenAddresses) {
                const tokenData = balances.find((token: Balance) => token.token.address === tokenAddress)
                if (tokenData) {
                    const tokenIndex = CONFIG.tokenList.findIndex((token) => token.address === tokenAddress)
                    if (tokenIndex === -1) return

                    const decimals = tokenData.token.decimals
                    const balanceInSmallestUnit = ethers.utils.parseUnits(tokenData.balance, decimals)

                    dispatch(
                        setTokenAmount({
                            token: tokenIndex,
                            amount: balanceInSmallestUnit,
                        }),
                    )
                }
            }
        } catch (error) {
            console.error('Error fetching balance:', error)
        }
    }

    const getBalances = async (useUpdatedApi: boolean): Promise<void> => {
        if (!address) return
        const tokenAddresses = CONFIG.tokenList.map((token) => token.address)
        await getBalance(address, tokenAddresses, useUpdatedApi)
    }

    return {
        getBalance,
        getBalances,
    }
}
