import { useAccount, useContractWrite, useWaitForTransaction } from '@starknet-react/core'
import { CONFIG } from 'config/config'
import { STARKNET_CONTRACTS } from 'constants/addresses'
import { BigNumber } from 'ethers'
import { useGetApprove } from 'hooks/useGetApprove'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uint256 } from 'starknet'
import type { RootState } from 'store'
import { setIsSigned, setPendingPayload, setPendingStatus } from 'store/slicers/limitOrder'
import { setConfirm } from 'store/slicers/settings'
import type { InvokeTransactionReceiptResponse } from 'types/network'
import { PendingState } from 'types/pending'
import { parseInputAmountToUint256ExecuteCall } from 'utils/parseAddress'

import { getUserNonce } from './limitOrderUtils/hooks/getUserNonce'
import { addOrder } from './limitOrderUtils/hooks/placeOrder'
import { signMessage } from './limitOrderUtils/signMessage'

export const useSigning = (): {
    signing: () => Promise<void>
} => {
    const { account, address } = useAccount()
    const dispatch = useDispatch()
    const { getApprove } = useGetApprove()

    const [hash, setHash] = useState<string>('')

    const inputToken = useSelector((state: RootState) => state.limitOrder.inputTokens)
    const outputToken = useSelector((state: RootState) => state.limitOrder.outputToken)
    const inputValue = useSelector((state: RootState) => state.limitOrder.inputValues)
    const outputValue = useSelector((state: RootState) => state.limitOrder.outputValue)
    const rate = useSelector((state: RootState) => state.limitOrder.rate)
    const expiration = useSelector((state: RootState) => state.limitOrder.expiryDate)
    const isPartialFill = useSelector((state: RootState) => state.limitOrder.isPartialFill)
    const isUseSolver = useSelector((state: RootState) => state.limitOrder.isUseSolver)
    const isSpendMax = useSelector((state: RootState) => state.limitOrder.isSpendMax)
    const costumSpendAmount = useSelector((state: RootState) => state.limitOrder.costumSpendAmount)
    // const pendingStatus = useSelector((state: RootState) => state.pending.status)
    const isApprove = useSelector((state: RootState) => state.limitOrder.isApprove)
    const isSigned = useSelector((state: RootState) => state.limitOrder.isSigned)

    const approveToken = {
        contractAddress: CONFIG.tokenList[inputToken].address,
        entrypoint: 'approve',
        calldata: [
            STARKNET_CONTRACTS.LIMIT_ORDER_ADDRESS,
            ...parseInputAmountToUint256ExecuteCall(
                address && inputValue
                    ? BigNumber.from(isSpendMax ? costumSpendAmount.toString() : inputValue.toString()).toString()
                    : '0',
                0,
            ),
        ],
    }

    const calls: Array<any> = [approveToken]
    const { writeAsync } = useContractWrite({ calls })

    const { data } = useWaitForTransaction({
        hash,
        watch: true,
        retry: true,
    })

    const processOrder = async (): Promise<void> => {
        const nonce = await getUserNonce(address as string)

        const orderStruct = {
            signer: address,
            makerAsset: CONFIG.tokenList[inputToken].address,
            takerAsset: CONFIG.tokenList[outputToken].address,
            makerAmount: uint256.bnToUint256(inputValue.toString()),
            takerAmount: uint256.bnToUint256(outputValue.toString()),
            orderPrice: uint256.bnToUint256(rate.toString()),
            useSolver: isUseSolver,
            partialFill: isPartialFill,
            expiration: expiration,
            nonce: nonce,
        }

        const typedValidateData = await signMessage(
            address as string,
            (await account?.getChainId()) as string,
            orderStruct,
        )

        account
            ?.signMessage(typedValidateData.typedDataValidate)
            .then(async (signature) => {
                await addOrder(signature, typedValidateData.typedDataHash, orderStruct)
                dispatch(setConfirm(false))
                dispatch(setIsSigned(true))
                dispatch(setPendingStatus(PendingState.SUCCESSFUL))
            })
            .catch(() => {
                dispatch(setConfirm(false))
                dispatch(setIsSigned(false))
                dispatch(setPendingStatus(PendingState.FAILED))
            })
    }

    useEffect(() => {
        getApprove()
    }, [inputToken, inputValue, isSpendMax, costumSpendAmount, address, setConfirm])

    useEffect(() => {
        // if ((data as InvokeTransactionReceiptResponse)?.finality_status === 'ACCEPTED_ON_L2' && !isSigned) {
        //     processOrder()
        // }
        if ((data as InvokeTransactionReceiptResponse)?.finality_status === 'ACCEPTED_ON_L2' && isSigned) {
            dispatch(setPendingStatus(PendingState.SUCCESSFUL))
        } else if ((data as InvokeTransactionReceiptResponse)?.execution_status === 'REVERTED') {
            dispatch(setPendingStatus(PendingState.FAILED))
        }
    }, [data])

    const signing = async (): Promise<void> => {
        // const order = {
        //     maker_asset: '0x03e85bfbb8e2a42b7bead9e88e9a1b19dbccf661471061807292120462396ec9',
        //     taker_asset: '0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7',
        //     maker_amount: '100000000000000000',
        //     taker_amount: '100000000000000000',
        //     order_price: '123',
        //     expiration: '1707098659',
        //     nonce: '',
        // }

        if (!account || !address) return

        if (isApprove) {
            processOrder()
        } else {
            const txDetails = await writeAsync()
                .then(async (tx: any) => {
                    setHash(tx.transaction_hash)
                    dispatch(setPendingPayload(tx.transaction_hash))
                    dispatch(setConfirm(false))
                    dispatch(setIsSigned(false))
                    dispatch(setPendingStatus(PendingState.PENDING))
                })
                .catch(() => {
                    dispatch(setConfirm(false))
                    dispatch(setPendingStatus(PendingState.FAILED))
                })
        }
    }

    return { signing }
}
