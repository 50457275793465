export interface ITokenInterface {
    address: string
    name: string
    symbol: string
    decimals: string
    price?: string
    imageUrl?: string
    valuable: boolean
    verified: boolean
    category: string
}

export class Token implements ITokenInterface {
    address: string
    name: string
    symbol: string
    decimals: string
    price?: string
    imageUrl?: string
    valuable: boolean
    verified: boolean
    category: string

    constructor({ address, name, symbol, decimals, price, imageUrl, valuable, verified, category }: ITokenInterface) {
        this.address = address
        this.name = name
        this.symbol = symbol
        this.decimals = decimals
        this.price = price
        this.imageUrl = imageUrl
        this.valuable = valuable
        this.verified = verified
        this.category = category
    }
}
