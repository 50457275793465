import { useDispatch } from 'react-redux'
import { apiGetTokenPrices } from 'restapi'
import { setTokens } from 'store/slicers/settings'
import type { ApiToken } from 'types/tokenPrice'

export const useGetTokens = (): { getTokens: () => Promise<Array<ApiToken>> } => {
    const dispatch = useDispatch()

    const getTokens = async (): Promise<Array<ApiToken>> => {
        const response = await apiGetTokenPrices()
        const data: Array<ApiToken> = response.data
        dispatch(setTokens(data))
        return data
    }

    return {
        getTokens,
    }
}
