import OFF from 'assets/rates-off.svg'
import ON from 'assets/rates-on.svg'
import { CONFIG } from 'config/config'
import { AMMS } from 'constants/amms'
import { ethers } from 'ethers'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import { WAITING } from 'types/route'
import type { RouterResponse } from 'types/route'
import { checkArraysEqual } from 'utils/checkArraysEqual'
import { clsnm } from 'utils/clsnm'
import { formatValue } from 'utils/formatValue'
import { setFixedNumber } from 'utils/setFixedNumber'

import styles from './Rates.module.scss'

interface RatesProp {
    rates: boolean
    setRates: (rates: (rate: boolean) => boolean) => void
}

const Rates = ({ rates, setRates }: RatesProp): JSX.Element => {
    const [textWidth, setTextWidth] = useState<number>(0)
    const routeResult = useSelector((state: RootState) => state.route.routeResult)
    const isRouterWaiting = useSelector((state: RootState) => state.route.waiting)
    const inputValue = useSelector((state: RootState) => state.input.value)
    const isRouteOpen = useSelector((state: RootState) => state.route.isRouteOpen)
    const outputToken = useSelector((state: RootState) => state.output.token)
    const liquiditySources = useSelector((state: RootState) => state.settings.liquiditySources)

    const preloaded = useRef(false)
    const textRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (isRouteOpen) {
            if (!rates) {
                setRates((rate) => !rate)
            }
        }
    }, [isRouteOpen])

    useEffect(() => {
        if (isRouterWaiting === WAITING.PENDING) {
            setTextWidth(0)
        } else if (textRef.current) {
            setTextWidth(textRef.current.getBoundingClientRect().width)
        } else {
            setTextWidth(0)
        }
    }, [rates, inputValue, routeResult, outputToken, isRouterWaiting])
    return (
        <div
            onClick={(): void => {
                setRates((rate) => !rate)
                if (!preloaded.current) {
                    preloaded.current = true
                }
            }}
            className={
                rates
                    ? clsnm(styles.wrapper, styles.opening)
                    : clsnm(styles.wrapper, preloaded.current && styles.closing)
            }
            style={{
                width: `${rates && textRef.current ? 32 + textWidth : 32}px`,
            }}
        >
            <img src={rates ? ON : OFF} alt="logo"></img>
            {/* <DefaultRates /> */}

            <div className={styles.infos} ref={textRef}>
                <div className={styles.best}>
                    Fibrous:{' '}
                    {!(isRouterWaiting === WAITING.FALSE) || Number(inputValue) <= 0
                        ? '0'
                        : formatValue(
                              setFixedNumber(
                                  ethers.utils.formatUnits(
                                      routeResult.outputAmount,
                                      CONFIG.tokenList[outputToken].decimals,
                                  ),
                                  CONFIG.tokenList[outputToken].valuable ? 4 : 2,
                              ),
                          )}{' '}
                    {CONFIG.tokenList[outputToken].symbol}
                </div>
                <div className={styles.line}>l</div>
                <div className={styles.medium}>{setResult(0, routeResult, outputToken)}</div>
                {liquiditySources.filter((value) => value).length > 1 && (
                    <>
                        <div className={styles.line}>l</div>
                        <div className={styles.worst}>{setResult(1, routeResult, outputToken)} </div>
                    </>
                )}
            </div>
        </div>
    )
}

const orderAMMs = (values: Array<string>): Array<number> => {
    if (checkArraysEqual(values, new Array(4).fill('0'))) {
        return [1, 2]
    }

    const indexedValues = values.map((value, index) => ({
        value: Number(value),
        index,
    }))

    indexedValues.sort((a, b) => b.value - a.value)

    return [indexedValues[0].index, indexedValues[1].index]
}

const setResult = (index: number, routeResult: RouterResponse, outputToken: number): string => {
    const isRouterWaiting = useSelector((state: RootState) => state.route.waiting)
    const inputValue = useSelector((state: RootState) => state.input.value)

    const orderedIndices = orderAMMs(routeResult.bestQuotesByProtocols)
    const currentIndex = orderedIndices[index]
    const amount =
        isRouterWaiting !== WAITING.FALSE || Number(inputValue) <= 0
            ? '0'
            : setFixedNumber(
                  ethers.utils.formatUnits(
                      routeResult.bestQuotesByProtocols[currentIndex],
                      CONFIG.tokenList[outputToken].decimals,
                  ),
                  CONFIG.tokenList[outputToken].valuable ? 4 : 2,
              )

    return `${AMMS[currentIndex].name}: ${formatValue(amount)} ${CONFIG.tokenList[outputToken].symbol}`
}

export { Rates }
