import { useAccount, useContractWrite } from '@starknet-react/core'
import type BN__default from 'bn.js'
import { CONFIG } from 'config/config'
import { STARKNET_CONTRACTS } from 'constants/addresses'
import { BigNumber, utils } from 'ethers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setBatchAllInputValues } from 'store/slicers/batch'
import { setBatchPendingPayload, setBatchPendingStatus, setBatchPendingsData } from 'store/slicers/batchPending'
import { setBatchConfirm, setHistoryData } from 'store/slicers/settings'
import { PendingState } from 'types/pending'
import type { RouterResponse } from 'types/route'
import { addressToId } from 'utils/addressToId'
import { formatRouterCall } from 'utils/fmtRouterCallV3'
import { parseInputAmountToUint256ExecuteCall } from 'utils/parseAddress'
import { setBatchHistory } from 'utils/setHistory'

type BigNumberish = string | number | BN__default

export const useBatchSwap = (): {
    batchSwap: () => Promise<void>
} => {
    const dispatch = useDispatch()
    const { address, account } = useAccount()
    const slippage = useSelector((state: RootState) => state.settings.slippage)

    const approves = useSelector((state: RootState) => state.settings.approves)
    const batchInputToken = useSelector((state: RootState) => state.batch.inputTokens)
    const batchInputValue = useSelector((state: RootState) => state.batch.inputValues)

    const batchOutputToken = useSelector((state: RootState) => state.batch.outputToken)
    const batchOutputValue = useSelector((state: RootState) => state.batch.outputValue)
    const batchRouterResponses = useSelector((state: RootState) => state.batch.routerResponses)
    const tokenAmounts = useSelector((state: RootState) => state.starknet.tokenAmounts)
    const batchPendingStatus = useSelector((state: RootState) => state.batchPending.status)
    const fee = useSelector((state: RootState) => state.settings.fee)

    const [fmtSlippage, setfmtSlippage] = useState<number>(3)
    const [fmtFee, setfmtFee] = useState<number>(0)

    useEffect(() => {
        setfmtSlippage(Number(slippage) / 100)
        if (slippage === '') {
            setfmtSlippage(0.01)
        }
    }, [slippage])

    useEffect(() => {
        setfmtFee(fee / 100)
    }, [fee])

    const createApproveToken = (i: number) => {
        return {
            contractAddress: CONFIG.tokenList[batchInputToken[i]].address,
            entrypoint: 'approve',
            calldata: [
                STARKNET_CONTRACTS.ROUTER_ADDRESS,
                ...parseInputAmountToUint256ExecuteCall(
                    batchInputValue[i]._hex ? batchInputValue[i].toString() : '0',
                    0,
                ),
            ],
        }
    }

    const createBatchSwap = (i: number) => {
        if (!address) return { contractAddress: '', entrypoint: '', calldata: [] }
        const setInput: Array<BigNumberish> = formatRouterCall(batchRouterResponses[i], fmtSlippage, address, fmtFee)
        return {
            contractAddress: STARKNET_CONTRACTS.ROUTER_ADDRESS,
            entrypoint: 'swap',
            calldata: setInput,
        }
    }

    const calls: Array<any> = []
    batchRouterResponses.map((route: RouterResponse, i: number) => {
        if (i + 1 > batchInputToken.length) return null
        if (route.success) {
            const approve = createApproveToken(i)
            const swap = createBatchSwap(i)
            if (!approves[addressToId(route.inputToken.address, CONFIG.tokenList)]) {
                calls.push(approve)
            }
            calls.push(swap)
        }
        return null
    })
    const { writeAsync } = useContractWrite({ calls })

    const batchSwap = async (): Promise<void> => {
        if (batchInputValue.length !== batchRouterResponses.length || !address || !account || calls.length === 0) {
            return
        }

        dispatch(
            setBatchPendingsData({
                batchInputToken: batchInputToken,
                batchInputValue: batchInputValue.map((inputValue, i) =>
                    utils.formatUnits(inputValue, CONFIG.tokenList[batchInputToken[i]].decimals),
                ),
                batchOutputToken: batchOutputToken,
                batchOutputValue: utils.formatUnits(
                    tokenAmounts[batchOutputToken],
                    CONFIG.tokenList[batchOutputToken].decimals,
                ),
            }),
        )

        const txDetails: any = await writeAsync()
            .then((data: any) => {
                dispatch(setBatchPendingPayload(data.transaction_hash))
                dispatch(setBatchAllInputValues(new Array(batchInputValue.length).fill(BigNumber.from(0))))
                dispatch(setBatchConfirm(false))
                const history: string | null = localStorage.getItem(`${address}-history`)
                if (history === null) {
                    return
                } else {
                    const historyData = JSON.parse(history)
                    if (historyData.length > 4) {
                        historyData.shift()
                    }
                    dispatch(
                        setHistoryData(
                            JSON.stringify([
                                ...historyData,
                                setBatchHistory(
                                    {
                                        batchInputToken: batchInputToken,
                                        batchInputValue: batchInputValue,
                                        batchOutputToken: batchOutputToken,
                                        batchOutputValue: utils.formatUnits(
                                            batchOutputValue,
                                            CONFIG.tokenList[batchOutputToken].decimals,
                                        ),
                                    },
                                    data.transaction_hash,
                                    '',
                                ),
                            ]),
                        ),
                    )
                }
            })

            .catch((err: any) => {
                dispatch(setBatchPendingPayload(`Transaction Failed`))
                dispatch(setBatchPendingStatus(PendingState.FAILED))
                dispatch(setBatchConfirm(false))
            })
        if (txDetails) {
            // dispatch(
            //   setBatchAllInputValues(new Array(batchInputValue.length).fill(BigNumber.from(0)))
            // );
        } else {
            if (batchPendingStatus === PendingState.FAILED) return
        }
    }

    return {
        batchSwap,
    }
}
